import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { GridPageChangeParams } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import SearchField from 'components/SearchField';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import {
  ROUTE_PARAM_IDENTIFIER as EditPageParamIdentifier,
  ROUTE_PATH as EditPageRoute
} from 'modules/client-admin/EditClientPage/constants';
import * as childConstants from 'modules/client-admin/NewClientPage/constants';
import {
  ClientDto,
  useClientsQuery,
  useDeleteClientMutation
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { replace } from 'ramda';
import React from 'react';
import {
  Link,
  useHistory
} from 'react-router-dom';
import ClientsTable from './ClientsTable';

const NewClientButton = () => (
  <>
    <Hidden xsDown>
      <Button
        aria-label="add new client"
        variant="contained"
        color="primary"
        size="large"
        startIcon={<AddIcon />}
        component={Link}
        to={childConstants.ROUTE_PATH}
      >
        New Client
      </Button>
    </Hidden>
    <Hidden smUp>
      <IconButton
        aria-label="add new client"
        color="primary"
        component={Link}
        to={childConstants.ROUTE_PATH}
      >
        <AddIcon />
      </IconButton>
    </Hidden>
  </>
);

const ClientsPage = () => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION_DEFAULT_PAGE_SIZE);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const history = useHistory();
  const confirm = useConfirmation();
  const mutation = useDeleteClientMutation();
  const {
    data,
    isFetching,
    isFetched,
    isPreviousData,
  } = useClientsQuery({ page, pageSize, searchTerm });

  const handlePageChange = (change: GridPageChangeParams) => {
    setPage(change.page + 1);
  };

  const handlePageSizeChange = (change: GridPageChangeParams) => {
    setPage(1);
    setPageSize(change.pageSize);
  };

  const handleSearch = (text: string | null) => {
    setPage(1);
    setSearchTerm(text);
  };

  const handleEdit = (clientId: string) => {
    history.push(replace(EditPageParamIdentifier, clientId, EditPageRoute));
  };

  const handleDelete = (client: ClientDto) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to remove this client?',
    }).then(() => mutation.mutate(client));
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box maxWidth={600} flex={1} mr={2}>
          <SearchField onSearch={handleSearch} />
        </Box>
        <NewClientButton />
      </Box>
      <Box mt={3} component={Paper}>
        <ClientsTable
          data={data?.items}
          page={page}
          pageSize={pageSize}
          totalItems={data?.pager.totalItems}
          loading={isFetching || mutation.isLoading}
          loadingNew={isFetching && (!isFetched || isPreviousData)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onEdit={handleEdit}
          onDelete={handleDelete}
          searchTerm={searchTerm}
        />
      </Box>
    </Container>
  );
};

export default ClientsPage;
