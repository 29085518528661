import Point from '../Diagra/Point';
import { VectorJSON } from '../Diagra/types';
import * as GConstants from '../GConstants';
import * as PCConstants from './PCConstants';

// The node will simply have a 'previous' parameter
// which the point does not have

export default class PCNode extends Point {
  pipeId: string;

  previousId?: string;

  previousNode?: PCNode;

  isStope: boolean;

  isRequired: boolean = false;

  chokeLength = 0;

  constructor(
    vector: VectorJSON,
    id: string,
    pipeId: string,
    chokeLength?: number,
    previousId?: string,
    isStope?: boolean,
    previousNode?: PCNode,
  ) {
    super(vector, id);
    this.pipeId = pipeId; // pipe type as index, not id
    this.previousId = previousId;
    this.previousNode = previousNode;
    this.chokeLength = chokeLength || 0;
    this.isStope = isStope || false;
  }

  // used to set up pressure array for route
  // pressureChange(node: PCNode, pipes: Pipe[]) { // pipes is pipe types
  //   const i = this.pipeId;
  //   // eslint-disable-next-line no-underscore-dangle
  //   return (this.length(node) + node.choke) * pipes[i].friction;
  // }
  // screenVector() {
  //   return this.screenVector;
  // }

  renderNode(ctx: CanvasRenderingContext2D, colour?: string | CanvasGradient | CanvasPattern) {
    if (this.isStope) {
      this.drawStope(ctx);
    }
    if (this.chokeLength > 0) {
      this.drawChoke(ctx);
    }
    if (this.hitBool || this.selected) {
      this.render(ctx);
      // this.drawPipe(ctx, this.colour); // Pipe drawn by spool
    } else if (colour) {
      this.drawPoint(ctx, colour);
      // this.drawPipe(ctx, colour);
    } else this.drawPoint(ctx, this.colour);
    if (this.isRequired && (!this.hitBool || this.selected)) {
      this.drawRequired(ctx);
    }
  }

  drawStope(ctx: CanvasRenderingContext2D) {
    if (!(this.selected || this.hitBool)) {
      ctx.fillStyle = PCConstants.STOPE_COLOUR;
      ctx.beginPath();
      ctx.arc(this.screenVector.x, this.screenVector.y, PCConstants.STOPE_RADIUS, 0, Math.PI * 2, false);
      ctx.closePath();
      ctx.fill();
    } else {
      ctx.strokeStyle = '#fff';
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.arc(this.screenVector.x, this.screenVector.y, PCConstants.STOPE_RADIUS, 0, Math.PI * 2, false);
      ctx.closePath();
      ctx.stroke();
      ctx.fillStyle = GConstants.DARK_GREY;
    }
  }

  drawChoke(ctx: CanvasRenderingContext2D) {
    ctx.beginPath();
    ctx.arc(this.screenVector.x, this.screenVector.y, PCConstants.NODE_RADIUS + 2, 0, Math.PI * 2, false);
    ctx.closePath();
    ctx.stroke();
  }

  drawPoint(ctx: CanvasRenderingContext2D, colour: string | CanvasGradient | CanvasPattern) {
    this.draw(ctx, colour, this.radius);
  }

  drawPipe(ctx: CanvasRenderingContext2D, colour: string | CanvasGradient | CanvasPattern) {
    if (this.previousNode) {
      ctx.fillStyle = colour;
      ctx.strokeStyle = colour;
      ctx.lineCap = 'round';
      ctx.lineWidth = PCConstants.SPOOL_LINE_WIDTH;
      ctx.beginPath();
      ctx.moveTo(this.screenVector.x, this.screenVector.y);
      ctx.lineTo(this.previousNode.screenVector.x, this.previousNode.screenVector.y);
      ctx.closePath();
      ctx.stroke();
    }
  }

  drawRequired(ctx: CanvasRenderingContext2D) {
    ctx.strokeStyle = '#222';
    ctx.lineWidth = 1;
    ctx.fillStyle = '#fff';
    ctx.beginPath();
    ctx.arc(this.screenVector.x, this.screenVector.y, 5, 0, Math.PI * 2, false);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }
}
