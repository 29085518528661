import MailIcon from '@material-ui/icons/Mail';
import PeopleIcon from '@material-ui/icons/People';
import makePage from 'modules/makePage';
import Page from 'modules/Page';
import withNavigation from '../../withNavigation';
import * as constants from './constants';
import InvitationsPage from './InvitationsPage';
import UsersPage from './UsersPage';

const page: Page = {
  title: constants.TITLE,
  mainMenuConfig: {
    group: 'Admin',
    iconComponent: PeopleIcon,
  },
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: false,
  },
  admin: true,
  component: withNavigation({
    Component: makePage({
      titleFn: () => constants.TITLE,
    }),
    defaultRoute: constants.USER_ACCOUNTS_ROUTE_PATH,
    pageNavigation: [
      {
        title: constants.USER_ACCOUNTS_TITLE,
        iconComponent: PeopleIcon,
        routeProps: {
          path: constants.USER_ACCOUNTS_ROUTE_PATH,
          exact: true,
        },
        Component: UsersPage,
      },
      {
        title: constants.INVITATION_TITLE,
        iconComponent: MailIcon,
        routeProps: {
          path: constants.INVITATION_ROUTE_PATH,
          exact: true,

        },
        Component: InvitationsPage,
      },
    ],
  }),
};

export default page;
