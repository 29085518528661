import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import AlertText from 'components/AlertText';
import { MineModelDto, StopeBackfillSummaryDto, StopeLocationDto } from 'providers/api';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { compareAlphaNum } from 'utils';
import StopeListItem, { StopeProfile } from './StopeListItem';

const SEARCH_BOX_CLOSED_HEIGHT = 100;
const SEARCH_BOX_EXPANDED_HEIGHT = 230;

interface StopeListFilters {
  filterStopeName: string;
  filterLevels: string[];
  filterAreas: string[];
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  list: {
    background: theme.palette.secondary.light,
    borderRadius: theme.spacing(1),
    '& .MuiDivider-root:last-child': {
      display: 'none',
    },
    height: `calc(100% - ${SEARCH_BOX_CLOSED_HEIGHT}px)`,
  },
  listCompressed: {
    height: `calc(100% - ${SEARCH_BOX_EXPANDED_HEIGHT}px)`,
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
}));
interface StopeListProps {
  mineModel: MineModelDto;
  mineOverview: StopeBackfillSummaryDto[];
  onSelect: (stopeLocation: StopeLocationDto) => void;
  onHover: (stopeLocation?: StopeLocationDto) => void;
}

const StopeList = ({ mineModel, mineOverview, onSelect, onHover }: StopeListProps) => {
  const [searchExpanded, setSearchExpanded] = React.useState(false);
  const [stopeListFilters, setStopeListFilters] = React.useState<StopeListFilters>({
    filterStopeName: '',
    filterLevels: [],
    filterAreas: [],
  });
  const classes = useStyles();
  const { stopeLocations } = mineModel.reticulationData;

  if (stopeLocations.length <= 0) {
    return <AlertText severity="info">No stopes have been added to the mine model.</AlertText>;
  }

  // package the info for each stope from three different DTOs into a more manageable single array
  const stopeProfiles: StopeProfile[] = React.useMemo(() => stopeLocations.map((stopeLocation) => {
    const stopeData = mineModel.reticulationData.stopes.find((data) => data.stopeId === stopeLocation.stopeId);
    const stopeSummary = mineOverview.find((backfill) => backfill.stopeIdentifier.stopeId === stopeLocation.stopeId);

    return {
      ...stopeLocation,

      stopeIdentifier: stopeSummary?.stopeIdentifier ?? null,
      totalCount: stopeSummary?.totalCount ?? null,
      plannedCount: stopeSummary?.plannedCount ?? null,
      completedCount: stopeSummary?.completedCount ?? null,
      plannedVolume: stopeSummary?.plannedVolume ?? null,
      completedVolume: stopeSummary?.completedVolume ?? null,
      nextPourId: stopeSummary?.nextPourId ?? null,
      nextPourDate: stopeSummary?.nextPourDate ?? null,
      isComplete: stopeSummary?.isComplete ?? null,

      stopeName: stopeData?.stopeName ?? null,
      references: stopeData?.references ?? null,
      pipeLength: stopeData?.pipeLength ?? 0,
      pipeVerticalLength: stopeData?.pipeVerticalLength,
      pipeHorizontalLength: stopeData?.pipeHorizontalLength ?? 0,
      pipeId: stopeData?.pipeId ?? null,
      volume: stopeData?.volume ?? null,
      physicalPropertiesUpdated: stopeData?.physicalPropertiesUpdated ?? null,
      routeUpdated: stopeData?.routeUpdated ?? null,
      level: stopeData?.level ?? null,
      area: stopeData?.area ?? null,
    };
  }), [stopeLocations]);

  // INFO: he is up to no good with the reduce again! In my defense there could be many, many, many of these sneaky stope profiles
  const aggregatedLevelsAndAreas = React.useMemo(
    () => stopeProfiles.reduce(
      (aggregatedOptions: { levelOptions: string[], areaOptions: string[] }, stope: StopeProfile) => ({
        levelOptions: [
          ...aggregatedOptions.levelOptions,
          ...(stope.level && !aggregatedOptions.levelOptions.includes(stope.level) ? [stope.level] : []),
        ],
        areaOptions: [
          ...aggregatedOptions.areaOptions,
          ...(stope.area && !aggregatedOptions.areaOptions.includes(stope.area) ? [stope.area] : []),
        ],
      }),
      { levelOptions: [], areaOptions: [] },
    ),
    [stopeProfiles],
  );

  const availableStopes = stopeProfiles.filter((stope) => [
    (
      (stopeListFilters.filterStopeName === '' || stope.stopeName?.includes(stopeListFilters.filterStopeName)) ?? false
    ),
    (stopeListFilters.filterLevels.length === 0 || stopeListFilters.filterLevels.includes(stope.level ?? '')),
    (stopeListFilters.filterAreas.length === 0 || stopeListFilters.filterAreas.includes(stope.area ?? '')),
  ].every((result) => result === true));

  availableStopes.sort((a, b) => compareAlphaNum(a.stopeName ?? '', b.stopeName ?? ''));

  return (
    <>
      <Box mb={2}>
        <Accordion
          expanded={searchExpanded}
        >
          <AccordionSummary
            expandIcon={(
              <IconButton onClick={() => setSearchExpanded((currentState) => !currentState)}>
                <ExpandMoreIcon />
              </IconButton>
            )}
            aria-controls="advanced search"
            id="advanced-search"
          >
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              value={stopeListFilters.filterStopeName}
              onChange={(event) => setStopeListFilters((existingFilters) => ({ ...existingFilters, filterStopeName: event.target.value }))}
              onFocus={(event) => event.stopPropagation()}
              fullWidth
              size="small"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Autocomplete
                  id="level-filter-input"
                  options={['', ...aggregatedLevelsAndAreas.levelOptions]}
                  autoHighlight
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter by level"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  onChange={(event, newValue) => setStopeListFilters((existingFilters) => ({ ...existingFilters, filterLevels: newValue }))}
                />
              </Grid>
              <Grid item sm={12}>
                <Autocomplete
                  id="area-filter-input"
                  options={['', ...aggregatedLevelsAndAreas.areaOptions]}
                  autoHighlight
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter by area"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  onChange={(event, newValue) => setStopeListFilters((existingFilters) => ({ ...existingFilters, filterAreas: newValue }))}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <List className={clsx(classes.list, { [classes.listCompressed]: searchExpanded })} dense>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className="List"
              height={height}
              itemCount={availableStopes.length}
              itemSize={120}
              width={width}
            >
              {({ index, style }) => (
                <div style={style}>
                  <StopeListItem
                    onSelect={onSelect}
                    onHover={onHover}
                    {...availableStopes[index]}
                  />
                </div>
              )}
            </FixedSizeList>
          )}
        </AutoSizer>
      </List>
    </>
  );
};

export default StopeList;
