import BadgeRow from 'components/BadgeRow';
import DataTransactionBox from 'components/DataTransactionBox';
import {
  MineModelDto,
  ProjectDto,
  StopeDataDto,
  StopeDataForm,
  StopeLocationDto,
  StopesMutation,
  UpdateStopeDataForm,
  useUpdateStopeMutation
} from 'providers/api';
import { omit } from 'ramda';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { useParams } from 'react-router';
import { Weaken } from 'utils';
import { ProjectAreaPageParams } from '../constants';

interface StopeDataCsv extends Weaken<Omit<StopeDataDto, 'routeUpdated' | 'physicalPropertiesUpdated'>> { }

const buildStopeDataCsv = (stopeLocations: StopeLocationDto[], stopes: StopeDataDto[]): StopeDataCsv[] | undefined => (
  stopeLocations.length > 0
    ? stopeLocations.map((sl) => {
      const stopeData = stopes
        .find((sd) => sd.stopeId === sl.stopeId);

      let csvData: StopeDataCsv | undefined = stopeData;

      if (csvData) {
        csvData = omit(['routeUpdated', 'physicalPropertiesUpdated', 'stopeType'], csvData);
      }

      const data: StopeDataCsv = csvData ?? {
        stopeId: sl.stopeId,
        stopeName: sl.nodeId,
      };

      return data;
    })
    : undefined
);

const buildUpdateStopeDataFormList = (stopeLocations: StopeLocationDto[], data: StopeDataCsv[]): UpdateStopeDataForm[] => data.map(
  (newStopeData) => {
    const stopeLocation = stopeLocations.find((sl) => sl.stopeId === newStopeData.stopeId);
    return {
      nodeId: stopeLocation ? stopeLocation.nodeId : '',
      stopeDataForm: newStopeData as StopeDataForm,
    };
  },
);

interface StopeTransactionBoxProps {
  project: ProjectDto;
  mineModel: MineModelDto;
  mineModelQuery: UseQueryResult<MineModelDto | undefined, unknown>;
}

const StopeTransactionBox = ({ project, mineModel, mineModelQuery }: StopeTransactionBoxProps) => {
  const { entityId } = useParams<ProjectAreaPageParams>();
  const [isSubmittingStopes, setIsSubmittingStopes] = React.useState(false);
  const [stopesErrors, setStopesErrors] = React.useState<string[]>([]);
  const updateMutation = useUpdateStopeMutation(entityId);

  const saveStopesData = (data: StopeDataCsv[]) => {
    // Validate data
    if (project) {
      const stopesMutations: StopesMutation = {
        projectName: project.name,
        data: buildUpdateStopeDataFormList(mineModel.reticulationData.stopeLocations, data),
      };

      setIsSubmittingStopes(true);
      updateMutation.mutate(stopesMutations, {
        onSuccess: () => {
          setIsSubmittingStopes(false);
          mineModelQuery.refetch();
        },
        onError: () => {
          setStopesErrors((existingErrors) => [...existingErrors, 'There was an issue uploading the stope data to the server.']);
          setIsSubmittingStopes(false);
        },
      });
    }
  };

  return (
    <DataTransactionBox
      title="Stope Data"
      errors={stopesErrors}
      fileName={`${project.name}-stope-data`}
      fileFormat="csv"
      fileData={buildStopeDataCsv(mineModel.reticulationData.stopeLocations, mineModel.reticulationData.stopes)}
      disabled={isSubmittingStopes || mineModel.reticulationData.stopeLocations.length <= 0}
      handleFileSelect={(data) => {
        setStopesErrors([]);
        saveStopesData(data);
      }}
      csvColParser={{
        stopeId: 'string',
        pipeId: 'string',
        stopeType: 'string',
        pipeLength: 'number',
        volume: 'number',
        area: 'string',
        level: 'string',
      }}
    >
      <BadgeRow badgeList={[
        { text: 'Stope Total', total: mineModel.reticulationData.stopeLocations.length ?? 0 },
        { text: 'Stope Data Total', total: mineModel.reticulationData.stopes.length ?? 0 },
      ]}
      />
    </DataTransactionBox>
  );
};

export default StopeTransactionBox;
