import { BasicDisplayUnits, additionalUnitLabels, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import { DisplayUnits } from 'providers/api';
import { round } from 'utils';
import {
  densityMetricToImperial,
  distanceMetricToImperial,
  flowRateMetricToImperial,
  pressureMetricToImperial,
  pumpPressureMetricToImperial,
  speedMetricToImperial,
  tonnageMetricToImperial
} from 'utils/unitConversions';

export function convertPressure(pressureKpa: number, imperial: boolean) {
  let convertedPressure = pressureKpa;
  let units = unitLabels[BasicDisplayUnits.PressureUCS].metric.abbreviation;
  if (imperial) {
    convertedPressure = pressureMetricToImperial(convertedPressure);
    units = unitLabels[BasicDisplayUnits.PressureUCS].imperial.abbreviation;
  }
  convertedPressure = round(convertedPressure, 0);
  if (Number.isNaN(convertedPressure)) return `- ${units}`;
  return `${convertedPressure} ${units}`;
}

export function convertPumpPressure(pressureBar: number, imperial: boolean) {
  let convertedPressure = pressureBar;
  let units = unitLabels[BasicDisplayUnits.PressurePump].metric.abbreviation;
  if (imperial) {
    convertedPressure = pumpPressureMetricToImperial(convertedPressure);
    units = unitLabels[BasicDisplayUnits.PressurePump].imperial.abbreviation;
  }
  convertedPressure = round(convertedPressure, 1);
  if (Number.isNaN(convertedPressure)) return `- ${units}`;
  return `${convertedPressure} ${units}`;
}

export function convertDistance(_d: number, imperial: boolean) {
  let d = _d;
  let units = unitLabels[BasicDisplayUnits.Distance].metric.abbreviation;
  if (imperial) {
    d = distanceMetricToImperial(d);
    units = unitLabels[BasicDisplayUnits.Distance].imperial.abbreviation;
  }
  d = round(d, 0);
  if (Number.isNaN(d)) return `- ${units}`;
  return `${d} ${units}`;
}
export function convertSpeed(_s: number, imperial: boolean) {
  let s = _s;
  let units = unitLabels[BasicDisplayUnits.Speed].metric.abbreviation;
  if (imperial) {
    s = speedMetricToImperial(s);
    units = unitLabels[BasicDisplayUnits.Speed].imperial.abbreviation;
  }
  s = round(s, 1);
  if (Number.isNaN(s)) return `- ${units}`;
  return `${s} ${units}`;
}
export function convertVolume(_v: number, imperial: boolean) {
  let v = _v;
  let units = unitLabels[BasicDisplayUnits.Volume].metric.abbreviation;
  if (imperial) {
    v = speedMetricToImperial(v);
    units = unitLabels[BasicDisplayUnits.Volume].imperial.abbreviation;
  }
  v = round(v, 0);
  if (Number.isNaN(v)) return `- ${units}`;
  return `${v} ${units}`;
}

export function convertDensity(_d: number, imperial: boolean) {
  let d = _d;
  let units = unitLabels[BasicDisplayUnits.Density].metric.abbreviation;
  if (imperial) {
    d = densityMetricToImperial(d);
    units = unitLabels[BasicDisplayUnits.Density].imperial.abbreviation;
  }
  d = round(d, 0);
  if (Number.isNaN(d)) return `- ${units}`;
  return `${d} ${units}`;
}

export function convertTonnage(_t: number, imperial: boolean) {
  let t = _t;
  let units = unitLabels[BasicDisplayUnits.DryTonnage].metric.abbreviation;
  if (imperial) {
    t = tonnageMetricToImperial(t);
    units = unitLabels[BasicDisplayUnits.DryTonnage].imperial.abbreviation;
  }
  t = round(t, 0);
  if (Number.isNaN(t)) return `- ${units}`;
  return `${t} ${units}`;
}

export function convertFlowRate(_f: number, imperial: boolean) {
  let f = _f;
  let units = unitLabels[BasicDisplayUnits.Volume].metric.abbreviation;
  if (imperial) {
    f = flowRateMetricToImperial(f);
    units = additionalUnitLabels[DisplayUnits.FlowRate].imperial.abbreviation;
  }
  f = round(f, 0);
  if (Number.isNaN(f)) return `- ${units}`;
  return `${f} ${units}`;
}
