/* eslint-disable class-methods-use-this */
import { IUtils } from '@date-io/core/IUtils';
import {
  convert,
  DateTimeFormatter,
  Duration,
  Instant, LocalDate, LocalDateTime, LocalTime,
  ZonedDateTime,
  ZoneId
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en';
import '@js-joda/timezone';
import { is, isNil, not } from 'ramda';

export const getTimeZoneAbbreviation = (zonedDateTime: ZonedDateTime) => {
  const timeZoneFormatter = Intl.DateTimeFormat('default', {
    timeZoneName: 'short',
    timeZone: zonedDateTime.zone().id(),
  });

  return timeZoneFormatter.formatToParts(convert(zonedDateTime).toDate())
    .find((part) => part.type === 'timeZoneName')?.value || '';
};

ZonedDateTime.prototype.toJSON = function toJSON() {
  const formatter = DateTimeFormatter
    .ofPattern('HH:mm:ss.nnnnnnnnn');

  return `${this.toLocalDate().toString()}T${this.toLocalTime().format(formatter)}${this.offset().toString()} ${this.zone()}`;
};

// eslint-disable-next-line no-useless-escape, max-len
const OFFSET_DATE_TIME_REG_EX = /([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?/;
// eslint-disable-next-line no-useless-escape
const SINGLE_OFFSET_REG_EX = /[\+-][0-9]{2}$/;

export const makeZonedDateTime = (dateTimeString: string): ZonedDateTime => {
  const matches = dateTimeString.match(OFFSET_DATE_TIME_REG_EX);

  const timezone = dateTimeString.replace(OFFSET_DATE_TIME_REG_EX, '').trim();

  const offsetDateTime = matches![0];

  const offset = offsetDateTime.match(SINGLE_OFFSET_REG_EX);

  const jsJodaFriendly = offset?.length ? offsetDateTime.replace(SINGLE_OFFSET_REG_EX, `${offset[0]}:00`) : offsetDateTime;

  return ZonedDateTime.parse(`${jsJodaFriendly}[${timezone}]`);
};

export const makeZonedDateTimeUtils = (timeZone: ZoneId = ZoneId.of('Europe/London')) => class ZonedDateTimeUtils implements IUtils<ZonedDateTime> {
  locale: any;

  timeZone: ZoneId;

  yearFormat = 'yyyy';

  yearMonthFormat = 'MMMM yyyy';

  dateTime12hFormat = 'MMMM do hh:mm aaaa';

  dateTime24hFormat = 'MMMM do HH:mm';

  time12hFormat = 'hh:mm a';

  time24hFormat = 'HH:mm';

  dateFormat = 'MMMM do';

  constructor({ locale }: { locale?: any }) {
    this.locale = locale ?? Locale.UK;
    this.timeZone = timeZone ?? ZoneId.of('Europe/London');
  }

  date(value?: any): ZonedDateTime | null {
    if (value === null) {
      return null;
    }

    if (typeof value === 'undefined') {
      return ZonedDateTime.now(this.timeZone);
    }

    if (typeof value === 'string') {
      const date = new Date(value);
      if (not(is(Number, date.valueOf()))) {
        return null;
      }
      const instant = Instant.ofEpochMilli(date.valueOf());
      const zonedDateTime = ZonedDateTime.ofInstant(instant, this.timeZone);
      return zonedDateTime;
    }

    if (value instanceof ZonedDateTime) {
      return value;
    }

    if (value instanceof Date) {
      const instant = Instant.ofEpochMilli(value.valueOf());
      return ZonedDateTime.ofInstant(instant, this.timeZone);
    }

    throw new Error(`Unknown Date value in function date(): ${value}`);
  }

  parse(value: string, format: string): ZonedDateTime | null {
    if (value === '' || value.includes('_')) {
      return null;
    }

    const formatter = DateTimeFormatter.ofPattern(format).withLocale(this.locale);

    // js-joda does not parse upper case AM or PM
    value.replace('AM', 'am');
    value.replace('PM', 'pm');

    const isDateFormat = format.includes('d') && format.includes('M') && format.includes('y');
    const isTimeFormat = format.includes('H') && format.includes('m');

    if (isDateFormat && isTimeFormat) {
      return ZonedDateTime.of(
        LocalDateTime.parse(value, formatter),
        this.timeZone,
      );
    }

    if (isDateFormat) {
      return ZonedDateTime.of(
        LocalDateTime.of(
          LocalDate.parse(value, formatter),
          LocalTime.MIN,
        ),
        this.timeZone,
      );
    }

    if (isTimeFormat) {
      return ZonedDateTime.of(
        LocalDateTime.of(
          LocalDate.now(this.timeZone),
          LocalTime.parse(value.toLowerCase(), formatter),
        ),
        this.timeZone,
      );
    }

    throw new Error('Format not supported');
  }

  isNull(value: ZonedDateTime | null): boolean {
    return value === null;
  }

  isValid(value: any): boolean {
    return !isNil(this.date(value));
  }

  getDiff(value: ZonedDateTime, comparing: string | ZonedDateTime): number {
    const comparingZonedDateTime = this.date(comparing);

    if (!comparingZonedDateTime) {
      return 0;
    }

    const duration = Duration.between(value, comparingZonedDateTime);
    return duration.toMillis();
  }

  isEqual(value: any, comparing: any): boolean {
    const valueZonedDateTime = this.date(value);
    const comparingZonedDateTime = this.date(comparing);

    if (valueZonedDateTime === null && comparingZonedDateTime === null) {
      return true;
    }

    if (comparingZonedDateTime === null || valueZonedDateTime === null) {
      return false;
    }

    return valueZonedDateTime.isEqual(comparingZonedDateTime);
  }

  isSameDay(value: ZonedDateTime, comparing: ZonedDateTime): boolean {
    return this.isSameMonth(value, comparing) && value.dayOfMonth() === comparing.dayOfMonth();
  }

  isSameMonth(value: ZonedDateTime, comparing: ZonedDateTime): boolean {
    return this.isSameYear(value, comparing) && value.monthValue() === comparing.monthValue();
  }

  isSameYear(value: ZonedDateTime, comparing: ZonedDateTime): boolean {
    return value.year() === comparing.year();
  }

  isSameHour(value: ZonedDateTime, comparing: ZonedDateTime): boolean {
    return this.isSameDay(value, comparing) && value.hour() === comparing.hour();
  }

  isAfter(value: ZonedDateTime, comparing: ZonedDateTime): boolean {
    return value.isAfter(comparing);
  }

  isAfterDay(value: ZonedDateTime, comparing: ZonedDateTime): boolean {
    return value.with(LocalTime.MIN).isAfter(this.endOfDay(comparing));
  }

  isAfterYear(value: ZonedDateTime, comparing: ZonedDateTime): boolean {
    const endOfYear = comparing
      .withMonth(12)
      .withDayOfMonth(31)
      .with(LocalTime.MAX);

    return value.isAfter(endOfYear);
  }

  isBeforeDay(value: ZonedDateTime, comparing: ZonedDateTime): boolean {
    return value.isBefore(this.startOfDay(comparing));
  }

  isBeforeYear(value: ZonedDateTime, comparing: ZonedDateTime): boolean {
    const startOfYear = comparing
      .withMonth(1)
      .withDayOfMonth(1)
      .with(LocalTime.MIN);

    return value.isBefore(startOfYear);
  }

  isBefore(value: ZonedDateTime, comparing: ZonedDateTime): boolean {
    return value.isBefore(comparing);
  }

  startOfMonth(value: ZonedDateTime): ZonedDateTime {
    return value
      .withDayOfMonth(1)
      .with(LocalTime.MIN);
  }

  endOfMonth(value: ZonedDateTime): ZonedDateTime {
    return value
      .plusMonths(1)
      .minusDays(1)
      .with(LocalTime.MAX);
  }

  addDays(value: ZonedDateTime, count: number): ZonedDateTime {
    return value.plusDays(count);
  }

  startOfDay(value: ZonedDateTime): ZonedDateTime {
    return value.with(LocalTime.MIN);
  }

  endOfDay(value: ZonedDateTime): ZonedDateTime {
    return value.with(LocalTime.MAX);
  }

  format(value: ZonedDateTime, formatString: string): string {
    const hasTimeZone = formatString.includes('z');

    const parsedFormatString = hasTimeZone ? formatString.replace('z', `'${getTimeZoneAbbreviation(value)}'`) : formatString;

    const formatter = DateTimeFormatter
      .ofPattern(parsedFormatString)
      .withLocale(this.locale);

    return value.format(formatter);
  }

  formatNumber(numberToFormat: string): string {
    return numberToFormat;
  }

  getHours(value: ZonedDateTime): number {
    return value.hour();
  }

  setHours(value: ZonedDateTime, count: number): ZonedDateTime {
    return value.withHour(count);
  }

  getMinutes(value: ZonedDateTime): number {
    return value.minute();
  }

  setMinutes(value: ZonedDateTime, count: number): ZonedDateTime {
    return value.withMinute(count);
  }

  getSeconds(value: ZonedDateTime): number {
    return value.second();
  }

  setSeconds(value: ZonedDateTime, count: number): ZonedDateTime {
    return value.withSecond(count);
  }

  getMonth(value: ZonedDateTime): number {
    return value.monthValue() - 1;
  }

  setMonth(value: ZonedDateTime, count: number): ZonedDateTime {
    return value.withMonth(count + 1);
  }

  getNextMonth(value: ZonedDateTime): ZonedDateTime {
    return value.plusMonths(1);
  }

  getPreviousMonth(value: ZonedDateTime): ZonedDateTime {
    return value.minusMonths(1);
  }

  getMonthArray(value: ZonedDateTime): ZonedDateTime[] {
    const startOfYear = value
      .withMonth(1)
      .withDayOfMonth(1)
      .with(LocalTime.MIN);

    const firstMonth = startOfYear;
    const monthArray = [firstMonth];

    while (monthArray.length < 12) {
      const prevMonth = monthArray[monthArray.length - 1];
      monthArray.push(this.getNextMonth(prevMonth));
    }

    return monthArray;
  }

  getYear(value: ZonedDateTime): number {
    return value.year();
  }

  setYear(value: ZonedDateTime, count: number): ZonedDateTime {
    return value.withYear(count);
  }

  mergeDateAndTime(date: ZonedDateTime, time: ZonedDateTime): ZonedDateTime {
    return this.setMinutes(
      this.setHours(date, this.getHours(time)),
      this.getMinutes(time),
    );
  }

  getWeekdays(): string[] {
    const today = ZonedDateTime.now(this.timeZone);
    const startOfWeek = this.startOfWeek(today);

    const weekdays = [];
    const formatter = DateTimeFormatter.ofPattern('eee').withLocale(this.locale);
    for (let i = 0; i < 7; i += 1) {
      weekdays.push(startOfWeek.plusDays(i).format(formatter));
    }
    return weekdays;
  }

  getWeekArray(value: ZonedDateTime): ZonedDateTime[][] {
    const startOfMonth = this.startOfMonth(value);
    const endOfMonth = this.endOfMonth(value);

    const start = this.startOfWeek(startOfMonth);
    const end = this.endOfWeek(endOfMonth);

    let count = 0;
    let current = start;
    const nestedWeeks: ZonedDateTime[][] = [];

    while (current.isBefore(end)) {
      const weekNumber = Math.floor(count / 7);
      nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
      nestedWeeks[weekNumber].push(current);
      current = current.plusDays(1);
      count += 1;
    }
    return nestedWeeks;
  }

  getYearRange(start: ZonedDateTime, end: ZonedDateTime): ZonedDateTime[] {
    const startDate = start
      .withMonth(1)
      .withDayOfMonth(1)
      .with(LocalTime.MIN);

    const endDate = end
      .withMonth(12)
      .withDayOfMonth(31)
      .with(LocalTime.MAX);

    const years: ZonedDateTime[] = [];

    let current = startDate;
    while (this.isBefore(current, endDate)) {
      years.push(current);
      current = current.plusYears(1);
    }

    return years;
  }

  getMeridiemText(ampm: 'am' | 'pm'): string {
    return ampm === 'am' ? 'AM' : 'PM';
  }

  getCalendarHeaderText(date: ZonedDateTime): string {
    return this.format(date, this.yearMonthFormat);
  }

  getDatePickerHeaderText(date: ZonedDateTime): string {
    return this.format(date, 'EEE, MMM d');
  }

  getDateTimePickerHeaderText(date: ZonedDateTime): string {
    return this.format(date, 'MMM d');
  }

  getMonthText(date: ZonedDateTime): string {
    return this.format(date, 'MMMM');
  }

  getDayText(date: ZonedDateTime): string {
    return this.format(date, 'd');
  }

  getHourText(date: ZonedDateTime, ampm: boolean) {
    return this.format(date, ampm ? 'hh' : 'HH');
  }

  getMinuteText(date: ZonedDateTime) {
    return this.format(date, 'mm');
  }

  getSecondText(date: ZonedDateTime) {
    return this.format(date, 'ss');
  }

  getYearText(date: ZonedDateTime): string {
    return this.format(date, 'yyyy');
  }

  private startDayOfWeek(): number {
    let startDayOfWeek;
    switch (this.locale.localeString()) {
      case 'en-US':
        startDayOfWeek = 7; // Sunday (day 7 in js-joda, which implements ISO8601 calendar only)
        break;
      default:
        startDayOfWeek = 1; // Monday
    }
    return startDayOfWeek % 7;
  }

  private startOfWeek(value: ZonedDateTime) {
    const dayOfWeek = value.dayOfWeek().value();
    return value
      .minusDays(dayOfWeek - this.startDayOfWeek())
      .with(LocalTime.MIN);
  }

  private endOfWeek(value: ZonedDateTime) {
    const dayOfWeek = value.dayOfWeek().value();
    return value
      .plusDays(7 - (dayOfWeek - this.startDayOfWeek()))
      .with(LocalTime.MAX);
  }
};
