import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { GridRowData } from '@material-ui/data-grid/';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { ReactElement } from 'react';
import { capitalize } from 'utils';

export type TableAction = {
  name: string;
  icon: ReactElement;
  action: (row: GridRowData) => void;
};

interface TableActionsButtonProps {
  Icon: ReactElement;
  onClick: () => void;
}

const TableActionsButton = ({ Icon, onClick }: TableActionsButtonProps) => {
  const handleOnClick = () => {
    onClick();
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleOnClick}>
        {Icon}
      </IconButton>
    </>
  );
};

interface TableActionsMenuProps {
  actions: TableAction[];
  row: GridRowData;
}
const TableActionsMenu = ({ actions, row }: TableActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="project-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { actions.map((action) => (
          <MenuItem onClick={() => action.action(row)}>
            <ListItemIcon>
              {action.icon}
            </ListItemIcon>
            <Typography variant="inherit">{capitalize(action.name)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface BasicTableActionsProps {
  row: GridRowData;
  actions: TableAction[];

}
const BasicTableActions = ({ row, actions }: BasicTableActionsProps) => {
  if (actions.length === 1) {
    return (
      <>
        {
          actions.map((action) => (
            <TableActionsButton key={action.name} Icon={action.icon} onClick={() => action.action(row)} />
          ))
        }
      </>
    );
  }
  return <TableActionsMenu actions={actions} row={row} />;
};

export default BasicTableActions;
