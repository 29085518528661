import { Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { GridPageChangeParams } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClientField from 'components/ClientField';
import SearchField from 'components/SearchField';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import {
  ROUTE_PARAM_IDENTIFIER as EditProjectLicenceExpiryPageParamIdentifier,
  ROUTE_PATH as EditProjectLicenceExpiryPageRoute
} from 'modules/project-admin/EditProjectLicenceExpiryPage/constants';
import * as childConstants from 'modules/project-admin/NewProjectPage/constants';
import {
  ROUTE_PARAM_IDENTIFIER as ProjectDetailsPageParamIdentifier,
  ROUTE_PATH as ProjectDetailsPageRoute
} from 'modules/project-admin/ProjectDetailsPage/constants';
import {
  ClientDto,
  ProjectDto,
  useDeleteProjectMutation,
  useProjectsQuery
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { replace } from 'ramda';
import React from 'react';
import {
  Link,
  useHistory
} from 'react-router-dom';
import ProjectsTable from './ProjectsTable';

const NewProjectButton = () => (
  <>
    <Hidden xsDown>
      <Button
        aria-label="add new project"
        variant="contained"
        color="primary"
        size="large"
        startIcon={<AddIcon />}
        component={Link}
        to={childConstants.ROUTE_PATH}
      >
        New Project
      </Button>
    </Hidden>
    <Hidden smUp>
      <IconButton
        aria-label="add new project"
        color="primary"
        component={Link}
        to={childConstants.ROUTE_PATH}
      >
        <AddIcon />
      </IconButton>
    </Hidden>
  </>
);

const ProjectsPage = () => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION_DEFAULT_PAGE_SIZE);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const [clientId, setClientId] = React.useState<string | null>(null);
  const history = useHistory();
  const confirm = useConfirmation();
  const mutation = useDeleteProjectMutation();
  const {
    data,
    isFetching,
    isFetched,
    isPreviousData,
  } = useProjectsQuery({ page, pageSize, searchTerm, clientId });

  const handlePageChange = (change: GridPageChangeParams) => {
    setPage(change.page + 1);
  };

  const handlePageSizeChange = (change: GridPageChangeParams) => {
    setPage(1);
    setPageSize(change.pageSize);
  };

  const handleSearch = (text: string | null) => {
    setPage(1);
    setSearchTerm(text);
  };

  const handleClientChange = (client?: ClientDto | null) => {
    setPage(1);
    setClientId(client?.entityId ?? null);
  };

  const handleSelect = (project: ProjectDto) => {
    history.push(
      replace(ProjectDetailsPageParamIdentifier, project.entityId, ProjectDetailsPageRoute),
    );
  };

  const handleUpdateLicenceExpiry = (project: ProjectDto) => {
    history.push(
      replace(EditProjectLicenceExpiryPageParamIdentifier, project.entityId, EditProjectLicenceExpiryPageRoute),
    );
  };

  const handleDelete = (project: ProjectDto) => {
    confirm({
      variant: 'danger',
      description: `Are you sure you want to remove '${project.name}'?`,
    }).then(() => mutation.mutate(project));
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
        <Box flex={1} mr={3}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <ClientField onChange={handleClientChange} textFieldVariant="filled" />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <SearchField onSearch={handleSearch} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <NewProjectButton />
      </Box>
      <Box mt={3} component={Paper}>
        <ProjectsTable
          data={data?.items}
          page={page}
          pageSize={pageSize}
          totalItems={data?.pager.totalItems}
          loading={isFetching || mutation.isLoading}
          loadingNew={isFetching && (!isFetched || isPreviousData)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onSelect={handleSelect}
          onUpdateProjectExpiry={handleUpdateLicenceExpiry}
          onDelete={handleDelete}
          searchTerm={searchTerm}
        />
      </Box>
    </Container>
  );
};

export default ProjectsPage;
