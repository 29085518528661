import { LocalDate, LocalDateTime, ZonedDateTime } from '@js-joda/core';
import React from 'react';
import { displayTemporal } from 'utils';

interface TemporalProps {
  temporal: ZonedDateTime | LocalDate | LocalDateTime | Date;
  options?: Intl.DateTimeFormatOptions;
}

const Temporal = ({ temporal, options }: TemporalProps) => <>{displayTemporal(temporal, options)}</>;

export default Temporal;
