import { WebStorageStateStore } from 'oidc-client';

export const LOCAL_STORAGE_PREFIX = 'PC-BACKFILL';
export const OIDC_CONFIGURATION = {
  client_id: 'BackfillPlanner',
  redirect_uri: `${window.location.origin}/app/authentication/callback`,
  response_type: 'code',
  post_logout_redirect_uri: `${window.location.origin}/app`,
  scope: 'openid email profile roles offline_access',
  authority: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  silent_redirect_uri: `${window.location.origin}/app/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};
export const PAGINATION_PAGE_SIZES = [10, 25, 50];
export const PAGINATION_DEFAULT_PAGE_SIZE = PAGINATION_PAGE_SIZES[0];
export const BAD_REQUEST_CODE = 400;
export const UNAUTHORISED_ERROR_CODE = 401;
export const NOT_FOUND_ERROR_CODE = 404;
export const NOT_ACCEPTABLE_ERROR_CODE = 406;
export const TIMEOUT_ERROR_CODE = 408;
export const EXPECTED_ERROR_CODES = [BAD_REQUEST_CODE, UNAUTHORISED_ERROR_CODE, NOT_FOUND_ERROR_CODE, NOT_ACCEPTABLE_ERROR_CODE];

export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * 60;
export const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60;
export const DAY_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 24;

export const DEFAULT_MAX_PUMP_PRESSURE = 10;
