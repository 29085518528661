import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import { BackfillWarningDto } from 'providers/api';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: '1.1rem',
  },
}));

interface WarningTooltipIconProps {
  warnings: BackfillWarningDto[];
}

const WarningTooltipIcon = ({ warnings }: WarningTooltipIconProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={(
        <List>
          {
            warnings.map((warning: BackfillWarningDto) => (
              <ListItem key={(Math.random() + 1).toString(36).substring(7)}>
                {warning.display}
              </ListItem>
            ))
          }
        </List>
      )}
    >
      <WarningIcon className={classes.warningIcon} />
    </Tooltip>
  );
};

export default WarningTooltipIcon;
