import { capitalize } from 'utils';
import {
  BackfillRecipeDto,
  CreateBackfillRecipeCommand,
  UpdateBackfillRecipeCommand
} from './api';
import {
  makeCreateMutation, makeDeleteMutation, makeGetQuery, makeListQuery, makeUpdateMutation
} from './useApi';

const RECIPE_KEY = 'recipe';
export const RECIPES_KEY = 'recipes';

export interface RecipeMutation {
  projectId: string;
  stopeId: string;
  recipeId?: string | null;
  data: CreateBackfillRecipeCommand | UpdateBackfillRecipeCommand;
  context: { stopeName: string }
}
export const useCreateRecipeMutation = makeCreateMutation<string, RecipeMutation>(
  RECIPES_KEY,
  (api) => (mutation) => api.recipes.create(mutation.projectId, mutation.stopeId, mutation.data),
  (mutation) => `A recipe was added to stope ${capitalize(mutation.context.stopeName)}.`,
);

export const useUpdateRecipeMutation = makeUpdateMutation<void, RecipeMutation>(
  RECIPE_KEY, RECIPES_KEY,
  (api) => (mutation) => api.recipes.update(mutation.recipeId ?? '', mutation.data),
  () => 'Recipe updated.',
);

export interface RecipesQueryParams {
  projectId: string;
  stopeId: string;
}

export const useRecipesQuery = makeListQuery<RecipesQueryParams, BackfillRecipeDto[]>(
  RECIPES_KEY,
  (api, params) => () => api.recipes.getAll(params.projectId, params.stopeId),
);

export const useRecipeQuery = makeGetQuery<BackfillRecipeDto, string>(
  RECIPE_KEY,
  (api, entityId) => () => api.recipes.get(entityId),
);

export const useDeleteRecipeMutation = makeDeleteMutation<void, BackfillRecipeDto>(
  RECIPE_KEY,
  RECIPES_KEY,
  (api) => async (recipe) => {
    const result = await api.recipes.delete(recipe.entityId);
    return result;
  },
  () => 'Recipe successfully deleted',
  (mutation) => mutation.entityId,
);
