import { ButtonProps } from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ProgressButton from 'components/ProgressButton';
import usePrevious from 'hooks/usePrevious';
import { FileResponse } from 'providers/api';
import React from 'react';

interface DownloadButtonProps {
  initialButtonText?: string;
  buttonText?: string;
  buttonProps?: ButtonProps;
  downloading?: boolean;
  onDownload: () => void;
  file?: FileResponse;
}

const DownloadButton = ({ file, initialButtonText = 'Download', buttonText, buttonProps, onDownload, downloading = false }: DownloadButtonProps) => {
  const downloadLink = React.useRef<HTMLInputElement>(null);
  const previousFile = usePrevious(file);

  const fetchDownload = (e: React.MouseEvent) => {
    onDownload();

    // Unlikely that you would need event bubbling for download button. If this is correct we need to handle this logic via probs
    e.stopPropagation();
  };

  // Automatically download the file after onClick with lazyLoaded
  React.useEffect(() => {
    if (downloadLink.current && !previousFile && file) {
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      downloadLink.current.dispatchEvent(clickEvent);
    }
  }, [file]);

  return (
    <>
      <Link
        download={file?.fileName ?? undefined}
        href={file ? URL.createObjectURL(file.data) : undefined}
        type={file?.data.type}
        ref={downloadLink}
        target="_blank"
      >
        <ProgressButton loading={downloading} {...buttonProps} onClick={fetchDownload}>
          {buttonText ?? ((file?.fileName) ?? initialButtonText)}
        </ProgressButton>
      </Link>
    </>
  );
};

export default DownloadButton;
