import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { FormikActions } from 'components/Form';
import useForm from 'hooks/useForm';
import {
  ClientDto,
  CreateClientCommand,
  UpdateClientCommand
} from 'providers/api';
import React from 'react';
import { UseMutationResult } from 'react-query';
import {
  object,
  SchemaOf,
  string
} from 'yup';

const ClientSchema: SchemaOf<CreateClientCommand> = object().shape({
  name: string().required('Name is required'),
});

interface ClientFormProps {
  mutation: UseMutationResult<string | void, unknown, CreateClientCommand | UpdateClientCommand>;
  client?: ClientDto;
}
const ClientForm = ({ mutation, client }: ClientFormProps) => {
  const initialClientRef = React.useRef(client);
  const initialClient = initialClientRef.current;
  const {
    formik,
    helpers,
  } = useForm<CreateClientCommand | UpdateClientCommand>({
    mutation,
    formikConfig: {
      initialValues: { name: initialClient ? initialClient.name : '' },
      onSubmit: (clientModel, { setSubmitting }) => {
        setSubmitting(true);
        mutation.mutate(clientModel, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: ClientSchema,
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Box component={Paper} p={2}>
        <TextField
          required
          autoFocus
          fullWidth
          id="name"
          name="name"
          label="Client Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={helpers.hasError('name')}
          helperText={helpers.getErrorHelpText('name')}
        />
      </Box>
      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText={initialClient ? 'Edit' : 'Create'}
          right={['reset', 'submit']}
        />
      </Box>
    </form>
  );
};

export default ClientForm;
