import CircularProgress from '@material-ui/core/CircularProgress';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';

interface SimpleAutoCompleteProps<T> {
  label: string;
  value: any;
  autoFocus?: boolean;
  disabled?: boolean;
  options: T[];
  loading: boolean;
  getOptionDisabled: (option: T) => boolean;
  getOptionLabel: (option: T) => string;
  getOptionSelected: (option: T, value: T) => boolean;
  filterOptions: (options: T[], state: FilterOptionsState<T>) => T[];
  noOptionsText: string;
  onChange: (option: T) => void;
  onSearch: (searchTerm: string) => void;
  onBlur?: () => void;
  touched?: boolean;
  error?: string;
  required?: boolean
  open: boolean
  onOpen: (isOpen: boolean) => void;
  textFieldVariant?: TextFieldProps['variant'];
}

const SimpleAutoComplete = <T extends any>({
  label,
  value,
  autoFocus,
  disabled,
  options,
  loading,
  getOptionDisabled,
  getOptionLabel,
  getOptionSelected,
  filterOptions,
  noOptionsText,
  onChange,
  onSearch,
  onBlur,
  touched,
  error,
  required,
  open,
  onOpen,
  textFieldVariant = 'outlined',
}: SimpleAutoCompleteProps<T>) => (
  <Autocomplete
    fullWidth
    id={`autocomplete-select-${label.replace(/\s/g, '')}`}
    open={open}
    onOpen={() => {
      onOpen(true);
    }}
    onClose={() => {
      onOpen(false);
    }}
    disabled={disabled}
    options={options}
    loading={loading}
    autoHighlight
    value={value}
    getOptionDisabled={getOptionDisabled}
    getOptionLabel={getOptionLabel}
    getOptionSelected={getOptionSelected}
    filterOptions={filterOptions}
    onChange={(event: any, newValue: any | null, reason) => {
      if ((reason === 'select-option' && newValue !== null) || !required) {
        onChange(newValue);
      }
    }}
    onInputChange={(event, newInputValue) => {
      onSearch(newInputValue);
    }}
    noOptionsText={noOptionsText}
    renderInput={(params) => (
      <TextField
        {...params}
        autoFocus={autoFocus}
        label={label}
        variant={textFieldVariant}
        onBlur={onBlur}
        error={touched && Boolean(error)}
        helperText={touched && error}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
      />
    )}
  />
  // eslint-disable-next-line indent
);

export default SimpleAutoComplete;
