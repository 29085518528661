import {
  BackfillWarningDto,
  FillType,
  ThroughputControlType,
  UcsCoefficientsDto
} from 'providers/api';
import Point from './Diagra/Point';
import PCNode from './DiagraExtended/PCNode';
import PCPipe from './DiagraExtended/PCPipe';
import {
  GREEN,
  LIGHT_BLUE,
  LIGHT_RED,
  PURPLE,
  RED
} from './GConstants';

export type VelocityResult = {
  velocity: number;
  errors: string;
}

export type MinVelocityResult = {
  velocity: number;
  pipe: PCPipe;
}

export interface RecipeState {
  throughputControlType?: ThroughputControlType;
  dryDensity?: number;
  wetDensity?: number;
  dryTonnage?: number;
  wetTonnage?: number;
  dryFlowRate?: number;
  wetFlowRate?: number;
  tailingsDryTonnage?: number;
  binderConcentrationByMass?: number;
  totalDryConcentrationByMass?: number;
  pumpPressureKpa?: number;
  unconfinedCompressiveStrengthKpa?: number;
  curingDuration?: number;
  tailingsSolidsDensity?: number;
  binderParticleDensity?: number;
  carrierFluidDensity?: number;
}
export interface RheologyCoefficients {
  a: number;
  b: number;
  c: number;
  d: number;
}
export interface PCFluidSpecification {
  version: string;
  type: FillType;
  concentrationRange: Range;
  tonnageRange: Range;
  binderRange: Range;
  drySolidsDensity: number; // kPa
  rheologyCoefficients: RheologyCoefficients;
  ucsCoefficients: Record<number, UcsCoefficientsDto>;
  maxPumpPressure?: number; // bar
  heightOfCylinder?: number; // m
  mixerCoefficients?: any;
  carrierFluidDensity?: number;
  binderDryParticleDensity?: number;
}

export type Telemetry = [string, string][];

export interface RouteWarning extends BackfillWarningDto {
  color: string;
}

export interface RangeBase {
  min: number;
  max: number;
}

export interface Range extends RangeBase {
  interval: number;
  default?: number;
}

export interface DictionaryRange {
  concentration: Range;
  tons: Range;
  cement: Range;
}

export interface PasteOptimiseResult {
  cement: number;
  concentrationByMass: number;
  actualUCSKpa: number;
  actualPumpPressureKpa: number;
}

export interface PasteAdjustedResult {
  actualUCSKpa: number;
  actualPumpPressureKpa: number;
}

export interface HydraulicOptimiseResult {
  concentrationByMass: number;
}
export interface HydraulicCalculateResult {
  settlingVelocity: number;
  maxPipePressure: number;
  minVelocity: number;
}

export type RoutePoint = { // unique to route
  point: PCNode | Point,
  distanceFromPump?: number,
  pressure?: number,
  pipeUpstream?: PCPipe,
  pipeDownstream?: PCPipe,
}

export const OVER_PRESSURE_WARNING: RouteWarning = {
  code: 'OP',
  display: 'Over Pressure',
  color: RED,
};

export const OVER_PRESSURE_POSSIBLE_WARNING: RouteWarning = {
  code: 'OPP',
  display: 'Over Pressure Possible',
  color: LIGHT_RED,
};

export const NO_WARNING: RouteWarning = {
  code: 'NW',
  display: 'No Warnings',
  color: GREEN,
};

export const SLACK_FLOW_POSSIBLE_WARNING: RouteWarning = {
  code: 'SFP',
  display: 'Slack Flow possible',
  color: LIGHT_BLUE,
};

export const SLACK_FLOW_WARNING: RouteWarning = {
  code: 'SF',
  display: 'Slack Flow',
  color: PURPLE,
};

export const WARNINGS = [
  OVER_PRESSURE_WARNING,
  OVER_PRESSURE_POSSIBLE_WARNING,
  NO_WARNING,
  SLACK_FLOW_POSSIBLE_WARNING,
  SLACK_FLOW_WARNING,
];
