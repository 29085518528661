import Slider, { SliderProps } from '@material-ui/core/Slider';
import { UnitBase } from 'hooks/useDisplayUnits/useDisplayUnits';
import { UnitSystem } from 'providers/api';
import { ChangeEvent } from 'react';
import { round } from 'utils';

interface UnitSystemNumberSliderProps extends SliderProps {
  unitSystem: UnitSystem;
  unitInfo: UnitBase;
  min: number;
  max: number;
  decimalPlaces: number;
  onSliderChange: (event: ChangeEvent<{}>, newValue: number | number[]) => void
}

const UnitSystemNumberSlider = (props: UnitSystemNumberSliderProps) => {
  const { value, unitSystem, unitInfo, min, max, decimalPlaces, onSliderChange, ...other } = props;
  const checkedValue = value ?? min;

  const handleOnChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
    const currentValue = Array.isArray(newValue) ? newValue[0] : newValue;
    const unitSystemBaseValue = unitSystem === UnitSystem.Metric
      ? currentValue
      : unitInfo.metric.conversion(currentValue ?? 0);

    onSliderChange(event, unitSystemBaseValue ?? 0);
  };

  return (
    <Slider
      {...other}
      value={
        unitSystem === UnitSystem.Metric
          ? checkedValue
          : round(unitInfo.imperial.conversion(checkedValue as number), decimalPlaces)
      }
      onChange={handleOnChange}
      valueLabelDisplay="auto"
      min={
        unitSystem === UnitSystem.Metric
          ? min
          : round(unitInfo.imperial.conversion(min), 0)
      }
      max={
        unitSystem === UnitSystem.Metric
          ? max
          : round(unitInfo.imperial.conversion(max), 0)
      }
    />
  );
};

export default UnitSystemNumberSlider;
