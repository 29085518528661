import { StopeDataDto } from 'providers/api';
import PCNode from './PCNode';

export default class PCStope {
  // stope node (object)
  originNode: PCNode;

  data: StopeDataDto;

  constructor(s: PCNode, data: StopeDataDto) {
    this.originNode = s;
    this.data = data;
  }

  public updateData(data: StopeDataDto) {
    this.data = data;
  }
}
