import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {
  ClientTeamsDto,
  RoleDto,
  TeamDto,
  TeamRolesAssignmentForm
} from 'providers/api';
import {
  dissoc,
  indexBy,
  lensPath,
  merge,
  partial,
  prop,
  set,
  uniq,
  values,
  view,
  without,
  zipObj
} from 'ramda';
import React from 'react';
import { equalValues } from 'utils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  sectionTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  sectionContent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
    },
  },
  removeLastRowBorder: {
    '& > tr:last-child th, & > tr:last-child td': {
      borderBottom: 0,
    },
  },
}));

/**
 * Project Permissions
 */

interface ProjectPermissionsProps {
  roles: RoleDto[];
  projects: TeamDto[];
  roleAssignments: TeamRolesAssignmentForm[];
  clientPermissionOverrides: string[];
  disabled?: boolean;
  onProjectsSelect: (projectIds: string[], role: string) => void;
  onProjectsDeselect: (projectIds: string[], role: string) => void;
}

const ProjectPermissions = ({
  disabled = false,
  roles,
  projects,
  roleAssignments,
  clientPermissionOverrides,
  onProjectsSelect,
  onProjectsDeselect,
}: ProjectPermissionsProps) => {
  const classes = useStyles();
  const roleNames = roles
    .map(prop('name'));

  const projectTeamIds = projects
    .map(prop('teamId'));

  const selectedProjects = roleAssignments.filter((role) => projectTeamIds.includes(role.teamId));

  const selectedProjectIdsByRole = roleNames.reduce((acc, role) => ({
    ...acc,
    [role]: selectedProjects
      .filter((p) => p.roles.includes(role))
      .map(prop('teamId')),
  }), zipObj(roleNames, [[]] as string[][]));

  // eslint-disable-next-line max-len
  const handleProjectChange = (role: string, projectId: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      onProjectsSelect([projectId], role);
    } else {
      onProjectsDeselect([projectId], role);
    }
  };

  // eslint-disable-next-line max-len
  const handleMassChange = (role: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      onProjectsSelect(projectTeamIds, role);
    } else {
      onProjectsDeselect(projectTeamIds, role);
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 150 }}>Projects</TableCell>
            {roles.map((role) => {
              const allSelected = equalValues(selectedProjectIdsByRole[role.name], projectTeamIds) || clientPermissionOverrides.includes(role.name);
              const someSelected = !allSelected && projectTeamIds.some((id) => selectedProjectIdsByRole[role.name].includes(id));
              return (
                <TableCell key={role.name} align="center">
                  <Checkbox
                    color="primary"
                    disabled={disabled || clientPermissionOverrides.includes(role.name)}
                    checked={allSelected}
                    indeterminate={someSelected}
                    onChange={handleMassChange(role.name)}
                  />
                  {role.name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody className={classes.removeLastRowBorder}>
          {projects.map((project) => (
            <TableRow key={project.name}>
              <TableCell>
                {project.name}
              </TableCell>
              {roles.map((role) => (
                <TableCell key={role.name} align="center">
                  <Checkbox
                    checked={selectedProjectIdsByRole[role.name].includes(project.teamId) || clientPermissionOverrides.includes(role.name)}
                    disabled={disabled || clientPermissionOverrides.includes(role.name)}
                    onChange={handleProjectChange(role.name, project.teamId)}
                    color="primary"
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/**
 * Client Permissions
 */

interface ClientPermissionsProps {
  roles: RoleDto[];
  selectedRoles: string[];
  disabled?: boolean;
  onSelect: (roleName: string) => void;
  onDeselect: (roleName: string) => void;
}

const ClientPermissions = ({ disabled, roles, selectedRoles, onSelect, onDeselect }: ClientPermissionsProps) => {
  const classes = useStyles();

  const handleChange = (role: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      onSelect(role);
    } else {
      onDeselect(role);
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableBody className={classes.removeLastRowBorder}>
          <TableRow>
            <TableCell style={{ width: 150 }} />
            {roles.map((role) => (
              <TableCell key={role.name} align="center">
                <Checkbox
                  disabled={disabled}
                  checked={selectedRoles.includes(role.name)}
                  onChange={handleChange(role.name)}
                  color="primary"
                />
                {role.name}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/**
 * Permissions
 */

interface PermissionsProps {
  roles: RoleDto[];
  clientTeamId: string;
  teamRoles: TeamRolesAssignmentForm[];
  clientTeams?: ClientTeamsDto;
  disabled?: boolean;
  onChange: (teamRoles: TeamRolesAssignmentForm[]) => void
}

type IndexedTeamRoles = {
  [x: string]: TeamRolesAssignmentForm;
}

const PermissionsField = ({ disabled, clientTeamId, roles, teamRoles, clientTeams, onChange }: PermissionsProps) => {
  const classes = useStyles();

  const indexedTeamRoles = indexBy(prop('teamId'), teamRoles);
  const clientLevelRole: TeamRolesAssignmentForm | undefined = indexedTeamRoles[clientTeamId];

  const handleRoleAdd = (teamIds: string[], role: string) => {
    const newTeamRoles = teamIds.map((teamId) => {
      const rolesLens = lensPath<IndexedTeamRoles, string[]>([teamId, 'roles']);
      const currentRoles = view<IndexedTeamRoles, string[]>(rolesLens, indexedTeamRoles) ?? [];
      return { teamId, roles: uniq([...currentRoles, role]) };
    }) as TeamRolesAssignmentForm[];

    const newTeamAssignments = indexBy(prop('teamId'), newTeamRoles);

    const updated = merge(
      indexedTeamRoles,
      newTeamAssignments,
    );

    onChange(values(updated));
  };

  const handleRoleRemove = (teamIds: string[], role: string) => {
    const updated = teamIds.reduce((currentIndexedTeamRoles, teamId) => {
      const rolesLens = lensPath<IndexedTeamRoles, string[]>([teamId, 'roles']);
      const currentRoles = view<IndexedTeamRoles, string[]>(rolesLens, currentIndexedTeamRoles) ?? [];
      const updatedRoles = without([role], currentRoles);
      const update = updatedRoles.length > 0 ? set(rolesLens, updatedRoles, currentIndexedTeamRoles) : dissoc(teamId, currentIndexedTeamRoles);
      return update;
    }, indexedTeamRoles);

    onChange(values(updated));
  };

  return (
    <Box className={classes.root}>
      <Box mb={4}>
        <Grid container>
          <Grid item xs={12} md={4} className={classes.sectionTitle}>
            <Typography variant="body1">Client Level Permissions</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box className={classes.sectionContent}>
              <ClientPermissions
                disabled={disabled}
                roles={roles}
                selectedRoles={clientLevelRole?.roles ?? []}
                onSelect={partial(handleRoleAdd, [[clientTeamId]])}
                onDeselect={partial(handleRoleRemove, [[clientTeamId]])}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Hidden smDown><Divider variant="fullWidth" /></Hidden>
      <Box mt={4}>
        <Grid container>
          <Grid item xs={12} md={4} className={classes.sectionTitle}>
            <Typography variant="body1">Project Level Permissions</Typography>
          </Grid>
          <Grid item xs={12} md={8} className={classes.sectionContent}>
            {
              (clientTeams && clientTeams.projectTeams.length) ? (
                <ProjectPermissions
                  disabled={disabled}
                  projects={clientTeams.projectTeams}
                  roles={roles}
                  clientPermissionOverrides={clientLevelRole?.roles ?? []}
                  roleAssignments={teamRoles}
                  onProjectsSelect={handleRoleAdd}
                  onProjectsDeselect={handleRoleRemove}
                />
              )
                : <Box mb={4}><Typography variant="body2">No projects found</Typography></Box>
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PermissionsField;
