import Page from 'modules/Page';
import makePage from 'modules/makePage';
import * as parentConstants from 'modules/project-admin/ProjectDetailsPage/constants';
import EditRheologyPage from './EditRheologyPage';
import * as constants from './constants';
import { EditRheologyPageParams } from './constants';
import useEditRheologyQueries, { EditRheologyQueries } from './useEditRheologyQueries';

const page: Page = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: true,
  component: makePage<EditRheologyPageParams, EditRheologyQueries>({
    WrappedPage: EditRheologyPage,
    titleFn: ([rheologyQuery]) => {
      const title = rheologyQuery.data ? rheologyQuery.data.reference : '';
      return `Edit ${title} Rheology Data`;
    },
    parentPathTemplate: parentConstants.ROUTE_PATH,
    parentTitleFn: () => parentConstants.TITLE,
    pageDataHook: useEditRheologyQueries,
  }),
};

export default page;
