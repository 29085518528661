import { createContext } from 'react';
import {
  BackfillRecipesService,
  ClientsService,
  InvitationsService,
  MineModelsService,
  PoursService,
  PourTypesService,
  ProjectsService,
  StopeBackfillingService,
  TeamRolesService,
  TeamsService,
  UsersService
} from './api';

export interface IApiContext {
  clients: ClientsService,
  projects: ProjectsService,
  users: UsersService,
  teams: TeamsService,
  invitations: InvitationsService,
  mineModels: MineModelsService,
  recipes: BackfillRecipesService,
  pours: PoursService,
  teamRoles: TeamRolesService,
  stopes: StopeBackfillingService,
  pourTypes: PourTypesService,
}

const ApiContext = createContext<IApiContext | null>(null);

export default ApiContext;
