import { HOUR_IN_MILLISECONDS } from 'config';
import {
  EntitySummaryDto,
  TeamDto,
  TeamRolesAssignmentForm,
  useClientTeamQuery,
  useRolesQuery
} from 'providers/api';
import {
  dissoc,
  prop
} from 'ramda';
import React from 'react';

const useClientPermissions = (
  onChange: (roles: TeamRolesAssignmentForm[]) => void,
  existingRoles: TeamRolesAssignmentForm[] = [],
  existingClients: EntitySummaryDto[] = [],
) => {
  const [teamRoles, setTeamRoles] = React.useState<TeamRolesAssignmentForm[]>(existingRoles);
  const [clientTeamErrors, setClientTeamErrors] = React.useState<{ [clientId: string]: boolean }>({});
  const [clientTeams, setClientTeams] = React.useState<EntitySummaryDto[]>(existingClients);
  const [clientProjects, setClientProjects] = React.useState<{ [clientId: string]: string[] }>({});
  const [activeClientTeamId, setActiveClientTeamId] = React.useState<string | null>(null);

  const { data: roles } = useRolesQuery();
  const { data: activeClientTeams } = useClientTeamQuery(activeClientTeamId!, {
    enabled: !!activeClientTeamId,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  React.useEffect(() => {
    if (activeClientTeams) {
      setClientProjects((currentClientProjects) => ({
        ...currentClientProjects,
        [activeClientTeams.clientTeam.teamId]: activeClientTeams.projectTeams.map(prop('teamId')),
      }));
    }
  }, [activeClientTeams]);

  const activeTeamHasError = (rolesList: TeamRolesAssignmentForm[]) => {
    if (activeClientTeams) {
      const clientTeamRole = rolesList.find((role) => role.teamId === activeClientTeamId);

      if (clientTeamRole && clientTeamRole.roles.length > 0) {
        return false;
      }

      const projectIds = activeClientTeams.projectTeams.map(prop('teamId'));
      const projectRoles = rolesList.filter((role) => projectIds.includes(role.teamId) && role.roles.length > 0);

      return !(projectRoles.length > 0);
    }

    return false;
  };

  const updateActiveClientError = (rolesList: TeamRolesAssignmentForm[]) => {
    if (activeClientTeamId) {
      if (activeTeamHasError(rolesList)) {
        setClientTeamErrors((currentClientTeamErrors) => ({ ...currentClientTeamErrors, [activeClientTeamId]: true }));
      } else {
        setClientTeamErrors((currentClientTeamErrors) => dissoc(activeClientTeamId, currentClientTeamErrors));
      }
    }
  };

  const handleClientTeamRemove = (removeClientTeamId: string) => {
    setClientTeams((currentClientTeams) => currentClientTeams.filter((clientTeam) => removeClientTeamId !== clientTeam.entityId));
    setActiveClientTeamId(null);

    const updatedTeamRoles = teamRoles.filter((role) => {
      if (role.teamId === removeClientTeamId) {
        return false;
      }
      if (!clientProjects[removeClientTeamId]) {
        return true;
      }
      return !clientProjects[removeClientTeamId].includes(role.teamId);
    });
    setTeamRoles(updatedTeamRoles);
    onChange(updatedTeamRoles);
  };

  const updateTeamRoles = (rolesList: TeamRolesAssignmentForm[]) => {
    setTeamRoles(rolesList);
    onChange(rolesList);
    updateActiveClientError(rolesList);
  };

  const addNewClientTeamRole = (clientTeam: TeamDto | null) => {
    if (clientTeam) {
      setClientTeams((currentClientTeams) => [...currentClientTeams, { entityId: clientTeam.teamId, name: clientTeam.name }]);
      setActiveClientTeamId(clientTeam.teamId);
    }
  };

  const handleSetActiveTeamId = (clientTeamId: string | null) => {
    setActiveClientTeamId(clientTeamId);
    updateActiveClientError(teamRoles);
  };

  const resetForm = () => {
    setTeamRoles(existingRoles);
  };

  return {
    roles,
    teamRoles,
    activeClientTeams: activeClientTeamId ? activeClientTeams : null,
    clientTeamErrors,
    clientTeams,
    resetForm,
    setActiveClientTeam: handleSetActiveTeamId,
    onUpdate: updateTeamRoles,
    onAdd: addNewClientTeamRole,
    onRemove: handleClientTeamRemove,
  };
};

export default useClientPermissions;
