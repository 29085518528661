import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Alert from '@material-ui/lab/Alert';
import DownloadButton from 'components/DownloadButton';
import ProgressButton from 'components/ProgressButton';
import Temporal from 'components/Temporal';
import {
  DisplayUnits,
  FileResponse,
  FillType,
  MixerCoefficientsDto,
  PourDto,
  PourStatus,
  ThroughputControlType,
  UcsCoefficientsDto,
  UnitSystem
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import { DEFAULT_SHORT_DATE_CONFIG } from 'utils';
import PourDetails from '../PourDetails';
import PourStatusChip from '../PourStatusChip';
import ProjectAuthorise from '../ProjectAuthorise';
import WarningsList from './WarningsList';

const useStyles = makeStyles((theme: Theme) => createStyles({
  cardContent: {
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
}));
interface PourDetailsCardProps {
  pour: PourDto;
  pourNoteFile?: FileResponse;
  pourNoteDownloading: boolean;
  displayUnitPreferences: DisplayUnits[];
  unitSystemPreference: UnitSystem;
  specificGravity?: number;
  heightOfCylinder?: number;
  mixerCoefficients?: MixerCoefficientsDto;
  yieldStressA?: number;
  yieldStressB?: number;
  tailingsSolidsDensity?: number;
  binderParticleDensity?: number;
  carrierFluidDensity?: number;
  fillType: FillType;
  throughputControlType: ThroughputControlType;
  rheologyDataMissing: boolean;
  unApprovedIsLoading: boolean;
  onAbandonIsLoading: boolean;
  voidIsLoading: boolean;
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto; };
  onVoidPourNote: () => void;
  onAbandonPour: () => void;
  onUnapprovePourNote: () => void;
  onDownloadPourNote: () => void;
}

const PourDetailsCard = ({
  pour,
  pourNoteFile,
  pourNoteDownloading,
  displayUnitPreferences,
  unitSystemPreference,
  specificGravity,
  heightOfCylinder,
  mixerCoefficients,
  yieldStressA,
  yieldStressB,
  tailingsSolidsDensity,
  binderParticleDensity,
  carrierFluidDensity,
  fillType,
  throughputControlType,
  rheologyDataMissing,
  unApprovedIsLoading,
  onAbandonIsLoading,
  voidIsLoading,
  targetDaysCoefficients,
  onVoidPourNote,
  onAbandonPour,
  onUnapprovePourNote,
  onDownloadPourNote,
}: PourDetailsCardProps) => {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader
        action={(
          <Box mt={1} mr={1}>
            <PourStatusChip pourStatus={pour.status} />
          </Box>
        )}
        title={pour.reference}
        subheader={(
          <Typography variant="caption">
            <Temporal temporal={pour.intendedPourDate} options={DEFAULT_SHORT_DATE_CONFIG} />
          </Typography>
        )}
      />

      <Divider />
      {pour && (
        <>
          <CardContent className={classes.cardContent}>
            <Box p={2} pb={0}>
              <Typography variant="overline">{`Recipe: ${pour.recipeReference}`}</Typography>
            </Box>
            <Box p={2}>
              {!rheologyDataMissing && <Alert severity="error">Rheology data no longer available</Alert>}
            </Box>
            <PourDetails
              pour={pour}
              displayUnitPreferences={displayUnitPreferences}
              unitSystemPreference={unitSystemPreference}
              specificGravity={specificGravity}
              heightOfCylinder={heightOfCylinder}
              mixerCoefficients={mixerCoefficients}
              yieldStressA={yieldStressA}
              yieldStressB={yieldStressB}
              fillType={fillType}
              throughputControlType={throughputControlType}
              tailingsSolidsDensity={tailingsSolidsDensity}
              binderParticleDensity={binderParticleDensity}
              carrierFluidDensity={carrierFluidDensity}
              targetDaysCoefficients={targetDaysCoefficients}
            />
            <Box p={2} pb={0}>
              <ProjectAuthorise permission={Permission.ManagePours}>
                {pour.status !== PourStatus.Abandoned && pour.status < PourStatus.Approved && (
                  <Box pl={1} pt={2} display="flex" flexDirection="row-reverse">
                    <ProgressButton
                      loading={onAbandonIsLoading}
                      onClick={onAbandonPour}
                      disabled={pour.status >= PourStatus.Approved}
                      size="small"
                      variant="contained"
                      color="secondary"
                    >
                      Abandon Pour
                    </ProgressButton>
                  </Box>
                )}
                {pour.status === PourStatus.Approved && (
                  <Box pl={1} pt={2} display="flex" flexDirection="row-reverse">
                    <ProgressButton
                      loading={unApprovedIsLoading}
                      onClick={onUnapprovePourNote}
                      size="small"
                      variant="contained"
                      color="secondary"
                    >
                      Unapprove Pour Note
                    </ProgressButton>
                  </Box>
                )}
              </ProjectAuthorise>
            </Box>
          </CardContent>
          <Divider />
        </>
      )}
      {pour.warnings && pour.warnings.length > 0 && (
        <>
          <CardContent>
            <Typography variant="overline">Warnings</Typography>
            <WarningsList warnings={pour.warnings ?? []} />
          </CardContent>
          <Divider />
        </>
      )}
      {pour.pourNote && (
        <>
          <CardContent>
            <Typography variant="overline">Pour Note</Typography>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <DownloadButton
                  downloading={pourNoteDownloading}
                  buttonProps={
                    {
                      color: 'primary',
                      fullWidth: true,
                      startIcon: <CloudDownloadIcon />,
                    }
                  }
                  buttonText="Pour Note"
                  file={pourNoteFile}
                  onDownload={onDownloadPourNote}
                />
              </Box>
              <ProjectAuthorise permission={Permission.ManagePours}>
                <Box pl={1}>
                  {[PourStatus.PourNoteGenerated, PourStatus.AwaitingApproval].includes(pour.status) && (
                    <ProgressButton
                      loading={voidIsLoading}
                      onClick={onVoidPourNote}
                      size="small"
                      variant="contained"
                      color="secondary"
                    >
                      Void
                    </ProgressButton>
                  )}
                </Box>
              </ProjectAuthorise>
            </Box>
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default PourDetailsCard;
