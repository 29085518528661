import {
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));

interface FormSpacingProps {
  children: React.ReactNode
}

const FormSpacing = ({ children }: FormSpacingProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

export default FormSpacing;
