import makePage from 'modules/makePage';
import Page from 'modules/Page';
import * as parentConstants from 'modules/project-admin/ProjectsPage/constants';
import { toTitleCase } from 'utils';
import * as constants from './constants';
import { ProjectDetailsPageParams } from './constants';
import ProjectDetailsPage from './ProjectDetailsPage';
import useProjectDetailsQueries, { ProjectDetailsQueries } from './useProjectDetailsQueries';

const page: Page = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: true,
  component: makePage<ProjectDetailsPageParams, ProjectDetailsQueries>({
    WrappedPage: ProjectDetailsPage,
    titleFn: ([project]) => (project.data ? `${toTitleCase(project.data.name)} Details` : ''),
    parentPathTemplate: parentConstants.ROUTE_PATH,
    parentTitleFn: () => parentConstants.TITLE,
    pageDataHook: useProjectDetailsQueries,
  }),
};

export default page;
