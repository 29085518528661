import {
  UpdateUserDto,
  useUserQuery
} from 'providers/api';
import { UseQueryResult } from 'react-query';
import { UserDetailsPageParams } from './constants';

export type UserDetailsQueries = [UseQueryResult<UpdateUserDto>]

const useUserDetailsQueries = (params: UserDetailsPageParams): UserDetailsQueries => [useUserQuery(params.userId)];

export default useUserDetailsQueries;
