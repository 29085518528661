export interface NewPourPageParams {
  projectId: string;
  stopeId: string;
  recipeId: string;
}

export const ROUTE_PARAM_IDENTIFIER = ':projectId';
export const STOPE_PARAM_IDENTIFIER = ':stopeId';
export const RECIPE_PARAM_IDENTIFIER = ':recipeId';

export const ROUTE_PATH = `/projects/${ROUTE_PARAM_IDENTIFIER}/overview/stopes/${STOPE_PARAM_IDENTIFIER}/recipes/${RECIPE_PARAM_IDENTIFIER}/new-pour`;
export const TITLE = 'New Pour';
