import Vector from '../../Diagra/Vector';
import PCNode from '../../DiagraExtended/PCNode';
import { vectorInDOMRect } from '../../genericFunctions';
import GuiState from './GuiState';

export function findAverageScreenCoordinates(screenRect: DOMRect, nodes: Record<string, PCNode> | PCNode[]) {
  let totalX = 0;
  let totalY = 0;
  let totalZ = 0;
  let count = 0;
  Object.values(nodes).forEach((node) => {
    if (vectorInDOMRect(screenRect, node.screenVector)) {
      totalX += node.screenVector.x;
      totalY += node.screenVector.y;
      totalZ += node.screenVector.z;
      count += 1;
    }
  });
  const avgX = totalX / count;
  const avgY = totalY / count;
  const avgZ = totalZ / count;
  const centreOfGravity = new Vector(avgX, avgY, avgZ);
  return centreOfGravity;
}

export function rotateNodesWithVector(nodes: Record<string, PCNode> | PCNode[], COG: Vector, x: number, y: number, z: number) {
  Object.values(nodes).forEach((node) => {
    // const m = copyNodes[id];
    node.screenVector.rotate(COG, x, y, z);
  });
}

export function rotateNodesWithMousePos(guiState: GuiState, nodes: Record<string, PCNode>, pos: Vector, modelRect: DOMRect) {
  console.log('rotate');
  const currentGuiState = guiState;
  let x = 0;
  let y = 0;
  let z = 0;
  if (currentGuiState.rotateAxis === 'za') {
    x = 0;
    y = 0;
    z = ((pos.y - currentGuiState.lastMousePos.y) / currentGuiState.screenHeight) * Math.PI;
  } else if (currentGuiState.rotateAxis === 'zb') {
    x = 0;
    y = 0;
    z = ((pos.y - currentGuiState.lastMousePos.y) / currentGuiState.screenHeight) * Math.PI * -1;
  } else if (currentGuiState.rotateAxis === 'xy') {
    x = ((pos.y - currentGuiState.lastMousePos.y) / currentGuiState.screenHeight) * Math.PI * 2;
    y = ((pos.x - currentGuiState.lastMousePos.x) / currentGuiState.screenHeight) * Math.PI * -2;
    z = 0;
  }
  const COG = findAverageScreenCoordinates(modelRect, nodes);
  currentGuiState.centreOfGravity = COG;
  rotateNodesWithVector(nodes, COG, x, y, z);
  currentGuiState.currentRotation = new Vector(x, y, z);
}

export function panNodes(list: Record<string, PCNode> | PCNode[], vector: Vector) {
  console.log('pan');
  Object.values(list).forEach((node) => {
    node.screenVector.add(vector);
  });
}

export function findCentre(min: number, max: number) {
  return (min + max) / 2;
}

export function centrePlot(nodes: Record<string, PCNode> | PCNode[], screenWidth: number, screenHeight: number) {
  // Find extents in x, y, and z directions
  let s = 1;
  const aNode = Object.values(nodes)[0];
  let minX = aNode.screenVector.x;
  let maxX = aNode.screenVector.x;
  let minY = aNode.screenVector.y;
  let maxY = aNode.screenVector.y;
  let minZ = aNode.screenVector.z;
  let maxZ = aNode.screenVector.z;

  Object.values(nodes).forEach((node) => {
    const n = node;
    if (n.screenVector.x < minX) { minX = n.screenVector.x; }
    if (n.screenVector.x > maxX) { maxX = n.screenVector.x; }
    if (n.screenVector.y < minY) { minY = n.screenVector.y; }
    if (n.screenVector.y > maxY) { maxY = n.screenVector.y; }
    if (n.screenVector.z < minZ) { minZ = n.screenVector.z; }
    if (n.screenVector.z > maxZ) { maxZ = n.screenVector.z; }
  });
  // console.log("min and max", minX, maxX, minY, maxY, minZ, maxZ);
  const centreX = findCentre(minX, maxX);
  const centreY = findCentre(minY, maxY);
  const centreZ = findCentre(minZ, maxZ);
  const centreV = new Vector(centreX, centreY, centreZ);
  const scaleX = Math.abs((maxX - minX) / screenWidth);
  const scaleY = Math.abs((maxY - minY) / screenHeight);
  if (scaleX > scaleY) { // zoom to fit screen
    s = (1 / scaleX) * 0.5;
  } else {
    s = (1 / scaleY) * 0.5;
  }
  // move to centralise on origin, scale, then reposition
  // console.log("centre", centreV);
  Object.values(nodes).forEach((node) => {
    const n = node;
    n.screenVector.sub(centreV);
    n.screenVector.scale(s);
    n.screenVector.add(new Vector(screenWidth / 2.3, screenHeight / 2, 0));
  });
}

// TODO: Below used in initial setup
// const originNode = Object.values(nodeObjs).find((node) => node.pointId === node.previousId);
// setupVector = new Vector(originNode!.x - screenWidth / 2, o!.y - screenHeight / 2, o!.z);

// Object.values(nodes).forEach((node) => {
//   const j = node;
//   j.sub(setupVector!);
// });

  // const zoom = (x: number) => {
  //   Object.values(nodes).forEach((node) => {
  //     const n = node;
  //     n.scale(x);
  //   });

  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   scale *= x;
  // };

  // const pan = (x: number, y: number) => {
  //   // Pan the image
  //   const n = Object.values(nodes).find((node) => node.id === node.previous.id)!;
  //   const d = new Vector(x, y, 0); // new position of nodes[0]
  //   n.drag(d, Object.values(nodes)); // if pan active, move the nodes using first node arbitrarily
  // };
