export interface PourDetailsPageParams {
  projectId: string;
  stopeId: string;
  pourId: string;
}

export const ROUTE_PARAM_IDENTIFIER = ':projectId';
export const STOPE_PARAM_IDENTIFIER = ':stopeId';
export const POUR_PARAM_IDENTIFIER = ':pourId';

export const ROUTE_PATH = `/projects/${ROUTE_PARAM_IDENTIFIER}/overview/stopes/${STOPE_PARAM_IDENTIFIER}/pours/${POUR_PARAM_IDENTIFIER}`;
export const TITLE = 'Pour Details';
