import { Instant, ZonedDateTime } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Loading from 'components/Loading';
import { HOUR_IN_MILLISECONDS } from 'config';
import { MakePageProps } from 'modules/makePage';
import {
  UpdateDisclaimerOneDayCommand,
  UpdateDisclaimerSevenDaysCommand,
  useAcceptDisclaimerOneDayMutation,
  useAcceptDisclaimerSevenDaysMutation,
  useDisclaimerQuery,
  useProjectQuery
} from 'providers/api';
import { useAuthorisation } from 'providers/authorisation';
import { UseQueryResult } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { DEFAULT_SHORT_DATE_CONFIG, DEFAULT_TIMEZONE, displayTemporal } from 'utils';
import InformationBox from './InformationBox';

export interface withLicenceAndDisclaimerConfig<TParams, TQueries extends UseQueryResult[] = []> {
  Component: React.ComponentType<MakePageProps<TQueries>>,
  projectIdIkey?: string;
  pageDataHook?: (params: TParams) => TQueries;
}

const withLicenceAndDisclaimer = <TParams, TQueries extends UseQueryResult[] = []>({
  Component,
  projectIdIkey = 'projectId',
  pageDataHook,
}: withLicenceAndDisclaimerConfig<TParams, TQueries>) => () => {
  const { functions: { isAdmin }, state: { user, loading: authoriseLoading } } = useAuthorisation();
  const params = useParams<TParams>();
  const history = useHistory();
  const projectId = (params as any)[projectIdIkey];
  const queries = pageDataHook ? pageDataHook(params) : [] as unknown as TQueries;
  if (!projectId) {
    throw new Error('the Page component has been wrapped using the withLicenceAndDisclaimer component but the project id is not present in the url');
  }
   // Bypass and checks for admins
   if (isAdmin()) {
    return <Component parentQueries={queries} />;
  }

  const mutationsByNumberOfDays = {
    1: useAcceptDisclaimerOneDayMutation(user.id),
    7: useAcceptDisclaimerSevenDaysMutation(user.id),
  };

  const handleAcceptDisclaimer = (numberOfDays: keyof typeof mutationsByNumberOfDays) => {
    const command: UpdateDisclaimerOneDayCommand | UpdateDisclaimerSevenDaysCommand = {
      dateAccepted: Instant.now(),
      acceptedTimeZone: DEFAULT_TIMEZONE.id(),
      projectId,
    };

    const mutation = mutationsByNumberOfDays[numberOfDays];
    mutation.mutate(command, {
      onSuccess: () => history.go(0),
    });
  };

  const disclaimerQuery = useDisclaimerQuery(projectId);

  const projectQuery = useProjectQuery(projectId, {
    staleTime: HOUR_IN_MILLISECONDS,
  });

  if (projectQuery.isLoading || disclaimerQuery.isLoading || authoriseLoading) {
    return <Loading />;
  }

  if (projectQuery.isIdle && !projectQuery.data) {
    return <Box> Failed to load project </Box>;
  }

  if (!projectQuery.data) {
    return <Box> Failed to load project </Box>;
  }

  if (disclaimerQuery.isIdle && !disclaimerQuery.data) {
    return <Box> Failed to load disclaimer information </Box>;
  }

  const project = projectQuery.data;
  const disclaimer = disclaimerQuery.data;

  const licenceExpiryDate = project.licence.expiryDate && project.licence.expiryDate.atZone(DEFAULT_TIMEZONE);
  const licenceIsExpired = licenceExpiryDate && ZonedDateTime.now().isAfter(licenceExpiryDate);

  const disclaimerExpiryDate = disclaimer?.expiryDate && disclaimer.expiryDate.atZone(DEFAULT_TIMEZONE);
  const disclaimerIsExpired = disclaimerExpiryDate && ZonedDateTime.now().isAfter(disclaimerExpiryDate);
  const useOfString = `${project.client.name} / ${project.name}`;

  if (disclaimerExpiryDate === undefined || disclaimerIsExpired) {
    return (
      <InformationBox
        title="Disclaimer"
      >
        <p>
          This platform has been developed by MineSmart Solutions LTD for the exclusive use of
          {useOfString}
          .
          The platform contain MineSmart Solutions LTD proprietary algorithm and accordingly may not be distribute to third parties.
        </p>
        <p>
          This Platform is intended as an analysis guideline only. Actual operating conditions must be verified and monitored during paste backfill operations.
          The use of this platform shall be at the sole risk of the user regardless of any fault or negligence or MineSmart Solutions LTD.
        </p>
        <p>
          MineSmart Solutions LTD accepts no responsibility for damages, if any, suffered by any party as a result of decisions or actions based on this analysis tool.
        </p>
        <p>
          The license issues by MineSmart Solutions LTD will expire on:
          {disclaimerExpiryDate ? displayTemporal(disclaimerExpiryDate, DEFAULT_SHORT_DATE_CONFIG) : ' No disclaimer'}
          .
        </p>
        <p>
          MineSmart Solutions LTD can be contacted at: support@minesmart.io
        </p>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" p={2} width="100%">
          <Button variant="outlined" color="primary" onClick={() => handleAcceptDisclaimer(1)}>
            Accept and Continue
          </Button>
          <Box ml={2}>
            <Button variant="outlined" onClick={() => handleAcceptDisclaimer(7)}>
              Accept and mute for 7 days
            </Button>
          </Box>
        </Box>
      </InformationBox>
    );
  }

  if (licenceIsExpired) {
    return (
      <InformationBox title="Licence Expired">
        <p>
          There is no active licence for this project. Please contact MineSmart to acquire a new licence.
        </p>
      </InformationBox>
    );
  }

  return (
    <Component parentQueries={queries} />
  );
};

export default withLicenceAndDisclaimer;
