import { ZonedDateTime, ZoneId } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Initials from 'components/Initials';
import { ProjectDto } from 'providers/api';
import { ReactNode } from 'react';
import { DEFAULT_SHORT_DATE_CONFIG, displayTemporal } from 'utils';
import ImageUpload from './PourDetailsPage/PourDetailsStepper/ImageUpload';

const useStyles = makeStyles((theme: Theme) => createStyles({
  media: {
    paddingBottom: '80%',
    backgroundColor: theme.palette.grey[600],
    position: 'relative',
  },
  warning: {
    color: '#ee7777',
  },
}));

interface ProjectSummaryCardProps {
  project: ProjectDto;
  isUploading?: boolean;
  onSelect?: () => void;
  onNewImage?: (image: File) => void;
  children?: ReactNode;
}

const ProjectSummaryCard = ({ project, isUploading, onSelect, onNewImage, children }: ProjectSummaryCardProps) => {
  const classes = useStyles();

  const handleOnClick = onSelect ? () => onSelect() : undefined;

  const licenceExpiryDate = project.licence.expiryDate && project.licence.expiryDate.atZone(ZoneId.of('Europe/London'));
  const isExpired = licenceExpiryDate && ZonedDateTime.now().isAfter(licenceExpiryDate);

  const CardBody = (
    <>
      <CardContent>
        <Box display="flex-row">
          <Box>
            <Typography gutterBottom variant="h5" component="h2">
              {project.name}
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
              {project.client.name}
            </Typography>
          </Box>
          <Box>
            {licenceExpiryDate && `License Expires: ${displayTemporal(licenceExpiryDate, DEFAULT_SHORT_DATE_CONFIG)} `}
            {isExpired && <Chip color="default" size="small" label="Expired" variant="outlined" className={classes.warning} />}
          </Box>
        </Box>

      </CardContent>
      <CardMedia
        className={classes.media}
        image={project.image?.sizes.thumb}
        title={project.name}
      >
        <Box display="flex" flexDirection="row-reverse" p={1}>
          { onNewImage && <ImageUpload label="Upload Image" onNewImage={onNewImage} Icon={<AddPhotoAlternateIcon />} uploading={isUploading} /> }
        </Box>
        { !project.image?.sizes.thumb && <Initials projectName={project.name} /> }
      </CardMedia>

      {children && (
        <CardContent>
          {children}
        </CardContent>
      )}
    </>
  );

  return (
    <Card>
      { handleOnClick ? (
        <CardActionArea onClick={handleOnClick}>
          {CardBody}
        </CardActionArea>
      ) : (
        <>{CardBody}</>
      )}
    </Card>
  );
};

export default ProjectSummaryCard;
