import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import { Form } from 'hooks/useForm';
import { AddressForm } from 'providers/api';
import { isNil } from 'ramda';
import { FormikUpdateFn } from 'utils';
import {
  object,
  SchemaOf,
  string
} from 'yup';

export const validation: SchemaOf<AddressForm> = object()
  .shape({
    addressLine1: string()
      .required('Address Line 1 is required')
      .max(200, 'Address Line 1 cannot exceed 200 characters'),
    addressLine2: string()
      .notRequired()
      .max(200, 'Address Line 2 cannot exceed 200 characters'),
    city: string()
      .required('City is required')
      .max(200, 'City cannot exceed 200 characters'),
    region: string()
      .required('State/Province/Region is required')
      .max(200, 'State/Province/Region cannot exceed 200 characters'),
    postCode: string()
      .required('ZIP/Postal Code is required')
      .max(200, 'ZIP/Postal Code cannot exceed 10 characters'),
    country: string()
      .required('Country is required')
      .max(200, 'Country cannot exceed 200 characters'),
  });

export const initialData: AddressForm = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  region: '',
  postCode: '',
  country: '',
};

interface AdditionalAddressFieldProps {
  id: string;
  name: string;
  label: string;
  value?: string;
  handleChange: FormikUpdateFn;
  handleBlur: FormikUpdateFn;
  error?: boolean;
  helperText?: string;
  onAdd: () => void;
}

const AdditionalAddressField = ({ id, name, label, value, handleChange, handleBlur, error, helperText, onAdd }: AdditionalAddressFieldProps) => (
  <>
    {
      isNil(value) ? (
        <Box>
          <Button variant="contained" color="default" startIcon={<AddIcon />} onClick={onAdd}>{label}</Button>
        </Box>
      ) : (
        <TextField
          fullWidth
          id={id}
          name={name}
          label={label}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error}
          helperText={helperText}
        />
      )
    }
  </>
);

interface AddressFormFieldsProps {
  baseKey: string,
  values: AddressForm;
  hasError: Form['helpers']['hasError'];
  getErrorHelpText: Form['helpers']['getErrorHelpText'];
  handleChange: FormikUpdateFn,
  handleBlur: FormikUpdateFn,
  handleAddAdditionalAddressField: (field: string) => void
}

const AddressFormFields = ({
  baseKey,
  values,
  hasError,
  getErrorHelpText,
  handleChange,
  handleBlur,
  handleAddAdditionalAddressField,
}: AddressFormFieldsProps) => {
  const makeOnAddHandler = (field: string) => () => {
    handleAddAdditionalAddressField(field);
  };

  return (
    <>
      <TextField
        fullWidth
        required
        id={`${baseKey}.addressLine1`}
        name={`${baseKey}.addressLine1`}
        label="Address Line 1"
        value={values.addressLine1}
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasError(`${baseKey}.addressLine1`)}
        helperText={getErrorHelpText(`${baseKey}.addressLine1`)}
      />
      <AdditionalAddressField
        id={`${baseKey}.addressLine2`}
        name={`${baseKey}.addressLine2`}
        label="Address Line 2"
        value={values.addressLine2}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={hasError(`${baseKey}.addressLine2`)}
        helperText={getErrorHelpText(`${baseKey}.addressLine2`)}
        onAdd={makeOnAddHandler('addressLine2')}
      />
      <TextField
        fullWidth
        required
        id={`${baseKey}.city`}
        name={`${baseKey}.city`}
        label="City"
        value={values.city}
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasError(`${baseKey}.city`)}
        helperText={getErrorHelpText(`${baseKey}.city`)}
      />
      <TextField
        fullWidth
        required
        id={`${baseKey}.region`}
        name={`${baseKey}.region`}
        label="State/Province/Region"
        value={values.region}
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasError(`${baseKey}.region`)}
        helperText={getErrorHelpText(`${baseKey}.region`)}
      />
      <TextField
        fullWidth
        required
        id={`${baseKey}.postCode`}
        name={`${baseKey}.postCode`}
        label="ZIP/Postal Code"
        value={values.postCode}
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasError(`${baseKey}.postCode`)}
        helperText={getErrorHelpText(`${baseKey}.postCode`)}
      />
      <TextField
        fullWidth
        required
        id={`${baseKey}.country`}
        name={`${baseKey}.country`}
        label="Country"
        value={values.country}
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasError(`${baseKey}.country`)}
        helperText={getErrorHelpText(`${baseKey}.country`)}
      />
    </>
  );
};

export default AddressFormFields;
