import { Permission } from './permissions';
import useAuthorisation from './use';

interface AuthoriseProps {
  permission: Permission,
  teamId: string,
  children?: React.ReactNode,
  render?: (isAuthorised: boolean) => React.ReactNode
}

const Authorise = ({ permission, teamId, children, render }: AuthoriseProps) => {
  const { functions: { validatePermission } } = useAuthorisation();

  if (!children && !render) {
    throw new Error('expect either children or render prop');
  }

  const isAuthorised = validatePermission(permission, [teamId]);

  if (render) {
    return <>{render(isAuthorised)}</>;
  }

  if (!isAuthorised) {
    return <></>;
  }

  return <>{children}</>;
};

export default Authorise;
