import Page from 'modules/Page';
import makePage from 'modules/makePage';
import * as parentConstants from 'modules/project-admin/ProjectDetailsPage/constants';
import { toTitleCase } from 'utils';
import EditProjectLicenceExpiryPage from './EditProjectLicenceExpiryPage';
import * as constants from './constants';
import { EditProjectLicenceExpiryPageParams } from './constants';
import useEditProjectQueries, { EditProjectQueries } from './useEditProjectLicenceExpiryQueries';

const page: Page = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: true,
  component: makePage<EditProjectLicenceExpiryPageParams, EditProjectQueries>({
    WrappedPage: EditProjectLicenceExpiryPage,
    titleFn: ([project]) => (project.data ? `Edit ${toTitleCase(project.data.name)} Licence Expiry` : ''),
    parentPathTemplate: parentConstants.ROUTE_PATH,
    parentTitleFn: () => parentConstants.TITLE,
    pageDataHook: useEditProjectQueries,
  }),
};

export default page;
