import Button, { ButtonProps } from '@material-ui/core/Button';
import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';
import clsx from 'clsx';
import csv from 'csvtojson';
import React from 'react';

const useStyles = makeStyles(() => createStyles({
  inputLabel: {
    margin: 0,
  },
}));

const internalErrorMessages: any = {
  file: 'The file selected was not a JSON file.',
  parse: 'Failed to parse JSON.',
};

interface DataUploadProps {
  buttonValue: string;
  buttonProps: ButtonProps;
  onSelect: (data: any) => void;
  onError: (error: string) => void;
  fileFormat?: string;
  csvColParser?: { [column: string]: string }
}

const DataUpload = ({ buttonValue, buttonProps, onSelect, onError, fileFormat = 'json', csvColParser }: DataUploadProps) => {
  const classes = useStyles();
  const [rawJSONData, setRawJSONData] = React.useState<any>(null);
  const [currentFile, setCurrentFile] = React.useState<File | null>();
  const inputRef = React.useRef<HTMLInputElement>(null);

  function readFile(fileToRead: File) {
    // Check if the file is an json
    if (fileToRead.type && !fileToRead.name.endsWith(fileFormat)) {
      onError(internalErrorMessages.file);
      return;
    }

    const reader = new FileReader();

    reader.addEventListener('load', (event) => {
      if (event.target && reader && reader.result) {
        try {
          switch (fileFormat) {
            case 'json':
              setRawJSONData(JSON.parse(reader.result.toString()));
              break;
            case 'csv':
              csv(csvColParser ? { colParser: csvColParser } : {})
                .fromString(reader.result.toString())
                .then((data) => {
                  setRawJSONData(data);
                });
              break;
            default:
              throw new Error(`File format ${fileFormat} not supported`);
          }
        } catch (error) {
          onError(internalErrorMessages.parse);
        }
      }
    });
    reader.readAsText(fileToRead);
  }

  const handleFileChange = (fileList: FileList | null) => {
    if (fileList) {
      setRawJSONData(null);
      setCurrentFile(fileList[0]);
      readFile(fileList[0]);
    }
  };

  React.useEffect(() => {
    if (rawJSONData && currentFile) onSelect(rawJSONData);
    // Reset hidden input so file upload can be reused
    if (inputRef.current?.value) inputRef.current.value = '';
  }, [rawJSONData]);

  return (
    <>
      <Button
        // !Type issue - component prop not part of the ButtonProps type
        {...buttonProps as any}
        component="label"
        className={clsx([buttonProps.className, classes.inputLabel])}
      >
        {buttonValue}
        <input
          ref={inputRef}
          id="data-upload"
          name="file"
          type="file"
          accept={`.${fileFormat}`}
          onChange={(event) => handleFileChange(event.target.files)}
          hidden
        />
      </Button>
    </>
  );
};

export default DataUpload;
