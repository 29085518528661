/* eslint-disable react/jsx-boolean-value */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import {
  AddressFormFields,
  initialData as addressFormInitialData,
  validation as addressFormValidation
} from 'components/AddressFormFields';
import ClientField from 'components/ClientField';
import { FormikActions } from 'components/Form';
import FormSpacing from 'components/Form/FormSpacing';
import {
  GeoPointFormFields,
  initialData as geoPointFormInitialData,
  validation as geoPointFormValidation
} from 'components/GeoPointFormFields';
import TimeZoneField from 'components/TimeZoneField';
import { FormikErrors } from 'formik';
import useForm from 'hooks/useForm';
import {
  CreateProjectCommand,
  DisplayUnits,
  FillType,
  ProjectDto,
  ProjectMutation,
  ThroughputControlType,
  UnitSystem
} from 'providers/api';
import {
  dissocPath,
  partial,
  prop,
  useWith
} from 'ramda';
import React from 'react';
import { UseMutationResult } from 'react-query';
import {
  array, number,
  object,
  string
} from 'yup';

const ProjectSchema = object().shape({
  clientId: string().required('Client is required'),
  command: object().shape({
    project: object().shape({
      name: string().required('Name is required'),
    }).required(),
    site: object().shape({
      siteAddress: addressFormValidation.notRequired().default(undefined),
      siteLocation: geoPointFormValidation,
      timeZone: string().required('Time zone is required'),
    }),
    billing: object().shape({
      email: string().email('Contract email much be a valid email address').required('Contact email is required'),
      contractNumber: string().required('Contract number is required'),
      poNumber: string().required('PO Number is required'),
      billingAddress: addressFormValidation,
    }),
    fillType: number().required('A unit system preference is required'),
    unitSystemPreference: number().required('A unit system preference is required'),
    displayUnitPreferences: array().of(number()),
    throughputControlType: number().oneOf([1, 2, 3]).required('Throughput control type is required'),
  }),
});

type DisplayUnitsKey = keyof typeof DisplayUnits;

const displayUnitTypes: Record<DisplayUnitsKey, string> = {
  DrySolidFeedRate: 'Dry Solid FeedRate',
  SlurryFeedRate: 'Slurry Feed Rate',
  FlowRate: 'Flow Rate',
  SolidsDensity: 'Solids Density',
  SlurryDensity: 'Slurry Density',
  Slump: 'Slump',
  ContinuousMixerPowerDraw: 'Continuous Mixer Power Draw',
  BatchMixerPowerDraw: 'Batch Mixer Power Draw',
};

interface ProjectFormProps {
  project?: ProjectDto;
  mutation: UseMutationResult<string | void, unknown, ProjectMutation>;
}
const ProjectForm = ({ mutation, project }: ProjectFormProps) => {
  const initialProjectRef = React.useRef(project);
  const initialProject = initialProjectRef.current;

  const { formik, helpers } = useForm<ProjectMutation>(
    {
      initialPath: 'command',
      mutation,
      formikConfig: {
        initialValues: {
          clientId: initialProject?.client.entityId ?? '',
          command: {
            project: {
              name: initialProject?.name ?? '',
            },
            site: {
              siteLocation: initialProject?.siteLocation ?? geoPointFormInitialData,
              timeZone: initialProject?.timeZone ?? '',
              siteAddress: initialProject?.siteAddress ?? undefined,
            },
            billing: {
              email: initialProject?.billingEmail ?? '',
              contractNumber: initialProject?.contractNumber ?? '',
              poNumber: initialProject?.poNumber ?? '',
              billingAddress: initialProject?.billingAddress ?? addressFormInitialData,
            },
            fillType: initialProject?.fillType ?? FillType.Paste,
            unitSystemPreference: initialProject?.unitSystemPreference ?? UnitSystem.Metric,
            displayUnitPreferences: initialProject?.displayUnitPreferences ?? [],
            throughputControlType: initialProject?.throughput,
          },
        },
        onSubmit: (form, { setSubmitting }) => {
          setSubmitting(true);
          mutation.mutate(form, {
            onSettled: () => {
              setSubmitting(false);
            },
          });
        },
        validationSchema: ProjectSchema,
      },
    },
  );

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setErrors,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  const handleAddAddress = () => {
    setFieldValue('command.site.siteAddress', addressFormInitialData);
  };

  const handleRemoveAddress = () => {
    setFieldValue('command.site.siteAddress', undefined);
    const updatedErrors = dissocPath<FormikErrors<ProjectMutation>>(['site', 'siteAddress'], errors);
    setErrors(updatedErrors);
  };

  const handleAddAdditionalSiteAddressField = (field: string) => {
    setFieldValue(`command.site.siteAddress.${field}`, '');
  };

  const handleAddAdditionalBillingAddressField = (field: string) => {
    setFieldValue(`command.billing.billingAddress.${field}`, '');
  };

  const handleManualChange = (field: string, value?: string) => {
    setFieldValue(field, value ?? '');
  };

  const handleManualBlur = (field: string) => {
    setFieldTouched(field);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const unit = DisplayUnits[event.target.name as keyof typeof DisplayUnits];
    event.target.checked
      ? setFieldValue('command.displayUnitPreferences', [...values.command.displayUnitPreferences, unit])
      : setFieldValue(
        'command.displayUnitPreferences',
        values.command.displayUnitPreferences.filter((value) => value !== unit),
      );
  };

  return (
    <form onSubmit={handleSubmit}>
      {/**
       * Project Details
       */}
      <Typography variant="subtitle1">Project Details</Typography>
      <Box component={Paper} p={2} mb={2}>
        <TextField
          required
          autoFocus
          fullWidth
          id="name"
          name="command.project.name"
          label="Project Name"
          value={values.command.project.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={helpers.hasError('command.project.name')}
          helperText={helpers.getErrorHelpText('command.project.name')}
          autoComplete="off"
        />
      </Box>

      {/**
       * Client
       */}
      <Typography variant="subtitle1">Client</Typography>
      <Box component={Paper} p={2} mb={2}>
        <ClientField
          required
          disabled={!!project}
          entityId={values.clientId}
          touched={touched.clientId as boolean}
          error={helpers.getErrorHelpText('clientId')}
          onChange={useWith(
            partial(handleManualChange, ['clientId']), // Pre apply the field param
            [prop('entityId')], // transform call param into string
          )}
          onBlur={() => handleManualBlur('clientId')}
        />
      </Box>

      {/**
       * Mine Address
       */}
      <Typography variant="subtitle1">Mine Address</Typography>
      {!values.command.site.siteAddress ? (
        <Box my={2}>
          <Button variant="contained" color="secondary" onClick={handleAddAddress} startIcon={<AddIcon />}>Address</Button>
        </Box>
      ) : (
        <>
          <Box component={Paper} p={2}>
            <FormSpacing>
              <AddressFormFields
                baseKey="command.site.siteAddress"
                values={values.command.site.siteAddress}
                hasError={helpers.hasError}
                getErrorHelpText={helpers.getErrorHelpText}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleAddAdditionalAddressField={handleAddAdditionalSiteAddressField}
              />
            </FormSpacing>
          </Box>
          <Box my={2}>
            <Button variant="contained" color="default" onClick={handleRemoveAddress}>Remove Address</Button>
          </Box>
        </>
      )}

      {/**
       * Location
       */}
      <Typography variant="subtitle1">Mine Location</Typography>
      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <GeoPointFormFields
            baseKey="command.site.siteLocation"
            values={values.command.site.siteLocation}
            hasError={helpers.hasError}
            getErrorHelpText={helpers.getErrorHelpText}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <TimeZoneField
            timeZone={values.command.site.timeZone}
            required
            error={helpers.hasError('command.site.timeZone')}
            helperText={helpers.getErrorHelpText('command.site.timeZone')}
            onChange={partial(handleManualChange, ['command.site.timeZone'])}
            onBlur={() => handleManualBlur('command.site.timeZone')}
          />
        </FormSpacing>
      </Box>

      {/**
       * Billing
       */}
      <Typography variant="subtitle1">Billing Details</Typography>
      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            required
            fullWidth
            id="command.billing.email"
            name="command.billing.email"
            label="Contact Email"
            value={values.command.billing.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('command.billing.email')}
            helperText={helpers.getErrorHelpText('command.billing.email')}
          />
          <TextField
            required
            fullWidth
            id="command.billing.contractNumber"
            name="command.billing.contractNumber"
            label="Contract Number"
            value={values.command.billing.contractNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('command.billing.contractNumber')}
            helperText={helpers.getErrorHelpText('command.billing.contractNumber')}
          />
          <TextField
            required
            fullWidth
            id="name"
            name="command.billing.poNumber"
            label="PO Number"
            value={values.command.billing.poNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('command.billing.poNumber')}
            helperText={helpers.getErrorHelpText('command.billing.poNumber')}
          />
          <AddressFormFields
            baseKey="command.billing.billingAddress"
            values={values.command.billing.billingAddress}
            hasError={helpers.hasError}
            getErrorHelpText={helpers.getErrorHelpText}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleAddAdditionalAddressField={handleAddAdditionalBillingAddressField}
          />
        </FormSpacing>
      </Box>

      {/* INFO: Fill type is only editable on project creation */}
      {!initialProject && (
        <>
          <Typography variant="subtitle1">Fill Type</Typography>
          <Box component={Paper} p={2} mb={2}>
            <Box>
              <TextField
                name="command.fillType"
                fullWidth
                variant="outlined"
                value={(values.command as any).fillType}
                label="Fill Type"
                onChange={handleChange}
                onBlur={handleBlur}
                select
              >
                <MenuItem value={FillType.Paste}>Paste</MenuItem>
                <MenuItem value={FillType.Hydraulic}>Hydraulic</MenuItem>
              </TextField>
            </Box>
          </Box>
        </>
      )}

      {/* INFO: Throughput type is only editable on project creation */}
      {!initialProject && (
      <>
        <Typography variant="subtitle1">Throughput Control Type</Typography>
        <Box component={Paper} p={2} mb={2}>
          <Box>
            <TextField
              name="command.throughputControlType"
              fullWidth
              variant="outlined"
              value={(values.command as CreateProjectCommand).throughputControlType}
              label="Throughput Type"
              onChange={handleChange}
              onBlur={handleBlur}
              select
            >
              <MenuItem value={ThroughputControlType.DryTonnage}>Dry Tonnage</MenuItem>
              <MenuItem value={ThroughputControlType.WetTonnage}>Wet Tonnage</MenuItem>
              <MenuItem value={ThroughputControlType.FlowRate}>Flow Rate</MenuItem>
            </TextField>
          </Box>
        </Box>
      </>
    )}

      {/**
       * Display unit preferences
       */}
      <Typography variant="subtitle1">Display Unit Preferences</Typography>
      <Box component={Paper} p={2}>
        <Box mb={2}>
          <TextField
            name="command.unitSystemPreference"
            fullWidth
            variant="outlined"
            value={values.command.unitSystemPreference}
            label="Unit System"
            onChange={handleChange}
            onBlur={handleBlur}
            select
          >
            <MenuItem value={UnitSystem.Metric}>Metric</MenuItem>
            <MenuItem value={UnitSystem.Imperial}>Imperial</MenuItem>
          </TextField>
        </Box>

        <Grid container spacing={2}>
          {
            Object.entries(displayUnitTypes).map(([key, displayValue]) => (
              <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={values.command.displayUnitPreferences.includes(DisplayUnits[key as keyof typeof DisplayUnits])}
                      onChange={handleCheckboxChange}
                      name={key}
                    />
                  )}
                  label={displayValue}
                />
              </Grid>
            ))
          }
        </Grid>
      </Box>

      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText={initialProject ? 'Edit' : 'Create'}
          right={['reset', 'submit']}
        />
      </Box>
    </form>
  );
};

export default ProjectForm;
