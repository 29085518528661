/* eslint-disable indent */
import {
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import { usePage } from 'providers/page';
import React from 'react';
import { UseQueryResult } from 'react-query';
import {
  useLocation,
  useParams
} from 'react-router-dom';

export interface BasePageProps<TQueries extends UseQueryResult[] = []> {
  queries: [...TQueries];
}
export interface makePageConfig<TParams, TQueries extends UseQueryResult[] = [], TParentQueries extends UseQueryResult[] = []> {
  WrappedPage?: React.ComponentType<BasePageProps<[...TParentQueries, ...TQueries]>>;
  titleFn?: (data: [...TParentQueries, ...TQueries]) => string;
  inheritParent?: boolean;
  parentPathTemplate?: string;
  parentTitleFn?: (data: [...TParentQueries, ...TQueries]) => string;
  pageDataHook?: (params: TParams) => TQueries;
}

const resolveParentPath = (actualPath: string, parentPathTemplate?: string) => {
  if (!parentPathTemplate) {
    return null;
  }

  if (actualPath.startsWith(parentPathTemplate)) {
    return parentPathTemplate;
  }

  const actualPathParts = actualPath.split('/');
  return parentPathTemplate
    .split('/')
    .map((part, index) => (part.includes(':') ? actualPathParts[index] : part))
    .join('/');
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(3),
  },
}));

export const pathMatchesWithPathTemplate = (pathToMatch: string, pathTemplate: string) => {
  const pathToMatchParts = pathToMatch.split('/');
  const pathTemplateParts = pathTemplate.split('/');

  if (pathToMatchParts.length < pathTemplateParts.length) {
    return false;
  }

  const pathToMatchPartsRelativeToTemplate = pathToMatchParts.slice(0, pathTemplateParts.length);

  return pathToMatchPartsRelativeToTemplate.every((part, index) => pathTemplateParts[index].includes(':') || part === pathTemplateParts[index]);
};

export interface MakePageProps<TParentQueries extends UseQueryResult[] = []> {
  parentQueries?: TParentQueries;
}

/* eslint-disable indent */
const makePage = <TParams, TQueries extends UseQueryResult[] = [], TParentQueries extends UseQueryResult[] = []>({
  WrappedPage,
  titleFn,
  inheritParent = false,
  parentTitleFn,
  parentPathTemplate,
  pageDataHook,
}: makePageConfig<TParams, TQueries, TParentQueries>) => ({ parentQueries = [] as unknown as TParentQueries }: MakePageProps<TParentQueries>) => {
  const classes = useStyles();
  const { actions } = usePage();
  const { pathname } = useLocation();
  const params = useParams<TParams>();

  const pageQueries = pageDataHook ? pageDataHook(params) : [] as unknown as TQueries;
  const queries: [...TParentQueries, ...TQueries] = [...parentQueries, ...pageQueries];

  const title = titleFn ? titleFn(queries) : undefined;
  const parentTitle = parentTitleFn ? parentTitleFn(queries) : undefined;
  const resolvedParentPath = resolveParentPath(pathname, parentPathTemplate);

  React.useEffect(() => {
    !inheritParent && actions.setParentPath(resolvedParentPath ?? null);
    !inheritParent && actions.setParentTitle(parentTitle ?? null);
    title !== undefined && actions.setTitle(title);
  }, [actions, resolvedParentPath, title, parentTitle]);

  if (!WrappedPage) {
    return <></>;
  }

  return (
    <section className={classes.root}>
      <WrappedPage queries={queries} />
    </section>
  );
};
/* eslint-enable indent */

export default makePage;
