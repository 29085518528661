import EditableSection from 'components/EditableSection';
import useForm from 'hooks/useForm';
import {
  TeamRolesAssignmentForm,
  UpdateUserDto,
  UpdateUserTeamRolesCommand
} from 'providers/api';
import { difference } from 'ramda';
import React from 'react';
import { UseMutationResult } from 'react-query';
import {
  array,
  object,
  SchemaOf,
  string
} from 'yup';
import ClientPermissions from '../ClientPermissions';
import useClientPermissions from '../ClientPermissions/useClientPermissions';

const UpdateUserPermissionsSchema: SchemaOf<UpdateUserTeamRolesCommand> = object().shape({
  teamRoles: array().min(0).of(
    object().shape({
      teamId: string().required('Required'),
      roles: array().min(0).of(
        string().required('Required'),
      ).required('Required'),
    }),
  ),
});

interface UpdateUserPermissionsFormProps {
  mutation: UseMutationResult<void, unknown, UpdateUserTeamRolesCommand>;
  user: UpdateUserDto;
}
const UpdateUserPermissionsForm = ({ mutation, user }: UpdateUserPermissionsFormProps) => {
  const initialUserRef = React.useRef(user);
  const initialUser = initialUserRef.current;

  let clientTeamErrors = {};

  const {
    formik: {
      setFieldValue,
      isValid,
      isSubmitting,
      handleSubmit,
    },
  } = useForm<UpdateUserTeamRolesCommand>({
    mutation,
    formikConfig: {
      initialValues: {
        teamRoles: initialUser.teamRoles.map((tr) => ({ teamId: tr.teamId, roles: tr.roles })),
      },
      onSubmit: ({ teamRoles }, { setSubmitting }) => {
        const initialTeams = initialUser.teamRoles.map(({ teamId }) => teamId);
        const updatedTeams = teamRoles.map(({ teamId }) => teamId);

        const removedTeams = difference(initialTeams, updatedTeams);

        const formWithRemovedTeams = {
          teamRoles: [...teamRoles, ...removedTeams.map((teamId) => ({ teamId, roles: [] }))],
        };

        if (isValid) {
          setSubmitting(true);
          mutation.mutate(formWithRemovedTeams, {
            onSettled: () => {
              setSubmitting(false);
            },
          });
        }
      },
      validationSchema: UpdateUserPermissionsSchema,
    },
  });

  const handlePermissionsChange = (teamRoles: TeamRolesAssignmentForm[]) => {
    setFieldValue('teamRoles', teamRoles);
  };

  const {
    roles,
    clientTeamErrors: _clientTeamErrors,
    teamRoles,
    activeClientTeams,
    clientTeams,
    setActiveClientTeam,
    resetForm,
    onUpdate,
    onAdd,
    onRemove,
  } = useClientPermissions(handlePermissionsChange, initialUser.teamRoles, initialUser.clients);

  const handleCancel = () => {
    resetForm();
  };

  clientTeamErrors = _clientTeamErrors;

  return (
    <EditableSection
      title="Permissions"
      isSubmitting={isSubmitting}
      isValid={isValid}
      onSave={() => handleSubmit()}
      onCancel={handleCancel}
    >
      {(editMode) => (
        <ClientPermissions
          teamRoles={teamRoles}
          roles={roles ?? []}
          clientErrors={clientTeamErrors}
          activeClientTeams={activeClientTeams ?? null}
          clientTeams={clientTeams}
          onRemove={onRemove}
          onAdd={onAdd}
          onUpdate={onUpdate}
          setActiveClientTeam={setActiveClientTeam}
          disabled={!editMode}
        />
      )}
    </EditableSection>
  );
};

export default UpdateUserPermissionsForm;
