import Vector from '../Diagra/Vector';
import PCNode from '../DiagraExtended/PCNode';
import PCPipe from '../DiagraExtended/PCPipe';
import PCRoute from '../DiagraExtended/PCRoute';
import PCSpool from '../DiagraExtended/PCSpool';
import PCStope from '../DiagraExtended/PCStope';
import Graph from '../Diagra/graph';

function findNodesInSpoolList(route: PCSpool[], includeNodes: PCNode[]) {
  const inclPointIds = includeNodes.map((n) => (n.pointId)); // create a list of pointIds from the nodes to be included in the route
  let toFindCount = includeNodes.length; // marker for if the node is in the list of spools
  for (let i = 0; i < inclPointIds.length; i += 1) {
    const pointId = inclPointIds[i];
    const foundSpoolArr = route.filter((s) => (s.pointIn(pointId))); // list all spools containing the pointId
    if (foundSpoolArr.length > 0) toFindCount -= 1; // subtract from count if any found
  }
  if (toFindCount === 0) return true;
  return false;
}

export function filterRoutesByIncludedNodes(routes: Record<string, PCRoute>, includeNodes: PCNode[]) {
  const filteredRoutes: Record<string, PCRoute> = {};
  if (includeNodes.length > 0) {
    console.log('filtering routes', includeNodes);
    const filteredRouteArr = Object.values(routes).filter((route) => findNodesInSpoolList(route.spools, includeNodes));
    filteredRouteArr.forEach((r, id) => { filteredRoutes[id] = r; });
    return filteredRoutes; // if nodes must be included, filter
  } return routes; // if no nodes specified, return the original list
}

function generateGraph(spools: Record<string, PCSpool>) {
  const graph = new Graph();
  Object.values(spools).forEach((spool) => {
    // AB check shouldnt be required as all spools will be ture in AB direction - but just in case
    if (spool.allowFlowAB === true) {
      graph.setEdge(spool.pointAId, spool.pointBId, spool.length());
    }
    if (spool.allowFlowBA === true) {
      graph.setEdge(spool.pointBId, spool.pointAId, spool.length());
    }
  });
  return graph;
}

function findSpool(spools: PCSpool[], pointAId: string, pointBId: string): PCSpool | undefined {
  return spools.find((spool) => (spool.pointAId === pointAId && spool.pointBId === pointBId)
  || (spool.pointAId === pointBId && spool.pointBId === pointAId));
}

function routeListToSpools(nodeRoute: string[], spoolArray: PCSpool[]) {
  const spoolList: PCSpool[] = [];
  for (let i = 0; i < nodeRoute.length - 1; i += 1) {
    const pointAId = nodeRoute[i];
    const pointBId = nodeRoute[i + 1];
    const spool = findSpool(spoolArray, pointAId, pointBId);
    if (spool) {
        spoolList.push(spool);
    } else {
        console.error(`Spool not found for connection: ${pointAId} -> ${pointBId}`);
    }
  }
  return spoolList;
}

function getMatchingPipe(pipes: Record<string, PCPipe>, pipeID: string): PCPipe | undefined {
  const matchingPipe = Object.values(pipes).find((pipe) => pipe.pipeId === pipeID);
  return matchingPipe ?? undefined;
}

function getDischargeNode(stope: PCStope): PCNode {
  // Creates a new node at the position where the stope piping would end.
  // Adds the stope pipe horizontal length to the x and the stope vertical length to the z of the stope coords
  const dischargeNode = new PCNode(
    new Vector(
      stope.originNode.coords.x + (stope.data.pipeHorizontalLength ?? 0),
      stope.originNode.coords.y,
      stope.originNode.coords.z + (stope.data.pipeVerticalLength ?? 0),
    ),
    'dischargeNode',
    stope.data.pipeId,
    0,
    stope.originNode.pointId,
    false,
    stope.originNode,
  );
  return dischargeNode;
}

function getDischargeSpool(stope: PCStope, dischargePipe: PCPipe) {
  const dischargeNode = getDischargeNode(stope);
  const dischargeSpool = new PCSpool(
    {
      spoolId: stope.data.stopeId,
      pointAId: stope.originNode.pointId,
      pointBId: 'dischargeNode',
      pipeId: stope.data.pipeId,
    },
    {
      [stope.data.stopeId]: stope.originNode,
      dischargePoint: dischargeNode,
    },
    {
      [dischargePipe.pipeId]: dischargePipe,
    },
    true,
    true,
  );
  return dischargeSpool;
}

function assignSpoolFlowDirections(spools: PCSpool[], routePointStrings: string[]): PCSpool[] {
  const node = routePointStrings;
  const newSpoolList: PCSpool[] = [];

  // These values are the opposite of the expected as paste flows from the surface to the stope, but wre evaluating from the stope back to the surface
  for (let i = 0; i < spools.length; i += 1) {
    const spool: PCSpool = spools[i];
    if (spool.pointAId === node[i] && spool.pointBId === node[i + 1]) {
      spool.isFlowingFromAtoB = false;
    } else if (spool.pointBId === node[i] && spool.pointAId === node[i + 1]) {
      spool.isFlowingFromAtoB = true;
    } else {
      throw new Error('route point strings and spools dont align');
    }
    newSpoolList.push(spool);
  }
  return newSpoolList;
}

function convertGraphRouteToPCRoute(
  graphRoutes: string[][],
  spoolArray: PCSpool[],
  stope: PCStope,
  _pipeList: Record<string, PCPipe>,
  graph: Graph,
): Record<string, PCRoute> {
  let counter = 0;
  const PCRoutes: Record<string, PCRoute> = {};
  Object.values(graphRoutes).forEach((graphRoute) => {
    const dischargePipe: PCPipe = getMatchingPipe(_pipeList, stope.data.pipeId)!;
    const routeSpools = routeListToSpools(graphRoute, spoolArray);
    const dischargeSpool = getDischargeSpool(stope, dischargePipe);
    const routePointStrings = ['dischargeNode', ...graphRoute];
    const spoolsWithDischargeSpool = [dischargeSpool, ...routeSpools];
    const directionalSpools = assignSpoolFlowDirections(spoolsWithDischargeSpool, routePointStrings);
    PCRoutes[counter] = new PCRoute(
      stope.originNode,
      stope,
      directionalSpools,
      graph.calculatePathLength(graphRoute) + dischargeSpool.length(),
      routePointStrings,
    );
    counter += 1;
  });
  return PCRoutes;
}

function findAllPaths(graph: Graph, startNode: string, endNode: string): string[][] {
  const paths: string[][] = [];

  function depthFirstSearch(currentNode: string, path: string[], visited: Set<string>) {
    visited.add(currentNode);
    path.push(currentNode);

    if (currentNode === endNode) {
      paths.push([...path]);
    } else {
      const successors = graph.predecessors(currentNode);
      Object.values(successors).forEach((successor) => {
        if (!visited.has(successor)) {
          depthFirstSearch(successor, path, visited);
        }
      });
    }

    path.pop();
    visited.delete(currentNode);
  }

  depthFirstSearch(startNode, [], new Set());

  return paths;
}

export function generateAllRoutes(pastePlantNode: PCNode, spools: Record<string, PCSpool>, stope: PCStope, pipeList: Record<string, PCPipe>) {
  // Called:
  // when selected stope has no route listA
  // when stope route list reset
  const startNode = pastePlantNode.pointId;
  const endNode = stope.originNode.pointId;
  const spoolArray = Object.values(spools); // convert dictionary to array
  const graph = generateGraph(spools);
  let routeObjects: Record<string, PCRoute> = {}; // Store all unique paths with their lengths
  const allPaths = findAllPaths(graph, endNode, startNode);
  routeObjects = convertGraphRouteToPCRoute(allPaths, spoolArray, stope, pipeList, graph);
  console.log(routeObjects);
  return routeObjects;
}

export function returnShortestRoute(routes: Record<string, PCRoute>): PCRoute {
  let shortestRoute: PCRoute | undefined;
  Object.values(routes).forEach((route) => {
      if (shortestRoute === undefined || route.routeLength < shortestRoute.routeLength) {
          shortestRoute = route;
      }
  });
  if (shortestRoute === undefined) {
      throw new Error('No routes found');
  }
  return shortestRoute;
}
