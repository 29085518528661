import { ZoneId } from '@js-joda/core';
import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowId, GridToolbarContainer
} from '@material-ui/data-grid/';
import EmailIcon from '@material-ui/icons/Email';
import EmailFailIcon from '@material-ui/icons/Unsubscribe';
import clsx from 'clsx';
import Temporal from 'components/Temporal';
import { PourNoteApprovalDto, PourNoteApprovalStatus } from 'providers/api';
import React from 'react';
import { DEFAULT_SHORT_DATE_TIME_CONFIG } from 'utils';
import ImageUpload from '../ImageUpload';
import PourAttachmentDownload from '../PourAttachmentDownload';
import ApprovalTableFileContextMenu from './ApprovalTableFileContextMenu';

const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
    paddingTop: `${theme.spacing(1)}px !important`,
    paddingBottom: `${theme.spacing(1)}px !important`,
  },
  toolbarSelected: {
    background: theme.palette.secondary.main,
  },
}));

interface ApprovalTableToolbarProps {
  rowsSelectedCount: number;
  onResendEmailSelect: () => void;
}

const ApprovalTableToolbar = ({ rowsSelectedCount, onResendEmailSelect }: ApprovalTableToolbarProps) => {
  const classes = useStyles();

  const toolBarClasses = clsx({
    [classes.toolbar]: true,
    [classes.toolbarSelected]: rowsSelectedCount > 0,
  });

  return (
    <GridToolbarContainer className={toolBarClasses}>
      <Box flex={1}>
        {rowsSelectedCount > 0 && (
          <Typography color="inherit" variant="subtitle1" component="div">
            {`${rowsSelectedCount} selected`}
          </Typography>
        )}
      </Box>
      <Button
        startIcon={<EmailIcon />}
        variant="contained"
        size="small"
        disabled={rowsSelectedCount <= 0}
        onClick={onResendEmailSelect}
        color="primary"
      >
        Re-send approval emails
      </Button>
    </GridToolbarContainer>
  );
};

interface ApprovalTableProps {
  pourId: string;
  data: PourNoteApprovalDto[];
  isEditable: boolean;
  onApproverApprove: (email: string, image?: File) => void;
  onApproverUnapprove: (email: string) => void;
  onResendEmails: (approvers: string[]) => void;
}

const ApprovalTable = ({
  pourId,
  data,
  isEditable,
  onApproverApprove,
  onApproverUnapprove,
  onResendEmails,
}: ApprovalTableProps) => {
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);

  const handleResendClick = () => {
    onResendEmails(selectedRows.map((gr) => gr.toString()));
    setSelectedRows([]);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
    },
    {
      field: 'sent',
      headerName: '',
      editable: false,
      width: 50,
      renderCell: ({ row }: GridCellParams) => {
        const pourNoteApproval = row as PourNoteApprovalDto;
        const { lastMessage } = pourNoteApproval;

        if (lastMessage && !lastMessage.sendError && lastMessage.sent) {
          const zonedDateTime = lastMessage.sent.atZone(ZoneId.systemDefault());
          return (
            <Tooltip title={(
              <>
                {'Last sent: '}
                <Temporal temporal={zonedDateTime} options={DEFAULT_SHORT_DATE_TIME_CONFIG} />
              </>
            )}
            >
              <EmailIcon />
            </Tooltip>
          );
        }

        const sendError = lastMessage?.sendError ?? 'There was an issue sending the last message';

        return (
          <Tooltip title={sendError}>
            <EmailFailIcon color="error" />
          </Tooltip>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 1,
    },
    {
      field: 'signature',
      headerName: 'Evidence',
      editable: false,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => {
        if (!isEditable) {
          row.signatureFile
            ? <PourAttachmentDownload size="small" pourId={pourId} fileDetails={row.signatureFile} />
            : <></>;
        }
        return (
          row.signatureFile
            ? <PourAttachmentDownload size="small" pourId={pourId} fileDetails={row.signatureFile} />
            : (
              <ImageUpload
                disabled={!isEditable || row.status === PourNoteApprovalStatus.Approved}
                label="Upload Evidence"
                onNewImage={(image: File) => onApproverApprove(row.email, image)}
              />
            )
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      width: 120,
      renderCell: ({ row }: GridCellParams) => {
        switch (row.status) {
          case 20:
            return <Chip label="Approved" color="primary" />;
          case -10:
            return <Chip label="Removed" color="secondary" />;
          default:
            return <Chip label="Awaiting" color="secondary" />;
        }
      },
    },
    {
      field: 'Actions',
      headerName: '',
      editable: false,
      width: 120,
      renderCell: ({ row }) => (
        <ApprovalTableFileContextMenu
          onApproveWithoutEvidence={onApproverApprove}
          onApproverUnapprove={onApproverUnapprove}
          pourId={pourId}
          approver={row as PourNoteApprovalDto}
        />
      ),
    },
  ];

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick={!isEditable}
      hideFooter
      rows={data}
      columns={columns}
      checkboxSelection={isEditable}
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedRows(newSelectionModel.selectionModel);
      }}
      selectionModel={selectedRows}
      components={{
        Toolbar: isEditable ? ApprovalTableToolbar : undefined,
      }}
      componentsProps={{
        toolbar: { rowsSelectedCount: selectedRows.length, onResendEmailSelect: handleResendClick },
      }}
      getRowId={(row: any) => row.email}
    />
  );
};

export default ApprovalTable;
