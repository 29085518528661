import React, { ReactNode } from 'react';
import {
  ConfirmationDialog,
  ConfirmationOptions
} from './ConfirmDialog';
import ConfirmationContext from './context';

type PromiseRef = { resolve: () => void; reject: () => void; };
const ConfirmationProvider = ({ children }: { children: ReactNode }) => {
  const [
    confirmationState,
    setConfirmationState,
  ] = React.useState<ConfirmationOptions | null>(null);

  const [open, setOpen] = React.useState(false);

  const awaitingPromiseRef = React.useRef<PromiseRef>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setOpen(true);
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState && confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setOpen(false);
  };

  const handleExited = () => {
    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationContext.Provider>

      { confirmationState && (
        <ConfirmationDialog
          open={open}
          onSubmit={handleSubmit}
          onClose={handleClose}
          onExited={handleExited}
          {...confirmationState}
        />
      )}
    </>
  );
};

export default ConfirmationProvider;
