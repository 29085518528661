import Vector from '../../Diagra/Vector';
import { vectorInDOMRect } from '../../genericFunctions';
import ModelState from '../ModelState';
import GuiState from './GuiState';
import { findAverageScreenCoordinates, panNodes, rotateNodesWithMousePos } from './node3D';

export function hglMouseInteraction(
  pos: Vector,
  act: string,
  _guiState: GuiState,
  ) {
  const guiState = _guiState;
  const { modelState } = guiState;
  const { hglData, selectedRoute } = modelState;
  if (act === 'mousemove' && selectedRoute) {
    guiState.hitBool = false;
    // xScale in m/pixel
    const distance = (pos.x - hglData.xMargin) * hglData.xScale;
    const nodeId = selectedRoute.findNearestNodeIdFromDistanceFromPump(distance);
    if (typeof nodeId !== 'undefined') {
      guiState.hoverNode(nodeId);
    }
  }
}

export function pipe3DMouseInteraction(
  pos: Vector,
  act: string,
  _guiState: GuiState,
  modelRect: DOMRect,
  ) {
  const guiState = _guiState;
  const { modelState } = _guiState;
  const { nodes } = modelState;
  if (act === 'mousemove') {
    guiState.setMousePos(pos); // hover, hit
    if (guiState.rotateStatus && guiState.mouseDownBool) {
      rotateNodesWithMousePos(guiState, nodes, pos, modelRect);
    }
    if (guiState.panStatus && guiState.mouseDownBool) {
      const panVector = new Vector(pos.x - guiState.lastMousePos.x, pos.y - guiState.lastMousePos.y);
      panNodes(nodes, panVector);
    }
    guiState.lastMousePos.x = pos.x;
    guiState.lastMousePos.y = pos.y;
  }
  if (act === 'mousedown') {
    console.log('mouse down', guiState);
    guiState.mouseDownBool = true;
    if (guiState.hitBool && guiState.hoverId && !guiState.panStatus && !guiState.rotateStatus) { // check if any nodes
      modelState.setSelectedNode(guiState.hoverId, true, guiState);
      // guiState.activeRecipeStatus = false;
    }
    if (guiState.rotateStatus && Object.keys(nodes).length) {
      guiState.centreOfGravity = findAverageScreenCoordinates(modelRect, nodes);
      const actualModelWidth = guiState.screenWidth - guiState.drawerWidth - guiState.mainMenuWidth;
      if (pos.x < (actualModelWidth * 0.20) + guiState.mainMenuWidth) {
        guiState.rotateAxis = 'zb';
      } else if (pos.x < (actualModelWidth * 0.8) + guiState.mainMenuWidth) {
          guiState.rotateAxis = 'xy';
      } else if (pos.x < actualModelWidth + guiState.mainMenuWidth) {
        guiState.rotateAxis = 'za';
      }
      guiState.lastMousePos.x = pos.x;
      guiState.lastMousePos.y = pos.y;
    }
  }
  if (act === 'mouseup') {
    guiState.mouseDownBool = false;
  }
}

export function canvasPrecedence(e: MouseEvent, modelState: ModelState, guiState: GuiState) {
  // The purpose of this functin is to place the hgl canvas in front of the model
  // and to set the mouse vector to a location inside the appropriate canvas
  const { hglData } = guiState.modelState;
  const action = e.type;
  const modelRect = guiState.modelCanvas.getBoundingClientRect();
  const mouseVector = new Vector(e.clientX, e.clientY);
  if (vectorInDOMRect(hglData, mouseVector)) {
    mouseVector.x -= hglData.x;
    mouseVector.y -= hglData.y;
    hglMouseInteraction(mouseVector, action, guiState);
  } else if (vectorInDOMRect(modelRect, mouseVector)) {
    mouseVector.x -= modelRect.left;
    mouseVector.y -= modelRect.top;
    pipe3DMouseInteraction(mouseVector, action, guiState, modelRect);
  }
}
