import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import KeyValuePairList from 'components/KeyValuePairList';
import Temporal from 'components/Temporal';
import {
  additionalUnitLabels,
  BasicDisplayUnits, UnitBase,
  unitLabels
} from 'hooks/useDisplayUnits/useDisplayUnits';
import {
  DisplayUnits,
  FillType,
  PourDataDto,
  UnitSystem
} from 'providers/api';
import { DEFAULT_LONG_DATE_TIME_CONFIG, round } from 'utils';
import PourAttachmentDownload from '../PourAttachmentDownload';

interface CompletionSummaryProps {
  pourId: string;
  pourData: PourDataDto;
  unitSystemPreference: UnitSystem;
  fillType: FillType;
}

const buildKeyValuePair = (unitSystemPreference: UnitSystem, value: number, unitInfo: UnitBase, label?: string) => ({
  key: label ?? unitInfo.name,
  values: unitSystemPreference === UnitSystem.Metric
    ? [`${round(value, unitInfo.decimalPlaces)} ${unitInfo.metric.abbreviation}`]
    : [
      `
        ${round(unitInfo.imperial.conversion(value), unitLabels[BasicDisplayUnits.Volume].decimalPlaces)}
        ${unitLabels[BasicDisplayUnits.Volume].imperial.abbreviation}
      `,
    ],
});

const CompletionSummary = ({ pourId, pourData, unitSystemPreference, fillType }: CompletionSummaryProps) => (
  <>
    <Typography variant="subtitle1">Operator Log File</Typography>
    <Box display="flex" component={Paper} p={2} mb={2}>
      {pourData.operatorLogNoteFile && <PourAttachmentDownload pourId={pourId} fileDetails={pourData.operatorLogNoteFile} />}
    </Box>

    <Typography variant="subtitle1">Pour Completion Details</Typography>
    <Box component={Paper} p={2} mb={2}>

      <KeyValuePairList pairs={[
        {
          key: 'Completion Date',
          values: [<Temporal temporal={pourData.pourCompletedDate} options={DEFAULT_LONG_DATE_TIME_CONFIG} />],
        },
        buildKeyValuePair(
          unitSystemPreference,
          pourData.volume ?? 0,
          unitLabels[BasicDisplayUnits.Volume],
        ),
        buildKeyValuePair(
          unitSystemPreference,
          pourData.dryTonnage ?? 0,
          additionalUnitLabels[DisplayUnits.DrySolidFeedRate],
          'Dry Tonnage',
        ),
        {
          key: 'Concentration',
          values: [`${pourData.massConcentration} %m`],
        },
        ...(
          fillType === FillType.Paste
            ? [{ key: 'Cement', values: [`${pourData.binderContent} %`] }]
            : []
        ),
        {
          key: 'Total Cement',
          values: [`${pourData.totalBinder} ${unitSystemPreference === UnitSystem.Metric ? 't' : 'tn'}`],
        },
        ...(
          fillType === FillType.Hydraulic
            ? [{ key: 'Binder:Tailings', values: [`1:${round(100 / ((pourData.binderTailingsRatio ?? 0) * 100), 0)}`] }]
            : []),
      ]}
      />
    </Box>

    <Typography variant="subtitle1">Additional Comments</Typography>
    <Box component={Paper} p={2}>
      {pourData.comments}
    </Box>
  </>
);

export default CompletionSummary;
