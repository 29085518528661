import PCPipe from '../DiagraExtended/PCPipe';
import ModelState from '../Model/ModelState';
import updateFriction from '../Model/updateFriction';
import { updateRecipeState } from '../Model/updateRecipeState';
import { MinVelocityResult, VelocityResult } from '../typesInterfaces';
import { wilsonsNomograph } from './PCOtherFunctions';

export function binderPercentFromRatio(ratio: number) {
  return (ratio / (1 + ratio));
}

// function binderRatioFromPercent(percent: number) {
//   return (percent / (1 - percent));
// }

function findMinVelocity(pipes: Record<string, PCPipe>, flowrate: number) {
  const pipeKeys = Object.keys(pipes);
  const initPipeKey = pipeKeys[0];
  console.log(pipes, pipeKeys, initPipeKey, pipes[initPipeKey]);
  // TODO: calculate velocity again?
  let minVelocity = pipes[initPipeKey].getVelocity(flowrate);
  let returnKey = pipeKeys[0];
  pipeKeys.forEach((key) => {
    const pipe = pipes[key as any];
    const velocity = pipe.getVelocity(flowrate);
    // console.log(pipe, velocity);
    if (velocity < minVelocity) {
      returnKey = key;
      minVelocity = velocity;
    }
  });
  return {
    pipe: pipes[returnKey as any],
    // velocity: unitSystemPreference === UnitSystem.Metric ? minVelocity : speedMetricToImperial(minVelocity),
    velocity: minVelocity,
  };
}

// Function called during optimisation or manual adjustment
export function hydraulicStrengthFrR(
  modelState: ModelState,
  binderTailingsRatio: number,
  dryConcentrationByMass: number,
   // Rheology
   newTailingsSolidsDensity?: number,
   newBinderParticleDensity?: number,
   newCarrierFluidDensity?: number,
   // throughput
   newTailingsDryTonnage?: number,
   newWetFlowRate?: number,
   newWetTonnage?: number,
) {
  const { recipeState } = modelState;
  const { selectedRoute } = modelState;
  const binderConcentration = binderPercentFromRatio(binderTailingsRatio);
  let minV: MinVelocityResult | null = null;
  let settlingV: VelocityResult | null = null;
  let maxPress = 0;
  if (selectedRoute) {
    modelState.setRecipeState(updateRecipeState(
      recipeState,
      dryConcentrationByMass,
      binderConcentration,
      newTailingsSolidsDensity,
      newBinderParticleDensity,
      newCarrierFluidDensity,
      newTailingsDryTonnage,
      newWetFlowRate,
      newWetTonnage,
    ));
    updateFriction(modelState);
    // currentRoute.p = []; // Delete old pressure data
    // TODO: Confirm this exists - selectedRoute.updateRating(pipes, screenHeight);
    if (recipeState.wetFlowRate && recipeState.tailingsSolidsDensity) {
      minV = findMinVelocity(selectedRoute.pipeList, recipeState.wetFlowRate);
      selectedRoute.setMinimumVelocity(minV.velocity);
      settlingV = wilsonsNomograph(recipeState.tailingsSolidsDensity, minV.pipe.insideDiameter);
      if (settlingV) {
        selectedRoute.setSettlingVelocity(settlingV.velocity);
      }
      maxPress = selectedRoute.findMaxPipePressure() ?? 0;
      if (recipeState.wetDensity) {
        recipeState.pumpPressureKpa = selectedRoute.pumpPressureKpa;
      }
    }
  }
  return {
    recipeState,
    hydraulicCalculateResult: {
      minVelocity: minV?.velocity ?? 0,
      settlingVelocity: settlingV?.velocity ?? 0,
      maxPipePressure: maxPress,
    },
  };
}

interface UpdateSteadyStateInputs {
  modelState: ModelState,
  binderConcentration: number,
  drySolidsConcentrationByMass: number,
  newTailingsSolidsDensity?: number,
  newBinderParticleDensity?: number,
  newCarrierFluidDensity?: number,
  newTailingsDryTonnage?: number,
  newWetFlowRate?: number,
  newWetTonnage?: number,
}

const updateSteadyState = ({
  modelState,
  binderConcentration,
  drySolidsConcentrationByMass,
  newTailingsSolidsDensity,
  newBinderParticleDensity,
  newCarrierFluidDensity,
  newTailingsDryTonnage,
  newWetFlowRate,
  newWetTonnage,
}: UpdateSteadyStateInputs) => {
  const { recipeState, selectedRoute } = modelState;
  if (selectedRoute) {
   modelState.setRecipeState(updateRecipeState(
      recipeState,
      drySolidsConcentrationByMass,
      binderConcentration,
      newTailingsSolidsDensity,
      newBinderParticleDensity,
      newCarrierFluidDensity,
      newTailingsDryTonnage,
      newWetFlowRate,
      newWetTonnage,
    ));
    updateFriction(modelState);
    if (recipeState.wetDensity) {
      // eslint-disable-next-line no-param-reassign
      modelState.recipeState.pumpPressureKpa = selectedRoute.pumpPressureKpa;
    }
    selectedRoute.findWarnings();
  }
};

export function hydraulicRecipeFrS(
  modelState: ModelState,
  binderTailingsRatio: number,
  // Rheology
  newTailingsSolidsDensity?: number,
  newBinderParticleDensity?: number,
  newCarrierFluidDensity?: number,
  // throughput
  newTailingsDryTonnage?: number,
  newWetFlowRate?: number,
  newWetTonnage?: number,
) {
  const binderConcentration = binderPercentFromRatio(binderTailingsRatio);
  const { fluid, selectedRoute } = modelState;
  // let { recipeState } = modelState;
  const { min, max, interval } = fluid.concentrationRange;
  const drySolidsConcentrationByMass = min;
  let count = 0;

  const steadyStateInputs: UpdateSteadyStateInputs = {
    modelState,
    binderConcentration,
    drySolidsConcentrationByMass,
    newTailingsSolidsDensity,
    newBinderParticleDensity,
    newCarrierFluidDensity,
    newTailingsDryTonnage,
    newWetFlowRate,
    newWetTonnage,
  };

  if (selectedRoute) {
    for (steadyStateInputs.drySolidsConcentrationByMass = min;
      steadyStateInputs.drySolidsConcentrationByMass < max + interval;
      steadyStateInputs.drySolidsConcentrationByMass += interval
    ) {
      updateSteadyState(steadyStateInputs);
      count += 1;

      const hasOverPressureWarning = selectedRoute.warnings.some((warning) => warning.code === 'OP');

      if (modelState.recipeState.pumpPressureKpa! > 10) break;
      if (hasOverPressureWarning) break;
      if (count > 1000) break;
    }

    steadyStateInputs.drySolidsConcentrationByMass -= interval;
    if (steadyStateInputs.drySolidsConcentrationByMass > max) steadyStateInputs.drySolidsConcentrationByMass = max;
    if (steadyStateInputs.drySolidsConcentrationByMass < min) steadyStateInputs.drySolidsConcentrationByMass = min;

    updateSteadyState(steadyStateInputs);
  }
}
