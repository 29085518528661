import { LocalDateTime } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { FormikActions } from 'components/Form';
import FormSpacing from 'components/Form/FormSpacing';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import useForm from 'hooks/useForm';
import UnitSystemNumberField from 'modules/project/ProjectAreaPage/ProjectStopesPage/StopePanelPage/RecipesTab/RecipeForm/UnitSystemNumberField';
import { useProject } from 'modules/project/providers/project';
import {
  BackfillRecipeDto,
  FillType,
  PourForm,
  PourMutation,
  UnitSystem
} from 'providers/api';
import React from 'react';
import { UseMutationResult } from 'react-query';
import { makeLocalDateTimeUtils } from 'utils';
import generatePourSchema, { PourFormInput, pourSchemaInitialValues } from './PourSchema';
import WarningListInput from './WarningListInput';

interface NewPourFormProps {
  stopeId: string;
  recipe: BackfillRecipeDto;
  unitSystemPreference: UnitSystem;
  fillType: FillType;
  mutation: UseMutationResult<string, unknown, PourMutation, unknown>;
}

const NewPourForm = ({ stopeId, recipe, unitSystemPreference, fillType, mutation }: NewPourFormProps) => {
  const { state: { timeZone } } = useProject();
  const minDateRef = React.useRef(
    LocalDateTime
      .now(timeZone)
      .withHour(0)
      .withMinute(0)
      .withSecond(0)
      .withNano(0),
  );

  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  const {
    formik,
    helpers,
  } = useForm<PourFormInput>({
    mutation,
    formikConfig: {
      initialValues: pourSchemaInitialValues(),
      onSubmit: (pourForm, { setSubmitting }) => {
        setSubmitting(true);
        const pourPayload: PourMutation = {
          recipeId: recipe.entityId,
          recipeReference: recipe.reference,
          stopeId,
          data: pourForm as PourForm,
        };
        mutation.mutate(pourPayload, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: generatePourSchema(recipe.warnings ? recipe.warnings.length : 0, minDateRef.current),
    },
  });

  const {
    values,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setTouched,
    setErrors,
    validateForm,
  } = formik;

  const handleAcceptedWarningsChange = (warningCode: string) => {
    if (values.acceptedWarnings.some((code) => code === warningCode)) {
      setFieldValue('acceptedWarnings', values.acceptedWarnings.filter((code) => code !== warningCode));
    } else {
      setFieldValue('acceptedWarnings', [...values.acceptedWarnings, warningCode]);
    }
  };

  const handlePlannedDateChange = async (value: LocalDateTime | null) => {
    if (value) {
      const processedDateTime = value.withHour(0).withMinute(0).withSecond(0).withNano(0);
      setFieldValue('plannedDate', processedDateTime);
      setTouched({
        ...touched,
        plannedDate: true,
      } as any);
      const e = await validateForm({ ...values, plannedDate: processedDateTime });
      setErrors(e);
    }
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Typography variant="subtitle1">Pour Details</Typography>
        <Box component={Paper} p={2} mb={2}>
          <FormSpacing>
            <TextField
              error={helpers.hasError('reference')}
              helperText={helpers.getErrorHelpText('reference')}
              required
              autoFocus
              fullWidth
              id="reference"
              name="reference"
              label="Pour Reference"
              value={values.reference}
              onChange={(e) => setFieldValue('reference', e.target.value)}
              onBlur={handleBlur}
            />

            <MuiPickersUtilsProvider utils={makeLocalDateTimeUtils(timeZone)}>
              <KeyboardDatePicker
                open={datePickerOpen}
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                onClick={() => setDatePickerOpen(true)}
                fullWidth
                format="dd/MM/yyyy"
                mask="__/__/____"
                placeholder="__/__/____"
                id="plannedDate"
                name="plannedDate"
                label={`Pour Date (${timeZone})`}
                value={values.plannedDate}
                onChange={handlePlannedDateChange}
                minDate={minDateRef.current}
                onBlur={handleBlur}
                error={helpers.hasError('plannedDate')}
                helperText={helpers.getErrorHelpText('plannedDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputProps={{
                  autoComplete: 'off',
                  onClick: () => {
                    setDatePickerOpen(true);
                    setFieldValue('plannedDate', null);
                  },
                }}
              />
            </MuiPickersUtilsProvider>

            <UnitSystemNumberField
              unitSystem={unitSystemPreference}
              unitInfo={unitLabels[BasicDisplayUnits.Volume]}
              decimalPlaces={2}
              type="number"
              error={helpers.hasError('volume')}
              helperText={helpers.getErrorHelpText('volume')}
              required
              fullWidth
              id="volume"
              name="volume"
              label="Volume"
              value={values.volume}
              onChange={(e) => setFieldValue('volume', e.target.value)}
              onBlur={handleBlur}
            />

            {
              fillType === FillType.Hydraulic && (
                <>
                  <TextField
                    error={helpers.hasError('volumeRetainedAfterOutflow')}
                    helperText={helpers.getErrorHelpText('volumeRetainedAfterOutflow')}
                    required
                    autoFocus
                    fullWidth
                    type="number"
                    id="volumeRetainedAfterOutflow"
                    name="volumeRetainedAfterOutflow"
                    label="Volume Retained After Outflow (%)"
                    value={values.volumeRetainedAfterOutflow}
                    onChange={(e) => setFieldValue('volumeRetainedAfterOutflow', e.target.value)}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />

                  <UnitSystemNumberField
                    unitSystem={unitSystemPreference}
                    unitInfo={unitLabels[BasicDisplayUnits.Volume]}
                    decimalPlaces={2}
                    type="number"
                    fullWidth
                    disabled
                    label="Final Fill Volume"
                    value={
                      values.volumeRetainedAfterOutflow
                        ? values.volume * (values.volumeRetainedAfterOutflow / 100)
                        : values.volume
                    }
                    InputLabelProps={{
                      shrink: Boolean(values.volumeRetainedAfterOutflow || values.volume),
                    }}
                  />
                </>
              )
            }

          </FormSpacing>
        </Box>

        <Typography variant="subtitle1">Recipe</Typography>
        <Box component={Paper} p={2} mb={2}>
          <FormSpacing>
            <TextField
              disabled
              fullWidth
              id="recipe"
              name="recipe"
              label="Recipe"
              value={recipe.reference}
            />
            {
              recipe.warnings
              && recipe.warnings.length > 0
              && <WarningListInput acceptedWarnings={values.acceptedWarnings} warnings={recipe.warnings ?? []} disabled={false} onChange={handleAcceptedWarningsChange} />
            }
          </FormSpacing>
        </Box>

        <Box mt={2}>
          <FormikActions
            formik={formik}
            mutation={mutation}
            submitText="Create"
            right={['reset', 'submit']}
          />
        </Box>
      </form>
    </Container>
  );
};

export default NewPourForm;
