import Box from '@material-ui/core/Box';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import { usePage } from 'providers/page';
import React from 'react';
import { UseQueryResult } from 'react-query';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams
} from 'react-router-dom';
import {
  CSSTransition,
  SwitchTransition
} from 'react-transition-group';
import { CustomRouteProps } from 'utils';
import {
  MakePageProps,
  pathMatchesWithPathTemplate
} from './makePage';

export interface withNavigationConfig<TParams, TQueries extends UseQueryResult[] = []> {
  Component: React.ComponentType<MakePageProps<TQueries>>,
  defaultRoute: string;
  pageNavigation: {
    title: string;
    iconComponent: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    routeProps: CustomRouteProps;
    Component: React.ComponentType<MakePageProps<TQueries>>;
  }[],
  pageDataHook?: (params: TParams) => TQueries;
}

const withNavigation = <TParams, TQueries extends UseQueryResult[] = []>({
  Component,
  defaultRoute,
  pageNavigation = [],
  pageDataHook,
}: withNavigationConfig<TParams, TQueries>) => () => {
  const location = useLocation();
  const { actions } = usePage();
  const { setSubNavigation } = actions;
  const params = useParams<TParams>();

  const queries = pageDataHook ? pageDataHook(params) : [] as unknown as TQueries;

  React.useEffect(() => {
    setSubNavigation(pageNavigation.map(({ title, iconComponent, routeProps }) => ({
      title,
      iconComponent,
      routeProps: { ...routeProps, path: generatePath(routeProps.path, params) },
    })));

    return () => {
      setSubNavigation([]);
    };
  }, [setSubNavigation]);

  /*
  * Find the page with a route that partially matches current location. Use this page path as the route key
  * this prevent a re-render of parent route when a child route changes. Current use is to stop switch transition
  * happening at parent level when child routes change
  */
  const matchingRouteForCurrentLevel = pageNavigation.find((p) => pathMatchesWithPathTemplate(location.pathname, p.routeProps.path));
  const routeKey = matchingRouteForCurrentLevel?.routeProps.path;

  return (
    <Box>
      <Switch>
        <Route path={pageNavigation.map((p) => p.routeProps.path)}>
          <Component parentQueries={queries} />
          <SwitchTransition>
            <CSSTransition
              key={routeKey}
              timeout={200}
              classNames="fade"
              in
            >
              <Switch location={location} key={routeKey}>
                {pageNavigation.map((page) => (
                  <Route
                    key={page.title}
                    {...page.routeProps}
                    render={() => (<page.Component parentQueries={queries} />)}
                  />
                ))}
              </Switch>
            </CSSTransition>
          </SwitchTransition>
        </Route>
        <Route
          exact
          path="*"
          render={() => {
            const generatedRoute = generatePath(defaultRoute, params);
            return <Redirect to={generatedRoute} />;
          }}
        />
      </Switch>
    </Box>
  );
};

export default withNavigation;
