import Pipe from '../Diagra/Pipe';

export default class PCPipe extends Pipe {
  minVelocity: number;

  maxVelocity: number;

  friction?: number;

  constructor(
    pipeName: string,
    pipeId: string,
    clss: string,
    insideDiameter: number,
    pressureRating: number,
    minVelocity?: number,
    maxVelocity?: number,
    wallThickness?: number,
    material?: string,
    ) {
    super(
      pipeName,
      pipeId,
      clss,
      insideDiameter,
      pressureRating,
      wallThickness,
      material,
    );
    this.minVelocity = minVelocity || 0.7;
    this.maxVelocity = maxVelocity || 2.0;
  }
}

  // update(fluid: PCFluid, flowRate: number, concentration: number, tonnage: number, cement: number, rheoLim: any) {
  //   // console.log('update pipe friction', flowRate, concentration, tonnage, cement, rheoLim);
  //   this.friction = fluid.getFriction(concentration, tonnage, cement, rheoLim));
  // }
