import {
  ClientDto,
  useClientQuery
} from 'providers/api';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { EditClientPageParams } from './constants';

export type EditClientQueries = [
  UseQueryResult<ClientDto>
]

const useEditClientQueries = (params: EditClientPageParams): EditClientQueries => {
  const [client, setClient] = React.useState<ClientDto | null>(null);

  const query = useClientQuery(
    params.entityId,
    {
      enabled: !client,
      cacheTime: 0,
    },
  );

  const { data, isFetched } = query;

  React.useEffect(() => {
    if (data && isFetched && !client) {
      setClient(data);
    }
  }, [data, client, isFetched]);

  return [query];
};

export default useEditClientQueries;
