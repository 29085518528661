import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AlertText from 'components/AlertText';
import GoogleMapEmbed from 'components/GoogleMapEmbed';
import { BasePageProps } from 'modules/makePage';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import { ProjectProvider } from 'modules/project/providers/project';
import { useUpdateProjectImageMutation } from 'providers/api';
import { Permission } from 'providers/authorisation';
import React from 'react';
import { useParams } from 'react-router-dom';
import ProjectSummaryCard from '../../ProjectSummaryCard';
import { ProjectAreaPageParams } from '../constants';
import { ProjectAreaQueries } from '../useProjectAreaQueries';
import PourTypeDataTransactionBox from './PourTypeDataTransactionBox';
import StopeDataTransactionBox from './StopeDataTransactionBox';

const useStyles = makeStyles(() => createStyles({
  mapContainer: {},
}));

const ProjectDetailsPage = ({ queries }: BasePageProps<ProjectAreaQueries>) => {
  const [
    projectQuery,
    mineModelQuery, /* mineOverviewQuery */,
    minePourTypesQuery,
  ] = queries;

  const project = projectQuery?.data;
  const mineModel = mineModelQuery?.data;

  const classes = useStyles();
  const theme = useTheme();
  const params = useParams<ProjectAreaPageParams>();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const updateImageMutation = useUpdateProjectImageMutation(params.entityId);

  if (projectQuery.isFetched && !project) {
    throw Error('Issue loading project');
  }

  const handleNewImage = (image: File) => {
    image && updateImageMutation.mutate({ fileName: image.name, data: image });
  };

  if (!project) {
    return <AlertText severity="info">Loading required data</AlertText>;
  }

  return (
    <ProjectProvider project={project}>
      <Box maxWidth={550}>
        {project && (
          <>
            {
              !matches && (
                <GoogleMapEmbed
                  lat={project.siteLocation.latitude ?? 0}
                  lng={project.siteLocation.longitude ?? 0}
                  container={classes.mapContainer}
                />
              )
            }
            <ProjectSummaryCard project={project} onNewImage={handleNewImage} isUploading={updateImageMutation.isLoading} />
            <ProjectAuthorise permission={Permission.ManageStopeData}>
              <Box pt={3}>
                {mineModel && <StopeDataTransactionBox project={project} mineModel={mineModel} mineModelQuery={mineModelQuery} />}
              </Box>
              <Box pt={3}>
                {mineModel && <PourTypeDataTransactionBox project={project} pourTypesQuery={minePourTypesQuery} />}
              </Box>
            </ProjectAuthorise>
          </>
        )}
      </Box>
    </ProjectProvider>
  );
};

export default ProjectDetailsPage;
