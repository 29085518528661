/* eslint-disable react/jsx-boolean-value */
import { Duration, Instant, LocalDateTime } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormikActions } from 'components/Form';
import useForm from 'hooks/useForm';
import {
  ProjectDto,
  UpdateLicenceExpiryMutation
} from 'providers/api';
import React from 'react';
import { UseMutationResult } from 'react-query';
import { DEFAULT_TIMEZONE, makeLocalDateTimeUtils } from 'utils';
import {
  mixed,
  object,
  string
} from 'yup';

const LicenceExpirySchema = object().shape({
  projectId: string().required('Project is required'),
  command: object().shape({
    expiryDate: mixed().required('An expiry date is required'),
    acceptedTimeZone: string().required('A timezone is required'),
  }),
});

interface LicenceExpiryFormProps {
  project?: ProjectDto;
  mutation: UseMutationResult<void, unknown, any, unknown>;
}
const LicenceExpiryForm = ({ mutation, project }: LicenceExpiryFormProps) => {
  const initialProjectRef = React.useRef(project);
  const initialProject = initialProjectRef.current;
  const minDateRef = React.useRef(
    LocalDateTime
      .now(DEFAULT_TIMEZONE)
      .withHour(0)
      .withMinute(0)
      .withSecond(0)
      .withNano(0),
  );

  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  const { formik, helpers } = useForm<UpdateLicenceExpiryMutation>(
    {
      initialPath: 'command',
      mutation,
      formikConfig: {
        initialValues: {
          projectId: initialProject?.entityId ?? '',
          command: {
            expiryDate: initialProject && initialProject.licence.expiryDate ? initialProject.licence.expiryDate : Instant.now().plus(Duration.ofDays(365)),
            acceptedTimeZone: 'Europe/London',
          },
        },
        onSubmit: (form, { setSubmitting }) => {
          setSubmitting(true);
          mutation.mutate(form, {
            onSettled: () => {
              setSubmitting(false);
            },
          });
        },
        validationSchema: LicenceExpirySchema,
      },
    },
  );

  const {
    values,
    touched,
    setFieldValue,
    setErrors,
    handleBlur,
    handleSubmit,
    validateForm,
    setTouched,
  } = formik;

  const handleExpiryDateChange = async (value: LocalDateTime | null) => {
    if (value) {
      const processedDateTime = value.atZone(DEFAULT_TIMEZONE);

      setFieldValue('command.expiryDate', processedDateTime.toInstant());
      setTouched({
        ...touched,
       expiryDate: true,
      } as any);
      const e = await validateForm({ ...values, plannedDate: processedDateTime });
      setErrors(e);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <MuiPickersUtilsProvider utils={makeLocalDateTimeUtils(DEFAULT_TIMEZONE)}>
        <KeyboardDatePicker
          open={datePickerOpen}
          onOpen={() => setDatePickerOpen(true)}
          onClose={() => setDatePickerOpen(false)}
          onClick={() => setDatePickerOpen(true)}
          fullWidth
          format="dd/MM/yyyy"
          mask="__/__/____"
          placeholder="__/__/____"
          id="expiryDate"
          name="expiryDate"
          label="Licence Expiry Date"
          value={LocalDateTime.ofInstant(values.command.expiryDate)}
          onChange={handleExpiryDateChange}
          minDate={minDateRef.current}
          onBlur={handleBlur}
          error={helpers.hasError('plannedDate')}
          helperText={helpers.getErrorHelpText('plannedDate')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputProps={{
            autoComplete: 'off',
            onClick: () => {
              setDatePickerOpen(true);
              setFieldValue('plannedDate', null);
            },
          }}
        />
      </MuiPickersUtilsProvider>

      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText="Update"
          right={['reset', 'submit']}
        />
      </Box>
    </form>
  );
};

export default LicenceExpiryForm;
