import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import EditableSection from 'components/EditableSection';
import useForm from 'hooks/useForm';
import {
  UpdateUserCommand,
  UserDto
} from 'providers/api';
import React from 'react';
import { UseMutationResult } from 'react-query';
import { toTitleCase } from 'utils';
import {
  object,
  SchemaOf,
  string
} from 'yup';

const useStyles = makeStyles((theme: Theme) => createStyles({
  text: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
  },
}));

const UpdateUserSchema: SchemaOf<UpdateUserCommand> = object().shape({
  forename: string().required('First Name is required'),
  surname: string().required('Last Name is required'),
  email: string().email('Email must be a valid email address').required('Email is required'),
  userName: string().required('User Name is required'),
});

interface UpdateUserFormProps {
  mutation: UseMutationResult<void, unknown, UpdateUserCommand>;
  user: UserDto;
}
const UpdateUserForm = ({ mutation, user }: UpdateUserFormProps) => {
  const initialUserRef = React.useRef(user);
  const initialUser = initialUserRef.current;

  const classes = useStyles();

  const {
    formik,
    helpers,
  } = useForm<UpdateUserCommand>({
    mutation,
    formikConfig: {
      initialValues: {
        forename: initialUser.forename,
        surname: initialUser.surname,
        email: initialUser.email,
        userName: initialUser.userName,
      },
      onSubmit: (userUpdate, { setSubmitting }) => {
        setSubmitting(true);
        mutation.mutate(userUpdate, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: UpdateUserSchema,
    },
  });

  const {
    values,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = formik;

  const handleCancel = () => {
    resetForm();
  };

  return (
    <EditableSection
      title="Basic Info"
      isSubmitting={isSubmitting}
      isValid={isValid}
      onSave={() => handleSubmit()}
      onCancel={handleCancel}
    >
      {(editMode) => (
        <>
          <Grid container className={classes.listItem}>
            <Grid item xs={12} sm={3}><Typography variant="overline" color="textSecondary">Name</Typography></Grid>
            <Grid item xs={12} sm={9}>
              {
                editMode ? (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        autoFocus
                        fullWidth
                        id="forename"
                        name="forename"
                        label="First Name"
                        size="small"
                        value={values.forename}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={helpers.hasError('forename')}
                        helperText={helpers.getErrorHelpText('forename')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="surname"
                        name="surname"
                        label="Last Name"
                        size="small"
                        value={values.surname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={helpers.hasError('surname')}
                        helperText={helpers.getErrorHelpText('surname')}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Typography className={classes.text} variant="body1">{toTitleCase(`${user.forename} ${user.surname}`)}</Typography>
                )
              }
            </Grid>
          </Grid>
          <Divider />
          <Grid container className={classes.listItem}>
            <Grid item xs={12} sm={3}><Typography variant="overline" color="textSecondary">Email</Typography></Grid>
            <Grid item xs={12} sm={9}>
              {
                editMode ? (
                  <TextField
                    required
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    size="small"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={helpers.hasError('email')}
                    helperText={helpers.getErrorHelpText('email')}
                  />
                ) : (
                  <Typography className={classes.text} variant="body1">{user.email}</Typography>
                )
              }
            </Grid>
          </Grid>
        </>
      )}
    </EditableSection>
  );
};

export default UpdateUserForm;
