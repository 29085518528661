import { useReactOidc } from '@axa-fr/react-oidc-context';
import axios from 'axios';
import { ReactNode } from 'react';
import {
  BackfillRecipesService,
  ClientsService,
  InvitationsService,
  MineModelsService,
  PoursService,
  PourTypesService,
  ProjectsService, StopeBackfillingService, TeamRolesService, TeamsService, UsersService
} from './api';
import ApiContext, { IApiContext } from './context';

export interface ApiProviderProps {
  children: ReactNode;
}

export default function ApiProvider({ children }: ApiProviderProps) {
  const { oidcUser } = useReactOidc();

  const instance = axios.create({
    headers: { Authorization: `Bearer ${oidcUser?.access_token}` }, // TODO: Check this still works
    transformResponse: (res) => res,
  });

  const clients = new ClientsService(window.location.origin, instance);
  const projects = new ProjectsService(window.location.origin, instance);
  const users = new UsersService(window.location.origin, instance);
  const invitations = new InvitationsService(window.location.origin, instance);
  const teams = new TeamsService(window.location.origin, instance);
  const mineModels = new MineModelsService(window.location.origin, instance);
  const recipes = new BackfillRecipesService(window.location.origin, instance);
  const pours = new PoursService(window.location.origin, instance);
  const teamRoles = new TeamRolesService(window.location.origin, instance);
  const stopes = new StopeBackfillingService(window.location.origin, instance);
  const pourTypes = new PourTypesService(window.location.origin, instance);

  const value: IApiContext = {
    clients,
    projects,
    users,
    teams,
    invitations,
    mineModels,
    recipes,
    pours,
    teamRoles,
    stopes,
    pourTypes,
  };

  return (
    <ApiContext.Provider value={value}>
      {children}
    </ApiContext.Provider>
  );
}
