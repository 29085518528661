import { HOUR_IN_MILLISECONDS } from 'config';
import { useQuery } from 'react-query';
import { ClientTeamsDto, PagedListOfTeamDto } from './api';
import useApi, {
  makeGetQuery,
  makeListQuery,
  PageParams
} from './useApi';

export const CLIENT_TEAMS_KEY = 'client-teams';
export interface ClientTeamsQueryParams extends PageParams {
  searchTerm?: string | null;
}

export const useClientTeamsQuery = makeListQuery<ClientTeamsQueryParams, PagedListOfTeamDto>(
  CLIENT_TEAMS_KEY,
  (api, params) => () => api.teams.listClientTeams(params.page, params.pageSize, params.searchTerm),
);

export const CLIENT_TEAM_KEY = 'client-team';
export const useClientTeamQuery = makeGetQuery<ClientTeamsDto, string>(
  CLIENT_TEAM_KEY,
  (api, entityId) => () => api.teams.getTeamsForClient(entityId),
);

const ROLES_KEY = 'roles';
export const useRolesQuery = () => {
  const api = useApi();
  return useQuery(
    ROLES_KEY,
    () => api.teams.getRoles(),
    {
      staleTime: 24 * HOUR_IN_MILLISECONDS,
    },
  );
};
