import { findAverageScreenCoordinates } from './node3D';
import PCNode from '../../DiagraExtended/PCNode';

export default function setZoom(modelRect: DOMRect, nodes: Record<string, PCNode> | PCNode[], s: number) {
  const COG = findAverageScreenCoordinates(modelRect, nodes);
  Object.values(nodes).forEach((node) => {
    const n = node;
    n.screenVector.sub(COG);
    n.screenVector.scale(s);
    n.screenVector.add(COG);
  });
}
