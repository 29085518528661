import LineGraph from './LineGraph';
import * as GConstants from '../../GConstants';

export default function hglSetup() {
  return new LineGraph({
    x: 5,
    y: 150,
    width: 320,
    height: 250,
    title: 'HGL',
    colour1: GConstants.GREY,
    plotCol: [GConstants.GREEN, GConstants.WHITE, GConstants.RED],
    xInterval: 500,
    yInterval: 250,
    xAxisFloat: 1,
    yAxisFloat: 1,
    xLabel: 'Distance',
    yLabel: 'Elevation',
    labelPos: 'center-out',
    xMirror: 0,
    yMirror: 0,
    xMargin: 35,
    yMargin: 0,
    legend: 1,
    legendPos: 'top-right',
    legendKey: ['Elevation', 'Pressure', 'Rating'],
  });
}
