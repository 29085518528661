function calculateWCfromUCSandDays(
  UCS: number,
  coefficient1: number,
  coefficient2: number,
) {
    // return (data.rheologyData.targetDaysCoefficients[days].coefficient1 * UCS ** data.rheologyData.targetDaysCoefficients[days].coefficient2);
  return coefficient1 * UCS ** coefficient2;
}

function calculateUCSfromWCandDays(
  WC: number,
  coefficient1: number,
  coefficient2: number,
) {
  return (WC / coefficient1) ** (1 / coefficient2);
}

export { calculateWCfromUCSandDays, calculateUCSfromWCandDays };
