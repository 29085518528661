import { AddressDto } from 'providers/api';
import { notNilOrEmpty } from 'utils';

interface AddressProps {
  type: 'inline' | 'traditional';
  address: AddressDto;
}
type AddressParts = string[]

interface AddressTypeProps {
  addressParts: AddressParts
}

const InlineAddress = ({ addressParts }: AddressTypeProps) => <address>{addressParts.join(', ')}</address>;

const TraditionalAddress = ({ addressParts }: AddressTypeProps) => {
  const [first, ...parts] = addressParts;
  return (
    <address>
      {first}
      {
        parts.map((part) => (
          <>
            <br />
            {part}
          </>
        ))
      }
    </address>
  );
};

const Address = ({ type, address }: AddressProps) => {
  const AddressType = type === 'inline' ? InlineAddress : TraditionalAddress;
  const { addressLine1, addressLine2, city, region, postCode, country } = address;
  const addressParts = [addressLine1, addressLine2, city, region, postCode.toLocaleUpperCase(), country]
    .filter(notNilOrEmpty) as string[];

  return <AddressType addressParts={addressParts} />;
};

export default Address;
