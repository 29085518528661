import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Actions from 'components/Actions';
import React from 'react';

const useStyles = makeStyles(() => createStyles({
  title: {
    height: '48px',
  },
}));

interface EditableSectionProps {
  title: string;
  isSubmitting: boolean;
  isValid: boolean;
  onSave: () => void;
  onCancel?: () => void;
  children: (isEdit: boolean) => JSX.Element;
}

const EditableSection = ({ title, isValid, isSubmitting, onSave, onCancel, children }: EditableSectionProps) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const [saveCalled, setSaveCalled] = React.useState(false);

  React.useEffect(() => {
    if (saveCalled && !isSubmitting) {
      setEditMode(false);
    }
  }, [saveCalled, isSubmitting]);

  const handleSave = () => {
    onSave();
    setSaveCalled(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    if (onCancel) onCancel();
  };

  const actions: [string, JSX.Element][] = [
    ['save', (
      <Button variant="contained" color="primary" onClick={handleSave} disabled={isSubmitting || !isValid}>
        Save
      </Button>
    )],
    ['cancel', (
      <Button variant="contained" color="secondary" onClick={handleCancel} disabled={isSubmitting}>
        Cancel
      </Button>
    )],
  ];

  return (
    <>
      <Box component={Paper} p={2} mb={2}>
        <Box className={classes.title} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom>{title}</Typography>
          {
            !editMode && (
              <IconButton onClick={() => setEditMode(true)}>
                <EditIcon />
              </IconButton>
            )
          }
        </Box>
        <Box>
          {children(editMode)}
        </Box>
      </Box>
      {
        editMode && (
          <Box mb={2}>
            <Actions right={actions} />
          </Box>
        )
      }
    </>
  );
};

export default EditableSection;
