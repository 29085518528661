import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import SimpleAutoComplete from 'components/SimpleAutoComplete';
import { HOUR_IN_MILLISECONDS, PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import {
  TeamDto,
  useClientTeamQuery,
  useClientTeamsQuery
} from 'providers/api';
import React from 'react';

interface ClientTeamsFieldProps {
  teamId?: string;
  touched?: boolean;
  error?: string
  disabledOptions?: string[];
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  onChange: (client: TeamDto | null) => void;
  onBlur?: () => void;
}

const ClientTeamsField = ({
  teamId,
  touched,
  error,
  disabled,
  required,
  autoFocus,
  disabledOptions = [],
  onBlur,
  onChange,
}: ClientTeamsFieldProps) => {
  const [open, setOpen] = React.useState(autoFocus ?? false);
  const [value, setValue] = React.useState<TeamDto | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);

  const { data: client, isFetched } = useClientTeamQuery(teamId!, {
    /**
     * Only fetch if there is an entityId and no value. i.e. component is provided with an initial entity
     * id but value has not been set yet
     */
    enabled: !!teamId && !value,
    staleTime: HOUR_IN_MILLISECONDS,
  });
  const initialData = isFetched ? client?.clientTeam : null;

  /**
   * If there is an initial value set it
   */
  React.useEffect(() => {
    setValue(initialData ?? null);
  }, [initialData]);

  const {
    data,
    isFetching,
  } = useClientTeamsQuery({ page: 1, pageSize: PAGINATION_DEFAULT_PAGE_SIZE, searchTerm }, {
    enabled: open,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const getDisabledOptions = (clientTeam: TeamDto) => disabledOptions.includes(clientTeam.teamId);
  const getOptionLabel = (clientTeam: TeamDto) => clientTeam.name;
  const getOptionSelected = (clientTeam: TeamDto, clientTeamValue: TeamDto) => clientTeam.teamId === clientTeamValue.teamId;
  const filterOptions = ((clientTeams: TeamDto[], state: FilterOptionsState<TeamDto>) => clientTeams
    .filter((c) => c.name.toLocaleLowerCase().includes(state.inputValue.toLocaleLowerCase())));
  const noOptionsText = searchTerm ? `No clients matching "${searchTerm}"` : 'No clients';
  const handleOnChange = (clientTeamValue: TeamDto) => {
    setValue(clientTeamValue);
    onChange(clientTeamValue);
  };

  /**
   * Default options with initial data if available
   */
  const options = data?.items ?? (initialData ? [initialData!] : []);

  return (
    <SimpleAutoComplete
      label="Client"
      value={value}
      autoFocus={autoFocus}
      disabled={disabled}
      options={options}
      loading={isFetching}
      getOptionDisabled={getDisabledOptions}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={filterOptions}
      noOptionsText={noOptionsText}
      onChange={handleOnChange}
      onSearch={(term: string) => setSearchTerm(term)}
      onBlur={onBlur}
      touched={touched}
      error={error}
      required={required}
      open={open}
      onOpen={(isOpen: boolean) => setOpen(isOpen)}
    />
  );
};

export default ClientTeamsField;
