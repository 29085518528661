import PCNode from '../../DiagraExtended/PCNode';
import { WARNINGS } from '../../typesInterfaces';

const plotColorKey = (ctx: CanvasRenderingContext2D, originNode: PCNode) => {
  const rowHeight = 16;
  const colWidth = 120;
  const padding = 3;

  const topLeftX = originNode.screenVector.x - (colWidth / 2);
  const topLeftY = originNode.screenVector.y - (rowHeight * 8);

  ctx.globalAlpha = 1;
  ctx.font = '10px Arial';
  ctx.textBaseline = 'top';
  ctx.textAlign = 'left';

  for (let i = 0; i < WARNINGS.length; i += 1) {
    ctx.fillStyle = WARNINGS[i].color;
    ctx.fillRect(topLeftX, topLeftY + (rowHeight * i), colWidth, rowHeight);

    ctx.fillStyle = 'black';
    ctx.fillText(WARNINGS[i].display!, topLeftX + padding, topLeftY + (rowHeight * i) + padding);
  }
};

export default plotColorKey;
