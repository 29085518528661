import Page from 'modules/Page';
import makePage from 'modules/makePage';
import { PourDetailsPageParams } from 'modules/project/PourDetailsPage/constants';
import * as parentConstants from 'modules/project/ProjectAreaPage/constants';
import withLicenceAndDisclaimer from '../withLicenceAndDisclaimer';
import NewPourPage from './PourDetailsPage';
import * as constants from './constants';
import usePourDetailsQueries, { PourDetailsQueries } from './usePourDetailsQueries';

const page: Page<constants.PourDetailsPageParams> = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  authorise: (context, params) => ({
    handler: context.functions.canViewTeam,
    teamId: params.projectId,
  }),
  component: withLicenceAndDisclaimer({
    Component: makePage<PourDetailsPageParams, PourDetailsQueries>({
      WrappedPage: NewPourPage,
      titleFn: ([pour]) => (pour.data ? pour.data.reference : 'Pour Details'),
      parentPathTemplate: `${parentConstants.STOPES_ROUTE_PATH}/:stopeId/pours`,
      parentTitleFn: ([pour, /* project */, minemodel]) => {
        const stopeId = pour.data?.stopeIdentifier?.stopeId;
        const stopeData = minemodel.data?.reticulationData.stopes;

        if (stopeId && !stopeData) return `Stope ${stopeId}`;

        const stope = stopeData && stopeData.find((currentStope) => currentStope.stopeId === stopeId);

        if (stope) return `Stope ${stope.stopeName}`;

        return 'Stope';
      },
      pageDataHook: usePourDetailsQueries,
    }),
  }),
};

export default page;
