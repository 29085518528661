import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Actions from 'components/Actions';
import React from 'react';

interface FormActionsProps {
  isUpdate: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  isOptimised: boolean;
  dirty: boolean;
  resetForm: () => void;
  onCancel: () => void;
  classes: ClassNameMap;
}

const FormActions = ({ isUpdate, isSubmitting, isValid, isOptimised, dirty, resetForm, onCancel, classes }: FormActionsProps) => (
  <Grid container spacing={0}>
    <Grid item xs={12}>
      <Divider className={classes.fullWidthDivider} />
    </Grid>

    <Grid item xs={12}>
      <Actions
        left={[
          ['cancel', (
            <Button variant="contained" color="secondary" onClick={() => onCancel()}>
              Cancel
            </Button>
          )],
        ]}
        right={[
          ['reset', (
            <Button variant="contained" color="secondary" onClick={resetForm}>
              Reset
            </Button>
          )],
          ['submit', (
            <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || !isValid || !dirty || !isOptimised}>
              {isUpdate ? 'Update' : 'Create'}
            </Button>
          )],
        ]}
      />
    </Grid>
  </Grid>
);

export default FormActions;
