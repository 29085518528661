import * as parentConstants from 'modules/client-admin/ClientsPage/constants';
import makePage from 'modules/makePage';
import Page from 'modules/Page';
import { toTitleCase } from 'utils';
import * as constants from './constants';
import { EditClientPageParams } from './constants';
import EditClientPage from './EditClientPage';
import useEditClientQueries, { EditClientQueries } from './useEditClientQueries';

const page: Page = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: true,
  component: makePage<EditClientPageParams, EditClientQueries>({
    WrappedPage: EditClientPage,
    titleFn: ([client]) => (client.data ? toTitleCase(client.data.name) : ''),
    parentPathTemplate: parentConstants.ROUTE_PATH,
    parentTitleFn: () => parentConstants.TITLE,
    pageDataHook: useEditClientQueries,
  }),
};

export default page;
