import Page from 'modules/Page';
import makePage from 'modules/makePage';
import * as parentConstants from 'modules/project-admin/ProjectDetailsPage/constants';
import NewRheologyPage from './NewRheologyPage';
import * as constants from './constants';
import { NewRheologyPageParams } from './constants';
import useNewRheologyDataQueries, { NewRheologyDataQueries } from './useNewRheologyDataQueries';

const page: Page = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: true,
  component: makePage<NewRheologyPageParams, NewRheologyDataQueries>({
    WrappedPage: NewRheologyPage,
    titleFn: () => 'New Rheology Data',
    parentPathTemplate: parentConstants.ROUTE_PATH,
    parentTitleFn: () => parentConstants.TITLE,
    pageDataHook: useNewRheologyDataQueries,
  }),
};

export default page;
