import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { InputType } from './InputGridTypes';

interface DataInputProps {
  input: InputType;
  rowIndex: number;
  inputIndex: number;
  showLabel?: boolean;
  updateValues: (rowIndex: number, columnIndex: number, value: any) => void;
  onBlur?: () => void | undefined;
}

const DataInput = ({ input, rowIndex, inputIndex, showLabel, updateValues, onBlur }: DataInputProps) => (
  <TextField
    label={showLabel ? input.labelShort ?? input.label : undefined}
    select={input.type === 'select'}
    variant="outlined"
    value={input.value}
    fullWidth
    disabled={input.disabled}
    InputProps={{
      endAdornment: input.adornment && <InputAdornment position="end">{input.adornment}</InputAdornment>,
    }}
    onChange={(e) => updateValues(rowIndex, inputIndex, {
      ...input,
      value: e.target.value,
    })}
    onBlur={onBlur}
  >
    {
      input.options && input.options.map((option) => <MenuItem key={option} value={option}>{option}</MenuItem>)
    }
  </TextField>
);

export default DataInput;
