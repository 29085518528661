import { VectorJSON } from './types';

export default class Vector {
  x: number;

  y: number;

  z: number;

  constructor(
    x?: number,
    y?: number,
    z?: number,
  ) {
    this.x = x || 0;
    this.y = y || 0;
    this.z = z || 0;
  }

  negative(): Vector {
    const neg = new Vector(this.x * -1, this.y * -1, this.z * -1);
    return neg;
  }

  set(x: number | Vector, y?: number, z?: number): Vector {
    if (typeof x === 'object') {
      this.x = x.x || 0;
      this.y = x.y || 0;
      this.z = x.z || 0;
    } else {
      this.x = x || 0;
      this.y = y || 0;
      this.z = z || 0;
    }
    return this;
  }

  add(v: Vector): Vector {
    this.x += v.x;
    this.y += v.y;
    this.z += v.z;
    return this;
  }

  sub(v: Vector): Vector {
    this.x -= v.x;
    this.y -= v.y;
    this.z -= v.z;
    return this;
  }

  scale(s: number): Vector {
    this.x *= s;
    this.y *= s;
    this.z *= s;
    return this;
  }

  scaleToLength(len: number): Vector {
    const currentLength = this.length3d();
    const scaleValue = len / currentLength;
    this.scale(scaleValue);
    return this;
  }

  rotate(centre: Vector, ix: number | Vector, iy: number, iz: number) {
    let x;
    let y;
    let z;
    let xx;
    let xy;
    let xz;
    let yx;
    let yy;
    let yz;
    let zx;
    let zy;
    let zz;
    if (typeof ix === 'object') {
      x = ix.x;
      y = ix.y;
      z = ix.z;
    } else {
      x = ix;
      y = iy;
      z = iz;
    }
    const rx = this.x - centre.x || 0; // centralise on origin
    const ry = this.y - centre.y || 0;
    const rz = this.z - centre.z || 0;
    // rotate x axis
    if (x !== 0) {
      xx = rx;
      xy = Math.cos(x) * ry + Math.sin(x) * rz;
      xz = Math.cos(x) * rz - Math.sin(x) * ry;
    } else {
      xx = rx;
      xy = ry;
      xz = rz;
    }
    // rotate y axis
    if (y !== 0) {
      yx = Math.cos(y) * xx - Math.sin(y) * xz;
      yy = xy;
      yz = Math.cos(y) * xz + Math.sin(y) * xx;
    } else {
      yx = xx;
      yy = xy;
      yz = xz;
    }
    // rotate z axis
    if (z !== 0) {
      zx = Math.cos(z) * yx - Math.sin(z) * yy;
      zy = Math.cos(z) * yy + Math.sin(z) * yx;
      zz = yz;
    } else {
      zx = yx;
      zy = yy;
      zz = yz;
    }
    this.x = zx + centre.x; // return screen to original position
    this.y = zy + centre.y;
    this.z = zz + centre.z;
  }

  length3d(): number {
    return Math.sqrt((this.x ** 2) + (this.y ** 2) + (this.z ** 2));
  }

  length2dTo(n: Vector): number {
    return Math.sqrt((this.x - n.x) ** 2 + (this.y - n.y) ** 2);
  }

  length3dTo(n: Vector): number {
    return Math.sqrt((this.x - n.x) ** 2 + (this.y - n.y) ** 2 + (this.z - n.z) ** 2);
  }

  deltaZTo(n: Vector): number {
    return Math.abs(this.z - n.z);
  }

  angleXY(): number { // radians
    return Math.atan2(this.y, this.x);
  }

  angleXYTo(v: Vector): number { // radians
    const dx = v.x - this.x;
    const dy = v.y - this.y;
    return Math.atan2(dy, dx);
  }

  distanceXYTo(v: Vector): number {
    const dx = v.x - this.x;
    const dy = v.y - this.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  crossProduct(v: Vector): Vector {
    const x = this.y * v.z - v.y * this.z;
    const y = this.z * v.x - v.z * this.x;
    const z = this.x * v.y - v.x * this.y;
    return new Vector(x, y, z);
  }

  generateJSON(): VectorJSON {
    return {
      x: this.x,
      y: this.y,
      z: this.z,
    };
  }

  // old methods
  // public length(v: Vector) {
  //   return Math.sqrt(((this.ox - v.ox) ** 2) + ((this.oy - v.oy) ** 2) + ((this.oz - v.oz) ** 2));
  // }

  // public height(v: Vector) {
  //   return this.oz - v.oz;
  // }

  // public angle() {
  //   return Math.atan2(this.y, this.x);
  // }

  // public angleTo(v: { x: number, y: number }) {
  //   const dx = v.x - this.x;
  //   const dy = v.y - this.y;
  //   return Math.atan2(dy, dx);
  // }

  // public distanceTo(v: { x: number, y: number }) {
  //   const dx = v.x - this.x;
  //   const dy = v.y - this.y;
  //   return Math.sqrt(dx * dx + dy * dy);
  // }

  // public distanceToO(v: { ox: number, oy: number }) {
  //   const dx = v.ox - this.ox;
  //   const dy = v.oy - this.oy;
  //   return Math.sqrt(dx * dx + dy * dy);
  // }
}
