import {
  AuthenticationProvider,
  InMemoryWebStorage,
  oidcLog,
  withOidcSecure
} from '@axa-fr/react-oidc-context';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import App from 'App';
import Loading from 'components/Loading';
import { OIDC_CONFIGURATION } from 'config';
import 'index.css';
import { SnackbarProvider } from 'notistack';
import {
  ApiProvider,
  withQueryClientDefaults
} from 'providers/api';
import { AuthorisationProvider } from 'providers/authorisation';
import { ConfirmationProvider } from 'providers/confirm';
import { PageProvider } from 'providers/page';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import theme from 'theme/theme';
import reportWebVitals from './reportWebVitals';

const withAuthorisation = (WrappedComponent: React.ComponentType) => () => <AuthorisationProvider><WrappedComponent /></AuthorisationProvider>;
const AppWithAuthAndApi = withOidcSecure(withQueryClientDefaults(withAuthorisation(App)));

ReactDOM.render(
  <React.StrictMode>
    <PageProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthenticationProvider
          configuration={OIDC_CONFIGURATION}
          loggerLevel={oidcLog.DEBUG}
          isEnabled
          UserStore={InMemoryWebStorage}
          notAuthenticated={Loading}
          notAuthorized={Loading}
          authenticating={Loading}
          callbackComponentOverride={Loading}
        >
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <ApiProvider>
              <ConfirmationProvider>
                <Router basename="app">
                  <Switch>
                    <Route path="*">
                      <AppWithAuthAndApi />
                    </Route>
                  </Switch>
                </Router>
              </ConfirmationProvider>
            </ApiProvider>
          </SnackbarProvider>
        </AuthenticationProvider>
      </ThemeProvider>
    </PageProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
