import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import InputCard from './InputCard';
import { InputRecord, InputType, makeInputRecord } from './InputGridTypes';
import InputRow from './InputRow';

interface InputGridProps {
  inputList: InputRecord[];
  inputType: InputType[];
  forceMobile?: boolean;
  headers: string[];
  onChange?: (listValues: InputRecord[]) => void;
  addRow?: InputType[];
  onBlur?: () => void;
}

const InputGrid = ({ inputList, inputType, forceMobile, headers, onChange, onBlur }: InputGridProps) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('sm'));
  const [localInputList, setLocalInputList] = useState<InputRecord[]>(inputList);

  const handleUpdate = (rowIndex: number, inputIndex: number, inputDetails: InputType) => {
    const updatedList = localInputList.map((row, rIndex) => {
      if (rIndex === rowIndex) {
        const updatedInputs = row.inputs.map((input, iIndex) => (iIndex === inputIndex ? { ...input, ...inputDetails } : input));
        return { ...row, inputs: updatedInputs };
      }
      return row;
    });
    setLocalInputList(updatedList);
    onChange && onChange(updatedList);
  };

  const handleAddRow = () => setLocalInputList((currentInputList) => [...currentInputList, makeInputRecord(inputType)]);

  const handleDeleteRow = (uniqueKey: string) => {
    const updatedList = localInputList.filter((row) => row.uniqueKey !== uniqueKey);
    setLocalInputList(updatedList);
    onChange && onChange(updatedList);
  };

  return (
    <>
      <Box pr={9}>
        <Grid container spacing={1}>
          {headers.map((colData: string) => (
            <Grid item xs key={colData}>
              {colData}
            </Grid>
          ))}
        </Grid>
      </Box>
      {localInputList.map((row: InputRecord, index: number) => {
        const props = { rowId: row.uniqueKey, rowIndex: index, inputs: row.inputs, updateValues: handleUpdate, onBlur };
        if (isLarge && !forceMobile) {
          return (
            <div key={row.uniqueKey} style={{ display: 'flex', alignItems: 'center' }}>
              <InputRow {...props} />
              {index >= 0 && (
                <Button variant="contained" color="secondary" onClick={() => handleDeleteRow(row.uniqueKey)} style={{ marginLeft: '10px' }}>
                  <DeleteIcon />
                </Button>
              )}
            </div>
          );
        }
        return (
          <React.Fragment key={row.uniqueKey}>
            <InputCard {...props} />
            {index < localInputList.length - 1 && (
              <Box my={2}>
                <Divider />
              </Box>
            )}
          </React.Fragment>
        );
      })}
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleAddRow}>
          Add Row
        </Button>
      </Box>
    </>
  );
};

export default InputGrid;
