import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { SnackbarContent, useSnackbar } from 'notistack';
import { take } from 'ramda';
import { forwardRef, useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  alert: {
    width: '100%',
  },
}));

const SnackMessage = forwardRef<HTMLDivElement, { id: string | number, details?: string[], title: string }>((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const limitedDetails = props.details ? take(5, props.details) : undefined;

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Alert variant="filled" severity="error" className={classes.alert} onClose={handleDismiss}>
        <AlertTitle>{props.title}</AlertTitle>
        {limitedDetails ? limitedDetails.map((detail) => <Typography key={detail} variant="body1">{detail}</Typography>) : props.title}
        {limitedDetails && <Typography display="block" align="right" variant="caption">{`${limitedDetails?.length}/${props.details?.length}`}</Typography>}
      </Alert>
    </SnackbarContent>
  );
});

export default SnackMessage;
