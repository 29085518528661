import {
  BasicDisplayUnits,
  UnitBase,
  additionalUnitLabels,
  convertIfRequired,
  getAbbreviation,
  unitLabels
} from 'hooks/useDisplayUnits/useDisplayUnits';
import { round } from 'lodash';
import { DisplayUnits, ThroughputControlType, UnitSystem } from 'providers/api';

export const unitString = (unitSystem: UnitSystem, unitInfo: UnitBase, value?: number) => {
  if (!value) return 'no data';
  const convertedValue = round(convertIfRequired(value, unitSystem, unitInfo), 2);
  const abbreviation = getAbbreviation(unitInfo, unitSystem);
  return `${convertedValue} ${abbreviation}`;
};

export const throughputUnitLabelFromThroughputControlType = (throughputControlType: ThroughputControlType) => {
  let unitLabel;
  switch (throughputControlType) {
    case ThroughputControlType.WetTonnage:
      unitLabel = unitLabels[BasicDisplayUnits.WetTonnage];
      break;
    case ThroughputControlType.FlowRate:
      unitLabel = additionalUnitLabels[DisplayUnits.FlowRate];
      break;
    default:
      unitLabel = unitLabels[BasicDisplayUnits.DryTonnage];
      break;
  }
  return unitLabel;
};

export const displayThroughput = (
  unitSystemPreference: UnitSystem,
  throughputControlType: ThroughputControlType,
  throughput?: number,
) => unitString(unitSystemPreference, throughputUnitLabelFromThroughputControlType(throughputControlType), throughput);
