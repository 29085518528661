import { UnitSystem } from 'providers/api';
import {
  RoutePoint,
  Telemetry
} from '../../typesInterfaces';
import PCNode from '../../DiagraExtended/PCNode';
import { convertDistance, convertPressure, convertSpeed } from './conversions';
import PCStope from '../../DiagraExtended/PCStope';
import { addStopeDataToTelemetry } from './updateStopeTelemetry';

function addRoutePointToNodeTelemetry(
  _nodeTelemetry: Telemetry,
  _routePoint: RoutePoint,
  imperial: boolean,
  ) {
  const nodeTelemetry = _nodeTelemetry;
  const routePoint = { ..._routePoint }; // clone
  const { pipeUpstream: pipeTo, pipeDownstream: pipeFrom, distanceFromPump, pressure } = routePoint;
  if (typeof distanceFromPump !== 'undefined') {
    nodeTelemetry.push(['Distance:', convertDistance(distanceFromPump, imperial)]);
  }
  if (typeof pressure !== 'undefined') {
    nodeTelemetry.push(['Pressure:', convertPressure(pressure, imperial)]);
  }
  if (typeof pipeTo !== 'undefined') {
    const { name, pressureRating, velocity } = pipeTo;
    nodeTelemetry.push(['Pipe upstream:', name]);
    nodeTelemetry.push(['Rating upstream:', convertPressure(pressureRating, imperial)]);
    if (typeof velocity !== 'undefined') {
      nodeTelemetry.push(['Velocity upstream:', convertSpeed(velocity, imperial)]);
    }
  }
  if (typeof pipeFrom !== 'undefined') {
    const { name, pressureRating, velocity } = pipeFrom;
    nodeTelemetry.push(['Pipe downstream:', name]);
    nodeTelemetry.push(['Rating downstream:', convertPressure(pressureRating, imperial)]);
    if (typeof velocity !== 'undefined') {
      nodeTelemetry.push(['Velocity downstream:', convertSpeed(velocity, imperial)]);
    }
  }
}

function addNodeDataToNodeTelemetry(
  _nodeTelemetry: Telemetry,
  _node: PCNode,
  imperial: boolean,
) {
  const nodeTelemetry = _nodeTelemetry;
  const node = { ..._node }; // clone
  nodeTelemetry.push(['Node:', node.pointId.toString()]);
  nodeTelemetry.push(['Elevation:', convertDistance(node.coords.z, imperial)]);
}

// export interface StopeDataDto {
//   stopeId: string;
//   stopeName: string;
//   references: string[];
//   pipeLength: number;
//   pipeId: string;
//   volume: number;
//   level: string;
//   area: string;
//   /** NodaTimeType:Instant */
//   physicalPropertiesUpdated: Instant;
//   /** NodaTimeType:Instant */
//   routeUpdated: Instant;
// }

export default function updateNodeTelemetry(
  node: PCNode,
  routePoint: RoutePoint | undefined,
  stopePipeType: string,
  unitSystemPreference: UnitSystem,
  stope?: PCStope,
  ) {
  const nodeTelemetry: Telemetry = [];
  let imperial = false;
  if (unitSystemPreference === 10) imperial = true;

  // determine which values will be used
  addNodeDataToNodeTelemetry(nodeTelemetry, node, imperial);
  if (typeof routePoint !== 'undefined') {
    addRoutePointToNodeTelemetry(nodeTelemetry, routePoint, imperial);
  }
  if (node.isStope && typeof stope !== 'undefined') {
    addStopeDataToTelemetry(nodeTelemetry, stope, stopePipeType, imperial);
  }
  return nodeTelemetry;
}
