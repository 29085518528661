import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { GridOptions } from '@material-ui/data-grid/';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DataGridWithLoading, { ResponsiveGridColDef } from 'components/DataGrid';
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  PAGINATION_PAGE_SIZES
} from 'config';
import { ClientDto } from 'providers/api';
import React from 'react';

interface ClientActionsMenuProps {
  client: ClientDto;
  onEdit: (clientId: string) => void;
  onDelete: (client: ClientDto) => void;
}

const ClientActionsMenu = ({ client, onEdit, onDelete }: ClientActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(client.entityId);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(client);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls={`client-actions-menu-${client.entityId}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`client-actions-menu-${client.entityId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteForeverIcon color="error" fontSize="small" />
          </ListItemIcon>
          <Typography color="error" variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

interface ClientsTableProps {
  data: ClientDto[] | undefined;
  page: number;
  pageSize: number;
  totalItems: number | undefined;
  onPageChange: GridOptions['onPageChange'];
  onPageSizeChange: GridOptions['onPageSizeChange'];
  onEdit: (clientId: string) => void;
  onDelete: (client: ClientDto) => void;
  loading: boolean;
  loadingNew: boolean;
  searchTerm: string | null;
}

const ClientsTable = ({
  data,
  onPageChange,
  onPageSizeChange,
  onEdit,
  onDelete,
  loadingNew,
  loading,
  page,
  pageSize = PAGINATION_DEFAULT_PAGE_SIZE,
  totalItems = 0,
  searchTerm,
}: ClientsTableProps) => {
  const pageIndex = data ? (page - 1) : 0;
  const rows = data ?? [];

  const columns: ResponsiveGridColDef[] = [
    {
      field: 'name',
      headerName: 'Client Name',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      mobileConfig: {
        width: 200,
        isVisible: true,
      },
    },
    {
      field: '',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 80,
      renderCell: ({ row }) => (<ClientActionsMenu client={row as any} onEdit={onEdit} onDelete={onDelete} />),
    },
  ];

  return (
    <DataGridWithLoading
      loading={loading}
      loadingNew={loadingNew}
      searchTerm={searchTerm}
      searchFields={['name']}
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      idProp="entityId"
      rows={rows}
      columns={columns}
      pagination
      paginationMode="server"
      page={pageIndex}
      pageSize={pageSize}
      rowCount={totalItems}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={PAGINATION_PAGE_SIZES}
    />
  );
};

export default ClientsTable;
