import { ZonedDateTime } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import ProgressButton from 'components/ProgressButton';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import {
  FillType,
  UnitSystem,
  useCreatePourNoteQuery,
  useUpdatePourDateMutation,
  useUpdatePourVolumeMutation
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import UpdatePourDateForm from './UpdatePourDateForm';
import UpdatePourVolumeForm from './UpdatePourVolumeForm';

interface PlannedAreaProps {
  pourReference: string;
  pourId: string;
  fillType: FillType;
  pourDate: ZonedDateTime;
  pourVolume: number;
  volumeRetainedAfterOutflow?: number;
  unitSystemPreference: UnitSystem;
}

const PlannedArea = ({ pourReference, pourId, fillType, pourDate, pourVolume, volumeRetainedAfterOutflow, unitSystemPreference }: PlannedAreaProps) => {
  const createPourNoteMutation = useCreatePourNoteQuery(pourId);
  const updatePourDateMutation = useUpdatePourDateMutation(pourId);
  const updatePourVolumeMutation = useUpdatePourVolumeMutation(pourId);

  const handleGeneratePourNote = () => createPourNoteMutation.mutate({ pourId, pourReference });

  return (
    <>
      <Box mb={2}>
        <UpdatePourDateForm pourDate={pourDate} mutation={updatePourDateMutation} />
      </Box>
      <Box mb={2}>
        <UpdatePourVolumeForm
          fillType={fillType}
          volume={pourVolume}
          volumeRetainedAfterOutflow={volumeRetainedAfterOutflow}
          unitSystemPreference={unitSystemPreference}
          mutation={updatePourVolumeMutation}
        />
      </Box>
      <ProjectAuthorise permission={Permission.ManagePours}>
        <Box component={Paper} p={2}>
          <ProgressButton variant="contained" color="primary" onClick={handleGeneratePourNote} fullWidth loading={createPourNoteMutation.isLoading}>
            Generate Pour Note
          </ProgressButton>
        </Box>
      </ProjectAuthorise>
    </>
  );
};

export default PlannedArea;
