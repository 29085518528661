import Avatar from '@material-ui/core/Avatar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => createStyles({
  avatar: {
    width: 140,
    height: 140,
    borderWidth: 0,
    color: theme.palette.common.white,
    borderStyle: 'solid',
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.8,
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: -70,
    marginTop: -75,
  },
}));

interface InitialsProps {
  projectName: string;
}

const Initials = ({ projectName }: InitialsProps) => {
  const classes = useStyles();
  const initials = projectName.split(' ').map((Word) => Word[0]).join('');
  return <Avatar className={classes.avatar}><Typography variant="h3">{initials}</Typography></Avatar>;
};

export default Initials;
