import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { FormikActions, FormSpacing } from 'components/Form';
import useForm from 'hooks/useForm';
import {
  AddApproverCommand,
  CreateClientCommand, PourAddApproverMutation, useCreateApproverMutation
} from 'providers/api';
import {
  object,
  SchemaOf,
  string
} from 'yup';

const ApproverSchema: SchemaOf<CreateClientCommand> = object().shape({
  name: string().required('Name is required'),
  email: string().email('Invalid email address').required('Email is required'),
});

interface CreateApproverFormProps {
  pourId: string;
  pourNoteId: string;
  disabled: boolean;
}

const CreateApproverForm = ({ pourId, pourNoteId, disabled }: CreateApproverFormProps) => {
  const createApproverMutation = useCreateApproverMutation(pourId);

  const {
    formik,
    helpers,
  } = useForm<AddApproverCommand>({
    mutation: createApproverMutation,
    formikConfig: {
      initialValues: { name: '', email: '' },
      onSubmit: (addApproverCommand, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        const addApproverPayload: PourAddApproverMutation = {
          pourId,
          pourNoteId,
          addApprover: addApproverCommand,
        };
        createApproverMutation.mutate(addApproverPayload, {
          onSuccess: () => {
            resetForm();
          },
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: ApproverSchema,
    },
  });

  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            disabled={disabled}
            error={helpers.hasError('name')}
            helperText={helpers.getErrorHelpText('name')}
            required
            fullWidth
            id="name"
            name="name"
            label="Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            disabled={disabled}
            error={helpers.hasError('email')}
            helperText={helpers.getErrorHelpText('email')}
            required
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormSpacing>
      </Box>
      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={createApproverMutation}
          submitText="Add Approver"
          right={['reset', 'submit']}
        />
      </Box>
    </form>
  );
};

export default CreateApproverForm;
