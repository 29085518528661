import { times } from 'ramda';

export const getRandomInt = (min: number, max: number) => {
  const minCeil = Math.ceil(min);
  const maxFloor = Math.floor(max);
  return Math.floor(Math.random() * (maxFloor - minCeil + 1)) + minCeil;
};

export const getSignString = (num: number) => {
  const sign = Math.sign(num);

  if (sign === 0) {
    return '';
  }

  return sign === 1 ? '+' : '-';
};

export const padNumber = (number: number, requiredDigits: number, padVal = '0') => {
  const digits = number !== 0 ? Math.floor(Math.log10(number)) + 1 : 1;

  const pad = requiredDigits - digits;

  let paddedNumber = `${number}`;
  if (pad > 0) {
    // eslint-disable-next-line no-return-assign
    times(() => paddedNumber = padVal + paddedNumber, pad);
  }

  return paddedNumber;
};

export const round = (number: number, decimalPlaces: number = 2) => Math.round((number + Number.EPSILON) * (10 ** decimalPlaces)) / (10 ** decimalPlaces);

export const nearestTen = (num: number) => Math.ceil(num / 10) * 10;
export const isNumber = (value: any): value is number => typeof value === 'number' && Number.isFinite(value);
