import {
  ReactNode,
  useState
} from 'react';
import PageContext, {
  IPageContext,
  SubNavigation
} from './context';

export interface PageProviderProps {
  children: ReactNode;
}

export default function PageProvider({ children }: PageProviderProps) {
  const [title, setTitle] = useState<string | null>(null);
  const [parentPath, setParentPath] = useState<string | null>(null);
  const [parentTitle, setParentTitle] = useState<string | null>(null);
  const [subNavigation, setSubNavigation] = useState<SubNavigation[]>([]);

  const value: IPageContext = {
    state: { title, parentPath, parentTitle, subNavigation },
    actions: { setTitle, setParentPath, setParentTitle, setSubNavigation },
  };

  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  );
}
