import { findAverageScreenCoordinates, rotateNodesWithVector, centrePlot } from './GUI/node3D';
import setZoom from './GUI/setZoom';
import ModelState from './ModelState';
import GuiState from './GUI/GuiState';

export default function initialModelView(modelState: ModelState, guiState: GuiState) {
  const currentGuiState = guiState;
  const modelRect = guiState.modelCanvas.getBoundingClientRect();
  centrePlot(modelState.nodes, guiState.screenWidth, guiState.screenHeight);
  currentGuiState.centreOfGravity = findAverageScreenCoordinates(modelRect, modelState.nodes);
  rotateNodesWithVector(modelState.nodes, currentGuiState.centreOfGravity, -1.55, 2.5, 0.05);
  setZoom(modelRect, modelState.nodes, window.localStorage.viewZoom);
}
