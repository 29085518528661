import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

const useStyles = makeStyles(() => createStyles({
  root: {
    float: 'right',
    right: 0,
  },
  button: {
    borderRadius: 4,
    border: '1px solid rgba(255, 255, 255, 0.12)',
  },
}));

interface ModelDrawerTriggerProps {
  handleClick: () => void;
}

const ModelDrawerTrigger = ({ handleClick }: ModelDrawerTriggerProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Tooltip title="Open Drawer" enterDelay={500}>
        <IconButton
          className={classes.button}
          color="default"
          aria-label="close stope navigation"
          onClick={handleClick}
        >
          <MenuOpenIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ModelDrawerTrigger;
