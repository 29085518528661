import Grid from '@material-ui/core/Grid';
import React from 'react';
import DataInput from './DataInput';
import { InputType } from './InputGridTypes';

interface InputCardProps {
  rowIndex: number;
  inputs: InputType[];
  updateValues: (rowIndex: number, columnIndex: number, value: any) => void;
  onBlur?: () => void;
}

const InputCard = ({ rowIndex, inputs, updateValues, onBlur }: InputCardProps) => (
  <Grid container spacing={2}>
    {
      inputs.map((input: InputType, index: number) => (
        <Grid key={input.label} item xs={input.size}>
          <DataInput
            showLabel
            input={input}
            rowIndex={rowIndex}
            inputIndex={index}
            updateValues={updateValues}
            onBlur={onBlur}
          />
        </Grid>
      ))
    }
  </Grid>
);

export default InputCard;
