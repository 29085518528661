import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { UnitBase } from 'hooks/useDisplayUnits/useDisplayUnits';
import { UnitSystem } from 'providers/api';
import { ChangeEvent } from 'react';
import { round, sanitiseNumber } from 'utils';

const useStyles = makeStyles(() => ({
  reducedLabel: {
    '& p.MuiTypography-body1': {
      fontSize: '.7rem',
    },
  },
}));

const getNumberValue = (value: unknown, unitSystem: UnitSystem, unitInfo: UnitBase, roundingFn?: (v: number) => number) => {
  const numberValue = sanitiseNumber(value);

  const rounding = roundingFn || ((v: number) => v);

  if (!numberValue) {
    return undefined;
  }

  return unitSystem === UnitSystem.Metric
    ? rounding(numberValue)
    : rounding(unitInfo.imperial.conversion(value as number));
};

interface UnitSystemNumberFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  unitSystem: UnitSystem;
  unitInfo: UnitBase;
  decimalPlaces: number;
}

const UnitSystemNumberField = (props: UnitSystemNumberFieldProps) => {
  const classes = useStyles();
  const { value, unitSystem, unitInfo, decimalPlaces, onChange, ...other } = props;

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const valueAsNumber = sanitiseNumber(event.target.value) ?? 0;

    // Metric is the standardised unit so always return in metric
    const newValue = unitSystem === UnitSystem.Metric ? valueAsNumber : unitInfo.metric.conversion(valueAsNumber);

    onChange && onChange({
      ...event,
      target: {
        ...event.target,
        value: newValue.toString(),
      },
    });
  };

  const displayValue = getNumberValue(value, unitSystem, unitInfo, (number) => round(number, decimalPlaces)) ?? 0;

  return (
    <TextField
      {...other}
      value={displayValue}
      onChange={handleOnChange}
      type="number"
      fullWidth
      variant="outlined"
      className={classes.reducedLabel}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {
              unitSystem === UnitSystem.Metric
                ? unitInfo.metric.abbreviation
                : unitInfo.imperial.abbreviation
            }
          </InputAdornment>
        ),
      }}
    />
  );
};

export default UnitSystemNumberField;
