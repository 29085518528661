import { LocalDateTime, ZonedDateTime } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { FormikActions } from 'components/Form';
import useForm from 'hooks/useForm';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import { useProject } from 'modules/project/providers/project';
import { UpdatePourDateCommand } from 'providers/api';
import { Permission } from 'providers/authorisation';
import React from 'react';
import { UseMutationResult } from 'react-query';
import { makeLocalDateTimeUtils } from 'utils';
import { SchemaOf, object } from 'yup';

export interface UpdatePourDateInput {
  pourDate: LocalDateTime | null;
}
interface UpdatePourDateFormProps {
  pourDate: ZonedDateTime;
  mutation: UseMutationResult<void, unknown, UpdatePourDateCommand, unknown>;
}

const UpdatePourDateForm = ({ pourDate, mutation }: UpdatePourDateFormProps) => {
  const { state: { timeZone } } = useProject();
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const minDateRef = React.useRef(
    LocalDateTime
      .now(timeZone)
      .withHour(0)
      .withMinute(0)
      .withSecond(0)
      .withNano(0),
  );

  const updatePourDateSchema: SchemaOf<UpdatePourDateInput> = object().shape({
    pourDate: object()
      .shape({})
      .required()
      .nullable()
      .test(
        'date-valid',
        'Pour date cannot be in the past',
        (value: LocalDateTime) => value && (value.isEqual(minDateRef.current) || value.isAfter(minDateRef.current)),
      ),
  });

  const {
    formik,
    helpers,
  } = useForm<UpdatePourDateCommand>({
    mutation,
    formikConfig: {
      validateOnBlur: true,
      initialValues: {
        pourDate: pourDate.toLocalDateTime(),
      },
      onSubmit: (updatePourDateForm, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        mutation.mutate(updatePourDateForm, {
          onSuccess: () => {
            resetForm({
              values: updatePourDateForm,
            });
          },
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: updatePourDateSchema,
    },
  });

  const {
    values,
    setTouched,
    handleSubmit,
    setFieldValue,
    setErrors,
    validateForm,
  } = formik;

  const handleReportDateChange = async (value: LocalDateTime | null) => {
    if (value) {
      const processedDateTime = value.withHour(0).withMinute(0).withSecond(0).withNano(0);
      setFieldValue('pourDate', processedDateTime);
      setTouched({
        pourDate: true,
      } as any);
      const e = await validateForm({ pourDate: processedDateTime });
      setErrors(e);
    }
  };

  return (
    <ProjectAuthorise
      permission={Permission.ManagePours}
      render={(isAuthorised) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="subtitle1">Update Pour Date</Typography>
          <Box component={Paper} p={2} mb={2}>
            <MuiPickersUtilsProvider utils={makeLocalDateTimeUtils(timeZone)}>
              <KeyboardDatePicker
                open={datePickerOpen}
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                onClick={() => setDatePickerOpen(true)}
                fullWidth
                format="dd/MM/yyyy"
                mask="__/__/____"
                placeholder="__/__/____"
                id="pourDate"
                name="pourDate"
                label={`Pour Date (${timeZone})`}
                value={values.pourDate}
                onChange={handleReportDateChange}
                minDate={minDateRef.current}
                error={helpers.hasError('pourDate')}
                helperText={helpers.getErrorHelpText('pourDate')}
                disabled={!isAuthorised}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputProps={{
                  autoComplete: 'off',
                  onClick: () => {
                    setDatePickerOpen(true);
                    setFieldValue('pourDate', null);
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>

          {isAuthorised && (
            <Box mt={2}>
              <FormikActions
                formik={formik}
                mutation={mutation}
                submitText="Update"
                right={['reset', 'submit']}
              />
            </Box>
          )}
        </form>
      )}
    />
  );
};

export default UpdatePourDateForm;
