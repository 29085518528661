import { HOUR_IN_MILLISECONDS } from 'config';
import {
  MineModelSummaryDto,
  ProjectDto,
  useMineModelSummaryQuery,
  useProjectQuery
} from 'providers/api';
import { UseQueryResult } from 'react-query';
import { ProjectDetailsPageParams } from './constants';

export type ProjectDetailsQueries = [
  UseQueryResult<ProjectDto>,
  UseQueryResult<MineModelSummaryDto | undefined>,
];

const useProjectDetailsQueries = (params: ProjectDetailsPageParams): ProjectDetailsQueries => {
  const projectQuery = useProjectQuery(params.entityId, {
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const mineModelSummaryQuery = useMineModelSummaryQuery(params.entityId, {
    staleTime: HOUR_IN_MILLISECONDS,
  });

  return [projectQuery, mineModelSummaryQuery];
};

export default useProjectDetailsQueries;
