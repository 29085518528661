import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AlertText from 'components/AlertText';
import { BackfillWarningDto } from 'providers/api';
import React from 'react';

interface WarningListInputProps {
  acceptedWarnings: string[]; // warning.code
  warnings: BackfillWarningDto[];
  disabled: boolean;
  onChange: (code: string) => void;
}

const WarningListInput = ({ acceptedWarnings, warnings, disabled, onChange }: WarningListInputProps) => (
  <Box>
    <AlertText severity="error">
      The recipe in question contains the following warnings. In order to create a new pour from this recipe you are required to accept each warning by ticking the check box.
    </AlertText>
    <List component="nav" aria-label="main mailbox folders">
      <Divider />
      {
        warnings.map((warning: BackfillWarningDto) => (
          <React.Fragment key={warning.code}>
            <ListItem
              button
              onClick={() => onChange(warning.code ?? '')}
            >
              <ListItemText primary={warning.display} />
              <ListItemSecondaryAction>
                <Checkbox
                  disabled={disabled}
                  checked={acceptedWarnings.includes(warning.code ?? '')}
                  onChange={() => onChange(warning.code ?? '')}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))
      }
    </List>
  </Box>
);

export default WarningListInput;
