import { GridSize } from '@material-ui/core/Grid';
import uuid from 'modules/project/ProjectAreaPage/ProjectStopesPage/canvas/Diagra/uuid';

export type InputType = {
  name?: string,
  label: string,
  labelShort?: string,
  type?: 'text' | 'select' | 'number',
  options?: string[] | number[],
  value: string,
  size: GridSize,
  adornment?: string,
  disabled?: boolean,
};

export type InputRecord = {
  uniqueKey: string,
  inputs: InputType[],
}

export const makeInputRecord = (inputRowStructure: InputType[]): InputRecord => ({
  uniqueKey: uuid(),
  inputs: [...inputRowStructure],
});
