import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { UnitBase } from 'hooks/useDisplayUnits/useDisplayUnits';
import { UnitSystem } from 'providers/api';
import { round } from 'utils';

interface UnitSystemValueProps {
  value?: number;
  unitSystem: UnitSystem;
  unitInfo: UnitBase;
  decimalPlaces: number;
}

const UnitSystemValue = ({ value, unitSystem, unitInfo, decimalPlaces }: UnitSystemValueProps) => (
  <span>
    {
      value && unitSystem === UnitSystem.Metric
        ? round(value as number, decimalPlaces)
        : round(unitInfo.imperial.conversion(value as number), decimalPlaces)
    }
    {
      value && unitSystem === UnitSystem.Metric
        ? unitInfo.metric.abbreviation
        : unitInfo.imperial.abbreviation
    }
  </span>
);

interface UnitSystemDetailProps {
  designed?: number;
  selected?: number;
  unitSystem: UnitSystem;
  unitInfo: UnitBase;
  decimalPlaces: number;
}

const UnitSystemDetail = ({ designed, selected, unitSystem, unitInfo, decimalPlaces }: UnitSystemDetailProps) => (
  <TableRow key={unitInfo.name}>
    <TableCell component="th" scope="row">
      {unitInfo.name}
    </TableCell>
    <TableCell align="right">
      {
        designed && (
          <UnitSystemValue
            value={designed}
            unitInfo={unitInfo}
            unitSystem={unitSystem}
            decimalPlaces={decimalPlaces}
          />
        )
      }
    </TableCell>
    <TableCell align="right">
      <UnitSystemValue
        value={selected}
        unitInfo={unitInfo}
        unitSystem={unitSystem}
        decimalPlaces={decimalPlaces}
      />
    </TableCell>
  </TableRow>
);

export default UnitSystemDetail;
