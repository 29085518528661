import { round } from 'utils';
import PCRoute from '../DiagraExtended/PCRoute';
import type { Range, VelocityResult } from '../typesInterfaces';

function waterBinderRatioFromConcentrationAndBinder(
  dryConcentrationByMass: number,
  binderConcentration: number,
) {
  const C = binderConcentration;
  const Cm = dryConcentrationByMass;
  return ((1 / C) * (1 / (Cm) - 1));
}

function binderFromWaterBinderRatioAndBinder(
  waterBinderRatio: number,
  massConcentration: number,
) {
  return (((1 / massConcentration) - 1) / waterBinderRatio);
}
// const grWcCm = new LineGraph({
//   func: {
//     type: 2, // given %m and cement %, return W:C
//     f: (x: number, y?: number) => (1 / (1 + (x * y!))),
//   },
//   xMin: 0,

function wilsonsNomograph(solidsDensity: number, pipeID: number): VelocityResult {
  const d50Coarse = 0.01;
  let error = '';
  // check limits
  if (pipeID < 0.1 || pipeID > 1.5) {
    error += ' Pipe ID out of range';
  } else if (d50Coarse < (0.15 / 1000) || d50Coarse > (30 / 1000)) {
    error += ' Particle size out of range';
  } else if (solidsDensity < 1100 || solidsDensity > 20000) {
    error += ' Solids density out of range';
  }
  // xID
  const xID = 0;
  let yID = 0;
  // yID
  if (pipeID <= 0.5) {
    yID = 111185.18481636 * pipeID ** 6 - 236648.117943192 * pipeID ** 5
     + 207413.429812527 * pipeID ** 4 - 96662.9457350302 * pipeID ** 3
      + 25850.1670947266 * pipeID ** 2 - 4018.83195862502 * pipeID + 351.465494257566;
  } else if (pipeID <= 1) {
    yID = -39 * pipeID + 46.5;
  } else if (pipeID <= 1.5) {
    yID = -15 * pipeID + 22.5;
  }
  if (yID > 129.5) {
      yID = 129.5;
  } else if (yID < 0) {
    yID = 0;
  }
  // xPsize
  let xPsize = 0;
  if ((d50Coarse * 1000) <= 0.3) {
    xPsize = -20 * (d50Coarse * 1000) + 68;
  } else if ((d50Coarse * 1000) <= 2.5) {
    xPsize = -0.823666227830472 * (d50Coarse * 1000) ** 3
     + 5.3177663932569 * (d50Coarse * 1000) ** 2
      - 13.5998335710181 * (d50Coarse * 1000) + 65.6083234791437;
  } else if ((d50Coarse * 1000) <= 30) {
    xPsize = 0.0000663972767739884 * (d50Coarse * 1000) ** 4
     - 0.00555103017028136 * (d50Coarse * 1000) ** 3
      + 0.171861379205141 * (d50Coarse * 1000) ** 2
       - 2.67455632651806 * (d50Coarse * 1000) + 57.6734617940967;
  }
  if (xPsize > 65) {
    xPsize = 65;
  } else if (xPsize < 36) {
      xPsize = 36;
  }
  // yPsize
  let yPsize = 0;
  if ((d50Coarse * 1000) <= 0.3) {
      yPsize = 10000 * (d50Coarse * 1000) ** 3
       - 7821.42857142817 * (d50Coarse * 1000) ** 2
        + 2083.21428571425 * (d50Coarse * 1000) - 105.482142857149;
  } else if ((d50Coarse * 1000) <= 2.5) {
      yPsize = 0.181105426512659 * (d50Coarse * 1000) ** 6
       + 4.65974226612598 * (d50Coarse * 1000) ** 5
        - 41.2628919371869 * (d50Coarse * 1000) ** 4
         + 122.632350772675 * (d50Coarse * 1000) ** 3
          - 164.203457130467 * (d50Coarse * 1000) ** 2
           + 91.7213881050095 * (d50Coarse * 1000) + 69.7670920549741;
  } else if ((d50Coarse * 1000) <= 30) {
    yPsize = 0.000105263975373304 * (d50Coarse * 1000) ** 4
     - 0.00880041368459217 * (d50Coarse * 1000) ** 3
      + 0.272463162154136 * (d50Coarse * 1000) ** 2
       - 4.24015027374444 * (d50Coarse * 1000) + 85.360366258914;
  }
  if (yPsize > 87.5) {
    yPsize = 87.5;
  } else if (yPsize < 51) {
    yPsize = 51;
  }
  // xVdep2_65
  const xVdep265 = 86;
  // yVdep2_65
  const yVdep265 = xVdep265 * ((yPsize - yID) / (xPsize - xID)) + (yID - xID * ((yPsize - yID) / (xPsize - xID)));
  // xSs
  let xSs = 0;
  if ((solidsDensity / 1000) <= 5) {
    xSs = -0.175302624273172 * (solidsDensity / 1000) ** 6
     + 3.5002345434812 * (solidsDensity / 1000) ** 5
      - 28.512876957576 * (solidsDensity / 1000) ** 4
       + 121.550370591039 * (solidsDensity / 1000) ** 3
        - 288.205197216689 * (solidsDensity / 1000) ** 2
         + 370.810718018096 * (solidsDensity / 1000) - 82.1115055730683;
  } else if ((solidsDensity / 1000) <= 20) {
    xSs = -0.0585327128852988 * (solidsDensity / 1000) ** 2
     + 2.3568733893566 * (solidsDensity / 1000) + 129.716026058141;
  }
  if (xSs > 154) {
    xSs = 154;
  } else if (xSs < 102) {
    xSs = 102;
  }
  // ySs
  let ySs = 0;
  if ((solidsDensity / 1000) <= 5) {
    ySs = 0.328745011294814 * (solidsDensity / 1000) ** 6
     - 6.5639898391084 * (solidsDensity / 1000) ** 5
      + 53.4701981534725 * (solidsDensity / 1000) ** 4
       - 227.943409935811 * (solidsDensity / 1000) ** 3
        + 540.471206312517 * (solidsDensity / 1000) ** 2
         - 695.38133979304 * (solidsDensity / 1000) + 460.733706828601;
  } else if ((solidsDensity / 1000) <= 20) {
    ySs = 0.000654714807225121 * (solidsDensity / 1000) ** 4
     - 0.0404909333010943 * (solidsDensity / 1000) ** 3
      + 0.968500552459524 * (solidsDensity / 1000) ** 2
       - 11.7288696689901 * (solidsDensity / 1000) + 84.4386494233573;
  }
  if (ySs > 115.5) {
    ySs = 115.5;
  } else if (ySs < 18) {
    ySs = 18;
  }
  // xVdepSs
  let VdepSs = 0;
  const xVdepSs = 172;
  // yVdepSs
  const yVdepSs = xVdepSs * ((ySs - yVdep265) / (xSs - xVdep265)) + (yVdep265 - xVdep265 * ((ySs - yVdep265) / (xSs - xVdep265)));
  // Calculating Vdep outputs
  // Vdep2_65
  let Vdep265 = 0;
  if (yVdep265 <= 99.8) {
    Vdep265 = 0.00000115080296708269 * yVdep265 ** 3 + 0.0000261331413468069 * yVdep265 ** 2 + 0.00627255399336235 * yVdep265 + 1.01136423673796;
  } else if (yVdep265 <= 129.5) {
    Vdep265 = 3.19554771066238E-04 * yVdep265 ** 3 - 0.102538085320441 * yVdep265 ** 2 + 11.0764369580212 * yVdep265 - 398.739570274248;
  } else {
    error += ' Out of Nomograph Range';
    VdepSs = Vdep265;
  }
  // VdepSs
  if (yVdepSs <= 29.8) {
    VdepSs = -0.000319554771066141 * yVdepSs ** 3 + 0.0216089432387854 * yVdepSs ** 2 - 0.596113058447649 * yVdepSs + 10.0619256762058;
  } else if (yVdepSs <= 129.5) {
    VdepSs = -0.00000115080296708166 * yVdepSs ** 3 + 0.000473220094058015 * yVdepSs ** 2 - 7.09387979782823E-02 * yVdepSs + 4.76117261545106;
  }
  if (solidsDensity === 2650) {
    VdepSs = Vdep265;
  }
  // moved to error determination above
  // if (Vdep2_65 == 'Out of Nomograph Range') {
  //   VdepSs = Vdep2_65
  // }
  if (VdepSs <= 1 || VdepSs > 10) {
    error += ' Out of Nomograph Range';
  }

  return {
    // velocity: unitSystemPreference === UnitSystem.Metric ? VdepSs : speedMetricToImperial(VdepSs),
    velocity: VdepSs,
    errors: error,
  };
}

function roundUpBinderConc(cm: number, binderRange: Range) {
  const { interval } = binderRange;
  let x = interval;
  let digitCount = 0;
  while ((x % 1) > 0) { // while there is a decimal place
    x *= 10;
    digitCount += 1;
  }
  let roundedValue = cm * 10 ** digitCount;
  roundedValue /= x;
  roundedValue = Math.ceil(roundedValue);
  roundedValue *= x;
  roundedValue /= (10 ** digitCount);

  return roundedValue;
}
// const convertHeightToPressure = (h: number): number => round(h * currentRecipeState.wetDensity * 9.81);
export function convertHeightToPressure(height: number, wetDensity: number) {
  return round(height * (wetDensity / 1000) * 9.81);
}

export function getPumpPressure(route: PCRoute) {
  const { routePoints } = route;
  const rPoint = routePoints[routePoints.length - 1];
  const pumpPressure = rPoint.pressure;
  return pumpPressure;
}

export {
  binderFromWaterBinderRatioAndBinder,
  roundUpBinderConc,
  waterBinderRatioFromConcentrationAndBinder,
  wilsonsNomograph,
};
