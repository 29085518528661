import { makeStyles } from '@material-ui/core/styles';
import Alert, { AlertProps } from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
  },
});

const AlertText = (props: AlertProps) => {
  const classes = useStyles();
  return <Alert className={classes.root} {...props} />;
};

export default AlertText;
