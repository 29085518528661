/* eslint-disable class-methods-use-this */
import { IUtils } from '@date-io/core/IUtils';
import {
  DateTimeFormatter,
  Duration, LocalDate, LocalDateTime, LocalTime, ZonedDateTime, ZoneId
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en';
import '@js-joda/timezone';
import { is, isNil, not } from 'ramda';
import { getTimeZoneAbbreviation } from './zoned-date-time';

export const makeLocalDate = (dateTimeString: string): LocalDate => LocalDate.parse(dateTimeString);

const dateToLocalDate = (date: Date) => LocalDate.of(
  date.getFullYear(),
  date.getMonth() + 1,
  date.getDate(),
);

export const makeLocalDateUtils = (timeZone: ZoneId = ZoneId.of('Europe/London')) => class LocalDateUtils implements IUtils<LocalDate> {
  locale: any;

  timeZone: ZoneId;

  yearFormat = 'yyyy';

  yearMonthFormat = 'MMMM yyyy';

  dateTime12hFormat = 'MMMM do hh:mm aaaa';

  dateTime24hFormat = 'MMMM do HH:mm';

  time12hFormat = 'hh:mm a';

  time24hFormat = 'HH:mm';

  dateFormat = 'MMMM do';

  constructor({ locale }: { locale?: any }) {
    this.locale = locale ?? Locale.UK;
    this.timeZone = timeZone ?? ZoneId.of('Europe/London');
  }

  date(value?: any): LocalDate | null {
    if (value === null) {
      return null;
    }

    if (typeof value === 'undefined') {
      return LocalDate.now(this.timeZone);
    }

    if (typeof value === 'string') {
      const date = new Date(value);
      if (not(is(Number, date.valueOf()))) {
        return null;
      }

      return dateToLocalDate(date);
    }

    if (value instanceof LocalDate) {
      return value;
    }

    if (value instanceof Date) {
      return dateToLocalDate(value);
    }

    throw new Error(`Unknown Date value in function date(): ${value}`);
  }

  parse(value: string, format: string): LocalDate | null {
    if (value === '' || value.includes('_')) {
      return null;
    }

    const formatter = DateTimeFormatter.ofPattern(format).withLocale(this.locale);

    // js-joda does not parse upper case AM or PM
    value.replace('AM', 'am');
    value.replace('PM', 'pm');

    const isDateFormat = format.includes('d') && format.includes('M') && format.includes('y');
    const isTimeFormat = format.includes('H') && format.includes('m');

    if (isDateFormat && isTimeFormat) {
      return LocalDate.parse(value, formatter);
    }

    if (isDateFormat) {
      return LocalDate.parse(value, formatter);
    }

    if (isTimeFormat) {
      throw new Error('Time not supported');
    }

    throw new Error('Format not supported');
  }

  isNull(value: LocalDate | null): boolean {
    return value === null;
  }

  isValid(value: any): boolean {
    return !isNil(this.date(value));
  }

  getDiff(value: LocalDate, comparing: string | LocalDate): number {
    const comparingLocalDate = this.date(comparing);

    if (!comparingLocalDate) {
      return 0;
    }

    const duration = Duration.between(value, comparingLocalDate);
    return duration.toMillis();
  }

  isEqual(value: any, comparing: any): boolean {
    const valueLocalDate = this.date(value);
    const comparingLocalDate = this.date(comparing);

    if (valueLocalDate === null && comparingLocalDate === null) {
      return true;
    }

    if (comparingLocalDate === null || valueLocalDate === null) {
      return false;
    }

    return valueLocalDate.isEqual(comparingLocalDate);
  }

  isSameDay(value: LocalDate, comparing: LocalDate): boolean {
    return this.isSameMonth(value, comparing) && value.dayOfMonth() === comparing.dayOfMonth();
  }

  isSameMonth(value: LocalDate, comparing: LocalDate): boolean {
    return this.isSameYear(value, comparing) && value.monthValue() === comparing.monthValue();
  }

  isSameYear(value: LocalDate, comparing: LocalDate): boolean {
    return value.year() === comparing.year();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSameHour(value: LocalDate, comparing: LocalDate): boolean {
    return true;
  }

  isAfter(value: LocalDate, comparing: LocalDate): boolean {
    return value.isAfter(comparing);
  }

  isAfterDay(value: LocalDate, comparing: LocalDate): boolean {
    return value.isAfter(comparing);
  }

  isAfterYear(value: LocalDate, comparing: LocalDate): boolean {
    const endOfYear = comparing
      .withMonth(12)
      .withDayOfMonth(31);

    return value.isAfter(endOfYear);
  }

  isBeforeDay(value: LocalDate, comparing: LocalDate): boolean {
    return value.isBefore(this.startOfDay(comparing));
  }

  isBeforeYear(value: LocalDate, comparing: LocalDate): boolean {
    const startOfYear = comparing
      .withMonth(1)
      .withDayOfMonth(1);

    return value.isBefore(startOfYear);
  }

  isBefore(value: LocalDate, comparing: LocalDate): boolean {
    return value.isBefore(comparing);
  }

  startOfMonth(value: LocalDate): LocalDate {
    return value
      .withDayOfMonth(1);
  }

  endOfMonth(value: LocalDate): LocalDate {
    return value
      .plusMonths(1)
      .minusDays(1);
  }

  addDays(value: LocalDate, count: number): LocalDate {
    return value.plusDays(count);
  }

  startOfDay(value: LocalDate): LocalDate {
    return value;
  }

  endOfDay(value: LocalDate): LocalDate {
    return value;
  }

  format(value: LocalDate, formatString: string): string {
    const hasTimeZone = formatString.includes('z');

    const parsedFormatString = hasTimeZone
      ? formatString.replace('z', `'${getTimeZoneAbbreviation(ZonedDateTime.ofLocal(LocalDateTime.of(value, LocalTime.MIN), this.timeZone))}'`)
      : formatString;

    const formatter = DateTimeFormatter
      .ofPattern(parsedFormatString)
      .withLocale(this.locale);

    return value.format(formatter);
  }

  formatNumber(numberToFormat: string): string {
    return numberToFormat;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getHours(value: LocalDate): number {
    return 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setHours(value: LocalDate, count: number): LocalDate {
    return value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getMinutes(value: LocalDate): number {
    return 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setMinutes(value: LocalDate, count: number): LocalDate {
    return value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSeconds(value: LocalDate): number {
    return 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSeconds(value: LocalDate, count: number): LocalDate {
    return value;
  }

  getMonth(value: LocalDate): number {
    return value.monthValue() - 1;
  }

  setMonth(value: LocalDate, count: number): LocalDate {
    return value.withMonth(count + 1);
  }

  getNextMonth(value: LocalDate): LocalDate {
    return value.plusMonths(1);
  }

  getPreviousMonth(value: LocalDate): LocalDate {
    return value.minusMonths(1);
  }

  getMonthArray(value: LocalDate): LocalDate[] {
    const startOfYear = value
      .withMonth(1)
      .withDayOfMonth(1);

    const firstMonth = startOfYear;
    const monthArray = [firstMonth];

    while (monthArray.length < 12) {
      const prevMonth = monthArray[monthArray.length - 1];
      monthArray.push(this.getNextMonth(prevMonth));
    }

    return monthArray;
  }

  getYear(value: LocalDate): number {
    return value.year();
  }

  setYear(value: LocalDate, count: number): LocalDate {
    return value.withYear(count);
  }

  mergeDateAndTime(date: LocalDate, time: LocalDate): LocalDate {
    return this.setMinutes(
      this.setHours(date, this.getHours(time)),
      this.getMinutes(time),
    );
  }

  getWeekdays(): string[] {
    const today = LocalDate.now(this.timeZone);
    const startOfWeek = this.startOfWeek(today);

    const weekdays = [];
    const formatter = DateTimeFormatter.ofPattern('eee').withLocale(this.locale);
    for (let i = 0; i < 7; i += 1) {
      weekdays.push(startOfWeek.plusDays(i).format(formatter));
    }
    return weekdays;
  }

  getWeekArray(value: LocalDate): LocalDate[][] {
    const startOfMonth = this.startOfMonth(value);
    const endOfMonth = this.endOfMonth(value);

    const start = this.startOfWeek(startOfMonth);
    const end = this.endOfWeek(endOfMonth);

    let count = 0;
    let current = start;
    const nestedWeeks: LocalDate[][] = [];

    while (current.isBefore(end)) {
      const weekNumber = Math.floor(count / 7);
      nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
      nestedWeeks[weekNumber].push(current);
      current = current.plusDays(1);
      count += 1;
    }
    return nestedWeeks;
  }

  getYearRange(start: LocalDate, end: LocalDate): LocalDate[] {
    const startDate = start
      .withMonth(1)
      .withDayOfMonth(1);

    const endDate = end
      .withMonth(12)
      .withDayOfMonth(31);

    const years: LocalDate[] = [];

    let current = startDate;
    while (this.isBefore(current, endDate)) {
      years.push(current);
      current = current.plusYears(1);
    }

    return years;
  }

  getMeridiemText(ampm: 'am' | 'pm'): string {
    return ampm === 'am' ? 'AM' : 'PM';
  }

  getCalendarHeaderText(date: LocalDate): string {
    return this.format(date, this.yearMonthFormat);
  }

  getDatePickerHeaderText(date: LocalDate): string {
    return this.format(date, 'EEE, MMM d');
  }

  getDateTimePickerHeaderText(date: LocalDate): string {
    return this.format(date, 'MMM d');
  }

  getMonthText(date: LocalDate): string {
    return this.format(date, 'MMMM');
  }

  getDayText(date: LocalDate): string {
    return this.format(date, 'd');
  }

  getHourText(date: LocalDate, ampm: boolean) {
    return this.format(date, ampm ? 'hh' : 'HH');
  }

  getMinuteText(date: LocalDate) {
    return this.format(date, 'mm');
  }

  getSecondText(date: LocalDate) {
    return this.format(date, 'ss');
  }

  getYearText(date: LocalDate): string {
    return this.format(date, 'yyyy');
  }

  private startDayOfWeek(): number {
    let startDayOfWeek;
    switch (this.locale.localeString()) {
      case 'en-US':
        startDayOfWeek = 7; // Sunday (day 7 in js-joda, which implements ISO8601 calendar only)
        break;
      default:
        startDayOfWeek = 1; // Monday
    }
    return startDayOfWeek % 7;
  }

  private startOfWeek(value: LocalDate) {
    const dayOfWeek = value.dayOfWeek().value();
    return value
      .minusDays(dayOfWeek - this.startDayOfWeek());
  }

  private endOfWeek(value: LocalDate) {
    const dayOfWeek = value.dayOfWeek().value();
    return value
      .plusDays(7 - (dayOfWeek - this.startDayOfWeek()));
  }
};
