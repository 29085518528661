import BusinessIcon from '@material-ui/icons/Business';
import makePage from 'modules/makePage';
import Page from 'modules/Page';
import ClientsPage from './ClientsPage';
import * as constants from './constants';

const page: Page = {
  title: constants.TITLE,
  mainMenuConfig: {
    group: 'Admin',
    iconComponent: BusinessIcon,
  },
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  component: makePage({ WrappedPage: ClientsPage, titleFn: () => constants.TITLE }),
  admin: true,
};

export default page;
