import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface ProgressButtonProps {
  loading: boolean;
  isLabel?: boolean
}

const ProgressButton = ({ loading, isLabel, ...buttonProps }: ButtonProps & ProgressButtonProps) => {
  const classes = useStyles();

  const updatedProps = {
    ...buttonProps,
    disabled: loading ? true : buttonProps.disabled,
  };

  return (
    <div className={classes.wrapper}>
      {/* !Type issue - component prop not part of the ButtonProps type */}
      <Button component={isLabel ? 'label' : undefined} {...updatedProps as any} />
      {loading && <CircularProgress size={24} className={classes.buttonProgress} color="primary" />}
    </div>
  );
};

export default ProgressButton;
