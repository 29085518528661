import { capitalize } from 'utils';
import {
  CreateProjectCommand,
  FileParameter,
  MineModelDto,
  PagedListOfProjectDto,
  ProjectDto,
  SetProjectLicenceExpiryDateCommand,
  UpdateProjectCommand
} from './api';
import {
  PageParams,
  makeCreateMutation,
  makeDeleteMutation,
  makeGetQuery,
  makeInfiniteListQuery,
  makeListQuery,
  makeUpdateMutation
} from './useApi';
import {
  CLIENT_TEAMS_KEY,
  CLIENT_TEAM_KEY
} from './useTeams';

const PROJECTS_KEY = 'projects';
export interface ProjectsQueryParams extends PageParams {
  searchTerm?: string | null;
  clientId?: string | null;
}

export const useProjectsQuery = makeListQuery<ProjectsQueryParams, PagedListOfProjectDto>(
  PROJECTS_KEY,
  (api, params) => () => api.projects.list(params.page, params.pageSize, params.searchTerm, params.clientId),
);

export const useInfiniteProjectsQuery = makeInfiniteListQuery<null, ProjectDto>(
  PROJECTS_KEY,
  (api) => ({
    pageParam = { page: 1, pageSize: 12 },
  }) => api.projects.list(pageParam.page, pageParam.pageSize),
);

const PROJECT_KEY = 'project';
export const useProjectQuery = makeGetQuery<ProjectDto, string>(
  PROJECT_KEY,
  (api, projectId) => () => api.projects.get(projectId),
);

const PROJECT_CLIENT_KEY = 'project_client';
export const useProjectClientQuery = makeGetQuery<string, string>(
  PROJECT_CLIENT_KEY,
  (api, projectId) => () => api.projects.getClientId(projectId),
);

export interface ProjectWithMineModel {
  project: ProjectDto;
  mineModel: MineModelDto | null;
}

export interface ProjectMutation {
  clientId: string,
  command: CreateProjectCommand | UpdateProjectCommand
}

export const useCreateProjectMutation = makeCreateMutation<string, ProjectMutation>(
  PROJECTS_KEY,
  (api) => (mutation) => api.projects.create(mutation.clientId, mutation.command as any),
  (mutation) => `Project ${capitalize(mutation.command.project.name)} successfully created`,
  [CLIENT_TEAMS_KEY, CLIENT_TEAM_KEY],
);

export const useUpdateProjectMutation = makeUpdateMutation<void, ProjectMutation>(
  PROJECT_KEY, PROJECTS_KEY,
  (api, projectId) => (mutation) => api.projects.update(projectId, mutation.command),
  (mutation) => `Project ${capitalize(mutation.command.project.name)} successfully updated`,
);

export const useDeleteProjectMutation = makeDeleteMutation<void, ProjectDto>(
  PROJECT_KEY, PROJECTS_KEY,
  (api) => (project) => api.projects.delete(project.entityId),
  (mutation) => `Project ${capitalize(mutation.name)} successfully deleted`,
  (mutation) => mutation.entityId,
);

export const useUpdateProjectImageMutation = makeUpdateMutation<void, FileParameter>(
  PROJECT_KEY, PROJECTS_KEY,
  (api, projectId) => (file) => api.projects.updateProjectImage(projectId, file),
  () => 'Uploaded image successfully updated',
);

export interface UpdateLicenceExpiryMutation {
  projectId: string;
  command: SetProjectLicenceExpiryDateCommand,
}

export const useUpdateLicenceExpiryMutation = makeUpdateMutation<void, UpdateLicenceExpiryMutation>(
  PROJECT_KEY, PROJECTS_KEY,
  (api, projectId) => (mutation) => api.projects.updateProjectLicenceExpiryDate(projectId, mutation.command),
  () => 'Licence Expiry Date updated',
);
