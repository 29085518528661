import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import React from 'react';
import {
  TimeZoneWithLabel,
  TIME_ZONES
} from 'utils';

const timeZoneFilter = (tzs: TimeZoneWithLabel[], state: FilterOptionsState<TimeZoneWithLabel>) => tzs
  .filter((tz) => tz.name
    .toLocaleLowerCase()
    .includes(state.inputValue.toLocaleLowerCase()));

interface TimeZoneFieldProps {
  required?: boolean;
  timeZone?: string;
  error: boolean;
  helperText?: string
  onChange: (timeZone?: string) => void;
  onBlur: () => void;
}

const TimeZoneField = ({ required, timeZone, error, helperText, onBlur, onChange }: TimeZoneFieldProps) => {
  const timeZoneWithLabel = timeZone ? TIME_ZONES.find((tz) => tz.name === timeZone) : null;

  return (
    <Autocomplete
      fullWidth
      id="timezone-select"
      options={TIME_ZONES}
      autoHighlight
      value={timeZoneWithLabel}
      freeSolo={false}
      getOptionLabel={(tz) => tz.label}
      getOptionSelected={(tz, value) => tz.name === value.name}
      filterOptions={timeZoneFilter}
      onChange={(event: any, newValue) => {
        onChange(newValue?.name);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label="Time Zone"
          variant="outlined"
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default TimeZoneField;
