import {
  compose,
  concat,
  converge,
  head,
  join,
  map,
  replace,
  split,
  tail,
  toUpper
} from 'ramda';

export const capitalize = converge(
  concat,
  [
    compose(
      toUpper,
      head,
    ),
    tail,
  ],
);

export const compareAlphaNum = (stopeNameA: string, stopeNameB: string): number => {
  const nameA = (stopeNameA ?? '').toUpperCase();
  const nameB = (stopeNameB ?? '').toUpperCase();
  return nameA.localeCompare(nameB, 'en', { numeric: true });
};

export const toTitleCase = compose(
  join(' '),
  map(capitalize),
  split(' '),
);

export const resolveTemplate = (template: string, variables: { [key: string]: string | number }) => Object.keys(variables)
  .reduce((accResolvedTemplate, variableKey) => replace(
    `${variableKey}`,
    `${variables[variableKey]}`,
    accResolvedTemplate,
  ), template);

export const splitCaps = (currentString: string) => currentString.replace(/([A-Z])/g, ' $1').trim();

export const isNumeric = (str: string) => !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  && !Number.isNaN(Number(str)); // ...and ensure strings of whitespace fail
