import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BadgeRow from 'components/BadgeRow';
import Temporal from 'components/Temporal';
import { StopeBackfillSummaryDto, StopeDataDto, StopeLocationDto } from 'providers/api';
import React from 'react';
import { DEFAULT_SHORT_DATE_CONFIG, NullableProps } from 'utils';
import StopeProgress from '../../StopeProgress';

const useStyles = makeStyles((theme: Theme) => createStyles({
  listItemTitle: {
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(255, 255, 255, 0.87)',
    width: '100%',
  },
  listItemBodyAction: {
    fontSize: '12px',
    lineHeight: '12px',
    color: '#9E9E9E',
  },
  progress: {
    marginRight: theme.spacing(2),
  },
}));

interface AllStopeProperties extends StopeBackfillSummaryDto, StopeDataDto, StopeLocationDto { }
export interface StopeProfile extends NullableProps<AllStopeProperties> {
  nodeId: string;
  stopeId: string;
}

interface StopeListItemProps extends StopeProfile {
  onSelect: (stopeLocation: StopeLocationDto) => void;
  onHover: (stopeLocation?: StopeLocationDto) => void;
}

const StopeListItem = ({
  stopeId,
  stopeName,
  nodeId,
  totalCount,
  plannedCount,
  completedCount,
  plannedVolume,
  completedVolume,
  nextPourDate,
  isComplete,
  volume,
  onSelect,
  onHover,
}: StopeListItemProps) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      onMouseEnter={() => onHover({ stopeId, nodeId })}
      onMouseLeave={() => onHover()}
      onClick={() => onSelect({ stopeId, nodeId })}
    >
      <ListItemAvatar className={classes.progress}>
        <StopeProgress
          completedVolume={completedVolume ?? 0}
          plannedVolume={plannedVolume ?? 0}
          size={65}
          volume={volume ?? 0}
        />
      </ListItemAvatar>
      <ListItemText
        secondaryTypographyProps={{ component: 'span' }}
        primary={(
          <>
            <Typography
              className={classes.listItemTitle}
              component="span"
            >
              {stopeName ?? nodeId}
            </Typography>
            <br />
            <Typography
              className={classes.listItemBodyAction}
              component="span"
              variant="body2"
            >
              {isComplete && 'Stope complete'}
              {!isComplete && !nextPourDate && 'No upcoming pour'}
              {!isComplete && nextPourDate && (
                <>
                  {'Next Pour: '}
                  <Temporal temporal={nextPourDate} options={DEFAULT_SHORT_DATE_CONFIG} />
                </>
              )}
            </Typography>
          </>
        )}
        secondary={(
          <Box pt={2}>
            <BadgeRow
              linear
              size="small"
              badgeList={
                [
                  { text: 'Planned', total: plannedCount ?? 0 },
                  { text: 'Completed', total: completedCount ?? 0 },
                  { text: 'Total', total: totalCount ?? 0 },
                ]
              }
            />
          </Box>
        )}
      />
    </ListItem>
  );
};
export default StopeListItem;
