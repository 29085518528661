import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GridFooterContainer, GridPagination } from '@material-ui/data-grid';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { displayThroughput, unitString } from 'hooks/useDisplayUnits/display';
import useDisplayUnits, {
  AdditionalDisplayUnitsInputs,
  BasicDisplayUnits,
  getAbbreviation,
  UnitBaseWithValue,
  unitLabels
} from 'hooks/useDisplayUnits/useDisplayUnits';
import { Parser, transforms } from 'json2csv';
import {
  DisplayUnits,
  FillType,
  PourReportDto,
  RheologyDataDto,
  ThroughputControlType,
  UnitSystem
} from 'providers/api';
import { omit } from 'ramda';
import React from 'react';
import { round } from 'utils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  link: {
    marginLeft: theme.spacing(1),
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const getValue = (preference: UnitSystem, unitInfo: UnitBaseWithValue) => (preference === UnitSystem.Metric
  ? unitInfo.value
  : round(unitInfo.imperial.conversion(unitInfo.value ?? 0), 2));

interface PoursTableFooterProps {
  pours: PourReportDto[];
  rheologyDataIndexedById: Record<string, RheologyDataDto>;
  projectName: string;
  displayUnitPreferences: DisplayUnits[];
  unitSystemPreference: UnitSystem;
  fillType: FillType;
  throughputControlType: ThroughputControlType;
}

const PoursTableFooter = ({
  pours,
  rheologyDataIndexedById,
  projectName,
  displayUnitPreferences,
  unitSystemPreference,
  fillType,
  throughputControlType,
}: PoursTableFooterProps) => {
  const classes = useStyles();
  const [downloadLink, setDownloadLink] = React.useState('');

  const additionalUnitInputs: AdditionalDisplayUnitsInputs[] = pours.map((pour) => ({
    key: pour.entityId,
    displayUnitPreferences,
    specification: fillType === FillType.Paste ? pour.pasteSpecification : pour.hydraulicSpecification,
    selectedSpecification: fillType === FillType.Paste ? pour.pasteSelectedSpecification : pour.hydraulicSelectedSpecification,
    // Rheology data
    specificGravity: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].tailingsDrySolidsDensity : undefined,
    yieldStressA: pour.rheologyDataSetId ? Object.values(rheologyDataIndexedById[pour.rheologyDataSetId])[0].coefficient1 : undefined,
    yieldStressB: pour.rheologyDataSetId ? Object.values(rheologyDataIndexedById[pour.rheologyDataSetId])[0].coefficient2 : undefined,
    mixerCoefficients: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].mixerCoefficients : undefined,
    heightOfCylinder: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].heightOfCylinder : undefined,
    throughputControlType,
    fillType,
    targetDaysCoefficients: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].ucsCoefficients : undefined,
    binderParticleDensity: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].binderDryParticleDensity : undefined,
    carrierFluidDensity: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].carrierFluidDensity : undefined,
    tailingsSolidsDensity: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].tailingsDrySolidsDensity : undefined,
  }));

  const additionalUnitsIndexedByPourId = useDisplayUnits(additionalUnitInputs);

  const processData = () => {
    if (pours.length > 0) {
      const processedPours = pours.map((pour) => {
        // update values in pour
        const pourWithConversion = {
          ...pour,
          volume: unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.Volume], pour.volume),
          specification: {
            targetUCSStrength: unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressureUCS], pour.pasteSpecification?.targetUCSStrength),
            targetDays: `${pour.pasteSpecification?.targetDays
              ?? (Object.keys(rheologyDataIndexedById[pour.rheologyDataSetId].ucsCoefficients ?? {})[0] as unknown as number)} days`,
            throughput: displayThroughput(unitSystemPreference, throughputControlType, pour.pasteSpecification?.throughput),
            pumpPressure: unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressurePump], pour.pasteSpecification?.pumpPressure),
          },
          optimisedSpecification: {
            massConcentration: `${pour.pasteOptimisedSpecification?.massConcentration} %m`,
            binderContent: `${pour.pasteOptimisedSpecification?.binderContent} %b`,
            ucsStrength: unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressureUCS], pour.pasteOptimisedSpecification?.ucsStrength),
            pressure: unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressurePump], pour.pasteOptimisedSpecification?.pumpPressure),
          },
          selectedSpecification: {
            massConcentration: `${pour.pasteSelectedSpecification?.massConcentration} %m`,
            binderContent: `${pour.pasteSelectedSpecification?.binderContent} %b`,
            ucsStrength: unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressureUCS], pour.pasteSelectedSpecification?.ucsStrength),
            pressure: unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressurePump], pour.pasteSelectedSpecification?.pumpPressure),
          },
        };

        const additionalUnitsDisplay = Object.entries(additionalUnitsIndexedByPourId[pour.entityId].additionalDisplayUnits)
          .reduce((displayValues, [/* key */, unitInfo]) => ({
            ...displayValues,
            ...(
              unitInfo.value
              && { [` ${unitInfo.name}`]: `${getValue(unitSystemPreference, unitInfo)} ${getAbbreviation(unitInfo, unitSystemPreference)}` }),
          }),
            {});

        return {
          ...omit(['pourData'], pourWithConversion),
          projectSpecificUnits: additionalUnitsDisplay,
        };
      });

      const t = [transforms.flatten({ objects: true })];

      const data = new Blob([new Parser({ transforms: t }).parse(processedPours)], { type: 'csv/txt' });
      setDownloadLink(window.URL.createObjectURL(data));
    }
  };

  React.useEffect(() => {
    processData();
  }, [pours]);

  return (
    <GridFooterContainer>
      <Link
        download={`${projectName ?? 'project'}-pour-data.csv`}
        href={downloadLink}
        type="text/csv"
        className={classes.link}
      >
        <Button size="small" startIcon={<CloudDownloadIcon />} color="secondary" variant="contained">Download Data</Button>
      </Link>
      <GridPagination />
    </GridFooterContainer>
  );
};

export default PoursTableFooter;
