import { useReactOidc } from '@axa-fr/react-oidc-context';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import { useAuthorisation } from 'providers/authorisation';
import { usePage } from 'providers/page';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_CLOSED,
  DRAWER_WIDTH_CLOSED_SM
} from './utils';

interface AccountMenuProps {
  logout: () => void;
  name: string;
  isLarge: boolean;
}
const AccountMenu = ({ logout, name }: AccountMenuProps) => {
  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleAccountMenuToggle = () => {
    setAccountMenuOpen((prevOpen) => !prevOpen);
  };

  const handleAccountMenuClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setAccountMenuOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAccountMenuOpen(false);
    }
  };

  // return focus to the button when we transitioned from !accountMenuOpen -> accountMenuOpen
  const prevAccountMenuOpen = React.useRef(accountMenuOpen);
  React.useEffect(() => {
    if (prevAccountMenuOpen.current === true && accountMenuOpen === false) {
      anchorRef.current!.focus();
    }

    prevAccountMenuOpen.current = accountMenuOpen;
  }, [accountMenuOpen]);

  return (
    <>
      <Button
        ref={anchorRef}
        variant="outlined"
        aria-label="account of current user"
        aria-controls={accountMenuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleAccountMenuToggle}
        color="inherit"
      >
        <Typography noWrap>{name}</Typography>
      </Button>
      <Popper open={accountMenuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleAccountMenuClose}>
                <MenuList autoFocusItem={accountMenuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem component={Link} href="/ChangePassword" color="textPrimary">
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const InfoMenu = () => {
  const [infoMenuOpen, setInfoMenuOpen] = React.useState(false);
  const infoAnchorRef = React.useRef<HTMLButtonElement>(null);

  const handleInfoMenuToggle = () => {
    setInfoMenuOpen((prevOpen) => !prevOpen);
  };

  const handleInfoMenuClose = (event: React.MouseEvent<EventTarget>) => {
    if (infoAnchorRef.current && infoAnchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setInfoMenuOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setInfoMenuOpen(false);
    }
  };

  const prevInfoMenuOpen = React.useRef(infoMenuOpen);
  React.useEffect(() => {
    if (prevInfoMenuOpen.current === true && infoMenuOpen === false) {
      infoAnchorRef.current!.focus();
    }
    prevInfoMenuOpen.current = infoMenuOpen;
  }, [infoMenuOpen]);

  return (
    <>
      <IconButton
        ref={infoAnchorRef}
        aria-label="info menu"
        aria-controls={infoMenuOpen ? 'info-menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleInfoMenuToggle}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        open={infoMenuOpen}
        anchorEl={infoAnchorRef.current}
        placement="bottom-end"
        role={undefined}
        transition
        disablePortal
        modifiers={{
          offset: {
            enabled: true,
            offset: '60, 0',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleInfoMenuClose}>
                <MenuList autoFocusItem={infoMenuOpen} id="info-menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem
                    onClick={() => window.open('https://minesmart.io/privacy-policy', '_blank')}
                    color="textPrimary"
                  >
                    Privacy & Cookie Policy
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
interface BackNavButtonProps {
  path: string;
  text: string | null;
  isLarge: boolean;
}

const BackNavButton = ({ path, text, isLarge }: BackNavButtonProps) => (
  (isLarge && text) ? (
    <Button
      aria-label="back navigation"
      color="inherit"
      component={RouterLink}
      to={path}
      startIcon={<ArrowBackIcon />}
    >
      {`BACK TO ${text}`}
    </Button>
  ) : (<IconButton color="inherit" aria-label="back navigation" component={RouterLink} to={path}><ArrowBackIcon /></IconButton>)
);

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
    marginLeft: DRAWER_WIDTH_CLOSED_SM,
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_SM}px)`,
    [theme.breakpoints.up('sm')]: {
      marginLeft: DRAWER_WIDTH_CLOSED,
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  subNav: {
    position: 'fixed',
    backgroundColor: theme.palette.primary.dark,
    top: theme.mixins.toolbar.minHeight,
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: DRAWER_WIDTH_CLOSED_SM,
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_SM}px)`,
    [theme.breakpoints.up('sm')]: {
      top: 64,
      marginLeft: DRAWER_WIDTH_CLOSED,
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

type TopBarProps = {
  open: boolean
};

const TopBar = ({ open }: TopBarProps) => {
  const classes = useStyles();
  const { isEnabled, logout } = useReactOidc();
  const { state: { user, loading: authoriseLoading } } = useAuthorisation();
  const { state: pageState } = usePage();
  const location = useLocation();

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('sm'));

  const topBarShouldDisplay = !authoriseLoading && (user || !isEnabled) && !(!isLarge && open);

  const handleLogout = () => {
    logout();
  };

  const isPartialMatch = (path: string) => location.pathname.startsWith(path);

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open && isLarge,
        })}
      >
        <Toolbar>
          {topBarShouldDisplay && (
            <Box display="flex" alignItems="center" width="100%">
              <Box flex="1">
                {pageState.parentPath && (
                  <BackNavButton path={pageState.parentPath!} text={pageState.parentTitle} isLarge={isLarge} />
                )}
              </Box>
              <Box><Typography variant="h6">{pageState.title}</Typography></Box>
              <Box display="flex" flexDirection="row-reverse" flex="1">
                <InfoMenu />
                <AccountMenu logout={handleLogout} isLarge={isLarge} name={user.name} />
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {pageState.subNavigation.length > 0 && (
        <Paper
          square
          className={clsx(classes.subNav, {
            [classes.appBarShift]: open && isLarge,
          })}
        >
          <Tabs
            value={location.pathname}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="mobile navigation"
          >
            {pageState.subNavigation.map((nav) => (
              <Tab
                key={nav.title}
                icon={<nav.iconComponent />}
                value={isPartialMatch(nav.routeProps.path) ? location.pathname : `NO-MATCH-${nav.routeProps.path}`}
                label={isLarge ? nav.title : ''}
                component={RouterLink}
                to={nav.routeProps.path}
              />
            ))}
          </Tabs>
        </Paper>
      )}
    </>
  );
};

export default TopBar;
