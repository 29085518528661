// Point class constants

export const DEFAULT_POINT_COLOUR = '#fff';
export const DEFAULT_POINT_SIZE = 1;
export const DEFAULT_HIT_COLOUR = '#f00';
export const DEFAULT_SELECTED_COLOUR = '#009ddb';
export const DEFAULT_ACTIVE_COLOUR = '#ddd';
export const POINT_HIT_RADIUS = 4;
export const POINT_SELECTED_RADIUS = 4;
export const DEFAULT_SPOOL_COLOUR = '#ddd';
export const DEFAULT_SPOOL_WIDTH = 1;
export const SPOOL_HIT_WIDTH = 2;
export const SPOOL_SELECTED_WIDTH = 3;
export const SPOOL_ACTIVE_WIDTH = 3;
