import { BAD_REQUEST_CODE, EXPECTED_ERROR_CODES, SECOND_IN_MILLISECONDS } from 'config';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from 'react-query';
import ErrorSnackMessage from './ErrorSnackMessage';
import { ApiException, ProblemDetails, ValidationProblemDetails } from './api';

const GENERIC_ERROR_TITLE = 'Unexpected Error';
const GENERIC_ERROR_DETAILS = 'The application has encountered an error. Contact the administrator for further support: contact@minesmart.io';

// Error string format '[2].volume:[ "Input string '6500.04' is not a valid integer. Path '[2].volume', line 1, position 350." ]'
const processErrors = (errors: { [id: string]: string[] }) => Object.values(errors)
  .map((messages) => messages.map((errorMsg) => errorMsg.split('Path')[0]).join(', '))
  .slice(0, 10);

// eslint-disable-next-line import/prefer-default-export
export const withQueryClientDefaults = (WrappedComponent: React.ComponentType) => () => {
  const { enqueueSnackbar } = useSnackbar();

  const triggerErrorFeedback = (error: any) => {
    let errorTitle = GENERIC_ERROR_TITLE;
    let errorDetails: string[] | undefined = [GENERIC_ERROR_DETAILS];

    const isExpectedError = error instanceof ApiException && EXPECTED_ERROR_CODES.includes(error.status);
    const isBadRequest = error instanceof ApiException && error.status === BAD_REQUEST_CODE;

    if (isExpectedError) {
      errorDetails = undefined;
      if (isBadRequest) {
        const errorResult: ValidationProblemDetails = error.result;
        if (errorResult.title) errorTitle = errorResult.title;
        if (errorResult.errors) errorDetails = processErrors(errorResult.errors);
      } else {
        const errorResult: ProblemDetails = error.result;
        if (errorResult.title) errorTitle = errorResult.title;
        if (errorResult.detail) errorDetails = [errorResult.detail];
      }
    }

    enqueueSnackbar(errorDetails, {
      variant: 'error',
      persist: !isExpectedError,
      autoHideDuration: SECOND_IN_MILLISECONDS * 30,
      content: (key) => (
        <ErrorSnackMessage id={key} details={errorDetails} title={errorTitle} />
      ),
    });
  };

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: triggerErrorFeedback,
    }),
    mutationCache: new MutationCache({
      onError: triggerErrorFeedback,
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <WrappedComponent />
    </QueryClientProvider>
  );
};

// This was used to set query client defaults that have since been refactored.
// This might still be useful to decided to keep
