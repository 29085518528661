/* eslint-disable class-methods-use-this */
import { IUtils } from '@date-io/core/IUtils';
import {
  DateTimeFormatter,
  Duration, Instant, LocalDate, LocalDateTime, LocalTime, ZoneId
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en';
import '@js-joda/timezone';
import { is, isNil, not } from 'ramda';

Instant.prototype.toJSON = function toJSON() {
  const formatter = DateTimeFormatter
    .ofPattern('HH:mm:ss.nnnnnnnnn');

  const localDate = LocalDate.ofInstant(this);
  const localTime = LocalTime.ofInstant(this);

  return `${localDate.toString()}T${localTime.format(formatter)}Z`;
};

// eslint-disable-next-line no-useless-escape, max-len
const OFFSET_DATE_TIME_REG_EX = /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])(\.[0-9]{1,9})?Z$/;
// eslint-disable-next-line no-useless-escape
const SINGLE_OFFSET_REG_EX = /[\+-][0-9]{2}$/;

export const makeInstant = (dateTimeString: string): Instant => {
  const matches = dateTimeString.match(OFFSET_DATE_TIME_REG_EX);

  const offsetDateTime = matches![0];

  const offset = offsetDateTime.match(SINGLE_OFFSET_REG_EX);

  const jsJodaFriendly = offset?.length ? offsetDateTime.replace(SINGLE_OFFSET_REG_EX, `${offset[0]}:00`) : offsetDateTime;

  return Instant.parse(`${jsJodaFriendly}`);
};

export class InstantUtils implements IUtils<Instant> {
  locale: any;

  yearFormat = 'yyyy';

  yearMonthFormat = 'MMMM yyyy';

  dateTime12hFormat = 'MMMM do hh:mm aaaa';

  dateTime24hFormat = 'MMMM do HH:mm';

  time12hFormat = 'hh:mm a';

  time24hFormat = 'HH:mm';

  dateFormat = 'MMMM do';

  constructor({ locale }: { locale?: any, }) {
    this.locale = locale ?? Locale.UK;
  }

  date(value?: any): Instant | null {
    if (value === null) {
      return null;
    }

    if (typeof value === 'undefined') {
      return Instant.now();
    }

    if (typeof value === 'string') {
      const date = new Date(value);
      if (not(is(Number, date.valueOf()))) {
        return null;
      }

      return Instant.ofEpochMilli(date.valueOf());
    }

    if (value instanceof Instant) {
      return value;
    }

    if (value instanceof Date) {
      return Instant.ofEpochMilli(value.valueOf());
    }

    throw new Error(`Unknown Date value in function date(): ${value}`);
  }

  parse(value: string, format: string): Instant | null {
    if (value === '' || value.includes('_')) {
      return null;
    }

    const formatter = DateTimeFormatter.ofPattern(format).withLocale(this.locale);

    // js-joda does not parse upper case AM or PM
    value.replace('AM', 'am');
    value.replace('PM', 'pm');

    const isDateFormat = format.includes('d') && format.includes('M') && format.includes('y');
    const isTimeFormat = format.includes('H') && format.includes('m');

    if (isDateFormat && isTimeFormat) {
      return Instant.from(LocalDateTime.parse(value, formatter));
    }

    if (isDateFormat) {
      return Instant.from(
        LocalDateTime.of(
          LocalDate.parse(value, formatter),
          LocalTime.MIN,
        ),
      );
    }

    if (isTimeFormat) {
      return Instant.from(
        LocalDateTime.of(
          LocalDate.now(),
          LocalTime.parse(value.toLowerCase(), formatter),
        ),
      );
    }

    throw new Error('Format not supported');
  }

  isNull(value: Instant | null): boolean {
    return value === null;
  }

  isValid(value: any): boolean {
    return !isNil(this.date(value));
  }

  getDiff(value: Instant, comparing: string | Instant): number {
    const comparingInstant = this.date(comparing);

    if (!comparingInstant) {
      return 0;
    }

    const duration = Duration.between(value, comparingInstant);
    return duration.toMillis();
  }

  isEqual(value: any, comparing: any): boolean {
    const valueInstant = this.date(value);
    const comparingInstant = this.date(comparing);

    if (valueInstant === null && comparingInstant === null) {
      return true;
    }

    if (comparingInstant === null || valueInstant === null) {
      return false;
    }

    return valueInstant.equals(comparingInstant);
  }

  isSameDay(value: Instant, comparing: Instant): boolean {
    return this.isSameMonth(value, comparing) && value.atZone(ZoneId.UTC).dayOfMonth() === comparing.atZone(ZoneId.UTC).dayOfMonth();
  }

  isSameMonth(value: Instant, comparing: Instant): boolean {
    return this.isSameYear(value, comparing) && value.atZone(ZoneId.UTC).monthValue() === comparing.atZone(ZoneId.UTC).monthValue();
  }

  isSameYear(value: Instant, comparing: Instant): boolean {
    return value.atZone(ZoneId.UTC).year() === comparing.atZone(ZoneId.UTC).year();
  }

  isSameHour(value: Instant, comparing: Instant): boolean {
    return this.isSameDay(value, comparing) && value.atZone(ZoneId.UTC).hour() === comparing.atZone(ZoneId.UTC).hour();
  }

  isAfter(value: Instant, comparing: Instant): boolean {
    return value.isAfter(comparing);
  }

  isAfterDay(value: Instant, comparing: Instant): boolean {
    return value.with(LocalTime.MIN).isAfter(this.endOfDay(comparing));
  }

  isAfterYear(value: Instant, comparing: Instant): boolean {
    const endOfYear = comparing
      .atZone(ZoneId.UTC)
      .withMonth(12)
      .withDayOfMonth(31)
      .with(LocalTime.MAX);

    return value.atZone(ZoneId.UTC).isAfter(endOfYear);
  }

  isBeforeDay(value: Instant, comparing: Instant): boolean {
    return value.isBefore(this.startOfDay(comparing));
  }

  isBeforeYear(value: Instant, comparing: Instant): boolean {
    const startOfYear = comparing
      .atZone(ZoneId.UTC)
      .withMonth(1)
      .withDayOfMonth(1)
      .with(LocalTime.MIN);

    return value.atZone(ZoneId.UTC).isBefore(startOfYear);
  }

  isBefore(value: Instant, comparing: Instant): boolean {
    return value.isBefore(comparing);
  }

  startOfMonth(value: Instant): Instant {
    return value
      .atZone(ZoneId.UTC)
      .withDayOfMonth(1)
      .with(LocalTime.MIN)
      .toInstant();
  }

  endOfMonth(value: Instant): Instant {
    return value
      .atZone(ZoneId.UTC)
      .plusMonths(1)
      .minusDays(1)
      .with(LocalTime.MAX)
      .toInstant();
  }

  addDays(value: Instant, count: number): Instant {
    return value.atZone(ZoneId.UTC).plusDays(count).toInstant();
  }

  startOfDay(value: Instant): Instant {
    return value.with(LocalTime.MIN);
  }

  endOfDay(value: Instant): Instant {
    return value.with(LocalTime.MAX);
  }

  format(value: Instant, formatString: string): string {
    const hasTimeZone = formatString.includes('z');

    const parsedFormatString = hasTimeZone ? formatString.replace('z', '\'UTC\'') : formatString;

    const formatter = DateTimeFormatter
      .ofPattern(parsedFormatString)
      .withLocale(this.locale);

    return value.atZone(ZoneId.UTC).format(formatter);
  }

  formatNumber(numberToFormat: string): string {
    return numberToFormat;
  }

  getHours(value: Instant): number {
    return value.atZone(ZoneId.UTC).hour();
  }

  setHours(value: Instant, count: number): Instant {
    return value.atZone(ZoneId.UTC).withHour(count).toInstant();
  }

  getMinutes(value: Instant): number {
    return value.atZone(ZoneId.UTC).minute();
  }

  setMinutes(value: Instant, count: number): Instant {
    return value.atZone(ZoneId.UTC).withMinute(count).toInstant();
  }

  getSeconds(value: Instant): number {
    return value.atZone(ZoneId.UTC).second();
  }

  setSeconds(value: Instant, count: number): Instant {
    return value.atZone(ZoneId.UTC).withSecond(count).toInstant();
  }

  getMonth(value: Instant): number {
    return value.atZone(ZoneId.UTC).monthValue() - 1;
  }

  setMonth(value: Instant, count: number): Instant {
    return value.atZone(ZoneId.UTC).withMonth(count + 1).toInstant();
  }

  getNextMonth(value: Instant): Instant {
    return value.atZone(ZoneId.UTC).plusMonths(1).toInstant();
  }

  getPreviousMonth(value: Instant): Instant {
    return value.atZone(ZoneId.UTC).minusMonths(1).toInstant();
  }

  getMonthArray(value: Instant): Instant[] {
    const startOfYear = value
      .atZone(ZoneId.UTC)
      .withMonth(1)
      .withDayOfMonth(1)
      .with(LocalTime.MIN)
      .toInstant();

    const firstMonth = startOfYear;
    const monthArray = [firstMonth];

    while (monthArray.length < 12) {
      const prevMonth = monthArray[monthArray.length - 1];
      monthArray.push(this.getNextMonth(prevMonth));
    }

    return monthArray;
  }

  getYear(value: Instant): number {
    return value.atZone(ZoneId.UTC).year();
  }

  setYear(value: Instant, count: number): Instant {
    return value.atZone(ZoneId.UTC).withYear(count).toInstant();
  }

  mergeDateAndTime(date: Instant, time: Instant): Instant {
    return this.setMinutes(
      this.setHours(date, this.getHours(time)),
      this.getMinutes(time),
    );
  }

  getWeekdays(): string[] {
    const today = Instant.now();
    const startOfWeek = this.startOfWeek(today);

    const weekdays = [];
    const formatter = DateTimeFormatter.ofPattern('eee').withLocale(this.locale);
    for (let i = 0; i < 7; i += 1) {
      weekdays.push(startOfWeek.atZone(ZoneId.UTC).plusDays(i).format(formatter));
    }
    return weekdays;
  }

  getWeekArray(value: Instant): Instant[][] {
    const startOfMonth = this.startOfMonth(value);
    const endOfMonth = this.endOfMonth(value);

    const start = this.startOfWeek(startOfMonth);
    const end = this.endOfWeek(endOfMonth);

    let count = 0;
    let current = start;
    const nestedWeeks: Instant[][] = [];

    while (current.isBefore(end)) {
      const weekNumber = Math.floor(count / 7);
      nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
      nestedWeeks[weekNumber].push(current);
      current = current.atZone(ZoneId.UTC).plusDays(1).toInstant();
      count += 1;
    }
    return nestedWeeks;
  }

  getYearRange(start: Instant, end: Instant): Instant[] {
    const startDate = start
      .atZone(ZoneId.UTC)
      .withMonth(1)
      .withDayOfMonth(1)
      .with(LocalTime.MIN);

    const endDate = end
      .atZone(ZoneId.UTC)
      .withMonth(12)
      .withDayOfMonth(31)
      .with(LocalTime.MAX);

    const years: Instant[] = [];

    let current = startDate;
    while (this.isBefore(current.toInstant(), endDate.toInstant())) {
      years.push(current.toInstant());
      current = current.plusYears(1);
    }

    return years;
  }

  getMeridiemText(ampm: 'am' | 'pm'): string {
    return ampm === 'am' ? 'AM' : 'PM';
  }

  getCalendarHeaderText(date: Instant): string {
    return this.format(date, this.yearMonthFormat);
  }

  getDatePickerHeaderText(date: Instant): string {
    return this.format(date, 'EEE, MMM d');
  }

  getDateTimePickerHeaderText(date: Instant): string {
    return this.format(date, 'MMM d');
  }

  getMonthText(date: Instant): string {
    return this.format(date, 'MMMM');
  }

  getDayText(date: Instant): string {
    return this.format(date, 'd');
  }

  getHourText(date: Instant, ampm: boolean) {
    return this.format(date, ampm ? 'hh' : 'HH');
  }

  getMinuteText(date: Instant) {
    return this.format(date, 'mm');
  }

  getSecondText(date: Instant) {
    return this.format(date, 'ss');
  }

  getYearText(date: Instant): string {
    return this.format(date, 'yyyy');
  }

  private startDayOfWeek(): number {
    let startDayOfWeek;
    switch (this.locale.localeString()) {
      case 'en-US':
        startDayOfWeek = 7; // Sunday (day 7 in js-joda, which implements ISO8601 calendar only)
        break;
      default:
        startDayOfWeek = 1; // Monday
    }
    return startDayOfWeek % 7;
  }

  private startOfWeek(value: Instant) {
    const dayOfWeek = value.atZone(ZoneId.UTC).dayOfWeek().value();
    return value
      .atZone(ZoneId.UTC)
      .minusDays(dayOfWeek - this.startDayOfWeek())
      .with(LocalTime.MIN)
      .toInstant();
  }

  private endOfWeek(value: Instant) {
    const dayOfWeek = value.atZone(ZoneId.UTC).dayOfWeek().value();
    return value
      .atZone(ZoneId.UTC)
      .plusDays(7 - (dayOfWeek - this.startDayOfWeek()))
      .with(LocalTime.MAX)
      .toInstant();
  }
}
