import Chip from '@material-ui/core/Chip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { PourStatus } from 'providers/api';
import React from 'react';
import { splitCaps } from 'utils';

const pourStatusIcons = {
  0: <ScheduleIcon fontSize="small" />,
  10: <AssignmentIcon fontSize="small" />,
  20: <ThumbUpIcon fontSize="small" />,
  30: <CheckCircleIcon fontSize="small" />,
  40: <AssignmentTurnedInIcon fontSize="small" />,
  [-10]: <HighlightOffIcon fontSize="small" />,
};

interface PourStatusChipProps {
  pourStatus: PourStatus;
}

const PourStatusChip = (
  { pourStatus }: PourStatusChipProps,
) => <Chip icon={pourStatusIcons[pourStatus]} label={splitCaps(PourStatus[pourStatus])} />;

export default PourStatusChip;
