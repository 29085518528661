import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { PourTypeDataDto, useStopePourTypesQuery } from 'providers/api';
import React from 'react';

const getToolTipTitle = (pourTypes: PourTypeDataDto[] | undefined, isLoading: boolean) => {
  if (isLoading) return 'Loading available pour types';
  if (!pourTypes || pourTypes.length === 0) return 'No available pour types';
  return undefined;
};

interface PourTypeSelectMenuProps {
  projectId: string;
  stopeId: string;
  disabled?: boolean;
  onChange: (pourType: PourTypeDataDto) => void;
}

const PourTypeSelectMenu = ({ projectId, stopeId, disabled = false, onChange }: PourTypeSelectMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const { data: pourTypes, isLoading } = useStopePourTypesQuery({
    mineModelId: projectId,
    stopeId,
  });

  const handlePourTypeSelect = (pourType: PourTypeDataDto) => {
    onChange(pourType);
    handleClose();
  };

  const tooltip = !disabled ? getToolTipTitle(pourTypes, isLoading) : null;

  // Top level disable or when there is a tooltip the button can't be used
  const buttonDisabled = disabled || !!tooltip;

  return (
    <Tooltip title={tooltip ?? ''} disableFocusListener={!buttonDisabled} disableHoverListener={!buttonDisabled} disableTouchListener={!buttonDisabled}>
      <span>
        <Button aria-controls="pour-type-menu" aria-haspopup="true" onClick={handleClick} fullWidth variant="outlined" disabled={buttonDisabled}>
          Select pour type preset
        </Button>
        {(pourTypes && pourTypes.length > 0) && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            // This must be set to null to use anchor origin (https://github.com/mui/material-ui/issues/7961#issuecomment-326215406)
            getContentAnchorEl={null}
          >
            {pourTypes.map((pourType: PourTypeDataDto) => (
              <MenuItem
                style={{ width: anchorEl?.offsetWidth ?? undefined }}
                key={pourType.pourTypeDataId}
                onClick={() => handlePourTypeSelect(pourType)}
              >
                {pourType.pourType}
              </MenuItem>
            ))}
          </Menu>
        )}
      </span>
    </Tooltip>
  );
};

export default PourTypeSelectMenu;
