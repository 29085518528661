import {
  ProjectDto,
  useProjectQuery
} from 'providers/api';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { EditProjectLicenceExpiryPageParams } from './constants';

export type EditProjectQueries = [
  UseQueryResult<ProjectDto>
];

const useEditProjectLicenceExpiryQueries = (params: EditProjectLicenceExpiryPageParams): EditProjectQueries => {
  const [project, setProject] = React.useState<ProjectDto | null>(null);

  const query = useProjectQuery(
    params.entityId,
    {
      enabled: !project,
      cacheTime: 0,
    },
  );

  const { data, isFetched } = query;

  React.useEffect(() => {
    if (data && isFetched && !project) {
      setProject(data);
    }
  }, [data, project, isFetched]);

  return [query];
};

export default useEditProjectLicenceExpiryQueries;
