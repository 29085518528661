const volumeConversionFactor = 0.02832;
const distanceConversionFactor = 0.3048;
const tonnageConversionFactor = 0.907185;
const densityConversionFactor = 0.06242796;
const pressureConversionFactor = 6.895;
const pumpPressureConversionFactor = 0.06895;
const smallDistanceSlumpConversionFactor = 25.4;
const flowRateConversionFactor = 4.4028;
const powerConversionFactor = 1.341;
const speedConversionFactor = 0.3048;

// Volume (Stope, Pour)
// - cubic feet (ft^3)(imperial)
// - cubic metres (m^3)(metric)
export const volumeImperialToMetric = (ft: number) => volumeConversionFactor * ft;
export const volumeMetricToImperial = (m: number) => m / volumeConversionFactor;

// Distance
// - feet (ft)(imperial)
// - metres (m)(metric)
export const distanceImperialToMetric = (ft: number) => distanceConversionFactor * ft;
export const distanceMetricToImperial = (d: number) => d / distanceConversionFactor;

// Tonnage (Dry solids feed rate, slurry tonnage)
// - short tons per hour (tn/h)(imperial)
// - tonnes per hour (t/h)(metric)
export const tonnageImperialToMetric = (tn: number) => tonnageConversionFactor * tn;
export const tonnageMetricToImperial = (t: number) => t / tonnageConversionFactor;

// Density
// - short ton per cubic feet (lb/ft^3)(imperial)
// - kilograms per cubic metre (kg/m^3)(metric)
export const densityImperialToMetric = (lbPft: number) => lbPft / densityConversionFactor;
export const densityMetricToImperial = (kgPm: number) => kgPm * densityConversionFactor;

// Pressure (UCS)
// - pounds per square inch (psi)(imperial)
// - kilopascals (kPa)(metric)
export const pressureImperialToMetric = (psi: number) => pressureConversionFactor * psi;
export const pressureMetricToImperial = (kPa: number) => kPa / pressureConversionFactor;

// Pressure (Pump)
// - pounds per square inch (psi)(imperial)
// - bar (bar)(metric)
export const pumpPressureImperialToMetric = (psi: number) => pumpPressureConversionFactor * psi;
export const pumpPressureMetricToImperial = (bar: number) => bar / pumpPressureConversionFactor;
export const pumpPressureKpaToBar = (kpa: number) => kpa / 100;

// small distances (slump)
// - inches (in)(imperial)
// - milimetres (mm)(metric)
export const smallDistanceImperialToMetric = (inch: number) => smallDistanceSlumpConversionFactor * inch;
export const smallDistanceMetricToImperial = (mm: number) => mm / smallDistanceSlumpConversionFactor;

// Flow Rate
// - cubic feet per second (gpm)(imperial)
// - cubic metres per hour (m^3/h)(metric))
export const flowRateImperialToMetric = (gPm: number) => gPm / flowRateConversionFactor;
export const flowRateMetricToImperial = (mPh: number) => mPh * flowRateConversionFactor;

// Power (mixer)
// - horsepower (hp)(imperial)
// - kilowatts (kW)(metric)
export const powerImperialToMetric = (hp: number) => powerConversionFactor * hp;
export const powerMetricToImperial = (kW: number) => kW / powerConversionFactor;

// Speed (in pipe)
// - feet per second (ft/s)(imperial)
// - metres per second (m/s)(metric)
export const speedImperialToMetric = (ftPs: number) => speedConversionFactor * ftPs;
export const speedMetricToImperial = (mPs: number) => mPs / speedConversionFactor;

// output used to compare front and backend parity
export const run = () => {
  const testValue = 1234;
  /* eslint no-console: */
  console.log({ volumeImperialToMetric: volumeImperialToMetric(testValue) });
  console.log({ volumeMetricToImperial: volumeMetricToImperial(testValue) });
  console.log({ distanceImperialToMetric: volumeMetricToImperial(testValue) });
  console.log({ distanceMetricToImperial: distanceMetricToImperial(testValue) });
  console.log({ tonnageImperialToMetric: tonnageImperialToMetric(testValue) });
  console.log({ tonnageMetricToImperial: tonnageMetricToImperial(testValue) });
  console.log({ densityImperialToMetric: densityImperialToMetric(testValue) });
  console.log({ densityMetricToImperial: densityMetricToImperial(testValue) });
  console.log({ pressureImperialToMetric: pressureImperialToMetric(testValue) });
  console.log({ pressureMetricToImperial: pressureMetricToImperial(testValue) });
  console.log({ pumpPressureImperialToMetric: pumpPressureImperialToMetric(testValue) });
  console.log({ pumpPressureMetricToImperial: pumpPressureMetricToImperial(testValue) });
  console.log({ smlDistImperialToMetric: smallDistanceImperialToMetric(testValue) });
  console.log({ smlDistMetricToImperial: smallDistanceMetricToImperial(testValue) });
  console.log({ flowrateImperialToMetric: flowRateImperialToMetric(testValue) });
  console.log({ flowrateMetricToImperial: flowRateMetricToImperial(testValue) });
  console.log({ powerImperialToMetric: powerImperialToMetric(testValue) });
  console.log({ powerMetricToImperial: powerMetricToImperial(testValue) });
};
