import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ProgressButton from 'components/ProgressButton';
import {
  ROUTE_PARAM_IDENTIFIER as ProjectAreaParamIdentifier,
  ROUTE_PATH as ProjectAreaRoutePath
} from 'modules/project/ProjectAreaPage/constants';
import {
  ProjectDto,
  useInfiniteProjectsQuery
} from 'providers/api';
import { replace } from 'ramda';
import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import ProjectSummaryCard from '../ProjectSummaryCard';

const ProjectsPage = () => {
  const history = useHistory();

  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteProjectsQuery();

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });

  const handleSelect = (project: ProjectDto) => () => {
    history.push(
      replace(ProjectAreaParamIdentifier, project.entityId, ProjectAreaRoutePath),
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        {!!data && data.pages.map((page) => (
          <React.Fragment key={page.pager.page}>
            {page.items.map((project) => (
              <Grid key={project.entityId} item xs={12} md={6} lg={4} xl={3}>
                <ProjectSummaryCard project={project} onSelect={handleSelect(project)} />
              </Grid>
            ))}
          </React.Fragment>
        ))}
      </Grid>

      <div ref={sentryRef}>
        {!!hasNextPage && status === 'success' && (
          <Box mt={6} display="flex" justifyContent="center">
            <ProgressButton variant="contained" color="primary" onClick={() => fetchNextPage()} loading={isFetchingNextPage}>
              Load More
            </ProgressButton>
          </Box>
        )}
      </div>
    </>
  );
};

export default ProjectsPage;
