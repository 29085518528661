import { capitalize } from 'utils';
import {
  ClientDto,
  CreateClientCommand,
  PagedListOfClientDto,
  UpdateClientCommand
} from './api';
import {
  makeCreateMutation,
  makeDeleteMutation,
  makeGetQuery,
  makeListQuery,
  makeUpdateMutation,
  PageParams
} from './useApi';
import {
  CLIENT_TEAMS_KEY,
  CLIENT_TEAM_KEY
} from './useTeams';

const CLIENTS_KEY = 'clients';
export interface ClientsQueryParams extends PageParams {
  searchTerm?: string | null;
}

export const useClientsQuery = makeListQuery<ClientsQueryParams, PagedListOfClientDto>(
  CLIENTS_KEY,
  (api, params) => () => api.clients.list(params.page, params.pageSize, params.searchTerm),
);

const CLIENT_KEY = 'client';
export const useClientQuery = makeGetQuery<ClientDto, string>(
  CLIENT_KEY,
  (api, entityId) => () => api.clients.get(entityId),
);

export const useCreateClientMutation = makeCreateMutation<string, CreateClientCommand>(
  CLIENTS_KEY,
  (api) => (client) => api.clients.create(client),
  (mutation) => `Client ${capitalize(mutation.name)} successfully created`,
  [CLIENT_TEAMS_KEY, CLIENT_TEAM_KEY],
);

export const useUpdateClientMutation = makeUpdateMutation<void, UpdateClientCommand>(
  CLIENT_KEY,
  CLIENTS_KEY,
  (api, clientId) => (client) => api.clients.update(clientId, client),
  (mutation) => `${capitalize(mutation.name)} successfully updated`,
);

export const useDeleteClientMutation = makeDeleteMutation<void, ClientDto>(
  CLIENT_KEY,
  CLIENTS_KEY,
  (api) => (client) => api.clients.delete(client.entityId),
  (mutation) => `Client ${capitalize(mutation.name)} successfully deleted`,
  (mutation) => mutation.entityId,
);
