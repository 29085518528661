import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { throughputUnitLabelFromThroughputControlType } from 'hooks/useDisplayUnits/display';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import {
  PourDto,
  ThroughputControlType,
  UnitSystem,
  UcsCoefficientsDto
} from 'providers/api';
import UnitSystemDetail from './ProjectAreaPage/ProjectStopesPage/StopePanelPage/RecipesTab/UnitSystemDetail';

interface PourDetail {
  key: string;
  design: string;
  selected: string;
}

interface PourPasteFillDetailsProps {
  pour: PourDto;
  unitSystemPreference: UnitSystem;
  throughputControlType: ThroughputControlType;
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto; }
}

const PourPasteFillDetails = ({
  pour,
  unitSystemPreference,
  throughputControlType,
  targetDaysCoefficients,
}: PourPasteFillDetailsProps) => {
  const rows: PourDetail[] = [
    {
      key: 'Days',
      design: `${pour.pasteSpecification?.targetDays ?? (Object.keys(targetDaysCoefficients ?? {})[0] as unknown as number)} days`,
      selected: `${pour.pasteSpecification?.targetDays ?? (Object.keys(targetDaysCoefficients ?? {})[0] as unknown as number)} days`,
    },
    {
      key: 'Concentration',
      design: `${pour.pasteOptimisedSpecification?.massConcentration} %m`,
      selected: `${pour.pasteSelectedSpecification?.massConcentration} %m`,
    },
    {
      key: 'Cement',
      design: `${pour.pasteOptimisedSpecification?.binderContent} %`,
      selected: `${pour.pasteSelectedSpecification?.binderContent} %`,
    },
  ];

  return (
    <>
      <UnitSystemDetail
        selected={pour.volume}
        unitSystem={unitSystemPreference}
        unitInfo={unitLabels[BasicDisplayUnits.Volume]}
        decimalPlaces={2}
      />
      <UnitSystemDetail
        designed={pour.pasteSpecification?.throughput}
        selected={pour.pasteSpecification?.throughput}
        unitSystem={unitSystemPreference}
        unitInfo={throughputUnitLabelFromThroughputControlType(throughputControlType)}
        decimalPlaces={2}
      />
      <UnitSystemDetail
        designed={pour.pasteSpecification?.pumpPressure}
        selected={pour.pasteSelectedSpecification?.pumpPressure}
        unitSystem={unitSystemPreference}
        unitInfo={unitLabels[BasicDisplayUnits.PressurePump]}
        decimalPlaces={1}
      />
      <UnitSystemDetail
        designed={pour.pasteSpecification?.targetUCSStrength}
        selected={pour.pasteSelectedSpecification?.ucsStrength}
        unitSystem={unitSystemPreference}
        unitInfo={unitLabels[BasicDisplayUnits.PressureUCS]}
        decimalPlaces={2}
      />

      {rows.map((row) => (
        <TableRow key={row.key}>
          <TableCell component="th" scope="row">{row.key}</TableCell>
          <TableCell align="right">{row.design}</TableCell>
          <TableCell align="right">{row.selected}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default PourPasteFillDetails;
