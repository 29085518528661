import { ZonedDateTime } from '@js-joda/core';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { GridOptions } from '@material-ui/data-grid/';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import Address from 'components/Address';
import DataGridWithLoading, { ResponsiveGridColDef } from 'components/DataGrid';
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  PAGINATION_PAGE_SIZES
} from 'config';
import {
  AddressDto,
  EntitySummaryDto,
  ProjectDto
} from 'providers/api';
import React from 'react';
import { DEFAULT_SHORT_DATE_CONFIG, DEFAULT_TIMEZONE, displayTemporal } from 'utils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  media: {
    paddingBottom: '80%',
    backgroundColor: theme.palette.grey[600],
    position: 'relative',
  },
  warning: {
    color: '#ee7777',
  },
}));

interface ProjectActionsMenuProps {
  project: ProjectDto;
  onSelect: (project: ProjectDto) => void;
  onUpdateLicenceExpiry: (project: ProjectDto) => void;
  onDelete: (project: ProjectDto) => void;
}
const ProjectActionsMenu = ({ project, onSelect, onDelete, onUpdateLicenceExpiry }: ProjectActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onSelect(project);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(project);
    setAnchorEl(null);
  };

  const handleUpdateLicenceExpiry = () => {
    onUpdateLicenceExpiry(project);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls={`project-actions-menu-${project.entityId}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`project-actions-menu-${project.entityId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <ViewCompactIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Details</Typography>
        </MenuItem>
        <MenuItem onClick={handleUpdateLicenceExpiry}>
          <ListItemIcon>
            <VerifiedUserIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Update Licence Expiry</Typography>
        </MenuItem>
        <MenuItem component={Link} href="/Admin/DeploymentPlan/Index" color="textPrimary">
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Export</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteForeverIcon color="error" fontSize="small" />
          </ListItemIcon>
          <Typography color="error" variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

interface ProjectsTableProps {
  data: ProjectDto[] | undefined;
  page: number;
  pageSize: number;
  totalItems: number | undefined;
  onPageChange: GridOptions['onPageChange'];
  onPageSizeChange: GridOptions['onPageSizeChange'];
  onSelect: (project: ProjectDto) => void;
  onUpdateProjectExpiry: (project: ProjectDto) => void;
  onDelete: (project: ProjectDto) => void;
  loading: boolean;
  loadingNew: boolean;
  searchTerm: string | null;
}

const ProjectsTable = ({
  data,
  onPageChange,
  onPageSizeChange,
  onSelect,
  onUpdateProjectExpiry,
  onDelete,
  loadingNew,
  loading,
  page,
  pageSize = PAGINATION_DEFAULT_PAGE_SIZE,
  totalItems = 0,
  searchTerm,
}: ProjectsTableProps) => {
  const classes = useStyles();
  const pageIndex = data ? (page - 1) : 0;
  const rows = data ?? [];

  const columns: ResponsiveGridColDef[] = [
    {
      field: 'name',
      headerName: 'Project Name',
      flex: 1,
      resizable: false,
      sortable: false,
      filterable: false,
      mobileConfig: {
        width: 200,
        isVisible: true,
      },
    },
    {
      field: 'client',
      headerName: 'Client',
      flex: 1,
      resizable: false,
      sortable: false,
      filterable: false,
      renderCell: ({ getValue, id }) => {
        const client: EntitySummaryDto = getValue(id, 'client') as EntitySummaryDto;
        return <>{client?.name ?? ''}</>;
      },
      mobileConfig: {
        width: 200,
        isVisible: true,
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 2,
      renderCell: ({ getValue, id }) => {
        const address = getValue(id, 'siteAddress');
        return address ? (<Address type="inline" address={address as AddressDto} />) : <></>;
      },
      mobileConfig: {
        width: 300,
        isVisible: false,
      },
    },
    {
      field: 'licence',
      headerName: 'Licence',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 2,
      renderCell: ({ row }) => {
        const project = row as ProjectDto;
        const licenceExpiryDate = project.licence.expiryDate && project.licence.expiryDate.atZone(DEFAULT_TIMEZONE);
        const isExpired = licenceExpiryDate && ZonedDateTime.now().isAfter(licenceExpiryDate);
        return (
          <>
            {licenceExpiryDate && `Licence Expires: ${displayTemporal(licenceExpiryDate, DEFAULT_SHORT_DATE_CONFIG)} `}
            {isExpired && <Chip color="default" size="small" label="Expired" variant="outlined" className={classes.warning} />}
          </>
        );
      },
      mobileConfig: {
        width: 300,
        isVisible: false,
      },
    },
    {
      field: '',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 80,
      renderCell: ({ row }) => (
        <ProjectActionsMenu
          project={(row as any) as ProjectDto}
          onSelect={onSelect}
          onUpdateLicenceExpiry={onUpdateProjectExpiry}
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <DataGridWithLoading
      loading={loading}
      loadingNew={loadingNew}
      searchTerm={searchTerm}
      searchFields={['name']}
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      idProp="entityId"
      rows={rows}
      columns={columns}
      pagination
      paginationMode="server"
      page={pageIndex}
      pageSize={pageSize}
      rowCount={totalItems}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onRowClick={(event) => {
        onSelect(event.row as ProjectDto);
      }}
      rowsPerPageOptions={PAGINATION_PAGE_SIZES}
    />
  );
};

export default ProjectsTable;
