import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ProgressButton from 'components/ProgressButton/ProgressButton';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import {
  PourNoteApprovalDto,
  useRemoveApproverMutation,
  useSendForApprovalMutation
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import { useConfirmation } from 'providers/confirm';
import CreateApproverForm from '../CreateApproverForm';
import NewApproversTable from './NewApproverTable';

interface PourNoteGeneratedAreaProps {
  pourId: string;
  pourNoteId: string;
  pourNoteApprovers: PourNoteApprovalDto[];
  complete: boolean;
}

const PourNoteGeneratedArea = ({ pourId, pourNoteId, pourNoteApprovers, complete }: PourNoteGeneratedAreaProps) => {
  const confirm = useConfirmation();

  const removeApproverMutation = useRemoveApproverMutation(pourId);
  const sendForApprovalMutation = useSendForApprovalMutation(pourId);

  const handleRemoveApprover = (email: string) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to remove this approver?',
    }).then(() => {
      removeApproverMutation.mutate({ pourId, pourNoteId, removeApprover: { email } });
    });
  };

  const handleSendForApproval = () => {
    sendForApprovalMutation.mutate({ pourId, pourNoteId });
  };

  return (
    <ProjectAuthorise
      permission={Permission.ManagePours}
      render={(isAuthorised) => (
        <>
          {(!complete && isAuthorised) && (
            <>
              <Typography variant="subtitle1">Add Approver to Pour Note</Typography>
              <CreateApproverForm pourId={pourId} pourNoteId={pourNoteId} disabled={complete} />
            </>
          )}

          <Typography variant="subtitle1">Approvers List</Typography>
          <Box component={Paper} mb={2}>
            <NewApproversTable data={pourNoteApprovers ?? []} onRemoveApprover={handleRemoveApprover} hideActions={complete || !isAuthorised} />
          </Box>

          {isAuthorised && !complete && pourNoteApprovers && pourNoteApprovers.length > 0 && (
            <Box component={Paper} p={2} mb={2}>
              <ProgressButton
                loading={sendForApprovalMutation.isLoading}
                disabled={removeApproverMutation.isLoading}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSendForApproval}
              >
                Send for Approval
              </ProgressButton>
            </Box>
          )}
        </>
      )}
    />
  );
};

export default PourNoteGeneratedArea;
