import { Authorise, Permission } from 'providers/authorisation';
import { useProject } from './providers/project';

interface ProjectAuthoriseProps {
  permission: Permission,
  children?: React.ReactNode,
  render?: (isAuthorised: boolean) => React.ReactNode
}

const ProjectAuthorise = ({ permission, children, render }: ProjectAuthoriseProps) => {
  const { state: { projectId, clientId } } = useProject();

  return (
    // Check project permission first
    <Authorise
      permission={permission}
      teamId={projectId}
      render={(isProjectAuthorised) => {
        if (isProjectAuthorised) {
          return <>{render ? render(true) : children}</>;
        }

        // Then check for client permission
        return (
          <Authorise teamId={clientId} permission={permission} render={render}>
            {children}
          </Authorise>
        );
      }}
    />
  );
};

export default ProjectAuthorise;
