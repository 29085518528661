import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  variant: 'danger' | 'info';
  title?: string;
  description?: string;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onExited: () => void;
}

export const ConfirmationDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  onExited,
}: ConfirmationDialogProps) => (
  <Dialog open={open} onExited={onExited}>
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    {description && (
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      {variant === 'danger' && (
        <>
          <Button color="default" variant="text" onClick={onClose} autoFocus>
            CANCEL
          </Button>
          <Button color="primary" variant="text" onClick={onSubmit}>
            Yes
          </Button>
        </>
      )}

      {variant === 'info' && (
        <Button color="primary" variant="text" onClick={onSubmit}>
          OK
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
