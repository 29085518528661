import {
  ProjectDto,
  useProjectQuery
} from 'providers/api';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { NewRheologyPageParams } from './constants';

export type NewRheologyDataQueries = [
  UseQueryResult<ProjectDto>
];

const useNewRheologyDataQueries = (params: NewRheologyPageParams): NewRheologyDataQueries => {
  const [project, setProject] = React.useState<ProjectDto | null>(null);

  const query = useProjectQuery(
    params.entityId,
    {
      enabled: !project,
      cacheTime: 0,
    },
  );

  const { data, isFetched } = query;

  React.useEffect(() => {
    if (data && isFetched && !project) {
      setProject(data);
    }
  }, [data, project, isFetched]);

  return [query];
};

export default useNewRheologyDataQueries;
