import { ZoneId } from '@js-joda/core';
import { createContext } from 'react';

export interface IProjectContext {
  state: {
    timeZone: ZoneId;
    projectId: string;
    clientId: string;
  }
}

const ProjectContext = createContext<IProjectContext | null>(null);

export default ProjectContext;
