import * as invitationsPageConstants from './InvitationsPage/constants';
import * as usersPageConstants from './UsersPage/constants';

export const ROUTE_PATH = '/admin/users';
export const TITLE = 'Users';

export const INVITATION_ROUTE_PATH = ROUTE_PATH + invitationsPageConstants.ROUTE_PATH;
export const INVITATION_TITLE = invitationsPageConstants.TITLE;
export const USER_ACCOUNTS_ROUTE_PATH = ROUTE_PATH + usersPageConstants.ROUTE_PATH;
export const USER_ACCOUNTS_TITLE = usersPageConstants.TITLE;
