import Container from '@material-ui/core/Container';
import { BasePageProps } from 'modules/makePage';
import {
  ROUTE_PARAM_IDENTIFIER as ProjectDetailsPageParamIdentifier,
  ROUTE_PATH as ProjectDetailsPageRoute
} from 'modules/project-admin/ProjectDetailsPage/constants';
import ProjectForm from 'modules/project-admin/ProjectForm';
import { useUpdateProjectMutation } from 'providers/api';
import { replace } from 'ramda';
import {
  useHistory,
  useParams
} from 'react-router-dom';
import { EditProjectPageParams } from './constants';
import { EditProjectQueries } from './useEditProjectQueries';

const EditProjectPage = ({ queries }: BasePageProps<EditProjectQueries>) => {
  const { entityId } = useParams<EditProjectPageParams>();
  const [projectQuery] = queries;
  const project = projectQuery.data;

  const history = useHistory();
  const mutation = useUpdateProjectMutation(entityId, {
    onSuccess: () => {
      history.push(replace(ProjectDetailsPageParamIdentifier, entityId, ProjectDetailsPageRoute));
    },
  });

  return (
    <Container maxWidth="lg">
      <>
        {project && <ProjectForm mutation={mutation} project={project} />}
      </>
    </Container>
  );
};

export default EditProjectPage;
