import { TextFieldProps } from '@material-ui/core/TextField';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import SimpleAutoComplete from 'components/SimpleAutoComplete';
import { HOUR_IN_MILLISECONDS, PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import {
  ClientDto,
  useClientQuery,
  useClientsQuery
} from 'providers/api';
import React from 'react';

interface ClientFieldProps {
  entityId?: string;
  touched?: boolean;
  error?: string
  disabledOptions?: string[];
  disabled?: boolean;
  textFieldVariant?: TextFieldProps['variant'];
  required?: boolean;
  autoFocus?: boolean;
  onChange: (client?: ClientDto | null) => void;
  onBlur?: () => void;
}

const ClientField = ({
  entityId,
  touched,
  error, disabled,
  textFieldVariant = 'outlined',
  required,
  autoFocus,
  disabledOptions = [],
  onBlur,
  onChange,
}: ClientFieldProps) => {
  const [open, setOpen] = React.useState(autoFocus ?? false);
  const [value, setValue] = React.useState<ClientDto | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);

  const { data: client, isFetched } = useClientQuery(entityId!, {
    /**
     * Only fetch if there is an entityId and no value. i.e. component is provided with an initial entity
     * id but value has not been set yet
     */
    enabled: !!entityId && !value,
  });
  const initialData = isFetched ? client : null;

  /**
   * If there is an initial value set it
   */
  React.useEffect(() => {
    setValue(initialData ?? null);
  }, [initialData]);

  const {
    data,
    isFetching,
  } = useClientsQuery({ page: 1, pageSize: PAGINATION_DEFAULT_PAGE_SIZE, searchTerm }, {
    enabled: open,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const getDisabledOptions = (c: ClientDto) => disabledOptions.includes(c.entityId);
  const getOptionLabel = (c: ClientDto) => c.name;
  const getOptionSelected = (c: ClientDto, v: ClientDto) => c.entityId === v.entityId;
  const filterOptions = (clients: ClientDto[], state: FilterOptionsState<ClientDto>) => clients
    .filter((c) => c.name
      .toLocaleLowerCase()
      .includes(state.inputValue.toLocaleLowerCase()));

  const noOptionsText = searchTerm ? `No clients matching "${searchTerm}"` : 'No clients';
  const handleOnChange = (c: ClientDto) => {
    setValue(c);
    onChange(c);
  };

  /**
   * Default options with initial data if available
   */
  const options = data?.items ?? (initialData ? [initialData!] : []);

  return (
    <SimpleAutoComplete
      label="Client"
      value={value}
      autoFocus={autoFocus}
      disabled={disabled}
      options={options}
      loading={isFetching}
      getOptionDisabled={getDisabledOptions}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={filterOptions}
      noOptionsText={noOptionsText}
      onChange={handleOnChange}
      onSearch={(term: string) => setSearchTerm(term)}
      onBlur={onBlur}
      touched={touched}
      error={error}
      required={required}
      open={open}
      onOpen={(isOpen: boolean) => setOpen(isOpen)}
      textFieldVariant={textFieldVariant}
    />
  );
};

export default ClientField;
