import { ButtonProps } from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DownloadButton from 'components/DownloadButton';
import useManualQuery from 'hooks/useManualQuery';
import { EntitySummaryDto, FileResponse, useDownloadPourAttachment } from 'providers/api';
import React from 'react';

interface PourAttachmentDownloadProps {
  pourId: string;
  fileDetails: EntitySummaryDto;
  buttonText?: string;
  size?: ButtonProps['size'];
}

const PourAttachmentDownload = ({ pourId, buttonText, fileDetails, size }: PourAttachmentDownloadProps) => {
  const {
    fetch,
    queryResult: { data: attachment, isStale, isFetching },
  } = useManualQuery<FileResponse>({
    query: useDownloadPourAttachment,
    params: { pourId, fileId: fileDetails.entityId },
  });

  return (
    <DownloadButton
      downloading={isFetching}
      buttonProps={
        {
          size,
          color: 'primary',
          fullWidth: true,
          startIcon: <CloudDownloadIcon />,
        }
      }
      buttonText={buttonText ?? fileDetails.name}
      file={isStale ? undefined : attachment}
      onDownload={fetch}
    />
  );
};

export default PourAttachmentDownload;
