import { StepButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  FillType,
  PourDto,
  PourStatus,
  UnitSystem
} from 'providers/api';
import React from 'react';
import ApproveAndCompleteArea from './ApproveAndCompleteArea';
import AwaitingApprovalArea from './AwaitingApprovalArea';
import PlannedArea from './PlannedArea';
import PourNoteGeneratedArea from './PourNoteGeneratedArea';

export type PourDetailsStepperStep = {
  display: string;
  step: number;
  statuses: number[];
  disabledOnceComplete?: boolean;
}

const steps: PourDetailsStepperStep[] = [
  { display: 'Planned', step: 0, statuses: [0], disabledOnceComplete: true },
  { display: 'Generated', step: 1, statuses: [10] },
  { display: 'Awaiting Approval', step: 2, statuses: [20] },
  { display: 'Approved', step: 3, statuses: [30], disabledOnceComplete: true },
  { display: 'Complete', step: 4, statuses: [40] },
];

const getCurrentStepIndexFromPourStatus = (status: PourStatus): number => {
  const currentStep = steps.findIndex((step) => step.statuses.includes(status));
  return currentStep;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  activeStep: {
    '& *': {
      fontWeight: `${theme.typography.fontWeightBold} !important`,
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  completeDisabled: {
    '& *': {
      color: theme.palette.text.disabled,
    },
  },
}));

interface PourDetailsStepperProps {
  pour: PourDto;
  fillType: FillType;
  unitSystemPreference: UnitSystem;
}

const PourDetailsStepper = ({ pour, fillType, unitSystemPreference }: PourDetailsStepperProps) => {
  const classes = useStyles();
  const maxStep = getCurrentStepIndexFromPourStatus(pour.status);
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const activeStep = React.useRef(maxStep);
  const setActiveStep = (step: number, update = false) => {
    activeStep.current = step;

    if (update) {
      forceUpdate();
    }
  };

  // If change in pour results in a step back we need to correct the active step
  if (maxStep < activeStep.current) {
    setActiveStep(maxStep);
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <PlannedArea
            pourReference={pour.reference}
            pourId={pour.entityId}
            fillType={fillType}
            pourDate={pour.intendedPourDate}
            pourVolume={pour.volume}
            volumeRetainedAfterOutflow={pour.volumeRetainedAfterOutflow}
            unitSystemPreference={unitSystemPreference}
          />
        );
      case 1:
        if (!pour.pourNote) {
          throw new Error('Attempted to load \'Pour Note Generated\' step without a pour note');
        }

        return (
          <PourNoteGeneratedArea
            pourId={pour.entityId}
            pourNoteId={pour.pourNote.entityId}
            pourNoteApprovers={pour.pourNote.activeApprovers}
            complete={maxStep > step}
          />
        );
      case 2:
        if (!pour.pourNote) {
          throw new Error('Attempted to load \'Pour Note Generated\' step without a pour note');
        }
        if (pour.pourNote.activeApprovers.length <= 0) {
          throw new Error('Attempted to load \'Awaiting Approval\' step without adding pour note approvers');
        }
        return (
          <AwaitingApprovalArea
            pourReference={pour.reference}
            pourId={pour.entityId}
            pourNoteId={pour.pourNote.entityId}
            pourNoteApprovers={pour.pourNote.activeApprovers}
            complete={maxStep > step}
          />
        );
      case 3:
      case 4:
        return (
          <ApproveAndCompleteArea
            pourId={pour.entityId}
            pourData={pour.pourData}
            unitSystemPreference={unitSystemPreference}
            fillType={fillType}
          />
        );
      default:
        return <></>;
    }
  };

  React.useEffect(() => {
    const nextStep = pour.status ? getCurrentStepIndexFromPourStatus(pour.status) : 0;
    if (nextStep !== activeStep.current) {
      setActiveStep(nextStep, true);
    }
  }, [pour]);

  return (
    <div className={classes.root}>
      <Stepper nonLinear activeStep={activeStep.current}>
        {steps.map((step, index) => (
          <Step
            key={step.display}
            className={index === activeStep.current ? classes.activeStep : undefined}
            completed={index <= maxStep}
            disabled={index > maxStep || (index < maxStep && step.disabledOnceComplete && step.disabledOnceComplete)}
          >
            <StepButton onClick={() => setActiveStep(index, true)}>
              <StepLabel className={(index < maxStep && step.disabledOnceComplete) ? classes.completeDisabled : ''}>{step.display}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box mt={2}>
        {getStepContent(activeStep.current)}
      </Box>
    </div>
  );
};

export default PourDetailsStepper;
