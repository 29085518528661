import { createContext } from 'react';
import { BackfillPlannerRole, Permission } from './permissions';

export interface BackfillPlannerUser {
  id: string,
  username: string,
  name: string,
  email: string,
  roles: string[],
  permissions: {
    teams: string[],
    teamRoles: { teamId: string, role: BackfillPlannerRole }[],
  }
}

export interface IAuthorisationContext {
  state: {
    user: BackfillPlannerUser;
    loading: boolean;
  },
  functions: {
    isAdmin: () => boolean,
    canViewTeam: (entityIds: string[]) => boolean,
    validatePermission: (permission: Permission, ids: string[]) => boolean,
  }
  actions: {}
}

const AuthorisationContextContext = createContext<IAuthorisationContext | null>(null);

export default AuthorisationContextContext;
