import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PipeDataDto } from 'providers/api';
import React from 'react';

const useStyles = makeStyles(() => createStyles({
    text: {
      flexGrow: 1,
    },
  }));

interface PipeTypeSelectFieldProps {
  pipeTypes: PipeDataDto[],
  value: string;
  onChange: (pipeTypeId: string | null) => void;
}

const PipeTypeSelectField = ({ value, pipeTypes, onChange }: PipeTypeSelectFieldProps) => {
  const classes = useStyles();
  const [selectedPipeType, setSelectedPipeType] = React.useState<PipeDataDto | null>(
    pipeTypes.find((pipe) => pipe.pipeId === value) ?? null,
  );

  React.useEffect(() => {
    onChange(selectedPipeType?.pipeId ?? null);
  }, [selectedPipeType]);

  return (
    <Autocomplete
      id="controlled-demo"
      value={selectedPipeType}
      options={pipeTypes}
      getOptionLabel={(types: PipeDataDto) => types.name}
      renderOption={(option) => (
        <>
          <div className={classes.text}>
            {`${option.name} - ${option.pipeClass}`}
            <Divider />
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  Inside diameter (mm):
                  <br />
                  Velocities (m/s):
                  <br />
                  Pressure rating (m
                  <sup>3</sup>
                  ):
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  {option.insideDiameter}
                  <br />
                  {`min: ${option.minimumVelocity} - max: ${option.maximumVelocity}`}
                  <br />
                  {`min: ${option.minimumPressure} - max: ${option.pressureRating}`}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </>
      )}
      onChange={(event: any, newValue: PipeDataDto | null) => {
        setSelectedPipeType(newValue);
      }}
      renderInput={(params) => <TextField {...params} fullWidth label="Stope Pipe Type" variant="outlined" required />}
    />
  );
};

export default PipeTypeSelectField;
