import { HOUR_IN_MILLISECONDS } from 'config';
import {
  BackfillRecipeDto,
  ProjectDto,
  useProjectQuery,
  useRecipeQuery
} from 'providers/api';
import { UseQueryResult } from 'react-query';
import { NewPourPageParams } from './constants';

export type NewPourQueries = [
  UseQueryResult<BackfillRecipeDto, unknown>,
  UseQueryResult<ProjectDto, unknown>,
];

const useNewPourQueries = (params: NewPourPageParams): NewPourQueries => {
  const recipeQuery = useRecipeQuery(params.recipeId, {
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const projectQuery = useProjectQuery(params.projectId, {
    staleTime: HOUR_IN_MILLISECONDS,
  });

  return [recipeQuery, projectQuery];
};

export default useNewPourQueries;
