import { HOUR_IN_MILLISECONDS } from 'config';
import {
  MineModelDto,
  PourTypeDataDto,
  ProjectDto,
  StopeBackfillSummaryDto,
  useMineModelQuery,
  useMineOverviewQuery,
  usePourTypesQuery,
  useProjectQuery
} from 'providers/api';
import { UseQueryResult } from 'react-query';
import { ProjectAreaPageParams } from './constants';

export type ProjectAreaQueries = [
  UseQueryResult<ProjectDto>,
  UseQueryResult<MineModelDto | undefined>,
  UseQueryResult<StopeBackfillSummaryDto[]>,
  UseQueryResult<PourTypeDataDto[]>
];

const useProjectAreaQueries = (params: ProjectAreaPageParams): ProjectAreaQueries => {
  const projectQuery = useProjectQuery(params.entityId, {
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const mineModelQuery = useMineModelQuery(params.entityId, {
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const mineOverviewQuery = useMineOverviewQuery(params.entityId, {
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const minePourTypesQuery = usePourTypesQuery(params.entityId);

  return [projectQuery, mineModelQuery, mineOverviewQuery, minePourTypesQuery];
};

export default useProjectAreaQueries;
