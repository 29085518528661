import { ThroughputControlType } from 'providers/api';
import { generateMassBalanceFromFlowRate, generateMassBalanceFromTonnage, isNumber } from 'utils';
import type { DictionaryRange, Range, RecipeState } from '../typesInterfaces';

// dryDensity: number;
// wetDensity: number;
// dryTonnage: number;
// wetTonnage: number;
// dryFlowRate: number;
// wetFlowRate: number;
// binderConcentrationByMass: number;
// totalDryConcentrationByMass: number;
// pumpPressure: number;
// unconfinedCompressiveStrength: number;
// curingDuration: number;
// tailingsSolidsDensity: number;
// binderParticleDensity: number;
// carrierFluidDensity: number;

// export interface Range {
//   min: number;
//   max: number;
//   data: number[];
//   interval: number;
// }

// export interface DictionaryRange {
//   concentration: Range;
//   tons: Range;
//   pipes: Range;
//   cement: Range;
// }

function checkInRange(
  values: number[],
  range: Range,
) {
  let errorFound = false;
  const faultyValues: number[] = [];
  const errors = {
    values: faultyValues,
    max: false,
    min: false,
    interval: false,
    data: false,
  };
  for (let i = 0; i < values.length; i += 1) {
    const value = values[i];
    if (value > range.max) {
      errors.max = true;
      errorFound = true;
    }
    if (value < range.min) {
      errors.min = true;
      errorFound = true;
    }
    const moduloCheck = value % range.interval;
    if (moduloCheck > 0) {
      errors.interval = true;
      errorFound = true;
    }
    // const found = range.data.find((o) => o === value);
    // if (!found) {
    //   errors.data = true;
    //   errorFound = true;
    // }
    // if (errorFound) {
    //   errors.values.push(value);
    // }
  }
  return {
    errorFound,
    errors,
  };
}

function verifyRecipeWithinDictionaryRange(
  currentRecipeState: RecipeState,
  dictionaryRange: DictionaryRange,
) {
  const errors = {
    concentration: {},
    tonnage: {},
    binder: {},
  };

  const { totalDryConcentrationByMass, dryTonnage, binderConcentrationByMass } = currentRecipeState;
  const { concentration, tons, cement } = dictionaryRange;
  if (totalDryConcentrationByMass && dryTonnage && binderConcentrationByMass) {
    errors.concentration = checkInRange([totalDryConcentrationByMass], concentration);
    errors.tonnage = checkInRange([dryTonnage], tons);
    errors.binder = checkInRange([binderConcentrationByMass], cement);
  }
  return errors;
}

// if any of the values change, the state must be updated
const updateRecipeState = (
  recipeState: RecipeState,
  newTotalDryConcentrationByMass?: number, // decimal
  newBinderConcentrationByMass?: number, // decimal
  // Rheology
  newTailingsSolidsDensity?: number,
  newBinderParticleDensity?: number,
  newCarrierFluidDensity?: number,
  // throughput
  newTailingsDryTonnage?: number,
  newWetFlowRate?: number,
  newWetTonnage?: number,
): RecipeState => {
  const totalDryConcentrationByMass = newTotalDryConcentrationByMass ?? recipeState.totalDryConcentrationByMass;
  const binderConcentration = newBinderConcentrationByMass ?? recipeState.binderConcentrationByMass;
  const tailingsSolidsDensity = newTailingsSolidsDensity ?? recipeState.tailingsSolidsDensity;
  const binderParticleDensity = newBinderParticleDensity ?? recipeState.binderParticleDensity;
  const carrierFluidDensity = newCarrierFluidDensity ?? recipeState.carrierFluidDensity;

  // Main throughput value
  const tailingsDryTonnage = newTailingsDryTonnage ?? recipeState.tailingsDryTonnage;
  const totalWetTonnage = newWetTonnage ?? recipeState.wetTonnage;
  const totalFlowRate = newWetFlowRate ?? recipeState.wetFlowRate;

  // check if enough data has been supplied, otherwise exit
  if (
    !isNumber(totalDryConcentrationByMass)
    || !isNumber(binderConcentration)
    || !isNumber(tailingsSolidsDensity)
    || !isNumber(binderParticleDensity)
    || !isNumber(carrierFluidDensity)
    || (!isNumber(tailingsDryTonnage) && !isNumber(totalWetTonnage) && !isNumber(totalFlowRate))
  ) {
    return recipeState;
    // throw new Error('Attempting to update recipe state without all the required parameters.');
  }
  let massBalance;

  const { throughputControlType } = recipeState;

  switch (throughputControlType) {
    case ThroughputControlType.DryTonnage:
      massBalance = tailingsDryTonnage && generateMassBalanceFromTonnage({
        totalTonnage: tailingsDryTonnage,
        totalDryConcentrationByMass,
        binderConcentration,
        tailingsSolidsDensity,
        binderParticleDensity,
        carrierFluidDensity,
        isDryTonnage: true,
      });
      break;
    case ThroughputControlType.WetTonnage:
      massBalance = totalWetTonnage && generateMassBalanceFromTonnage({
        totalTonnage: totalWetTonnage,
        totalDryConcentrationByMass,
        binderConcentration,
        tailingsSolidsDensity,
        binderParticleDensity,
        carrierFluidDensity,
        isDryTonnage: false,
      });
      break;
    case ThroughputControlType.FlowRate:
      massBalance = totalFlowRate && generateMassBalanceFromFlowRate({
        totalFlowRate,
        binderConcentration,
        tailingsSolidsDensity,
        binderParticleDensity,
        totalDryConcentrationByMass,
        carrierFluidDensity,
      });
      break;
    default:
      break;
  }

  // something wrong so keep recipe state asa it was
  if (!massBalance) return recipeState;

  return {
    ...recipeState,
    dryDensity: massBalance.totalDrySolidsDensity,
    wetDensity: massBalance.slurryDensity,
    dryTonnage: massBalance.totalDryTonnage,
    wetTonnage: massBalance.totalWetTonnage,
    dryFlowRate: massBalance.totalDryFlowRate,
    wetFlowRate: massBalance.totalFlowRate,
    tailingsDryTonnage: massBalance.tailingsDryTonnage,
    binderConcentrationByMass: binderConcentration,
    totalDryConcentrationByMass,
    tailingsSolidsDensity,
    binderParticleDensity,
    carrierFluidDensity,
  };
};

export {
  updateRecipeState,
  verifyRecipeWithinDictionaryRange,
};
