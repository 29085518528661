export default class Graph {
    public nodes: Set<string>;

    private edges: Map<string, Map<string, number>>;

    constructor() {
      this.nodes = new Set();
      this.edges = new Map();
    }

    setEdge(v: string, w: string, weight: number = 1): void {
      this.addNode(v);
      this.addNode(w);

      if (!this.edges.has(v)) {
        this.edges.set(v, new Map());
      }

      this.edges.get(v)!.set(w, weight);
    }

    edge(v: string, w: string): number | undefined {
      return this.edges.get(v)?.get(w);
    }

    successors(v: string): string[] {
      return this.edges.get(v) ? Array.from(this.edges.get(v)!.keys()) : [];
    }

    predecessors(v: string): string[] {
      const preds: string[] = [];
      this.edges.forEach((neighbors, node) => {
        if (neighbors.has(v)) {
          preds.push(node);
        }
      });
      return preds;
    }

    private addNode(v: string): void {
      this.nodes.add(v);
      if (!this.edges.has(v)) {
        this.edges.set(v, new Map());
      }
    }

    getNodes(): string[] {
      return Array.from(this.nodes);
    }

    calculatePathLength(path: string[]): number {
      let totalLength = 0;

      for (let i = 0; i < path.length - 1; i += 1) {
        const v = path[i];
        const w = path[i + 1];
        const edgeWeight = this.edge(v, w) ?? this.edge(w, v);

        if (edgeWeight === undefined) {
          throw new Error(`Edge from ${v} to ${w} does not exist`);
        }

        totalLength += edgeWeight;
      }

      return totalLength;
    }
}
