import {
  convert, Instant, LocalDate,
  LocalDateTime,
  ZonedDateTime,
  ZoneId
} from '@js-joda/core';
import '@js-joda/timezone';
import { isNil, path } from 'ramda';

export { InstantUtils, makeInstant } from './instant';
export { makeDate } from './js-date';
export { makeLocalDate, makeLocalDateUtils } from './local-date';
export { makeLocalDateTime, makeLocalDateTimeUtils } from './local-date-time';
export { makeZonedDateTime, makeZonedDateTimeUtils } from './zoned-date-time';

export const DEFAULT_TIMEZONE = ZoneId.of('Europe/London');

export const isTemporal = (testTemporal: any) => testTemporal instanceof ZonedDateTime
  || testTemporal instanceof LocalDateTime
  || testTemporal instanceof LocalDate
  || testTemporal instanceof Date;

export const DEFAULT_SHORT_DATE_CONFIG: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const DEFAULT_LONG_DATE_TIME_CONFIG: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
};

export const DEFAULT_SHORT_DATE_TIME_CONFIG: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
};
export const DEFAULT_TIMEZONE_CONFIG: Intl.DateTimeFormatOptions = {
  timeZone: 'short',
};

export const displayTemporal = (temporal: ZonedDateTime | LocalDate | LocalDateTime | Date, options?: Intl.DateTimeFormatOptions) => {
  const jsDate = temporal instanceof Date ? temporal : convert(temporal, temporal instanceof ZonedDateTime ? temporal.zone() : undefined).toDate();

  return jsDate.toLocaleDateString(undefined, {
    ...options,
    timeZone: temporal instanceof ZonedDateTime ? temporal.zone().id() : undefined,
    timeZoneName: 'short',
  });
};

interface DateSortOptions {
  dateProp?: string | string[];
  asc: boolean;
}

export const dateSort = ({ dateProp, asc = true }: DateSortOptions) => {
  let propPath: string[] | undefined;

  if (dateProp) {
    propPath = Array.isArray(dateProp) ? dateProp : [dateProp];
  }

  return (a: any, b: any) => {
    const aDate = propPath ? path(propPath, a) : a as ZonedDateTime | LocalDate | LocalDateTime | Date;
    const bDate = propPath ? path(propPath, b) : b as ZonedDateTime | LocalDate | LocalDateTime | Date;

    if (typeof aDate !== typeof bDate) {
      throw new Error('You cannot sort difference date types');
    }

    if (isNil(aDate) || isNil(bDate)) {
      return 0;
    }

    const left = asc ? aDate : bDate;
    const right = asc ? bDate : aDate;

    if (left instanceof ZonedDateTime && right instanceof ZonedDateTime) {
      return left.compareTo(right);
    }

    if (left instanceof LocalDate && right instanceof LocalDate) {
      return left.compareTo(right);
    }

    if (left instanceof LocalDateTime && right instanceof LocalDateTime) {
      return left.compareTo(right);
    }

    if (left instanceof Instant && right instanceof Instant) {
      return left.compareTo(right);
    }

    if (left instanceof Date && right instanceof Date) {
      return left.getTime() - right.getTime();
    }

    throw new Error('Unsupported date type');
  };
};
