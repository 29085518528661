import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

interface ConditionalButtonProps extends ButtonProps {
  disabledReason: string;
}

const ConditionalButton = ({ disabledReason, ...buttonProps }: ConditionalButtonProps) => {
  if (buttonProps.disabled) {
    return (
      <Tooltip title={disabledReason}>
        <Box><Button {...buttonProps} /></Box>
      </Tooltip>
    );
  }

  return <Button {...buttonProps} />;
};

export default ConditionalButton;
