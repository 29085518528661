import React from 'react';
import { UseQueryOptions, UseQueryResult } from 'react-query';

interface useManualQueryProps<TData> {
  rootEnabled?: boolean,
  params: any,
  query: (params: any, options?: UseQueryOptions<TData>) => UseQueryResult<TData>,
}

const useManualQuery = <TData>({ rootEnabled = true, params, query }: useManualQueryProps<TData>) => {
  const [enable, setEnable] = React.useState(false);

  const queryResult = query(params, {
    staleTime: 5 * 1000 * 60,
    enabled: rootEnabled && enable,
    keepPreviousData: false,
  });

  const fetch = () => {
    if (queryResult.isStale) {
      setEnable(true);
    }
  };

  React.useEffect(() => {
    if (!queryResult.isFetching) {
      setEnable(false);
    }
  }, [queryResult.isFetching]);

  return {
    queryResult,
    fetch,
  };
};

export default useManualQuery;
