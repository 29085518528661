import React from 'react';

interface CanvasProps{
    onLoaded: (canvasElement: HTMLCanvasElement) => void;
    className: string;
}
const Canvas = ({ onLoaded, className }: CanvasProps) => {
    const mineModelRef = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
      if (!mineModelRef.current) throw new Error('Canvas has not being initiated');
      onLoaded(mineModelRef.current);
    }, []);

    return (
      <canvas
        className={className}
        ref={mineModelRef}
      />
    );
};

export default Canvas;
