import {
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridRowData
} from '@material-ui/data-grid/';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  isNil,
  prop,
  times
} from 'ramda';
import { useDebounce } from 'use-debounce';
import { getRandomInt } from 'utils';

interface DataGridWithLoadingProps {
  loading: boolean;
  loadingNew: boolean;
  searchTerm: string | null;
  searchFields?: string[];
  pageSize: number;
  idProp: string;
}

interface MobileConfig {
  isVisible: boolean;
  width: number;
}
export interface ResponsiveGridColDef extends GridColDef {
  mobileConfig?: MobileConfig;
}

export interface ResponsiveDataGridProps extends DataGridProps {
  columns: ResponsiveGridColDef[];
}

const DataGridWithLoading = (
  { loadingNew, searchTerm, searchFields = [], loading, idProp, ...props }: ResponsiveDataGridProps & DataGridWithLoadingProps,
) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isSearchingNew = searchTerm !== null && loadingNew;
  let { rows, columns } = props;

  const { pageSize } = props;

  if (isSearchingNew && searchFields) {
    const bulkFilter = (
      newRows: GridRowData[],
      field: string,
    ) => ([
      ...newRows,
      ...rows.filter((c) => c[field].toLocaleLowerCase().includes(searchTerm!.toLocaleLowerCase())),
    ]);
    rows = searchFields.reduce(bulkFilter, []);
  }

  const hasLocalResultsForNewSearch = isSearchingNew && rows.length;
  const [loadingNewDebounced] = useDebounce(loadingNew && !hasLocalResultsForNewSearch, 300);

  columns = columns.map((col: ResponsiveGridColDef) => {
    const processedColumn = {
      ...col,
      renderCell: (params: any) => {
        if (loadingNewDebounced) {
          return <Skeleton animation="wave" height={20} width={getRandomInt(80, 150)} />;
        }
        if (isNil(col.renderCell)) {
          return <>{params.formattedValue}</>;
        }
        return col.renderCell(params);
      },
    };
    if (isSmall && col.mobileConfig) {
      processedColumn.width = col.mobileConfig.width;
      processedColumn.flex = undefined;
      processedColumn.hide = !col.mobileConfig.isVisible;
    }
    return processedColumn;
  });

  if (loadingNewDebounced) {
    rows = [...rows, ...times<any>((entityId) => ({ [idProp]: `${entityId}`, name: '' }), pageSize - rows.length)];
  }

  const updateProps = {
    ...props,
    rows,
    columns,
    getRowId: prop<string>(idProp),
  };

  return (
    <Box position="relative">
      <DataGrid {...updateProps} />
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '300ms' : '0ms',
        }}
        unmountOnExit
      >
        <Box position="absolute" top={52} left={0} width="100%"><LinearProgress /></Box>
      </Fade>
    </Box>
  );
};

export default DataGridWithLoading;
