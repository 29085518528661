import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FormikActions } from 'components/Form';
import useForm from 'hooks/useForm';
import {
  InviteUserToTeamsCommand,
  TeamRolesAssignmentForm
} from 'providers/api';
import { isEmpty } from 'ramda';
import { UseMutationResult } from 'react-query';
import {
  array,
  object,
  SchemaOf,
  string
} from 'yup';
import ClientPermissions from '../ClientPermissions';
import useClientPermissions from '../ClientPermissions/useClientPermissions';

const NewUserSchema: SchemaOf<InviteUserToTeamsCommand> = object().shape({
  email: string()
    .email('Email is invalid')
    .required('Email is required'),
  teamRoles: array().min(1).of(
    object().shape({
      teamId: string().required('Required'),
      roles: array().min(1).of(
        string().required('Required'),
      ).required('Required'),
    }),
  ),
});

interface UserInviteFormProps {
  mutation: UseMutationResult<void, unknown, InviteUserToTeamsCommand>;
}
const UserInviteForm = ({ mutation }: UserInviteFormProps) => {
  let clientTeamErrors = {};

  const {
    formik,
    helpers,
  } = useForm<InviteUserToTeamsCommand>({
    mutation,
    formikConfig: {
      initialValues: {
        email: '',
        teamRoles: [],
      },
      onSubmit: (form, { setSubmitting }) => {
        if (formik.isValid && isEmpty(clientTeamErrors)) {
          setSubmitting(true);
          mutation.mutate(form, {
            onSettled: () => {
              setSubmitting(false);
            },
          });
        }
      },
      validationSchema: NewUserSchema,
    },
  });

  const {
    values,
    isValid,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  const handlePermissionsChange = (teamRoles: TeamRolesAssignmentForm[]) => {
    setFieldValue('teamRoles', teamRoles);
  };

  const {
    roles,
    clientTeamErrors: _clientTeamErrors,
    teamRoles,
    activeClientTeams,
    clientTeams,
    setActiveClientTeam,
    onUpdate,
    onAdd,
    onRemove,
  } = useClientPermissions(handlePermissionsChange);

  clientTeamErrors = _clientTeamErrors;

  const inviteAction: [string, JSX.Element] = ['invite', (
    <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || !isValid || !isEmpty(clientTeamErrors)}>
      Invite
    </Button>
  )];

  return (
    <form onSubmit={handleSubmit}>
      {/**
       * Email
       */}
      <Typography variant="subtitle1">Email</Typography>
      <Box component={Paper} p={2} mb={2}>
        <TextField
          required
          autoFocus
          fullWidth
          id="name"
          name="email"
          label="Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={helpers.hasError('email')}
          helperText={helpers.getErrorHelpText('email')}
        />
      </Box>
      {/**
       * Permissions
       */}
      <Box my={2}>
        <Typography variant="subtitle1">Client Permissions</Typography>

        <ClientPermissions
          teamRoles={teamRoles}
          roles={roles ?? []}
          clientErrors={clientTeamErrors}
          activeClientTeams={activeClientTeams ?? null}
          clientTeams={clientTeams}
          onRemove={onRemove}
          onAdd={onAdd}
          onUpdate={onUpdate}
          setActiveClientTeam={setActiveClientTeam}
        />
      </Box>

      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText="Invite"
          right={['reset', inviteAction]}
        />
      </Box>
    </form>
  );
};

export default UserInviteForm;
