import Box, { BoxProps } from '@material-ui/core/Box';
import { UnitBaseWithValue } from 'hooks/useDisplayUnits/useDisplayUnits';
import { DisplayUnits as ServerAdditionalDisplayUnits, UnitSystem } from 'providers/api';
import { isNumber, round } from 'utils';
import WarningTooltipIcon from '../../WarningTooltipIcon';
import UnitSystemNumberField from './UnitSystemNumberField';

const BOX_PROPS: BoxProps = {
  width: '49%',
  pb: 2,
};
interface AdditionalDisplayUnitsProps {
  additionalDisplayUnitsMap: Record<ServerAdditionalDisplayUnits, UnitBaseWithValue>;
  warnings: Record<ServerAdditionalDisplayUnits, string | undefined>
  unitSystemPreference: UnitSystem;
}

const AdditionalDisplayUnits = ({ additionalDisplayUnitsMap, warnings, unitSystemPreference }: AdditionalDisplayUnitsProps) => {
  const processedWarnings = Object.values(warnings)
    .filter((warning) => warning !== undefined)
    .map((warning) => ({ display: warning! }));

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between" mt={3}>
      {processedWarnings && processedWarnings.length > 0 && (
        <Box width="100%" display="flex" flexDirection="row-reverse" mb={1}>
          <WarningTooltipIcon warnings={processedWarnings} />
        </Box>
      )}
      {
        Object.entries(additionalDisplayUnitsMap)
          .filter(([/* key */, unitInfo]) => unitInfo.value !== undefined)
          .map(([key, unitInfo]) => (
            <Box {...BOX_PROPS} key={key}>
              <UnitSystemNumberField
                unitSystem={unitSystemPreference}
                unitInfo={unitInfo}
                decimalPlaces={isNumber(unitInfo.imperial.decimalPlaces) ? unitInfo.imperial.decimalPlaces : unitInfo.decimalPlaces}
                disabled
                size="small"
                value={round(unitInfo.value ?? 0, 0)}
                label={unitInfo.name}
              />
            </Box>
          ))
      }
    </Box>
  );
};

export default AdditionalDisplayUnits;
