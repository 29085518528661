import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AlertText from 'components/AlertText';
import ClientTeamsField from 'components/ClientTeamsField';
import {
  ClientTeamsDto,
  EntitySummaryDto,
  RoleDto,
  TeamDto,
  TeamRolesAssignmentForm
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import {
  indexBy,
  map,
  prop
} from 'ramda';
import React from 'react';
import PermissionsField from './PermissionsField';

interface ClientPermissionsProps {
  teamRoles: TeamRolesAssignmentForm[];
  clientErrors: { [clientId: string]: boolean };
  clientTeams: EntitySummaryDto[];
  roles: RoleDto[];
  activeClientTeams: ClientTeamsDto | null;
  setActiveClientTeam: (teamId: string | null) => void;
  onAdd: (clientTeam: TeamDto) => void;
  onRemove: (clientTeamId: string) => void;
  onUpdate: (teamRoles: TeamRolesAssignmentForm[]) => void;
  disabled?: boolean;
}

const NO_PERMISSIONS_ERROR = 'No permissions for this client have been selected.';

const ClientPermissions = ({
  teamRoles,
  roles,
  clientErrors,
  activeClientTeams,
  clientTeams,
  disabled = false,
  onRemove,
  onAdd,
  onUpdate,
  setActiveClientTeam,
}: ClientPermissionsProps) => {
  const [showClientPermission, setShowClientPermission] = React.useState<boolean>(false);
  const confirm = useConfirmation();
  const activeClientTeamId = activeClientTeams?.clientTeam.teamId;

  const indexedClientTeam = indexBy(prop('entityId'), clientTeams);

  const handleAddPermissions = () => {
    setShowClientPermission(true);
  };

  const handleCancelPermissions = () => {
    setShowClientPermission(false);
  };

  const handleTeamRolesUpdate = (updatedRoles: TeamRolesAssignmentForm[]) => {
    onUpdate(updatedRoles);
  };

  const handleClientTeamSelectForNewPermission = (clientTeam: TeamDto | null) => {
    if (clientTeam) {
      onAdd(clientTeam);
      setShowClientPermission(false);
    }
  };

  const handleClientTeamRemove = (removeClientTeamId: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    confirm({
      variant: 'danger',
      description: `Are you sure you want to remove all permissions for '${indexedClientTeam[removeClientTeamId].name}'?`,
    }).then(() => {
      onRemove(removeClientTeamId);
    });
  };

  const handleAccordionChange = (clientTeamId: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setActiveClientTeam(isExpanded ? clientTeamId : null);
  };

  return (
    <>
      <Box mb={2}>
        {clientTeams.map((clientTeam) => (
          <Accordion
            key={clientTeam.entityId}
            expanded={activeClientTeamId === clientTeam.entityId}
            onChange={handleAccordionChange(clientTeam.entityId)}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box alignSelf="center">
                <Typography>{clientTeam.name}</Typography>
              </Box>
              <Box flex={1} px={4} alignSelf="center">
                {clientErrors[clientTeam.entityId] && (
                  <AlertText severity="error">{NO_PERMISSIONS_ERROR}</AlertText>
                )}
              </Box>
              <Box alignSelf="center">
                {!disabled && (
                  <IconButton
                    onClick={handleClientTeamRemove(clientTeam.entityId)}
                    onFocus={(event) => event.stopPropagation()}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box width={1}>
                {!!roles && (
                  <PermissionsField
                    clientTeams={activeClientTeams!}
                    clientTeamId={clientTeam.entityId}
                    teamRoles={teamRoles}
                    roles={roles}
                    onChange={handleTeamRolesUpdate}
                    disabled={disabled}
                  />
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {showClientPermission && !disabled && (
        <Accordion expanded>
          <AccordionSummary>
            <Typography>New Client Permissions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ClientTeamsField
              autoFocus
              required
              onChange={handleClientTeamSelectForNewPermission}
              disabledOptions={map(prop('entityId'), clientTeams)}
            />
          </AccordionDetails>
        </Accordion>
      )}
      <Box>
        {
          !disabled && (
            <>
              {!showClientPermission ? (
                <Button variant="contained" color="secondary" onClick={handleAddPermissions} startIcon={<AddIcon />}>Client Permissions</Button>
              ) : (
                <Button variant="contained" color="secondary" onClick={handleCancelPermissions}>Cancel</Button>
              )}
            </>
          )
        }
      </Box>
    </>
  );
};

export default ClientPermissions;
