import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { MineModelDto, StopeBackfillSummaryDto, StopeLocationDto } from 'providers/api';
import React from 'react';
import StopeList from './StopeList';

const useStyles = makeStyles(() => createStyles({
  listContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}));

interface StopesPanelPageProps {
  mineModel: MineModelDto;
  mineOverview: StopeBackfillSummaryDto[];
  onStopeHover: (stopeLocation?: StopeLocationDto) => void;
  onStopeSelect: (stopeLocation: StopeLocationDto) => void;
  setSelectedRoute: (spoolIds: string[] | undefined) => void;
}

const StopesPanelPage = ({ mineOverview, mineModel, onStopeHover, onStopeSelect, setSelectedRoute }: StopesPanelPageProps) => {
  const classes = useStyles();

React.useEffect(() => {
  setSelectedRoute(undefined);
 }, []);

  return (
    <Box height={1} display="flex" flexDirection="column" borderRadius={8}>
      <Typography variant="subtitle1" gutterBottom>Stopes</Typography>
      <Box className={classes.listContainer} flex={1} pb={2} borderRadius={8}>
        <StopeList
          mineModel={mineModel}
          onSelect={onStopeSelect}
          onHover={onStopeHover}
          mineOverview={mineOverview}
        />
      </Box>
    </Box>
  );
};

export default StopesPanelPage;
