import * as GConstants from '../../GConstants';
import GuiState from '../GUI/GuiState';

export function plotHGLTelemetry(ctx: CanvasRenderingContext2D, guiState: GuiState) {
  const { modelState: { hglData: { x, y, height }, hglTelemetry } } = guiState;
  const lineSpacing = 16;
  const nameColumnXPos = x;
  const valueColumnXPos = x + 250;
  const telYPos = y + height + lineSpacing;
  ctx.globalAlpha = 1;
  ctx.fillStyle = GConstants.GREY;
  ctx.font = '12px Arial';

  for (let i = 0; i < hglTelemetry.length; i += 1) {
    const txtTuple = hglTelemetry[i];
    ctx.textAlign = 'left';
    ctx.fillText(txtTuple[0], nameColumnXPos, telYPos + lineSpacing * i);
    ctx.textAlign = 'right';
    ctx.fillText(txtTuple[1], valueColumnXPos, telYPos + lineSpacing * i);
  }
}

export function plotNodeStopeTelemetry(guiState: GuiState) {
  const { modelCanvas, telemetryCanvas, modelState: { nodeTelemetry, stopeTelemetry } } = guiState;

  const modelContext = modelCanvas.getContext('2d');
  const telemetryCtx = telemetryCanvas.getContext('2d');

  if (telemetryCtx && modelContext) {
    const lineSpacing = 22;
    const barColour = GConstants.PC_BLUE;
    const textColour = GConstants.GREY;
    const backColour = GConstants.BLACK;

    const telemetryCanvasPadding = 30;
    const nameColumnXPos = 5;
    const valueColumnXPos = telemetryCanvas.width - 5;
    const topboxHeight = 15;
    const nodeTelYPos = topboxHeight + lineSpacing;
    const stopeTelYPos = nodeTelYPos + nodeTelemetry.length + lineSpacing;

    telemetryCanvas.height = ((1 + nodeTelemetry.length + stopeTelemetry.length) * lineSpacing) + topboxHeight;

    telemetryCtx.clearRect(0, 0, telemetryCanvas.width, telemetryCanvas.height);
    // background
    telemetryCtx.fillStyle = backColour;
    telemetryCtx.fillRect(0, topboxHeight, telemetryCanvas.width, telemetryCanvas.height);

    // write each line of text
    telemetryCtx.globalAlpha = 1;
    telemetryCtx.fillStyle = textColour;
    for (let i = 0; i < nodeTelemetry.length; i += 1) {
      telemetryCtx.font = '16px Arial';
      const txtTuple = nodeTelemetry[i];
      telemetryCtx.textAlign = 'left';
      telemetryCtx.fillText(txtTuple[0], nameColumnXPos, nodeTelYPos + lineSpacing * i);
      telemetryCtx.textAlign = 'right';
      telemetryCtx.fillText(txtTuple[1], valueColumnXPos, nodeTelYPos + lineSpacing * i);
    }
    // stope telemetry text
    for (let i = 0; i < stopeTelemetry.length; i += 1) {
      const txtTuple = stopeTelemetry[i];
      telemetryCtx.textAlign = 'left';
      telemetryCtx.fillText(txtTuple[0], nameColumnXPos, stopeTelYPos + lineSpacing * i);
      telemetryCtx.textAlign = 'right';
      telemetryCtx.fillText(txtTuple[1], valueColumnXPos, stopeTelYPos + lineSpacing * i);
    }
    // top border
    telemetryCtx.globalAlpha = 0.5;
    telemetryCtx.fillStyle = barColour;
    telemetryCtx.fillRect(0, 0, telemetryCanvas.width, topboxHeight);

    modelContext.drawImage(
      telemetryCanvas,
      guiState.screenWidth - telemetryCanvas.width - telemetryCanvasPadding - guiState.drawerWidth,
      guiState.screenHeight - telemetryCanvas.height - telemetryCanvasPadding,
    );
    }
}
