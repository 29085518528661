import TextField from '@material-ui/core/TextField';
import GoogleMapEmbed from 'components/GoogleMapEmbed';
import { Form } from 'hooks/useForm';
import { GeoPointForm } from 'providers/api';
import { FormikUpdateFn } from 'utils';
import {
  number,
  object,
  SchemaOf
} from 'yup';

export const validation: SchemaOf<GeoPointForm> = object()
  .shape({
    latitude: number().required('Latitude is required'),
    longitude: number().required('Longitude is required'),
  });

// Lat/Long of P&C Cornwall Office
export const initialData: GeoPointForm = {
  latitude: 50.242640617135756,
  longitude: -5.127777218002489,
};

interface GeoPointFormFieldsProps {
  baseKey: string;
  values: GeoPointForm;
  hasError: Form['helpers']['hasError'];
  getErrorHelpText: Form['helpers']['getErrorHelpText'];
  handleChange: FormikUpdateFn;
  handleBlur: FormikUpdateFn;
}

const GeoPointFormFields = ({ baseKey, values, hasError, getErrorHelpText, handleChange, handleBlur }: GeoPointFormFieldsProps) => {
  const lat = values.latitude ?? initialData.latitude;
  const lng = values.longitude ?? initialData.longitude;

  return (
    <>
      <GoogleMapEmbed lat={lat} lng={lng} />
      <TextField
        fullWidth
        id={`${baseKey}.latitude`}
        name={`${baseKey}.latitude`}
        label="Latitude"
        value={values.latitude}
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasError(`${baseKey}.latitude`)}
        helperText={getErrorHelpText(`${baseKey}.latitude`)}
      />
      <TextField
        fullWidth
        id={`${baseKey}.longitude`}
        name={`${baseKey}.longitude`}
        label="Longitude"
        value={values.longitude}
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasError(`${baseKey}.longitude`)}
        helperText={getErrorHelpText(`${baseKey}.longitude`)}
      />
    </>
  );
};

export default GeoPointFormFields;
