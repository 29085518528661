import uuid from './uuid';

export default class Pipe {
  name: string;

  pipeId: string;

  clss: string;

  insideDiameter: number; // m

  pressureRating: number; // kPa

  wallThickness?: number; // m

  material?: string;

  friction?: number;

  velocity?: number;

  constructor(
    name: string,
    pipeId: string,
    clss: string,
    insideDiameter: number,
    pressureRating: number,
    wallThickness?: number,
    material?: string,
  ) {
    this.name = name;
    this.pipeId = pipeId || uuid();
    this.clss = clss;
    this.insideDiameter = insideDiameter; // m
    this.pressureRating = pressureRating; // kPa
    this.wallThickness = wallThickness; // m
    this.material = material || 'not defined';
    this.friction = 0;
  }

  crossSectionalArea(): number {
    return (Math.PI * this.insideDiameter ** 2) / 4;
  }

  getVelocity(flowrate: number): number { // m3/h
    this.velocity = (flowrate / this.crossSectionalArea()) / 3600;
    return this.velocity;
  }

  pressureRatingInMetres(density: number): number { // density in t/m3
    const PRIM = this.pressureRating / (density * 9.81);
    return PRIM; // m
  }

  setFriction(value: number) {
    this.friction = value;
  }

  // generateJSON(): PipeJSON {
  //   return {
  //     name: this.name,
  //     class: this.clss,
  //     insideDiameter: this.insideDiameter,
  //     pressureRating: this.pressureRating,
  //     wallThickness: this.wallThickness,
  //     material: this.material,
  //     pipeId: this.pipeId,
  //   };
  // }
}
