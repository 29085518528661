import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useDisplayUnits from 'hooks/useDisplayUnits';
import { additionalUnitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import {
  BackfillRecipeDto,
  DisplayUnits,
  FillType,
  MixerCoefficientsDto,
  ThroughputControlType,
  UnitSystem,
  UcsCoefficientsDto
} from 'providers/api';
import WarningTooltipIcon from '../WarningTooltipIcon';
import RecipeHydraulicFillDetails from './RecipeHydraulicFillDetails';
import RecipePasteFillDetails from './RecipePasteFillDetails';
import UnitSystemDetail from './UnitSystemDetail';

const useStyles = makeStyles(() => createStyles({
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      fontSize: '0.9em',
      padding: 0,
      margin: 0,
      paddingBottom: 10,
    },
  },
}));

interface RecipeDetailsProps {
  recipe: BackfillRecipeDto;
  displayUnitPreferences: DisplayUnits[];
  unitSystemPreference: UnitSystem;
  specificGravity?: number;
  mixerCoefficients?: MixerCoefficientsDto;
  heightOfCylinder?: number;
  yieldStressA?: number;
  yieldStressB?: number;
  tailingsSolidsDensity?: number;
  carrierFluidDensity?: number;
  binderParticleDensity?: number;
  fillType: FillType;
  throughputControlType: ThroughputControlType;
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto; }
}

const RecipeDetails = ({
  recipe,
  displayUnitPreferences,
  unitSystemPreference,
  specificGravity,
  mixerCoefficients,
  heightOfCylinder,
  yieldStressA,
  yieldStressB,
  tailingsSolidsDensity,
  carrierFluidDensity,
  binderParticleDensity,
  fillType,
  throughputControlType,
  targetDaysCoefficients,
}: RecipeDetailsProps) => {
  const classes = useStyles();

  const displayUnitResults = useDisplayUnits([{
    key: 'adjusted',
    displayUnitPreferences,
    specification: fillType === FillType.Paste ? recipe.pasteSpecification : recipe.hydraulicSpecification,
    selectedSpecification: fillType === FillType.Paste ? recipe.pasteSelectedSpecification : recipe.hydraulicSelectedSpecification,
    specificGravity,
    yieldStressA,
    yieldStressB,
    mixerCoefficients,
    heightOfCylinder,
    fillType,
    throughputControlType,
    tailingsSolidsDensity,
    carrierFluidDensity,
    binderParticleDensity,
  },
  {
    key: 'optimised',
    displayUnitPreferences,
    specification: fillType === FillType.Paste ? recipe.pasteSpecification : recipe.hydraulicSpecification,
    selectedSpecification: fillType === FillType.Paste ? recipe.pasteOptimisedSpecification : recipe.hydraulicOptimisedSpecification,
    specificGravity,
    yieldStressA,
    yieldStressB,
    mixerCoefficients,
    heightOfCylinder,
    fillType,
    throughputControlType,
    tailingsSolidsDensity,
    carrierFluidDensity,
    binderParticleDensity,
  }]);

  const { additionalDisplayUnits: additionalAdjustedDisplayUnitMap, warnings } = displayUnitResults.adjusted;
  const { additionalDisplayUnits: additionalOptimisedDisplayUnitMap } = displayUnitResults.optimised;

  const processedWarnings = Object.entries(warnings).filter(([/* key */, value]) => value !== undefined).map(([/* key */, value]) => ({ display: value! }));

  return (
    <>
      {processedWarnings && processedWarnings.length > 0 && (
        <Box width="100%" display="flex" flexDirection="row-reverse" mb={1}>
          <WarningTooltipIcon warnings={processedWarnings as any} />
        </Box>
      )}
      <Box width="100%" display="flex">
        <Typography variant="caption">Rheology Data: </Typography>
      </Box>
      <Box width="100%" display="flex" mb={1}>
        <Typography variant="caption">{`${recipe.rheologyDataSetReference} - ${recipe.rheologyDataSetVersion}`}</Typography>
      </Box>
      <Table className={classes.table} aria-label="Recipe details table" size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right">Design</TableCell>
            <TableCell align="right">Selected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            fillType === FillType.Paste
              ? (
                <RecipePasteFillDetails
                  recipe={recipe}
                  unitSystemPreference={unitSystemPreference}
                  throughputControlType={throughputControlType}
                  targetDaysCoefficients={targetDaysCoefficients}
                />
)
              : <RecipeHydraulicFillDetails recipe={recipe} unitSystemPreference={unitSystemPreference} throughputControlType={throughputControlType} />
          }
          <TableRow>
            <TableCell colSpan={3}><Divider /></TableCell>
          </TableRow>

          {
            Object.entries(additionalAdjustedDisplayUnitMap)
              .filter(([/* key */, unitInfo]) => unitInfo.value !== undefined)
              .map(([key, unitInfo]) => (
                <UnitSystemDetail
                  key={key}
                  designed={(additionalOptimisedDisplayUnitMap as any)[key].value}
                  selected={unitInfo.value}
                  unitSystem={unitSystemPreference}
                  unitInfo={(additionalUnitLabels as any)[key]}
                  decimalPlaces={unitInfo.decimalPlaces}
                />
              ))
          }
        </TableBody>
      </Table>
      <Divider />
    </>

  );
};

export default RecipeDetails;
