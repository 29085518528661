import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

interface InformationBoxProps {
  title: string;
  children: JSX.Element | JSX.Element[];
  onClose?: () => void;
  buttonText?: string;
}

const InformationBox = ({ title, children, onClose, buttonText }: InformationBoxProps) => (
  <Box borderRadius={8} borderColor="#fff" border={1} m={14} maxWidth={800} mx="auto">
    <DialogTitle>
      <Box textAlign="center">
        <Typography variant="h4" color="primary">
          { title }
        </Typography>
      </Box>
    </DialogTitle>

    <DialogContent>
      {children}
    </DialogContent>

    {buttonText && onClose && (
      <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%" p={5}>
        <Button color="primary" onClick={onClose}>
          {buttonText}
        </Button>
      </Box>
    )}
  </Box>
);

export default InformationBox;
