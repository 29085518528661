import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningBadge from 'modules/project/ProjectAreaPage/ProjectReportsPage/WarningsBadge';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import { BackfillRecipeDto } from 'providers/api';
import { Permission } from 'providers/authorisation/permissions';

interface RecipeErroredPlaceHolderProps {
  recipe: BackfillRecipeDto;
  message: string;
  onDelete: (recipe: BackfillRecipeDto) => void;
}

const RecipeErroredPlaceHolder = ({ recipe, message, onDelete }: RecipeErroredPlaceHolderProps) => {
  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onDelete(recipe);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <Box
      key={message}
      color="primary.main"
      bgcolor="secondary.main"
    >
      <AccordionSummary
        expandIcon={(
          <ProjectAuthorise permission={Permission.ManageRecipes}>
            <IconButton onClick={handleDelete} size="small">
              <DeleteIcon />
            </IconButton>
          </ProjectAuthorise>
        )}
        onClick={handleClick}
      >
        <Box display="flex" flexDirection="row">
          <WarningBadge warnings={[{ display: message }]} />
          <Box ml={3}>
            {recipe.reference}
          </Box>
        </Box>

      </AccordionSummary>
    </Box>
  );
};

export default RecipeErroredPlaceHolder;
