import Page from 'modules/Page';
import makePage from 'modules/makePage';
import * as parentConstants from 'modules/project-admin/ProjectDetailsPage/constants';
import { toTitleCase } from 'utils';
import EditProjectPage from './EditProjectPage';
import * as constants from './constants';
import { EditProjectPageParams } from './constants';
import useEditProjectQueries, { EditProjectQueries } from './useEditProjectQueries';

const page: Page = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: true,
  component: makePage<EditProjectPageParams, EditProjectQueries>({
    WrappedPage: EditProjectPage,
    titleFn: ([project]) => (project.data ? `Edit ${toTitleCase(project.data.name)}` : ''),
    parentPathTemplate: parentConstants.ROUTE_PATH,
    parentTitleFn: () => parentConstants.TITLE,
    pageDataHook: useEditProjectQueries,
  }),
};

export default page;
