import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import { BackfillWarningDto } from 'providers/api';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  warningIcon: {
    color: theme.palette.warning.main,
  },
}));

interface WarningsListProps {
  warnings: BackfillWarningDto[];
}

const WarningsList = ({ warnings }: WarningsListProps) => {
  const classes = useStyles();
  return (
    <List dense component="nav" aria-label="List of warnings for this pour">
      {warnings.map(
        (warning) => (
          <ListItem key={warning.code}>
            <ListItemIcon>
              <WarningIcon className={classes.warningIcon} />
            </ListItemIcon>
            <ListItemText primary={warning.display} />
          </ListItem>
        ),
      )}
    </List>
  );
};

export default WarningsList;
