import {
  STOPES_ROUTE_PATH as StopeDetailsPageRoute,
  ROUTE_PARAM_IDENTIFIER as StopePageParamIdentifier
} from 'modules/project/ProjectAreaPage/constants';
import { useDeleteStopeMutation } from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { ConfirmationOptions } from 'providers/confirm/ConfirmDialog';
import { replace } from 'ramda';
import { useHistory } from 'react-router';

const confirmDialogOptions = (stopeName: string): ConfirmationOptions => ({
  variant: 'danger',
  description: `Are you sure you want to remove '${stopeName}'?`,
});

const useDeleteStope = () => {
  const history = useHistory();
  const confirm = useConfirmation();
  const deleteMutation = useDeleteStopeMutation();

  const deleteStope = (entityId: string, nodeId: string, stopeId: string, stopeName: string, onSuccess?: ((successNodeId: string) => void)) => {
    confirm(confirmDialogOptions(stopeName))
      .then(() => deleteMutation.mutate(
        { projectId: entityId, stopeId, stopeName },
        {
          onSuccess: () => {
            onSuccess && onSuccess(nodeId);
            history.push(replace(StopePageParamIdentifier, entityId, StopeDetailsPageRoute));
          },
        },
      ));
  };

  return deleteStope;
};

export default useDeleteStope;
