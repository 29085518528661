import { GridOptions, GridRowData } from '@material-ui/data-grid';
import DataGridWithLoading, { ResponsiveGridColDef } from 'components/DataGrid';

interface DataTableProps {
  data: GridRowData[];
  columns: ResponsiveGridColDef[];
  dataTotal: number;
  page: number;
  pageSize: number;
  pageSizeOptions: number[];
  loading: boolean;
  loadingNew: boolean;
  onPageChange: GridOptions['onPageChange'];
  onPageSizeChange: GridOptions['onPageSizeChange'];
}

const DataTable = ({
  data,
  columns,
  dataTotal,
  page,
  pageSize,
  pageSizeOptions,
  loading,
  loadingNew,
  onPageChange,
  onPageSizeChange,
}: DataTableProps) => {
  const pageIndex = data ? (page - 1) : 0;

  return (
    <DataGridWithLoading
      loading={loading}
      loadingNew={loadingNew}
      searchTerm=""
      searchFields={['reference']}
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      idProp="entityId"
      rows={data}
      columns={columns}
      pagination
      paginationMode="server"
      page={pageIndex}
      pageSize={pageSize}
      rowsPerPageOptions={pageSizeOptions}
      rowCount={dataTotal}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  );
};

export default DataTable;
