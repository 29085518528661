import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RheologyDataSummaryDto, useRheologyDataQuery } from 'providers/api';
import React, { ChangeEvent } from 'react';

interface RheologyDataSetSelectorProps {
  mineModelId: string;
  rheologyDatasetList: RheologyDataSummaryDto[];
  selectedRheologyDatasetId?: string;
  disabled?: boolean;
  onRheologyDataSetSelected: (rheologyData: any) => void;
}

const RheologyDataSetSelector = ({
  mineModelId,
  rheologyDatasetList,
  selectedRheologyDatasetId,
  disabled = false,
  onRheologyDataSetSelected,
}: RheologyDataSetSelectorProps) => {
  const [selectedRheologyData, setSelectedRheologyData] = React.useState<RheologyDataSummaryDto | null>(null);

  useRheologyDataQuery(
    {
      mineModelId,
      rheologyDatasetId: selectedRheologyData && selectedRheologyData.rheologyDataSetId ? selectedRheologyData.rheologyDataSetId : '',
    },
    {
      enabled: !!selectedRheologyData,
      onSuccess: (result) => {
        result && onRheologyDataSetSelected(result);
      },
    },
  );

  // when a RheologyDataSummaryDto has been selected - download the specific RheologyDataDto
  const handleChange = (event: ChangeEvent<{}>, value: RheologyDataSummaryDto | null) => {
    setSelectedRheologyData(value);
  };

  return (
    <Autocomplete
      options={rheologyDatasetList}
      autoHighlight
      getOptionLabel={(option: any) => option.reference}
      value={rheologyDatasetList.find((rheologyDataItem) => rheologyDataItem.rheologyDataSetId === selectedRheologyDatasetId)}
      onChange={handleChange}
      fullWidth
      disabled={disabled}
      renderOption={(option: any) => (
        <>
          {`${option.reference} ${option.version}`}
        </>
      )}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label="Select a rheology dataset"
          variant="outlined"
          inputProps={params.inputProps}
          fullWidth
          size="small"
        />
      )}
    />
  );
};

export default RheologyDataSetSelector;
