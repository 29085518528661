import { useReactOidc } from '@axa-fr/react-oidc-context';
import { HOUR_IN_MILLISECONDS } from 'config';
import { useMeQuery } from 'providers/api';
import { intersection, is } from 'ramda';
import { ReactNode } from 'react';
import AuthorisationContext, { BackfillPlannerUser, IAuthorisationContext } from './context';
import { BackfillPlannerRole, hasPermission, Permission } from './permissions';

const ADMINISTRATOR_ROLE = 'Administrator';

export interface AuthorisationProviderProps {
  children: ReactNode;
}

export default function AuthorisationProvider({ children }: AuthorisationProviderProps) {
  const { oidcUser } = useReactOidc();
  const { data, isFetching } = useMeQuery(undefined, {
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const { name, email, roles: oidcUserRoles, given_name: givenName } = oidcUser.profile;

  // If there is only one roles it comes in as just a sting.
  const roles = is(String, oidcUserRoles) ? [oidcUserRoles] : oidcUserRoles;

  if (!name) {
    throw new Error('oidcUser does not contain expected prop \'name\'');
  }

  if (!email) {
    throw new Error('oidcUser does not contain expected prop \'email\'');
  }

  if (!roles) {
    throw new Error('oidcUser does not contain expected prop \'roles\'');
  }

  if (!((is(Array, roles) && (roles as any[]).every(is(String))))) {
    throw new Error('oidcUser does not contain the correct type for \'roles\'');
  }

  const user: BackfillPlannerUser = {
    id: oidcUser.profile.sub,
    username: name,
    name: givenName ?? name,
    email,
    roles: roles as string[],
    permissions: {
      teams: data?.teams ?? [],
      teamRoles: (data?.teamroles as string[] | undefined)?.reduce((teamRoles, teamRoleString) => {
        const [teamId, roleString] = teamRoleString.split('_');
        const roleKey = roleString.replace(/\s/g, '');
        const role = BackfillPlannerRole[roleKey as any] as any as BackfillPlannerRole;
        if (!role) {
          return teamRoles;
        }
        return [
          ...teamRoles,
          {
            teamId,
            role,
          },
        ];
      }, [] as BackfillPlannerUser['permissions']['teamRoles']) ?? [],
    },
  };

  const isAdmin = () => user.roles.includes(ADMINISTRATOR_ROLE);

  const validatePermission = (permission: Permission, ids: string[]) => {
    if (isAdmin()) {
      return true;
    }

    return user.permissions.teamRoles
      .some(({ teamId, role }) => {
        if (!ids.includes(teamId)) {
          return false;
        }

        return hasPermission(role, permission);
      });
  };

  const canViewTeam = (teamIds: string[]) => isAdmin() || intersection(user.permissions.teams, teamIds).length > 0;

  const value: IAuthorisationContext = {
    state: { user, loading: isFetching },
    actions: {},
    functions: {
      isAdmin,
      canViewTeam,
      validatePermission,
    },
  };

  return (
    <AuthorisationContext.Provider value={value}>
      {children}
    </AuthorisationContext.Provider>
  );
}
