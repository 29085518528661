import Container from '@material-ui/core/Container';
import { BasePageProps } from 'modules/makePage';
import {
  useUpdateUserMutation,
  useUpdateUserRolesMutation
} from 'providers/api';
import { useParams } from 'react-router-dom';
import { UserDetailsPageParams } from './constants';
import UpdateUserForm from './UpdateUserForm';
import UpdateUserPermissionsForm from './UpdateUserPermissionsForm';
import { UserDetailsQueries } from './useUserDetailsQueries';

const UserDetailsPage = ({ queries }: BasePageProps<UserDetailsQueries>) => {
  const [userQuery] = queries;

  const { userId } = useParams<UserDetailsPageParams>();
  const userMutation = useUpdateUserMutation(userId);
  const permissionsMutation = useUpdateUserRolesMutation(userId);

  return (
    <Container maxWidth="lg">
      <>
        {userQuery.data && (
          <>
            <UpdateUserForm user={userQuery.data} mutation={userMutation} />
            <UpdateUserPermissionsForm user={userQuery.data} mutation={permissionsMutation} />
          </>
        )}
      </>
    </Container>
  );
};

export default UserDetailsPage;
