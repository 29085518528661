import Button, { ButtonProps } from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Parser, transforms } from 'json2csv';
import React from 'react';
import { isNilOrEmpty } from 'utils';

const useStyles = makeStyles(() => createStyles({
  link: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

interface DataDownloadProps {
  data?: any;
  fileName: string;
  buttonValue: string;
  buttonProps: ButtonProps;
  fileFormat?: string;
  downloadData?: () => void;
}

const DataDownload = ({ data, fileName, buttonValue, buttonProps, fileFormat = 'json', downloadData }: DataDownloadProps) => {
  const classes = useStyles();
  const [downloadLink, setDownloadLink] = React.useState('');
  const [downloading, setDownloading] = React.useState(false);
  const linkElementRef = React.useRef<HTMLLinkElement>(null);

  // process the data and add to link
  const processData = () => {
    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);

    switch (fileFormat) {
      case 'json': {
        const rawData = new Blob([JSON.stringify(data)], { type: 'application/json' });
        setDownloadLink(window.URL.createObjectURL(rawData));
        break;
      }
      case 'csv': {
        const t = [transforms.flatten({ arrays: true })];
        const rawData = new Blob([new Parser({ transforms: t }).parse(data)], { type: 'csv/txt' });
        setDownloadLink(window.URL.createObjectURL(rawData));
        break;
      }
      default:
        throw new Error(`File format ${fileFormat} not supported`);
    }
  };

  const handleClick = () => {
    if (!data && downloadData) {
      setDownloading(true);
      downloadData();
    }
  };

  React.useEffect(() => {
    if (data && !isNilOrEmpty(data)) {
      processData();
    }
  }, [data]);

  React.useEffect(() => {
    if (downloading && linkElementRef.current) {
      linkElementRef.current.click();
    }
  }, [downloadLink]);

  return (
    <>
      <Link
        // only active if has data
        ref={linkElementRef}
        download={data ? fileName : undefined}
        href={data ? downloadLink : undefined}
        type="application/json"
        className={classes.link}
      >
        <Button {...buttonProps} onClick={handleClick}>{buttonValue}</Button>
      </Link>
    </>
  );
};

export default DataDownload;
