import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React, { forwardRef } from 'react';

const useStyles = makeStyles(() => createStyles({
  menuItem: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
}));

// issue using vanilla MenuItemProps https://github.com/mui-org/material-ui/issues/16245
interface ConditionalMenuItemProps extends MenuItemProps<'li', { button?: true }> {
  disabledReason: string;
}

const ConditionalMenuItem = ({ disabledReason, ...menuItemProps }: ConditionalMenuItemProps, ref: any) => {
  const classes = useStyles();

  if (menuItemProps.disabled) {
    return (
      <Tooltip title={disabledReason}>
        <MenuItem ref={ref} className={classes.menuItem} {...menuItemProps} />
      </Tooltip>
    );
  }

  return (
    <MenuItem ref={ref} className={classes.menuItem} {...menuItemProps} />
  );
};

// Ref needs to be forwarded on to the Menu Item
export default forwardRef(ConditionalMenuItem);
