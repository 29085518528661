import Box from '@material-ui/core/Box';
import {
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import useDisplayUnits from 'hooks/useDisplayUnits';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import useForm from 'hooks/useForm';
import {
  BackfillRecipeDto,
  BackfillRecipeForm,
  BackfillWarningDto,
  DisplayUnits,
  FillType, MixerCoefficientsDto,
  PourTypeDataDto,
  RheologyDataSummaryDto,
  ThroughputControlType,
  UcsCoefficientsDto,
  UnitSystem,
  useCreateRecipeMutation,
  useUpdateRecipeMutation
} from 'providers/api';
import { equals } from 'ramda';
import React from 'react';
import { round } from 'utils';
import { pumpPressureKpaToBar } from 'utils/unitConversions';
import {
  DictionaryRange,
  HydraulicCalculateResult,
  PasteAdjustedResult,
  PasteOptimiseResult
} from '../../../canvas/typesInterfaces';
import AdditionalDisplayUnits from './AdditionalDisplayUnits';
import FormActions from './FormActions';
import HydraulicAdjustedInputs from './HydraulicAdjustedInputs';
import HydraulicTargetInputs from './HydraulicTargetInputs';
import PasteAdjustedInputs from './PasteAdjustedInputs';
import PasteTargetInputs from './PasteTargetInputs';
import generateRecipeSchema, { recipeSchemaInitialValues } from './RecipeSchema';

const useStyles = makeStyles((theme: Theme) => createStyles({
  fullWidthDivider: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  at: {
    width: '100%',
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  form: {
    width: '100%',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));
interface RecipeFormProps {
  projectId: string;
  stopeId: string;
  stopeName: string;
  selectedRouteSpoolIds: string[];
  recipe?: BackfillRecipeDto;
  rheologyDatasetList: RheologyDataSummaryDto[];
  limits: DictionaryRange;
  maxPumpPressure: number;
  throughputControlType: ThroughputControlType;
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto; }
  specificGravity?: number;
  heightOfCylinder?: number;
  mixerCoefficients?: MixerCoefficientsDto;
  yieldStressA?: number;
  yieldStressB?: number;
  tailingsSolidsDensity: number;
  carrierFluidDensity: number;
  binderParticleDensity: number;
  fillType: FillType;
  rheologyDataSetId: string;
  displayUnitPreferences: DisplayUnits[];
  unitSystemPreference: UnitSystem;
  isOptimised: boolean;
  onSuccess?: (recipeId: string) => void | string;
  triggerPasteRecipeFromStrength: (
    ucsStrength: number,
    curingPeriod: number,
    pumpPressureBar: number,
    newTailingsSolidsDensity?: number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,
    newTailingsDryTonnage?: number,
    newWetFlowRate?: number,
    newWetTonnage?: number,
  ) => PasteOptimiseResult | null;
  calculateHydraulicOptimise: (binderTailings: number, dryTonsIn?: number, flowRateIn?: number, wetTonsIn?: number) => { concentrationByMass: number };
  triggerPasteStrengthAndPressureFromRecipe: (
    dryConcentrationByMass: number,
    binderConcentration: number,
    newTailingsSolidsDensity?: number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,
    newTailingsDryTonnage?: number,
    newWetFlowRate?: number,
    newWetTonnage?: number
  ) => PasteAdjustedResult;
  triggerHydraulicFillStrengthFromRecipe: (binderTailings: number, concentrationByMass: number) => HydraulicCalculateResult | null;
  checkForWarnings: () => BackfillWarningDto[],
  onCancel: () => void;
  onWarning: (warnings: BackfillWarningDto[]) => void;
  updateIsOptimised: (isOptimised: boolean) => void;
}

const RecipeForm = ({
  stopeId,
  stopeName,
  selectedRouteSpoolIds,
  recipe,
  projectId,
  limits,
  maxPumpPressure,
  fillType,
  rheologyDataSetId,
  targetDaysCoefficients,
  specificGravity,
  heightOfCylinder,
  mixerCoefficients,
  yieldStressA,
  yieldStressB,
  tailingsSolidsDensity,
  carrierFluidDensity,
  binderParticleDensity,
  displayUnitPreferences,
  unitSystemPreference,
  isOptimised,
  onSuccess,
  triggerPasteRecipeFromStrength,
  triggerPasteStrengthAndPressureFromRecipe,
  calculateHydraulicOptimise,
  triggerHydraulicFillStrengthFromRecipe,
  checkForWarnings,
  onCancel,
  onWarning,
  updateIsOptimised,
  throughputControlType,
}: RecipeFormProps) => {
  const initialIsOptimisedState = React.useRef(isOptimised);
  const classes = useStyles();
  const [optimisationError, setOptimisationError] = React.useState(false);
  const mutation = recipe ? useUpdateRecipeMutation(recipe.entityId) : useCreateRecipeMutation();

  const recipeSchema = generateRecipeSchema(
    fillType,
    maxPumpPressure,
    unitSystemPreference === UnitSystem.Metric
      ? 5000
      : round(unitLabels[BasicDisplayUnits.PressureUCS].imperial.conversion(5000), 0),
    limits,
    optimisationError,
  );

  const {
    formik,
    helpers,
  } = useForm<BackfillRecipeForm>({
    mutation,
    formikConfig: {
      // Formik values should always be metric
      initialValues: recipeSchemaInitialValues(
        selectedRouteSpoolIds,
        recipe,
        limits,
        7,
        maxPumpPressure,
        fillType,
        rheologyDataSetId,
        targetDaysCoefficients,
      ),
      onSubmit: (recipeModel, { setSubmitting }) => {
        const payload = {
          projectId,
          stopeId,
          recipeId: recipe?.entityId ?? null,
          data: recipeModel,
          context: { stopeName },
        };
        setSubmitting(true);
        mutation?.mutate(payload, {
          onSuccess: (result) => {
            setSubmitting(false);
            if (recipe) {
              onSuccess && recipe && onSuccess(recipe.entityId);
            } else {
              onSuccess && result && onSuccess(result);
            }
          },
        });
      },
      validationSchema: recipeSchema,
    },
  });

  const {
    values,
    isValid,
    isSubmitting,
    dirty,
    resetForm,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = formik;

  const handleReset = () => {
    mutation?.reset();
    updateIsOptimised(false);
  };

  const updateUcsStrength = (UCSKpa: number) => {
    setFieldValue('pasteOptimisedSpecification.ucsStrength', UCSKpa);
    setFieldValue('pasteSelectedSpecification.ucsStrength', UCSKpa);
    setFieldValue('pasteSpecification.targetDays', values.pasteSpecification?.targetDays || (Object.keys(targetDaysCoefficients ?? {})[0] as unknown as number));
  };

  const updatePumpPressure = (pressureKpa: number) => {
    const pumpPressureBar = pumpPressureKpaToBar(pressureKpa);
    setFieldValue('pasteOptimisedSpecification.pumpPressure', pumpPressureBar);
    setFieldValue('pasteSelectedSpecification.pumpPressure', pumpPressureBar);
  };

  const handlePasteOptimise = () => {
    if (!dirty || !isValid) return;
    const result = triggerPasteRecipeFromStrength(
      values.pasteSpecification?.targetUCSStrength || 0,
      values.pasteSpecification?.targetDays || (Object.keys(targetDaysCoefficients ?? {})[0] as unknown as number),
      values.pasteSpecification?.pumpPressure || 0,
      tailingsSolidsDensity,
      binderParticleDensity,
      carrierFluidDensity,
      throughputControlType === ThroughputControlType.DryTonnage ? values.pasteSpecification?.throughput : undefined,
      throughputControlType === ThroughputControlType.FlowRate ? values.pasteSpecification?.throughput : undefined,
      throughputControlType === ThroughputControlType.WetTonnage ? values.pasteSpecification?.throughput : undefined,
    );

    if (!result) {
      setOptimisationError(true);
      return;
    }

    const { cement, concentrationByMass, actualUCSKpa, actualPumpPressureKpa } = result;

    const binderContent = round(cement * 100);
    setFieldValue('pasteOptimisedSpecification.binderContent', binderContent);
    setFieldValue('pasteSpecification.binderContent', binderContent);
    setFieldValue('pasteSelectedSpecification.binderContent', binderContent);
    setFieldValue('pasteSpecification.throughputControlType', throughputControlType);

    const massConcentration = round(concentrationByMass * 100);
    setFieldValue('pasteOptimisedSpecification.massConcentration', massConcentration);
    setFieldValue('pasteSelectedSpecification.massConcentration', massConcentration);

    updateUcsStrength(actualUCSKpa);
    updatePumpPressure(actualPumpPressureKpa);

    updateIsOptimised(true);
  };

  const handleHydraulicOptimise = () => {
    if (!dirty || !isValid) return;
    setFieldValue('hydraulicSpecification.binderTailingsRatio', values.hydraulicSpecification?.binderTailingsRatio || 0.02);
    setFieldValue('hydraulicSpecification.throughput', values.hydraulicSpecification?.throughput || limits.tons.min);
    const results = triggerHydraulicFillStrengthFromRecipe(
      values.hydraulicSpecification?.binderTailingsRatio || 0.02,
      values.hydraulicSelectedSpecification?.massConcentration || limits.concentration.min,
    );
    const { concentrationByMass } = calculateHydraulicOptimise(
      values.hydraulicSpecification?.binderTailingsRatio || limits.cement.min,
      throughputControlType === ThroughputControlType.DryTonnage ? values.hydraulicSpecification?.throughput : undefined,
      throughputControlType === ThroughputControlType.FlowRate ? values.hydraulicSpecification?.throughput : undefined,
      throughputControlType === ThroughputControlType.WetTonnage ? values.hydraulicSpecification?.throughput : undefined,
    );
    setFieldValue('hydraulicSpecification.throughputControlType', throughputControlType);

    if (!results) return;
    setFieldValue('hydraulicOptimisedSpecification.settlingVelocity', results.settlingVelocity);
    setFieldValue('hydraulicOptimisedSpecification.minVelocity', results.minVelocity);
    setFieldValue('hydraulicOptimisedSpecification.maxPipePressure', results.maxPipePressure);
    setFieldValue('hydraulicSelectedSpecification.settlingVelocity', results.settlingVelocity);
    setFieldValue('hydraulicSelectedSpecification.minVelocity', results.minVelocity);
    setFieldValue('hydraulicSelectedSpecification.maxPipePressure', results.maxPipePressure);
    setFieldValue('hydraulicOptimisedSpecification.massConcentration', concentrationByMass);
    setFieldValue('hydraulicSelectedSpecification.massConcentration', concentrationByMass);
    updateIsOptimised(true);
  };

  React.useEffect(
    () => {
      if (!dirty || !isValid) return;
      if (fillType === FillType.Paste) {
        const result = triggerPasteStrengthAndPressureFromRecipe(
          values.pasteSelectedSpecification?.massConcentration || limits.concentration.min,
          values.pasteSelectedSpecification?.binderContent || limits.cement.min,
          tailingsSolidsDensity,
          binderParticleDensity,
          carrierFluidDensity,
          throughputControlType === ThroughputControlType.DryTonnage ? values.pasteSpecification?.throughput : undefined,
          throughputControlType === ThroughputControlType.FlowRate ? values.pasteSpecification?.throughput : undefined,
          throughputControlType === ThroughputControlType.WetTonnage ? values.pasteSpecification?.throughput : undefined,
        );

        updateUcsStrength(result.actualUCSKpa);
        updatePumpPressure(result.actualPumpPressureKpa);
      }
    },
    [
      values.pasteSelectedSpecification?.massConcentration,
      values.pasteSelectedSpecification?.binderContent,
    ],
  );

  React.useEffect(
    () => {
      if (!dirty || !isValid) return;
      if (fillType === FillType.Hydraulic) {
        const results = triggerHydraulicFillStrengthFromRecipe(
          values.hydraulicSpecification?.binderTailingsRatio || limits.cement.min,
          values.hydraulicSelectedSpecification?.massConcentration || limits.concentration.min,
        );

        if (!results) return;
        setFieldValue('hydraulicOptimisedSpecification.settlingVelocity', results.settlingVelocity);
        setFieldValue('hydraulicOptimisedSpecification.minVelocity', results.minVelocity);
        setFieldValue('hydraulicOptimisedSpecification.maxPipePressure', results.maxPipePressure);
        setFieldValue('hydraulicSelectedSpecification.settlingVelocity', results.settlingVelocity);
        setFieldValue('hydraulicSelectedSpecification.minVelocity', results.minVelocity);
        setFieldValue('hydraulicSelectedSpecification.maxPipePressure', results.maxPipePressure);
      }
    },
    [
      values.hydraulicSelectedSpecification?.massConcentration,
    ],
  );

  React.useEffect(() => {
    let currentRecipeWarnings: BackfillWarningDto[] = checkForWarnings();
    if ((values.pasteSpecification?.targetUCSStrength || 0) > (values.pasteSelectedSpecification?.ucsStrength || 0)) {
      currentRecipeWarnings = [...currentRecipeWarnings, { code: 'FormUcsStrengthBelowTarget', display: 'UCS STRENGTH BELOW TARGET' }];
    }
    // only update display on change
    if (!equals(currentRecipeWarnings, values.warnings) && isOptimised) {
      onWarning(currentRecipeWarnings);
      setFieldValue('warnings', currentRecipeWarnings);
    }
  }, [values.pasteSelectedSpecification,
    values.hydraulicSelectedSpecification]);

  React.useEffect(() => {
    setFieldValue('rheologyDataSetId', rheologyDataSetId);
  }, [rheologyDataSetId]);

  React.useEffect(() => {
    setFieldValue('spools', selectedRouteSpoolIds);
  }, [selectedRouteSpoolIds]);

  React.useEffect(() => {
    if (optimisationError) {
      formik.validateForm()
        .then(() => setOptimisationError(false));
    }
  },
    [optimisationError]);

  const applyPourType = (pourType: PourTypeDataDto) => {
    setValues({
      ...values,
      pasteSpecification: {
        targetDays: pourType.curingDays,
        targetUCSStrength: pourType.ucsStrength,
        throughput: values.pasteSpecification?.throughput ?? 0,
        pumpPressure: maxPumpPressure,
        throughputControlType,
      },
    });
  };

  const baseInputsProps = {
    unitSystemPreference,
    throughputControlType,
    projectId,
    stopeId,
    values,
    limits,
    classes,
    setFieldValue,
    setFieldTouched,
    hasError: helpers.hasError,
    getErrorHelpText: helpers.getErrorHelpText,
  };

  const targetInputsProps = {
    ...baseInputsProps,
    isOptimised,
    isSubmitting,
    isValid,
    targetDaysCoefficients,
    maxPumpPressure,
    handleBlur,
    onReset: handleReset,
    onTargetsUpdate: fillType === FillType.Paste ? handlePasteOptimise : handleHydraulicOptimise,
    applyPourType,
    setFieldTouched,
  };

  const actionProps = {
    isOptimised,
    isUpdate: !!recipe,
    isSubmitting,
    isValid,
    resetForm: () => {
      resetForm();
      updateIsOptimised(initialIsOptimisedState.current);
    },
    dirty,
    onCancel,
    classes,
  };

  const displayUnitResults = useDisplayUnits([{
    key: 'adjusted',
    displayUnitPreferences,
    specification: fillType === FillType.Paste ? values.pasteSpecification : values.hydraulicSpecification,
    selectedSpecification: fillType === FillType.Paste ? values.pasteSelectedSpecification : values.hydraulicSelectedSpecification,
    specificGravity,
    yieldStressA,
    yieldStressB,
    mixerCoefficients,
    heightOfCylinder,
    fillType,
    throughputControlType,
    tailingsSolidsDensity,
    carrierFluidDensity,
    binderParticleDensity,
  }]);

  const { additionalDisplayUnits: additionalAdjustedDisplayUnitMap, warnings } = displayUnitResults.adjusted;

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box width="100%">
        <form className={classes.form} onSubmit={handleSubmit}>
          {
            fillType === FillType.Paste
              ? <PasteTargetInputs {...targetInputsProps} />
              : <HydraulicTargetInputs {...targetInputsProps} />
          }
          {isOptimised && (
            <>
              {
                fillType === FillType.Paste
                  ? <PasteAdjustedInputs {...targetInputsProps} />
                  : <HydraulicAdjustedInputs {...targetInputsProps} />
              }
              <AdditionalDisplayUnits
                additionalDisplayUnitsMap={additionalAdjustedDisplayUnitMap}
                warnings={warnings as any}
                unitSystemPreference={unitSystemPreference}
              />
            </>
          )}
          <FormActions {...actionProps} />
        </form>
      </Box>
    </Box>
  );
};

export default RecipeForm;
