import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import DataInput from './DataInput';
import { InputType } from './InputGridTypes';

interface InputRowProps {
  rowIndex: number;
  inputs: InputType[];
  updateValues: (rowIndex: number, columnIndex: number, value: any) => void;
  onBlur?: () => void;
}

const InputRow = ({ rowIndex, inputs, updateValues, onBlur }: InputRowProps) => (
  <Box mb={0.5}>
    <Grid container spacing={1}>
      {
        inputs.map((input: InputType, index: number) => (
          <Grid item xs key={input.label}>
            <DataInput
              input={input}
              rowIndex={rowIndex}
              inputIndex={index}
              updateValues={updateValues}
              onBlur={onBlur}
            />
          </Grid>
        ))
      }
    </Grid>
  </Box>
);

export default InputRow;
