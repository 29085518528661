import Vector from './Vector';
import * as constants from './DConstants';
import uuid from './uuid';
import { VectorJSON } from './types';

export default class Point {
  screenVector: Vector;

  coords: Vector;

  pointId: string;

  colour: string;

  radius: number;

  hitRadius: number;

  selectedRadius: number;

  hitBool: boolean;

  selected: boolean;

  hitColour: string;

  selectedColour: string;

  constructor(
    vector: VectorJSON,
    pointId?: string,
    colour?: string,
    radius?: number,
  ) {
    this.screenVector = new Vector(vector.x, vector.y, vector.z);
    this.coords = new Vector(vector.x, vector.y, vector.z);
    this.pointId = pointId || uuid();
    this.colour = colour || constants.DEFAULT_POINT_COLOUR;
    this.radius = radius || constants.DEFAULT_POINT_SIZE;
    this.hitRadius = constants.POINT_HIT_RADIUS;
    this.selectedRadius = constants.POINT_SELECTED_RADIUS;
    this.hitBool = false;
    this.selected = false;
    this.hitColour = constants.DEFAULT_HIT_COLOUR;
    this.selectedColour = constants.DEFAULT_SELECTED_COLOUR;
  }

  hitTest(p: Vector) {
    return this.screenVector.length2dTo(p) < 10;
  }

  render(ctx: CanvasRenderingContext2D, colour?: string) {
    if (this.hitBool) this.draw(ctx, this.hitColour, this.hitRadius);
    else if (this.selected) {
      this.draw(ctx, this.selectedColour, this.selectedRadius * 2);
    } else if (colour) this.draw(ctx, colour, this.radius);
    this.draw(ctx, this.colour, this.radius);
  }

  draw(ctx: CanvasRenderingContext2D, colour: string | CanvasGradient | CanvasPattern, radius: number) {
    ctx.fillStyle = colour;
    ctx.beginPath();
    ctx.arc(this.screenVector.x, this.screenVector.y, radius, 0, Math.PI * 2, false);
    ctx.closePath();
    ctx.fill();
  }
}
