import Badge from '@material-ui/core/Badge';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgressWithLabel from 'components/CircularProgressWithLabel';

const useStyles = makeStyles((theme: Theme) => createStyles({
  infoBadge: {
    background: 'none',
  },
  tooltip: {
    maxWidth: 200,
  },
  progress: {
    marginRight: theme.spacing(2),
  },
}));

const NoDataProgressPlaceholder = () => {
  const classes = useStyles();

  return (
    <Tooltip
      className={classes.tooltip}
      classes={{ tooltip: classes.tooltip }}
      title="Progress not available as no data has been uploaded for this stope"
    >
      <Badge className={classes.infoBadge} badgeContent={<InfoIcon color="primary" />} overlap="circle">
        <Skeleton animation={false} variant="circle" width={65} height={65} />
      </Badge>
    </Tooltip>
  );
};

interface StopeProgressProps {
  completedVolume: number;
  plannedVolume: number;
  volume?: number;
  size: number;
}

const StopeProgress = ({ completedVolume, plannedVolume, volume, size }: StopeProgressProps) => {
  if (!(volume)) {
    return <NoDataProgressPlaceholder />;
  }

  const planned = ((completedVolume + plannedVolume) / volume) * 100;
  const complete = (completedVolume / volume) * 100;

  return (
    <CircularProgressWithLabel
      value={complete}
      buffer={planned}
      thickness={5}
      color="primary"
      size={size}
    />
  );
};

export default StopeProgress;
