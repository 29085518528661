import Container from '@material-ui/core/Container';
import ClientForm from 'modules/client-admin/ClientForm';
import * as ClientsPageConstants from 'modules/client-admin/ClientsPage/constants';
import { BasePageProps } from 'modules/makePage';
import { useUpdateClientMutation } from 'providers/api';
import {
  useHistory,
  useParams
} from 'react-router-dom';
import { EditClientPageParams } from './constants';
import { EditClientQueries } from './useEditClientQueries';

const EditClientPage = ({ queries }: BasePageProps<EditClientQueries>) => {
  const { entityId } = useParams<EditClientPageParams>();
  const [clientQuery] = queries;
  const client = clientQuery.data;

  const history = useHistory();
  const mutation = useUpdateClientMutation(entityId, {
    onSuccess: () => {
      history.push(ClientsPageConstants.ROUTE_PATH);
    },
  });

  return (
    <Container maxWidth="lg">
      <>
        {client && <ClientForm client={client} mutation={mutation} />}
      </>
    </Container>
  );
};

export default EditClientPage;
