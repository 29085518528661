import ClientsPage from './ClientsPage';
import EditClientPage from './EditClientPage';
import NewClientPage from './NewClientPage';

const clientAdminModule = {
  pages: [
    ClientsPage,
    NewClientPage,
    EditClientPage,
  ],
};

export default clientAdminModule;
