import { useMediaQuery } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import {
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import {
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import {
  groupByProp,
  lastIndex
} from 'utils';
import LogoOpen from './logo.png';
import { TopLevelMenuItem } from './types';
import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_CLOSED,
  DRAWER_WIDTH_CLOSED_SM
} from './utils';

const menuByGroup = groupByProp<TopLevelMenuItem>('group');

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: DRAWER_WIDTH_CLOSED_SM + 1,
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH_CLOSED + 1,
    },
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DRAWER_WIDTH + 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: DRAWER_WIDTH_CLOSED_SM + 1,
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH_CLOSED + 1,
    },
  },
  logoContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    // match app bar height
    ...theme.mixins.toolbar,
  },
  logoContainerOpen: {
    background: '#000',
    transition: theme.transitions.create('background', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logoContainerClosed: {
    background: '#231F20',
    transition: theme.transitions.create('background', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoClosed: {
    position: 'absolute',
    width: DRAWER_WIDTH_CLOSED_SM,
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH_CLOSED,
    },
  },
  logoOpen: {
    height: DRAWER_WIDTH_CLOSED_SM / 1.7,
    [theme.breakpoints.up('sm')]: {
      height: DRAWER_WIDTH_CLOSED / 1.7,
    },
  },
  hide: {
    display: 'none',
  },
  invisible: {
    visibility: 'hidden',
  },
  listItemIconLarge: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '4px',
    },
  },
}));

interface MenuItemProps {
  menuItem: TopLevelMenuItem;
}

const MenuItem = ({ menuItem }: MenuItemProps) => {
  const match = useRouteMatch(menuItem.path);
  const classes = useStyles();
  const history = useHistory();

  const handleMenuSelect = () => history.push(menuItem.path);

  return (
    <ListItem button selected={!!match} onClick={handleMenuSelect}>
      <ListItemIcon classes={{ root: classes.listItemIconLarge }}><menuItem.icon /></ListItemIcon>
      <ListItemText primary={menuItem.display} />
    </ListItem>
  );
};

interface MenuProps {
  closeMenu: () => void;
  openMenu: () => void;
  open: boolean;
  menu: TopLevelMenuItem[];
}

interface SideDrawerProps extends MenuProps {
  variant: 'permanent' | 'temporary';
  disabled: boolean;
}

const SideDrawer = ({ closeMenu, openMenu, open, menu, variant, disabled }: SideDrawerProps) => {
  const classes = useStyles();

  const groupedMenu = menuByGroup(menu);
  const menuGroups = Object.keys(groupedMenu);

  const openAndEnabled = open && !disabled;
  const variantIsPermanent = variant === 'permanent';

  const logoContainerClass = clsx(classes.logoContainer, {
    [classes.logoContainerClosed]: !openAndEnabled && variantIsPermanent,
    [classes.logoContainerOpen]: openAndEnabled,
  });

  return (
    <Drawer
      anchor="left"
      variant={variant}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: openAndEnabled,
        [classes.drawerClose]: !openAndEnabled && variantIsPermanent,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: openAndEnabled,
          [classes.drawerClose]: !openAndEnabled && variantIsPermanent,
        }),
      }}
      open={openAndEnabled}
      onClose={closeMenu}
    >
      <div className={logoContainerClass}>
        <img
          className={clsx(classes.logoOpen, {
            [classes.hide]: !openAndEnabled && variantIsPermanent,
          })}
          src={LogoOpen}
          alt="Mine Smart Logo"
        />

        <IconButton
          color="primary"
          aria-label="close menu"
          onClick={closeMenu}
          className={clsx({
            [classes.hide]: !openAndEnabled && variantIsPermanent,
          })}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="open menu"
          onClick={openMenu}
          className={clsx({
            [classes.hide]: openAndEnabled,
          })}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Divider />
      {
        menuGroups.map((group, index) => {
          const listSubHeader = group !== 'undefined' ? (
            <ListSubheader classes={{ root: clsx({ [classes.invisible]: !openAndEnabled && variantIsPermanent }) }}>{group}</ListSubheader>
          ) : undefined;

          return (
            <div key={group ?? 'no-group'}>
              <List subheader={listSubHeader}>
                {groupedMenu[group].map((menuItem) => (<MenuItem key={menuItem.path} menuItem={menuItem} />))}
              </List>
              {lastIndex(menuGroups) !== index && (<Divider />)}

            </div>
          );
        })
      }
    </Drawer>
  );
};

const Menu = (props: MenuProps) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <SideDrawer {...props} variant="permanent" disabled={!isLarge} />
      <SideDrawer {...props} variant="temporary" disabled={isLarge} />
    </>
  );
};

export default Menu;
