import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  value: {
    marginRight: theme.spacing(3),
  },
}));

export type KeyValuePairProps = {
  display: string;
  values: ReactNode[];
};

const KeyValuePair = ({ display, values }: KeyValuePairProps) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography variant="subtitle2">{display}</Typography>
      </Box>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {values.map((item, index) => <Typography className={classes.value} display="block" variant="subtitle1" key={`${display}-${index}`}>{item}</Typography>)}
    </Box>
  );
};

export default KeyValuePair;
