type Environment = 'Preview' | 'Staging';

const getEnvironment = (): Environment | null => {
  const { hostname } = window.location;

  if (hostname.toLowerCase().includes('preview')) {
    return 'Preview';
  }

  if (hostname.toLowerCase().includes('staging')) {
    return 'Staging';
  }

  return null;
};

const EnvironmentBanner = () => {
  const environment = getEnvironment();

  if (!environment) {
    return null;
  }

return (
  <div style={{
      backgroundColor: 'red',
      color: 'white',
      textAlign: 'center',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      zIndex: 9999,
    }}
  >
    <strong>{ environment }</strong>
  </div>
  );
};

export default EnvironmentBanner;
