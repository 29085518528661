import Badge, { BadgeProps } from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  badge: {
    marginLeft: theme.spacing(2.5),
    zIndex: 10,
    '&:nth-of-type(1)': {
      marginLeft: 0,
    },
  },
}));

const LinearBadge = withStyles(() => createStyles({
  badge: {
    right: -6,
    top: 13,
    padding: '0 4px',
  },
}))(Badge);

type BadgeInfo = {
  text: string;
  total: number;
}
interface BadgeRowProps {
  badgeList: BadgeInfo[];
  size?: 'small' | 'medium';
  linear?: boolean,
}

const BadgeRow = ({ badgeList, size, linear = false }: BadgeRowProps) => {
  const classes = useStyles();

  return (
    <>
      {
        badgeList.map((badge) => {
          const badgeProps: BadgeProps = {
            key: badge.text,
            className: classes.badge,
            max: 9999999,
            badgeContent: badge.total,
            showZero: true,
            color: 'primary',
          };

          return (
            linear ? (
              <LinearBadge {...badgeProps}>
                <Chip size={size} label={`${badge.text}`} />
              </LinearBadge>
            ) : (
              <Badge {...badgeProps}>
                <Chip size={size} label={`${badge.text}`} />
              </Badge>
            )
          );
        })
      }
    </>
  );
};

export default BadgeRow;
