import {
  makeReticulationDataImportDto,
  useCreateMineModelMutation,
  useUpdateMineModelMutation
} from 'providers/api';
import React from 'react';

const reticulationErrors = {
  incomplete: 'The reticulation data provided is incomplete.',
  server: 'There was an issue uploading the reticulation data to the server.',
};

const useSaveReticulationData = (
  onSuccess: () => void,
  projectId?: string,
  projectName?: string,
  hasReticulationData = false,
) => {
  const updateReticulationMutation = useUpdateMineModelMutation(projectId ?? '');
  const createReticulationMutation = useCreateMineModelMutation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errors, setErrors] = React.useState<string[]>([]);

  const save = (rawJSON: any) => {
    setErrors([]);
    if (rawJSON) {
      // check json has correct props
      const hasPipeData = 'pipe_data' in rawJSON;
      const hasNodeData = 'node_data' in rawJSON;
      const hasStopeData = 'stope_data' in rawJSON;
      const hasSpoolData = 'spool_data' in rawJSON;
      const hasDateData = 'date' in rawJSON;

      if (hasPipeData && hasNodeData && hasStopeData && hasDateData && hasSpoolData) {
        if (!(projectId && projectName)) {
          throw new Error('Project Id not found');
        }

        const reticulationSaveData = {
          projectId,
          projectName,
          data: makeReticulationDataImportDto(rawJSON),
        };

        // attempt mutation
        const mutation = hasReticulationData ? updateReticulationMutation : createReticulationMutation;
        setIsSubmitting(true);
        mutation.mutate(reticulationSaveData, {
          onSuccess: () => {
            onSuccess();
            setErrors([]);
          },
          onError: () => {
            setErrors((existingErrors) => [...existingErrors, reticulationErrors.server]);
          },
          onSettled: () => {
            setIsSubmitting(false);
          },
        });
      } else {
        setErrors((existingErrors) => [...existingErrors, reticulationErrors.incomplete]);
      }
    }
  };

  return { save, errors, isSubmitting };
};

export default useSaveReticulationData;
