import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import {
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { Link } from 'react-router-dom';

const DRAWER_WIDTH = 400;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100%',
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 1000,
  },
  paper: {
    width: DRAWER_WIDTH,
    height: '100%',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
  },
  toolbar: theme.mixins.toolbar,
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface ModelDrawerProps {
  backNavPath?: string;
  backNavText?: string;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  children?: React.ReactNode;
}

const ModelDrawer = ({ children, backNavPath, backNavText, isOpen, setIsOpen }: ModelDrawerProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
        <Paper elevation={4} className={classes.paper}>
          <Box pt={3} pb={1}>
            <div className={classes.toolbar} />
            <div className={classes.toolbar} />
            <Box px={2} display="flex" flexDirection="row-reverse" justifyContent="space-between">
              <IconButton
                color="inherit"
                aria-label="close stope navigation"
                onClick={() => setIsOpen(false)}
              >
                <ChevronRightIcon />
              </IconButton>
              {
                backNavPath && backNavText && (
                  <Button
                    aria-label="back navigation"
                    color="inherit"
                    component={Link}
                    to={backNavPath}
                    startIcon={<ArrowBackIcon />}
                  >
                    {`BACK TO ${backNavText}`}
                  </Button>
                )
              }
            </Box>
          </Box>
          <Box px={2} flex={1} overflow="hidden">{children}</Box>
        </Paper>
      </Slide>
    </div>
  );
};

export default ModelDrawer;
