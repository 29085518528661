import {
  makeRheologyDataForm, RheologyMutation,
  useCreateRheologyMutation,
  useUpdateRheologyMutation
} from 'providers/api';
import React from 'react';

const rheologyErrors = {
  incomplete: 'The rheology data provided is incomplete or missing',
};

const requiredProps = [
  'frictionDictionary',
  'date',
  'maxPumpPressure',
  'ucsCoefficients',
  'mixerCoefficients',
  'yieldStressCoefficients',
  'rheologyVersion',
  'rheologyReference',
  'tailingsDrySolidsDensity',
  'carrierFluidDensity',
  'binderDryParticleDensity',
];

const useSaveRheologyData = (
  onSuccess: () => void,
  projectId?: string,
  projectName?: string,
  rheologyDatasetId?: string,
) => {
  const updateRheologyMutation = useUpdateRheologyMutation(projectId ?? '');
  const createRheologyMutation = useCreateRheologyMutation(projectId ?? '');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errors, setErrors] = React.useState<string[]>([]);

  const clearErrors = () => setErrors([]);

  const save = (rawJSON: any) => {
    // check json has correct props
    const missingProps = requiredProps.reduce((currrentMissingProps: string[], currentProp: string) => [
      ...currrentMissingProps,
      ...(currentProp in rawJSON ? [] : [currentProp]),
    ], []);

    if (missingProps.length === 0) {
      if (!(projectId && projectName)) {
        throw new Error('Project Id not found');
      }

      const data = makeRheologyDataForm(rawJSON);

      const rheologySaveData: RheologyMutation = {
        projectId,
        projectName,
        data,
        rheologyDatasetId,
      };

      // attempt mutation
      const mutation = rheologyDatasetId ? updateRheologyMutation : createRheologyMutation;
      setIsSubmitting(true);
      mutation.mutate(rheologySaveData, {
        onSuccess: () => {
          setIsSubmitting(false);
          onSuccess();
          setErrors([]);
        },
        onError: (requestError: any) => {
          setIsSubmitting(false);
          setErrors((existingErrors) => [...existingErrors, requestError.result.title]);
        },
      });
    } else {
      setErrors((existingErrors) => [...existingErrors, `${rheologyErrors.incomplete}: ${missingProps.join(', ')}`]);
    }
  };

  return { save, errors, isSubmitting, clearErrors };
};

export default useSaveRheologyData;
