import { Instant } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { FormikActions } from 'components/Form';
import ProgressButton from 'components/ProgressButton';
import useForm from 'hooks/useForm';
import {
  MineModelDto,
  ProjectDto,
  StopeDataDto,
  StopeDataForm,
  StopeLocationDto
} from 'providers/api';
import {
  SingleStopeMutation,
  useUpdateNodeToStopeMutation,
  useUpdateStopeMutation
} from 'providers/api/useMineModels';
import {
  SchemaOf,
  array,
  number,
  object,
  string
} from 'yup';
import PipeTypeSelectField from './PipeTypeSelectField';

const stopeDataSchema: SchemaOf<StopeDataForm> = object().shape({
  stopeName: string().required('Stope name required'),
  references: array().of(string()).notRequired(),
  pipeVerticalLength: number(),
  pipeHorizontalLength: number().required('Horizontal pipe length is required'),
  pipeId: string().required('Pipe type is required'),
  volume: number().moreThan(0).required('Volume is required'),
  level: string(),
  area: string(),
});

const singleStopeMutationSchema: SchemaOf<SingleStopeMutation> = object().shape({
  projectName: string().required(),
  nodeId: string().required(),
  data: stopeDataSchema,
});

interface StopeFormProps {
  project: ProjectDto;
  nodeId: string;
  mineModel: MineModelDto;
  onComplete: () => void; // set to null once mutation is complete of cancelled
  onAdd: (stopeLocation: StopeLocationDto, newStope: StopeDataDto) => void;
  onUpdate: (stopeLocation: StopeLocationDto, newStope: StopeDataDto) => void;
}

const StopeForm = ({ project, nodeId, mineModel, onComplete, onAdd, onUpdate }: StopeFormProps) => {
  const stopeLocation = mineModel.reticulationData.stopeLocations.find((location) => location.nodeId === nodeId);
  const isUpdate = !!stopeLocation;

  const stopeData = isUpdate
    ? mineModel.reticulationData.stopes.find((data) => data.stopeId === stopeLocation?.stopeId)
    : undefined;

  const mutation = isUpdate
    ? useUpdateStopeMutation(project.entityId)
    : useUpdateNodeToStopeMutation(project.entityId);

  const {
    formik,
    helpers,
  } = useForm({
    mutation,
    formikConfig: {
      initialValues: {
        projectName: project.name,
        nodeId,
        data: {
          stopeName: stopeData?.stopeName,
          pipeVerticalLength: stopeData?.pipeVerticalLength,
          pipeHorizontalLength: stopeData?.pipeHorizontalLength,
          pipeId: stopeData?.pipeId,
          volume: stopeData?.volume,
          level: stopeData?.level,
          area: stopeData?.area,
        },
      },
      onSubmit: (stopeDataForm, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        const payload = isUpdate
          ? {
            projectName: project.name,
            data: [{
              nodeId: stopeDataForm.nodeId,
              stopeDataForm: stopeDataForm.data,
            }],
          }
          : {
            projectName: stopeDataForm.projectName ?? '',
            nodeId: stopeDataForm.nodeId,
            data: stopeDataForm.data,
          };

        mutation.mutate(payload as any, {
          onSettled: () => {
            setSubmitting(false);
          },
          onSuccess: (result: any) => {
            const stopeId = stopeLocation?.stopeId ?? result;

            const stopeLocationDto: StopeLocationDto = {
              nodeId: stopeDataForm.nodeId,
              stopeId,
            };

            const stopeDataDto: StopeDataDto = {
              // Force values as we know they have been successfully validated
              stopeName: stopeDataForm.data.stopeName!,
              pipeVerticalLength: stopeDataForm.data.pipeVerticalLength,
              pipeHorizontalLength: stopeDataForm.data.pipeHorizontalLength ?? 0,
              pipeLength: 0, // remove this once PipeLength has been removed server side
              pipeId: stopeDataForm.data.pipeId!,
              volume: stopeDataForm.data.volume ?? 0,
              level: stopeDataForm.data.level ?? undefined,
              area: stopeDataForm.data.area ?? undefined,
              stopeId,
              references: [],
              physicalPropertiesUpdated: Instant.now(),
              routeUpdated: Instant.now(),
            };

            isUpdate ? onUpdate(stopeLocationDto, stopeDataDto) : onAdd(stopeLocationDto, stopeDataDto);

            resetForm();
            onComplete();
          },
          onError: (e) => {
            // eslint-disable-next-line
            console.log(e);
          },
        });
      },
      validationSchema: singleStopeMutationSchema,
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    isValid,
    isSubmitting,
  } = formik;

  const handleCloseModal = () => {
    resetForm();
    onComplete();
  };

  const submitButton = [
    'submit',
    (
      <ProgressButton
        variant="contained"
        color="primary"
        type="submit"
        disabled={!isValid}
        loading={isSubmitting}
      >
        Submit
      </ProgressButton>
    ),
  ];

  return (
    <Dialog
      title="Add Stope"
      open={!!nodeId}
      onClose={handleCloseModal}
    >
      <DialogTitle id="simple-dialog-title">Stope Edit</DialogTitle>
      <Box width={500} m={2}>
        <form onSubmit={handleSubmit}>
          <Box>
            <TextField
              required
              fullWidth
              id="nodeId"
              name="nodeId"
              label="Node Id"
              value={nodeId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('nodeId')}
              helperText={helpers.getErrorHelpText('nodeId')}
              disabled
            />
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              id="data.stopeName"
              name="data.stopeName"
              label="Stope Name"
              value={values.data.stopeName ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('data.stopeName')}
              helperText={helpers.getErrorHelpText('data.stopeName')}
              required
            />
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              id="data.pipeVerticalLength"
              name="data.pipeVerticalLength"
              label="Stope Pipe Vertical Length (m)"
              value={values.data.pipeVerticalLength ?? ''}
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('data.pipeVerticalLength')}
              helperText={helpers.getErrorHelpText('data.pipeVerticalLength')}
              required
            />
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              id="data.pipeHorizontalLength"
              name="data.pipeHorizontalLength"
              label="Stope Pipe Horizontal Length (m)"
              value={values.data.pipeHorizontalLength ?? ''}
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('data.pipeHorizontalLength')}
              helperText={helpers.getErrorHelpText('data.pipeHorizontalLength')}
              required
            />
          </Box>
          <Box pt={2}>
            <PipeTypeSelectField
              pipeTypes={mineModel.reticulationData.pipeData}
              value={values.data.pipeId ?? ''}
              onChange={(pipeId) => setFieldValue('data.pipeId', pipeId)}
            />
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              id="data.volume"
              name="data.volume"
              label="Stope Volume (m3)"
              value={values.data.volume ?? ''}
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('data.volume')}
              helperText={helpers.getErrorHelpText('data.volume')}
              required
            />
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              id="data.level"
              name="data.level"
              label="Level"
              value={values.data.level ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('data.level')}
              helperText={helpers.getErrorHelpText('data.level')}
            />
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              id="data.area"
              name="data.area"
              label="Area"
              value={values.data.area ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('data.area')}
              helperText={helpers.getErrorHelpText('data.area')}
            />
          </Box>
          <Box mt={2}>
            <FormikActions
              formik={formik}
              mutation={mutation}
              submitText="Create"
              right={
                [
                  'reset',
                  submitButton as [string, JSX.Element],
                ]
              }
            />
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default StopeForm;
