import Page from 'modules/Page';
import makePage from 'modules/makePage';
import * as parentConstants from 'modules/project/ProjectAreaPage/constants';
import { Permission } from 'providers/authorisation';
import { partial } from 'ramda';
import withLicenceAndDisclaimer from '../withLicenceAndDisclaimer';
import NewPourPage from './NewPourPage';
import * as constants from './constants';
import { NewPourPageParams } from './constants';
import useNewPourQueries, { NewPourQueries } from './useNewPourQueries';

const page: Page<constants.NewPourPageParams> = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  authorise: (context, params) => ({
    handler: partial(context.functions.validatePermission, [Permission.ManagePours]),
    teamId: params.projectId,
  }),
  component: withLicenceAndDisclaimer({
    pageDataHook: useNewPourQueries,
    Component: makePage<NewPourPageParams, [], NewPourQueries>({
      WrappedPage: NewPourPage,
      titleFn: () => constants.TITLE,
      parentPathTemplate: `${parentConstants.STOPES_ROUTE_PATH}/:stopeId/recipes`,
      parentTitleFn: () => 'Recipes',
    }),
  }),
};

export default page;
