import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { GridPageChangeParams } from '@material-ui/data-grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClientField from 'components/ClientField';
import SearchField from 'components/SearchField';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import {
  ClientDto,
  InvitationDto,
  useInvitationsQuery,
  useRevokeInvitationMutation
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import React from 'react';
import InviteUserButton from '../InviteUserButton';
import InvitationsTable from './InvitationsTable';

const InvitationsPage = () => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION_DEFAULT_PAGE_SIZE);
  const [clientId, setClientId] = React.useState<string | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const confirm = useConfirmation();
  const mutation = useRevokeInvitationMutation();

  const {
    data,
    isFetching,
    isFetched,
    isPreviousData,
  } = useInvitationsQuery({ page, pageSize, searchTerm, clientId });

  const handlePageChange = (change: GridPageChangeParams) => {
    setPage(change.page + 1);
  };

  const handlePageSizeChange = (change: GridPageChangeParams) => {
    setPage(1);
    setPageSize(change.pageSize);
  };

  const handleClientChange = (client?: ClientDto | null) => {
    setPage(1);
    setClientId(client?.entityId ?? null);
  };

  const handleSearch = (text: string | null) => {
    setPage(1);
    setSearchTerm(text);
  };

  const handleRevoke = (invite: InvitationDto) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to revoke this invite?',
    }).then(() => mutation.mutate(invite));
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
        <Box flex={1} mr={3}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <ClientField onChange={handleClientChange} textFieldVariant="filled" />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <SearchField onSearch={handleSearch} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <InviteUserButton />
      </Box>
      <Box mt={3} component={Paper}>
        <InvitationsTable
          data={data?.items}
          page={page}
          pageSize={pageSize}
          totalItems={data?.pager.totalItems}
          loading={isFetching}
          loadingNew={isFetching && (!isFetched || isPreviousData)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onRevoke={handleRevoke}
          searchTerm={searchTerm}
        />
      </Box>
    </Container>
  );
};

export default InvitationsPage;
