import * as detailsPageConstants from './ProjectDetailsPage/constants';
import * as reportsPageConstants from './ProjectReportsPage/constants';
import * as stopesPageConstants from './ProjectStopesPage/constants';

export interface ProjectAreaPageParams {
  entityId: string
}

export const ROUTE_PARAM_IDENTIFIER = ':entityId';
export const ROUTE_PATH = `/projects/${ROUTE_PARAM_IDENTIFIER}/overview`;
export const TITLE = 'Project';

export const DETAILS_ROUTE_PATH = ROUTE_PATH + detailsPageConstants.ROUTE_PATH;
export const DETAILS_TITLE = detailsPageConstants.TITLE;

export const STOPES_ROUTE_PATH = ROUTE_PATH + stopesPageConstants.ROUTE_PATH;
export const STOPES_TITLE = stopesPageConstants.TITLE;

export const REPORTS_ROUTE_PATH = ROUTE_PATH + reportsPageConstants.ROUTE_PATH;
export const REPORTS_TITLE = reportsPageConstants.TITLE;
