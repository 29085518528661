import { Box } from '@material-ui/core';
import SimpleRow from 'components/SimpleRow';
import React from 'react';

interface ActionsProps {
  left?: [string, JSX.Element][];
  center?: [string, JSX.Element][];
  right?: [string, JSX.Element][];
}

const Actions = ({ left, center, right }: ActionsProps) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    {left ? <SimpleRow>{left}</SimpleRow> : <div />}
    {center ? <SimpleRow>{center}</SimpleRow> : <div />}
    {right ? <SimpleRow>{right}</SimpleRow> : <div />}
  </Box>
);

export default Actions;
