import { ZoneId } from '@js-joda/core';
import { ProjectDto } from 'providers/api';
import React, { ReactNode } from 'react';
import ProjectContext, { IProjectContext } from './context';

export interface ProjectProviderProps {
  children: ReactNode;
  project: ProjectDto;
}

const ProjectProvider = ({ project, children }: ProjectProviderProps) => {
  const value: IProjectContext = {
    state: {
      timeZone: ZoneId.of(project.timeZone),
      projectId: project.entityId,
      clientId: project.client.entityId,
    },
  };

  return (
    <ProjectContext.Provider value={value}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
