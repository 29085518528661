import NewUserPage from './NewUserPage';
import UserDetailsPage from './UserDetailsPage';
import UsersAreaPage from './UsersAreaPage';

const userAdminModule = {
  pages: [
    NewUserPage,
    UserDetailsPage,
    UsersAreaPage,
  ],
};

export default userAdminModule;
