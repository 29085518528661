import Typography from '@material-ui/core/Typography';
import { GridOptions } from '@material-ui/data-grid';
import DataGridWithLoading, { ResponsiveGridColDef } from 'components/DataGrid';
import Temporal from 'components/Temporal';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import {
  DisplayUnits,
  FillType,
  PourReportDto,
  RheologyDataDto,
  ThroughputControlType,
  UnitSystem
} from 'providers/api';
import { DEFAULT_SHORT_DATE_TIME_CONFIG } from 'utils';
import PourStatusChip from '../../PourStatusChip';
import PoursTableFooter from './PoursTableFooter';
import WarningBadge from './WarningsBadge';

const columns = (unitSystemPreference: UnitSystem, fillType: FillType): ResponsiveGridColDef[] => ([
  {
    field: 'reference',
    headerName: 'Reference',
    flex: 1,
    resizable: false,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => <Typography variant="subtitle2">{row.reference}</Typography>,
  },
  {
    field: 'intendedPourDate',
    headerName: 'Pour Date',
    flex: 1,
    resizable: false,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => <Temporal temporal={row.intendedPourDate} options={DEFAULT_SHORT_DATE_TIME_CONFIG} />,
  },
  {
    field: 'volume',
    headerName: `Pour Volume ${unitSystemPreference === UnitSystem.Metric
      ? unitLabels[BasicDisplayUnits.Volume].metric.abbreviation
      : unitLabels[BasicDisplayUnits.Volume].imperial.abbreviation
      }`,
    width: 180,
    align: 'center',
    resizable: false,
    sortable: false,
    filterable: false,
  },
  ...(
    fillType === FillType.Hydraulic
      ? [
        {
          field: 'volumeRetainedAfterOutflow',
          headerName: 'Outflow %',
          width: 180,
          align: 'center',
          resizable: false,
          sortable: false,
          filterable: false,
        },
      ] as ResponsiveGridColDef[]
      : []
  ),
  {
    field: 'warnings',
    headerName: 'Warnings',
    width: 120,
    resizable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => (<WarningBadge warnings={row.warnings && Array.isArray(row.warnings) ? row.warnings : []} />),
  },
  {
    field: 'status',
    headerName: 'Status',
    resizable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => (<PourStatusChip pourStatus={row.status} />),
  },
]);

interface PourTableProps {
  pours: PourReportDto[];
  rheologyDataIndexedById: Record<string, RheologyDataDto>;
  totalPours: number;
  page: number;
  pageSize: number;
  pageSizeOptions: number[];
  loading: boolean;
  loadingNew: boolean;
  projectName: string;
  throughputControlType: ThroughputControlType;
  displayUnitPreferences: DisplayUnits[];
  unitSystemPreference: UnitSystem;
  fillType: FillType;
  onPageChange: GridOptions['onPageChange'];
  onPageSizeChange: GridOptions['onPageSizeChange'];
}

const PourTable = ({
  pours,
  rheologyDataIndexedById,
  totalPours,
  page,
  pageSize,
  pageSizeOptions,
  loading,
  loadingNew,
  projectName,
  throughputControlType,
  displayUnitPreferences,
  unitSystemPreference,
  fillType,
  onPageChange,
  onPageSizeChange,
}: PourTableProps) => {
  const pageIndex = pours ? (page - 1) : 0;

  return (
    <DataGridWithLoading
      loading={loading}
      loadingNew={loadingNew}
      searchTerm=""
      searchFields={['reference']}
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      idProp="entityId"
      rows={pours}
      columns={columns(unitSystemPreference, fillType)}
      pagination
      paginationMode="server"
      page={pageIndex}
      pageSize={pageSize}
      rowsPerPageOptions={pageSizeOptions}
      rowCount={totalPours}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      components={{
        Footer: () => (
          <PoursTableFooter
            pours={pours}
            rheologyDataIndexedById={rheologyDataIndexedById}
            projectName={projectName}
            displayUnitPreferences={displayUnitPreferences}
            unitSystemPreference={unitSystemPreference}
            fillType={fillType}
            throughputControlType={throughputControlType}
          />
        ),
      }}
    />
  );
};

export default PourTable;
