import Box from '@material-ui/core/Box';
import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

const API_KEY = 'AIzaSyBb3ZPW4JKF3Lj1jn2zjSjRHuC41QrrT7Q';
const useStyles = makeStyles(() => createStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'hidden',
    filter: 'grayscale(100%) invert(90%) contrast(83%)',
    '& > iframe': {
      width: '100%',
      height: 'calc(100% + 300px)',
      marginTop: '-150px',
      border: 0,
    },
    zIndex: -1,
  },
  container: {
    display: 'block',
    position: 'relative',
    height: '300px',
    zIndex: 1,
  },
}));

interface GoogleMapEmbedProps {
  lat: number;
  lng: number;
  container?: string;
}

const GoogleMapEmbed = ({ lat, lng, container }: GoogleMapEmbedProps) => {
  const classes = useStyles();
  const embedUrl = `https://www.google.com/maps/embed/v1/place?q=${lat},${lng}&center=${lat},${lng}&zoom=${18}&key=${API_KEY}`;

  return (
    <Box className={container ?? classes.container}>
      <Box className={classes.root}>
        <iframe title="map" frameBorder={0} src={embedUrl} />
      </Box>
    </Box>
  );
};

export default GoogleMapEmbed;
