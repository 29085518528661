/* eslint-disable class-methods-use-this */
import { IUtils } from '@date-io/core/IUtils';
import {
  DateTimeFormatter,
  Duration, LocalDate, LocalDateTime, LocalTime, ZonedDateTime, ZoneId
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en';
import '@js-joda/timezone';
import { is, isNil, not } from 'ramda';
import { getTimeZoneAbbreviation } from './zoned-date-time';

// Override toJSON function as format expected is slightly different to what js-joda produces
LocalDateTime.prototype.toJSON = function toJSON() {
  const formatter = DateTimeFormatter
    .ofPattern('HH:mm:ss.nnnnnnnnn');

  return `${this.toLocalDate().toString()}T${this.toLocalTime().format(formatter)}`;
};

export const makeLocalDateTime = (dateTimeString: string): LocalDateTime => LocalDateTime.parse(dateTimeString);

const dateToLocalDateTime = (date: Date) => LocalDateTime.of(
  date.getFullYear(),
  date.getMonth() + 1,
  date.getDate(),
  date.getHours(),
  date.getMinutes(),
  date.getSeconds(),
  date.getMilliseconds() * 1000000,
);

export const makeLocalDateTimeUtils = (timeZone: ZoneId = ZoneId.of('Europe/London')) => class LocalDateTimeUtils implements IUtils<LocalDateTime> {
  locale: any;

  timeZone: ZoneId;

  yearFormat = 'yyyy';

  yearMonthFormat = 'MMMM yyyy';

  dateTime12hFormat = 'MMMM do hh:mm aaaa';

  dateTime24hFormat = 'MMMM do HH:mm';

  time12hFormat = 'hh:mm a';

  time24hFormat = 'HH:mm';

  dateFormat = 'MMMM do';

  constructor({ locale }: { locale?: any }) {
    this.locale = locale ?? Locale.UK;
    this.timeZone = timeZone ?? ZoneId.of('Europe/London');
  }

  date(value?: any): LocalDateTime | null {
    if (value === null) {
      return null;
    }

    if (typeof value === 'undefined') {
      return LocalDateTime.now(this.timeZone);
    }

    if (typeof value === 'string') {
      const date = new Date(value);
      if (not(is(Number, date.valueOf()))) {
        return null;
      }

      return dateToLocalDateTime(date);
    }

    if (value instanceof LocalDateTime) {
      return value;
    }

    if (value instanceof Date) {
      return dateToLocalDateTime(value);
    }

    throw new Error(`Unknown Date value in function date(): ${value}`);
  }

  parse(value: string, format: string): LocalDateTime | null {
    if (value === '' || value.includes('_')) {
      return null;
    }

    const formatter = DateTimeFormatter.ofPattern(format).withLocale(this.locale);

    // js-joda does not parse upper case AM or PM
    value.replace('AM', 'am');
    value.replace('PM', 'pm');

    const isDateFormat = format.includes('d') && format.includes('M') && format.includes('y');
    const isTimeFormat = format.includes('H') && format.includes('m');

    if (isDateFormat && isTimeFormat) {
      return LocalDateTime.parse(value, formatter);
    }

    try {
      if (isDateFormat) {
        return LocalDateTime.of(
          LocalDate.parse(value, formatter),
          LocalTime.MIN,
        );
      }
    } catch {
      // noop
    }

    if (isTimeFormat) {
      return LocalDateTime.of(
        LocalDate.now(this.timeZone),
        LocalTime.parse(value.toLowerCase(), formatter),
      );
    }
    return null;
  }

  isNull(value: LocalDateTime | null): boolean {
    return value === null;
  }

  isValid(value: any): boolean {
    return !isNil(this.date(value));
  }

  getDiff(value: LocalDateTime, comparing: string | LocalDateTime): number {
    const comparingLocalDateTime = this.date(comparing);

    if (!comparingLocalDateTime) {
      return 0;
    }

    const duration = Duration.between(value, comparingLocalDateTime);
    return duration.toMillis();
  }

  isEqual(value: any, comparing: any): boolean {
    const valueLocalDateTime = this.date(value);
    const comparingLocalDateTime = this.date(comparing);

    if (valueLocalDateTime === null && comparingLocalDateTime === null) {
      return true;
    }

    if (comparingLocalDateTime === null || valueLocalDateTime === null) {
      return false;
    }

    return valueLocalDateTime.isEqual(comparingLocalDateTime);
  }

  isSameDay(value: LocalDateTime, comparing: LocalDateTime): boolean {
    return this.isSameMonth(value, comparing) && value.dayOfMonth() === comparing.dayOfMonth();
  }

  isSameMonth(value: LocalDateTime, comparing: LocalDateTime): boolean {
    return this.isSameYear(value, comparing) && value.monthValue() === comparing.monthValue();
  }

  isSameYear(value: LocalDateTime, comparing: LocalDateTime): boolean {
    return value.year() === comparing.year();
  }

  isSameHour(value: LocalDateTime, comparing: LocalDateTime): boolean {
    return this.isSameDay(value, comparing) && value.hour() === comparing.hour();
  }

  isAfter(value: LocalDateTime, comparing: LocalDateTime): boolean {
    return value.isAfter(comparing);
  }

  isAfterDay(value: LocalDateTime, comparing: LocalDateTime): boolean {
    return value.with(LocalTime.MIN).isAfter(this.endOfDay(comparing));
  }

  isAfterYear(value: LocalDateTime, comparing: LocalDateTime): boolean {
    const endOfYear = comparing
      .withMonth(12)
      .withDayOfMonth(31)
      .with(LocalTime.MAX);

    return value.isAfter(endOfYear);
  }

  isBeforeDay(value: LocalDateTime, comparing: LocalDateTime): boolean {
    return value.isBefore(this.startOfDay(comparing));
  }

  isBeforeYear(value: LocalDateTime, comparing: LocalDateTime): boolean {
    const startOfYear = comparing
      .withMonth(1)
      .withDayOfMonth(1)
      .with(LocalTime.MIN);

    return value.isBefore(startOfYear);
  }

  isBefore(value: LocalDateTime, comparing: LocalDateTime): boolean {
    return value.isBefore(comparing);
  }

  startOfMonth(value: LocalDateTime): LocalDateTime {
    return value
      .withDayOfMonth(1)
      .with(LocalTime.MIN);
  }

  endOfMonth(value: LocalDateTime): LocalDateTime {
    return value
      .plusMonths(1)
      .minusDays(1)
      .with(LocalTime.MAX);
  }

  addDays(value: LocalDateTime, count: number): LocalDateTime {
    return value.plusDays(count);
  }

  startOfDay(value: LocalDateTime): LocalDateTime {
    return value.with(LocalTime.MIN);
  }

  endOfDay(value: LocalDateTime): LocalDateTime {
    return value.with(LocalTime.MAX);
  }

  format(value: LocalDateTime, formatString: string): string {
    const hasTimeZone = formatString.includes('z');

    const parsedFormatString = hasTimeZone
      ? formatString.replace('z', `'${getTimeZoneAbbreviation(ZonedDateTime.ofLocal(value, this.timeZone))}'`)
      : formatString;

    const formatter = DateTimeFormatter
      .ofPattern(parsedFormatString)
      .withLocale(this.locale);

    return value.format(formatter);
  }

  formatNumber(numberToFormat: string): string {
    return numberToFormat;
  }

  getHours(value: LocalDateTime): number {
    return value.hour();
  }

  setHours(value: LocalDateTime, count: number): LocalDateTime {
    return value.withHour(count);
  }

  getMinutes(value: LocalDateTime): number {
    return value.minute();
  }

  setMinutes(value: LocalDateTime, count: number): LocalDateTime {
    return value.withMinute(count);
  }

  getSeconds(value: LocalDateTime): number {
    return value.second();
  }

  setSeconds(value: LocalDateTime, count: number): LocalDateTime {
    return value.withSecond(count);
  }

  getMonth(value: LocalDateTime): number {
    return value.monthValue() - 1;
  }

  setMonth(value: LocalDateTime, count: number): LocalDateTime {
    return value.withMonth(count + 1);
  }

  getNextMonth(value: LocalDateTime): LocalDateTime {
    return value.plusMonths(1);
  }

  getPreviousMonth(value: LocalDateTime): LocalDateTime {
    return value.minusMonths(1);
  }

  getMonthArray(value: LocalDateTime): LocalDateTime[] {
    const startOfYear = value
      .withMonth(1)
      .withDayOfMonth(1)
      .with(LocalTime.MIN);

    const firstMonth = startOfYear;
    const monthArray = [firstMonth];

    while (monthArray.length < 12) {
      const prevMonth = monthArray[monthArray.length - 1];
      monthArray.push(this.getNextMonth(prevMonth));
    }

    return monthArray;
  }

  getYear(value: LocalDateTime): number {
    return value.year();
  }

  setYear(value: LocalDateTime, count: number): LocalDateTime {
    return value.withYear(count);
  }

  mergeDateAndTime(date: LocalDateTime, time: LocalDateTime): LocalDateTime {
    return this.setMinutes(
      this.setHours(date, this.getHours(time)),
      this.getMinutes(time),
    );
  }

  getWeekdays(): string[] {
    const today = LocalDateTime.now(this.timeZone);
    const startOfWeek = this.startOfWeek(today);

    const weekdays = [];
    const formatter = DateTimeFormatter.ofPattern('eee').withLocale(this.locale);
    for (let i = 0; i < 7; i += 1) {
      weekdays.push(startOfWeek.plusDays(i).format(formatter));
    }
    return weekdays;
  }

  getWeekArray(value: LocalDateTime): LocalDateTime[][] {
    const startOfMonth = this.startOfMonth(value);
    const endOfMonth = this.endOfMonth(value);

    const start = this.startOfWeek(startOfMonth);
    const end = this.endOfWeek(endOfMonth);

    let count = 0;
    let current = start;
    const nestedWeeks: LocalDateTime[][] = [];

    while (current.isBefore(end)) {
      const weekNumber = Math.floor(count / 7);
      nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
      nestedWeeks[weekNumber].push(current);
      current = current.plusDays(1);
      count += 1;
    }
    return nestedWeeks;
  }

  getYearRange(start: LocalDateTime, end: LocalDateTime): LocalDateTime[] {
    const startDate = start
      .withMonth(1)
      .withDayOfMonth(1)
      .with(LocalTime.MIN);

    const endDate = end
      .withMonth(12)
      .withDayOfMonth(31)
      .with(LocalTime.MAX);

    const years: LocalDateTime[] = [];

    let current = startDate;
    while (this.isBefore(current, endDate)) {
      years.push(current);
      current = current.plusYears(1);
    }

    return years;
  }

  getMeridiemText(ampm: 'am' | 'pm'): string {
    return ampm === 'am' ? 'AM' : 'PM';
  }

  getCalendarHeaderText(date: LocalDateTime): string {
    return this.format(date, this.yearMonthFormat);
  }

  getDatePickerHeaderText(date: LocalDateTime): string {
    return this.format(date, 'EEE, MMM d');
  }

  getDateTimePickerHeaderText(date: LocalDateTime): string {
    return this.format(date, 'MMM d');
  }

  getMonthText(date: LocalDateTime): string {
    return this.format(date, 'MMMM');
  }

  getDayText(date: LocalDateTime): string {
    return this.format(date, 'd');
  }

  getHourText(date: LocalDateTime, ampm: boolean) {
    return this.format(date, ampm ? 'hh' : 'HH');
  }

  getMinuteText(date: LocalDateTime) {
    return this.format(date, 'mm');
  }

  getSecondText(date: LocalDateTime) {
    return this.format(date, 'ss');
  }

  getYearText(date: LocalDateTime): string {
    return this.format(date, 'yyyy');
  }

  private startDayOfWeek(): number {
    let startDayOfWeek;
    switch (this.locale.localeString()) {
      case 'en-US':
        startDayOfWeek = 7; // Sunday (day 7 in js-joda, which implements ISO8601 calendar only)
        break;
      default:
        startDayOfWeek = 1; // Monday
    }
    return startDayOfWeek % 7;
  }

  private startOfWeek(value: LocalDateTime) {
    const dayOfWeek = value.dayOfWeek().value();
    return value
      .minusDays(dayOfWeek - this.startDayOfWeek())
      .with(LocalTime.MIN);
  }

  private endOfWeek(value: LocalDateTime) {
    const dayOfWeek = value.dayOfWeek().value();
    return value
      .plusDays(7 - (dayOfWeek - this.startDayOfWeek()))
      .with(LocalTime.MAX);
  }
};
