import GuiState from '../GUI/GuiState';
import * as GConstants from '../../GConstants';

const plotPipeColorKey = (guiState: GuiState) => {
  if (guiState.showPipeTypesStatus) {
  const { modelState: { pipes, pipeLegend, recipeState }, modelCanvas, pipeColorCanvas } = guiState;
  const modelContext = modelCanvas.getContext('2d');
  const telemetryCtx = pipeColorCanvas.getContext('2d');

  if (telemetryCtx && modelContext) {
    const canvasPadding = 30;
    const rowHeight = 16;
    const colWidth = 150;
    const padding = 3;
    const barTitle = 'Pipe Legend';
    const barRowHeight = 32;
    const rectX = 0;
    const rectY = 20;
    const barX = 0;
    const barY = 0;
    telemetryCtx.globalAlpha = 1;
    telemetryCtx.font = '10px Arial';
    telemetryCtx.textBaseline = 'top';
    telemetryCtx.textAlign = 'left';
    telemetryCtx.fillStyle = GConstants.PC_BLUE;
    telemetryCtx.fillRect(barX, barY, colWidth, barRowHeight);
    telemetryCtx.fillStyle = 'white';
    telemetryCtx.fillText(barTitle, barX + padding, barY + padding);

    for (let i = 0; i < Object.values(pipes).length; i += 1) {
      Object.values(pipes)[i].velocity = Object.values(pipes)[i].getVelocity(recipeState.wetFlowRate!);
      guiState.updateLegend();
      if (pipeLegend.length > 0) {
        telemetryCtx.fillStyle = GConstants.PIPE_COLOURS[i];
        telemetryCtx.fillRect(rectX, rectY + (rowHeight * i), colWidth, rowHeight);
        telemetryCtx.fillStyle = 'black';
        const pipeName = pipeLegend[i][0]!;
        const pipeVelocity = pipeLegend[i][1]!;
        telemetryCtx.fillText(`${pipeName}  ---  ${pipeVelocity}`, rectX + padding, rectY + (rowHeight * i) + padding);
      }
    }

    modelContext.drawImage(
      pipeColorCanvas,
      pipeColorCanvas.width,
      guiState.screenHeight - pipeColorCanvas.height - canvasPadding,
    );
    }
  }
};

export default plotPipeColorKey;
