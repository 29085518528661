import React, { ReactNode } from 'react';
import MenuStateContext from './menuStateContext';

interface menuStateProviderProps {
  menuState: boolean;
  children: ReactNode;
}

const menuStateProvider = ({ menuState, children }: menuStateProviderProps) => (
  <MenuStateContext.Provider value={menuState}>
    {children}
  </MenuStateContext.Provider>
);

export default menuStateProvider;
