import Container from '@material-ui/core/Container';
import { BasePageProps } from 'modules/makePage';
import {
  ROUTE_PARAM_IDENTIFIER as ProjectDetailsPageParamIdentifier,
  ROUTE_PATH as ProjectDetailsPageRoute
} from 'modules/project-admin/ProjectDetailsPage/constants';
import { useUpdateLicenceExpiryMutation } from 'providers/api';
import { replace } from 'ramda';
import {
  useHistory,
  useParams
} from 'react-router-dom';
import LicenceExpiryForm from './LicenceExpiryForm';
import { EditProjectLicenceExpiryPageParams } from './constants';
import { EditProjectQueries } from './useEditProjectLicenceExpiryQueries';

const EditProjectLicenceExpiryPage = ({ queries }: BasePageProps<EditProjectQueries>) => {
  const { entityId } = useParams<EditProjectLicenceExpiryPageParams>();
  const [projectQuery] = queries;
  const project = projectQuery.data;

  const history = useHistory();
  const mutation = useUpdateLicenceExpiryMutation(entityId, {
    onSuccess: () => {
      history.push(replace(ProjectDetailsPageParamIdentifier, entityId, ProjectDetailsPageRoute));
    },
  });

  return (
    <Container maxWidth="lg">
      <>
        {project && <LicenceExpiryForm mutation={mutation} project={project} />}
      </>
    </Container>
  );
};

export default EditProjectLicenceExpiryPage;
