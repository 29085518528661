import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocalStorage } from '@rehooks/local-storage';
import clsx from 'clsx';
import { LOCAL_STORAGE_PREFIX } from 'config';
import { usePage } from 'providers/page';
import React, { ReactNode } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import Menu from './Menu';
import { MenuStateProvider } from './MenuStateProvider';
import TopBar from './TopBar';
import { TopLevelMenuItem } from './types';

export const CONTENT_WITHOUT_SUB_NAV_MOBILE_OFFSET = 55;
export const CONTENT_WITHOUT_SUB_NAV_OFFSET = 65;

export const CONTENT_WITH_SUB_NAV_MOBILE_OFFSET = 105;
export const CONTENT_WITH_SUB_NAV_OFFSET = 135;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  contentWithoutSubNav: {
    minHeight: `calc(100vh - ${CONTENT_WITHOUT_SUB_NAV_MOBILE_OFFSET}px)`,
    position: 'relative',
    flexGrow: 1,
    marginTop: CONTENT_WITHOUT_SUB_NAV_MOBILE_OFFSET,
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100vh - ${CONTENT_WITHOUT_SUB_NAV_OFFSET}px)`,
      marginTop: CONTENT_WITHOUT_SUB_NAV_OFFSET,
    },
  },
  contentWithSubNav: {
    minHeight: `calc(100vh - ${CONTENT_WITH_SUB_NAV_MOBILE_OFFSET}px)`,
    marginTop: CONTENT_WITH_SUB_NAV_MOBILE_OFFSET,
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100vh - ${CONTENT_WITH_SUB_NAV_OFFSET}px)`,
      marginTop: CONTENT_WITH_SUB_NAV_OFFSET,
    },
  },
}));

type LayoutProps = {
  menu: TopLevelMenuItem[];
  children: ReactNode;
};

const Layout = ({ children, menu }: LayoutProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const [savedOpen, setSavedOpenOpen] = useLocalStorage(`${LOCAL_STORAGE_PREFIX}-MENU`, true);
  const [open, setOpen] = React.useState(false);
  const { state: pageState } = usePage();

  // See: https://github.com/mui-org/material-ui/issues/9337
  const resizeEventDebounced = useDebouncedCallback(
    () => {
      window.dispatchEvent(new CustomEvent('resize'));
    },
    195,
  );

  React.useEffect(() => {
    !isLarge && setOpen(false);
    resizeEventDebounced();
  }, [isLarge, resizeEventDebounced]);

  const handleMenuOpen = () => {
    setOpen(true);
    // See: https://github.com/mui-org/material-ui/issues/9337
    resizeEventDebounced();
    isLarge && setSavedOpenOpen(true);
  };

  const handleMenuClosed = () => {
    setOpen(false);
    // See: https://github.com/mui-org/material-ui/issues/9337
    resizeEventDebounced();
    isLarge && setSavedOpenOpen(false);
  };

  const isOpen = isLarge ? savedOpen : open;

  return (
    <div className={classes.root}>
      <TopBar open={isOpen} />
      <Menu menu={menu} closeMenu={handleMenuClosed} openMenu={handleMenuOpen} open={isOpen} />
      <main className={clsx(
        classes.contentWithoutSubNav,
        { [classes.contentWithSubNav]: pageState.subNavigation.length > 0 },
      )}
      >
        <MenuStateProvider menuState={isOpen}>
          {children}
        </MenuStateProvider>
      </main>
    </div>
  );
};

export default Layout;
