import {
  CreatePourTypesCommand, PourTypeDataDto,
  PourTypeDataId,
  UpdatePourTypeCommand
} from './api';
import {
  makeCreateMutation, makeGetQuery,
  makeListQuery,
  makeUpdateMutation
} from './useApi';

const POUR_TYPES_KEY = 'pour_types';
const POUR_TYPE_KEY = 'pour_type';

export const usePourTypesQuery = makeListQuery<string, PourTypeDataDto[]>(
  POUR_TYPES_KEY,
  (api, mineModelId) => () => api.pourTypes.getAllByProject(mineModelId),
);

export interface PourTypeQueryParams {
  mineModelId: string;
  stopeId: string;
}

export const useStopePourTypesQuery = makeGetQuery<PourTypeDataDto[], PourTypeQueryParams>(
  POUR_TYPE_KEY,
  (api, params) => () => api.pourTypes.getByStopeId(params.mineModelId, params.stopeId),
);

export interface PourTypeCreateParams {
  mineModelId: string,
  command: CreatePourTypesCommand,
}

export const useCreatePourTypesMutation = makeCreateMutation<PourTypeDataId[], PourTypeCreateParams>(
  POUR_TYPES_KEY,
  (api) => (pourTypeData) => api.pourTypes.create(pourTypeData.mineModelId, pourTypeData.command),
  () => 'Pour types created successfully',
  [POUR_TYPES_KEY, POUR_TYPE_KEY],
);

export interface PourTypeUpdateParams {
  mineModelId: string,
  command: UpdatePourTypeCommand,
}

export const useUpdatePourTypesMutation = makeUpdateMutation<void, PourTypeUpdateParams>(
  POUR_TYPE_KEY,
  POUR_TYPES_KEY,
  (api) => (pourTypeData) => api.pourTypes.update(pourTypeData.mineModelId, pourTypeData.command),
  () => 'Pour types updated successfully',
  [POUR_TYPES_KEY, POUR_TYPE_KEY],
);
