import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles, Grid,
  makeStyles, Theme
} from '@material-ui/core';
import { GridPageChangeParams, GridRowData } from '@material-ui/data-grid';
import AlertText from 'components/AlertText';
import { ResponsiveGridColDef } from 'components/DataGrid';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import React, { useState } from 'react';
import DataTable from './DataTable';

const useStyles = makeStyles((theme: Theme) => createStyles({
  fileFormatIconContainer: {
    textAlign: 'center',
  },
  fileFormatIcon: {
    fontSize: 120,
    alignText: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  jsonMutationButton: {
    width: '90%',
    minWidth: 80,
    margin: 10,
  },
  orText: {
    textAlign: 'center',
  },
}));

interface DataMultipleTransactionProps {
  title?: string;
  dataList: GridRowData[],
  dataColumns: ResponsiveGridColDef[];
  dataListLength: number;
  errors: string[];
  disabled?: boolean,
  handleEnterRheologyData: () => void;
}

const DataMultipleTransactionBox = (
  {
    title,
    dataList,
    dataColumns,
    dataListLength,
    errors,
    disabled = false,
    handleEnterRheologyData,
  }: DataMultipleTransactionProps,
) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION_DEFAULT_PAGE_SIZE);

  const handlePageChange = (change: GridPageChangeParams) => {
    setPage(change.page + 1);
  };

  const handlePageSizeChange = (change: GridPageChangeParams) => {
    setPage(1);
    setPageSize(change.pageSize);
  };

  const [internalErrors] = useState<string[]>([]);
  return (
    <Card>
      {title && <CardHeader title={title} />}
      <CardContent>
        <Grid container spacing={1} direction="row">
          <Grid item container xs={12} direction="column" justify="center" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              className={classes.jsonMutationButton}
              onClick={() => handleEnterRheologyData()}
              disabled={disabled}
            >
              Add New Rheology Data
            </Button>
            {
              errors && (
                errors.map((error) => (
                  <AlertText key={error} severity="error">
                    {error}
                  </AlertText>
                ))
              )
            }
            {
              internalErrors && (
                internalErrors.map((error) => (
                  <AlertText key={error} severity="error">
                    {error}
                  </AlertText>
                ))
              )
            }
          </Grid>
        </Grid>
        <Grid spacing={1}>
          <Grid item xs={12}>
            <DataTable
              data={dataList}
              columns={dataColumns}
              dataTotal={dataListLength}
              page={page}
              pageSize={pageSize}
              pageSizeOptions={[1, 5, 10]} // use defaults
              loading={false}
              loadingNew={false}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DataMultipleTransactionBox;
