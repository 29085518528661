// import functions from API
import {
  InvitationDto,
  InviteUserToTeamsCommand,
  PagedListOfInvitationDto
} from './api';
import {
  makeCreateMutation,
  makeDeleteMutation,
  makeListQuery,
  PageParams
} from './useApi';

const INVITES_KEY = 'invitations';
export interface InvitationsQueryParams extends PageParams {
  searchTerm?: string | null;
  clientId?: string | null;
}

// GET Multiple invites
export const useInvitationsQuery = makeListQuery<InvitationsQueryParams, PagedListOfInvitationDto>(
  INVITES_KEY,
  (api, params) => () => api.invitations.listInvitations(params.page, params.pageSize, params.clientId, null, params.searchTerm),
);

const INVITE_KEY = 'invite';

export const useCreateInviteMutation = makeCreateMutation<void, InviteUserToTeamsCommand>(
  INVITE_KEY,
  (api) => (mutation) => api.invitations.create(mutation),
  (mutation) => `Invite successfully sent to ${mutation.email}`,
);

export const useRevokeInvitationMutation = makeDeleteMutation<void, InvitationDto>(
  INVITE_KEY,
  INVITES_KEY,
  (api) => (invite) => api.invitations.revokeInvitation(invite.invitationId),
  (invite) => `Invite for ${invite.email} successfully revoked`,
  (mutation) => mutation.invitationId,

);
