/* eslint-disable react/no-array-index-key */
import { makeStyles, Theme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme: Theme) => ({
  skeleton: {
    marginTop: theme.spacing(1),
  },
}));

interface ListSkeletonProps {
  numberOfListItems: number;
}

const ListSkeleton = ({ numberOfListItems }: ListSkeletonProps) => {
  const classes = useStyles();

  return (
    <>
      { Array.from(Array(numberOfListItems).keys()).map(
        (number) => <Skeleton key={number} className={classes.skeleton} animation="wave" variant="rect" width="100%" height={60} />,
      )}
    </>
  );
};

export default ListSkeleton;
