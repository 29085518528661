import {
  ProjectDto,
  RheologyDataDto,
  useProjectQuery,
  useRheologyDataQuery
} from 'providers/api';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { EditRheologyPageParams } from './constants';

  export type EditRheologyQueries = [
    UseQueryResult<RheologyDataDto | undefined>,
    UseQueryResult<ProjectDto, unknown>
  ];

  const useEditRheologyQueries = (params: EditRheologyPageParams): EditRheologyQueries => {
    const [rheologyData, setRheologyData] = React.useState<RheologyDataDto | null>(null);
    const [project, setProject] = React.useState<ProjectDto | null>(null);

    const rheologyQuery = useRheologyDataQuery(
      {
        mineModelId: params.entityId,
        rheologyDatasetId: params.rheologyId,
      },
    );

    const { data: rheologyQueryData, isFetched: rheologyDataIsFetched } = rheologyQuery;

    React.useEffect(() => {
      if (rheologyQueryData && rheologyDataIsFetched && !rheologyData) {
        setRheologyData(rheologyQueryData);
      }
    }, [rheologyQueryData, rheologyData, rheologyDataIsFetched]);

    const projectQuery = useProjectQuery(
      params.entityId,
      {
        enabled: !project,
        cacheTime: 0,
      },
    );

    const { data: projectQueryData, isFetched: projectIsFetched } = projectQuery;

    React.useEffect(() => {
      if (projectQueryData && projectIsFetched && !project) {
        setProject(projectQueryData);
      }
    }, [projectQueryData, project, projectIsFetched]);

    return [rheologyQuery, projectQuery];
  };

  export default useEditRheologyQueries;
