import { ZoneId } from '@js-joda/core';
import { MenuItem } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { PourStatus, ProjectPoursQueryParams } from 'providers/api';
import React from 'react';
import { makeLocalDateUtils, splitCaps } from 'utils';

interface PourTableSearchFormProps {
  searchParams: ProjectPoursQueryParams;
  onSearch: (searchParams: ProjectPoursQueryParams) => void;
}

const pourStatusKeys = Object.keys(PourStatus).filter((k) => typeof PourStatus[k as any] === 'number');

const PourTableSearchForm = ({ searchParams, onSearch }: PourTableSearchFormProps) => {
  const initSearchParams = React.useRef<ProjectPoursQueryParams>(searchParams);

  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <MuiPickersUtilsProvider utils={makeLocalDateUtils(ZoneId.systemDefault())}>
              <Grid item xs={12} sm={4}>
                <DatePicker
                  fullWidth
                  openTo={searchParams.startDate ? 'date' : 'year'}
                  format="dd/MM/yyyy z"
                  label="Start Date"
                  views={['year', 'month', 'date']}
                  value={searchParams.startDate ?? null}
                  maxDate={searchParams.endDate}
                  onChange={(date) => onSearch({ ...searchParams, startDate: date })}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePicker
                  fullWidth
                  openTo={searchParams.endDate ? 'date' : 'year'}
                  format="dd/MM/yyyy"
                  label="End Date"
                  views={['year', 'month', 'date']}
                  value={searchParams.endDate ?? null}
                  minDate={searchParams.startDate}
                  onChange={(date) => onSearch({ ...searchParams, endDate: date })}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                id="pour-status-filter"
                select
                label="Pour Status"
                value={PourStatus[searchParams.status as any] ?? ''}
                onChange={(e) => onSearch({ ...searchParams, status: PourStatus[e.target.value as any] as unknown as PourStatus })}
                variant="outlined"
              >
                <MenuItem value="">None</MenuItem>
                {pourStatusKeys.map((key) => (
                  <MenuItem key={key} value={key}>
                    {splitCaps(key)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" flexDirection="row-reverse">
                <Button variant="contained" onClick={() => onSearch(initSearchParams.current)}>
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PourTableSearchForm;
