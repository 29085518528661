import { PipeDataDto } from 'providers/api';

export const getWetTonnage = (concentrationAsPercentage: number, dryTonnage: number) => dryTonnage / (concentrationAsPercentage / 100);

export const coneSlump = (
  slump: number,
  wetDensity: number,
  yieldStress: number,
) => (
  0.3 - (((2 * yieldStress) / (0.66 * wetDensity * 9.81)) * Math.log(7 / (((1.102 - 0.34 * slump) ** 3) - 1)))
);

export const cylinderSlump = (
  cylinderHeight: number,
  wetDensity: number,
  yieldStress: number,
) => (cylinderHeight - (((2 * yieldStress) / (wetDensity * 9.81)) * (1 - Math.log((2 * yieldStress) / (wetDensity * 9.81 * cylinderHeight)))));

export const batchMixerPower = (
  slump: number,
  wetTonnage: number,
  batchMixerCoefficientM: number,
  batchMixerCoefficientN: number,
  batchMixerCoefficientD: number,
) => (batchMixerCoefficientM * slump + (batchMixerCoefficientN * wetTonnage + batchMixerCoefficientD));

export const continuousMixerPower = (
  slump: number,
  wetTonnage: number,
  continuousMixerCoefficientM: number,
  continuousMixerCoefficientC: number,
  continuousMixerCoefficientN: number,
  continuousMixerCoefficientD: number,
) => ((continuousMixerCoefficientM * slump + continuousMixerCoefficientC) * wetTonnage + (continuousMixerCoefficientN * slump + continuousMixerCoefficientD));

export const getWetFlowrate = (specificGravity: number, dryTonnage: number, wetTonnage: number) => {
  const waterDensity = 1;
  const waterTonnage = wetTonnage - dryTonnage;
  const waterFlow = waterTonnage * (waterDensity);
  const solidsFlow = dryTonnage / (specificGravity / 1000);
  return solidsFlow + waterFlow; // paste flow
};

export const getWetDensity = (wetTonnage: number, wetFlowrate: number) => 1000 * (wetTonnage / wetFlowrate);

export const calculateYieldfromConcentrationByMass = (
  concentrationAsPercentage: number,
  yieldStressA: number,
  yieldStressB: number,
) => yieldStressA * (concentrationAsPercentage / 100) ** yieldStressB;

export const calculateViscosityfromConcentrationByMass = (
  concentrationAsPercentage: number,
  plasticViscosityC: number,
  plasticViscosityD: number,
) => 0.001005 + (plasticViscosityC * (concentrationAsPercentage / 100) ** plasticViscosityD); // 0.001005 is water viscosity at 20 degrees

// in meters
export const calculateSlump = (yieldStress: number, wetDensity: number, cylinderHeight?: number) => {
  // If a cylinder height is provided, use the cylinder slump calculation
  if (cylinderHeight) {
    return cylinderSlump(cylinderHeight, wetDensity, yieldStress);
  }

  let lowx = 0;
  let midx = 0.15;
  let highx = 0.299; // prevents infinite
  let count = 0;
  let calculatedSlump = coneSlump(midx, wetDensity, yieldStress);
  while (Math.abs(midx - calculatedSlump) > 0.0001) {
    calculatedSlump = coneSlump(midx, wetDensity, yieldStress);
    if (calculatedSlump > midx) { // expand
      lowx = midx;
      midx = (highx + lowx) / 2;
    } else { // contract
      highx = midx;
      midx = (highx + lowx) / 2;
    }
    count += 1;
    if (count > 100) break;
  }
  return calculatedSlump;
};

export const getMaxPipePressure = (pipeData: PipeDataDto[]) => pipeData.reduce(
  (min: number | null, current: PipeDataDto) => {
    if (!min) return current.maximumVelocity;
    if (current.maximumVelocity < min) return current.maximumVelocity;
    return min;
  },
  null,
);

export const BINDER_TAILINGS_MARKS = [
  {
    value: 1 / 100,
  },
  {
    value: 1 / 50,
  },
  {
    value: 1 / 40,
  },
  {
    value: 1 / 30,
  },
  {
    value: 1 / 20,
  },
  {
    value: 1 / 18,
  },
  {
    value: 1 / 16,
  },
  {
    value: 1 / 15,
  },
  {
    value: 1 / 14,
  },
  {
    value: 1 / 13,
  },
  {
    value: 1 / 12,
  },
  {
    value: 1 / 11,
  },
  {
    value: 1 / 10,
  },
];

export const resolveWetTonnageFeedRate = (massConcentration?: number, solidsThroughput?: number) => {
  if (!massConcentration || !solidsThroughput) return undefined;
  return getWetTonnage(massConcentration, solidsThroughput);
};

export const resolveWetFlowRate = (specificGravity?: number, solidsThroughput?: number, slurryFeedRate?: number) => {
  if (!specificGravity || !slurryFeedRate || !solidsThroughput) return undefined;
  return getWetFlowrate(specificGravity, solidsThroughput, slurryFeedRate);
};

export const resolveSlurryDensity = (slurryFeedRate?: number, wetFlowRate?: number) => {
  if (!slurryFeedRate || !wetFlowRate) return undefined;
  return getWetDensity(slurryFeedRate, wetFlowRate);
};

export const resolveYieldStress = (yieldStressA?: number, yieldStressB?: number, massConcentration?: number) => {
  if (!yieldStressA || !yieldStressB || !massConcentration) return undefined;
  return calculateYieldfromConcentrationByMass(massConcentration, yieldStressA, yieldStressB);
};

export const resolveSlump = (yieldStress?: number, slurryDensity?: number, heightOfCylinder?: number) => {
  if (!yieldStress || !slurryDensity) return undefined; // heightOfCylinder can be undefined
  // calculateSlump is in meters - display is in mm
  return calculateSlump(yieldStress, slurryDensity, heightOfCylinder) * 1000;
};

export const pythagoras = (adjacent: number, opposite: number) => ((adjacent ** 2) + (opposite ** 2)) ** 0.5;
