import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#009DDC',
    },
    secondary: {
      main: '#242020',
    },
    error: {
      main: '#F88078',
    },
    warning: {
      main: '#FF9800',
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 0,
        paddingRight: 0,
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiButton: {
      sizeLarge: {
        padding: '11px 22px', // Matches height of icon button
      },
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
  },
});

export default theme;
