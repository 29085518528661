import makePage from 'modules/makePage';
import Page from 'modules/Page';
import * as parentConstants from 'modules/user-admin/UsersAreaPage/constants';
import * as constants from './constants';
import NewUserPage from './NewUserPage';

const page: Page = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: true,
  component: makePage({
    WrappedPage: NewUserPage,
    titleFn: () => constants.TITLE,
    parentPathTemplate: parentConstants.ROUTE_PATH,
    parentTitleFn: () => parentConstants.TITLE,
  }),
};

export default page;
