import Container from '@material-ui/core/Container';
import ProjectForm from 'modules/project-admin/ProjectForm';
import * as ProjectsPageConstants from 'modules/project-admin/ProjectsPage/constants';
import { useCreateProjectMutation } from 'providers/api';
import { useHistory } from 'react-router-dom';

const NewProjectPage = () => {
  const history = useHistory();
  const mutation = useCreateProjectMutation({
    onSuccess: () => {
      history.push(ProjectsPageConstants.ROUTE_PATH);
    },
  });

  return (
    <Container maxWidth="lg">
      <ProjectForm mutation={mutation} />
    </Container>
  );
};

export default NewProjectPage;
