import { capitalize } from 'utils';
import {
  PagedListOfUserDto,
  ProjectDisclaimer,
  UpdateDisclaimerOneDayCommand,
  UpdateDisclaimerSevenDaysCommand,
  UpdateUserCommand, UpdateUserDto,
  UpdateUserTeamRolesCommand
} from './api';
import {
  PageParams,
  makeGetQuery,
  makeListQuery,
  makeUpdateMutation
} from './useApi';

const USERS_KEY = 'users';
const DISCLAIMER_KEY = 'disclaimer';
export interface UsersQueryParams extends PageParams {
  searchTerm?: string | null;
  clientId?: string | null;
  teamId?: string | null;
}

export const useUsersQuery = makeListQuery<UsersQueryParams, PagedListOfUserDto>(
  USERS_KEY,
  (api, params) => () => api.users.listUsers(params.page, params.pageSize, params.clientId, params.teamId, params.searchTerm),
);

const USER_KEY = 'user';
export const useUserQuery = makeGetQuery<UpdateUserDto, string>(
  USER_KEY,
  (api, userId) => () => api.users.getUserDetail(userId),
);

export const useDisclaimerQuery = makeGetQuery<ProjectDisclaimer, string>(
  USER_KEY,
  (api, projectId) => () => api.users.getDisclaimerStatus(projectId),
);

export const useUpdateUserMutation = makeUpdateMutation<void, UpdateUserCommand>(
  USER_KEY, USERS_KEY,
  (api, userId) => (user) => api.users.updateUser(userId, user),
  (mutation) => `Info successfully updated for ${capitalize(mutation.forename)} ${capitalize(mutation.surname)}`,
);

export const useAcceptDisclaimerSevenDaysMutation = makeUpdateMutation<void, UpdateDisclaimerSevenDaysCommand>(
  DISCLAIMER_KEY, null,
  (api, userId) => (command) => api.users.updateDisclaimerSevenDays(userId, command),
  () => 'Disclaimer Accepted',
);

export const useAcceptDisclaimerOneDayMutation = makeUpdateMutation<void, UpdateDisclaimerOneDayCommand>(
  DISCLAIMER_KEY, null,
  (api, userId) => (command) => api.users.updateDisclaimerOneDay(userId, command),
  () => 'Disclaimer Accepted',
);

export const useUpdateUserRolesMutation = makeUpdateMutation<void, UpdateUserTeamRolesCommand>(
  USER_KEY, USERS_KEY,
  (api, userId) => (roles) => api.users.updateUserTeamsRoles(userId, roles),
  () => 'Permissions successfully updated',
);
