import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import KeyValuePair from 'components/KeyValuePair';
import { ReactNode } from 'react';

export type KeyValuePairListProps = {
  pairs: {
    key: string,
    values: ReactNode[]
  }[]
};

const KeyValuePairList = ({ pairs }: KeyValuePairListProps) => (
  <List>
    {pairs.map((keyValuePair) => (
      <ListItem key={keyValuePair.key}>
        <ListItemText primary={<KeyValuePair display={keyValuePair.key} values={keyValuePair.values} />} />
      </ListItem>
    ))}
  </List>
);

export default KeyValuePairList;
