import {
  DataGrid,
  GridCellParams,
  GridColDef
} from '@material-ui/data-grid/';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TableActions, { TableAction } from 'components/TableActions';
import { PourNoteApprovalDto } from 'providers/api';

interface NewApproversTableProps {
  data: PourNoteApprovalDto[];
  hideActions: boolean;
  onRemoveApprover: (email: string) => void
}

const NewApproversTable = ({ data, hideActions, onRemoveApprover }: NewApproversTableProps) => {
  const tableActions: TableAction[] = [{
    name: 'delete',
    icon: <DeleteForeverIcon />,
    action: (row) => onRemoveApprover(row.email),
  }];

  const newApproversColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 1,
    },
    {
      headerName: '',
      align: 'center',
      field: '',
      editable: false,
      width: 80,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => row && !hideActions && (<TableActions row={row} actions={tableActions} />),
    },
  ];

  return (
    <DataGrid
      autoHeight
      disableSelectionOnClick
      disableColumnMenu
      hideFooter
      rows={data.map((approver, index) => ({ name: approver.name, email: approver.email, id: index }))}
      columns={newApproversColumns}
    />
  );
};

export default NewApproversTable;
