import ProgressButton from 'components/ProgressButton';
import React from 'react';

interface ImageUploadProps {
  label: string;
  Icon?: JSX.Element;
  fullwidth?: boolean;
  uploading?: boolean;
  disabled?: boolean;
  onNewImage: (image: File) => void;
}

const ImageUpload = ({ label, Icon, fullwidth, uploading, disabled, onNewImage }: ImageUploadProps) => {
  const handleFileChange = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0) {
      onNewImage(fileList[0]);
    }
  };

  return (
    <ProgressButton
      loading={uploading ?? false}
      variant="contained"
      color="secondary"
      fullWidth={fullwidth}
      size="small"
      startIcon={Icon}
      isLabel
      disabled={disabled}
    >
      {label}
      <input
        id="data-upload"
        name="file"
        type="file"
        accept="image/*,.pdf"
        onChange={(event) => handleFileChange(event.target.files)}
        hidden
      />
    </ProgressButton>
  );
};

export default ImageUpload;
