import { round } from 'utils';
import LineGraph from './LineGraph';
import { RecipeState, RoutePoint } from '../../typesInterfaces';
import PCRoute from '../../DiagraExtended/PCRoute';

function convertkPaToM(rp: RoutePoint, pressure: number | undefined, density: number | undefined) {
  if (typeof pressure !== 'undefined' && typeof density !== 'undefined') return round((pressure * 1000) / (9.81 * density), 0); // density in kg/m^3
  console.log('error: no pressure on routepoint', rp);
  return 0;
}

export default function updateHGL(_hgl: LineGraph, selectedRoute: PCRoute | undefined, recipeState: RecipeState) {
  const hgl = _hgl;
  const { wetDensity } = recipeState;
  if (selectedRoute) {
    const elevationArray: number[] = [];
    const distanceArray: number[] = [];
    const pressureArray: number[] = [];
    const ratingArray: number[] = [];
    const { routePoints } = selectedRoute;
    let ratingToInM: number;
    let ratingFromInM: number;
    routePoints.forEach((rp) => {
      const pressureInM = convertkPaToM(rp, rp.pressure, wetDensity);
      if (rp.pipeUpstream) ratingToInM = convertkPaToM(rp, rp.pipeUpstream.pressureRating, wetDensity);
      if (rp.pipeDownstream) ratingFromInM = convertkPaToM(rp, rp.pipeDownstream.pressureRating, wetDensity);
      const elevation = rp.point.coords.z;
      const distance = rp.distanceFromPump;
      if (typeof distance === 'undefined') console.log('error: distance value of routepoint undefined');
      else {
        if (rp.pipeDownstream) {
          distanceArray.push(distance);
          elevationArray.push(elevation);
          pressureArray.push(pressureInM + elevation); // kPa to m
          ratingArray.push(ratingFromInM + elevation);
        }
        if (rp.pipeUpstream) {
          distanceArray.push(distance);
          elevationArray.push(elevation);
          pressureArray.push(pressureInM + elevation); // kPa to m
          ratingArray.push(ratingToInM + elevation);
        }
      }
    });
    hgl.data = [distanceArray, elevationArray, pressureArray, ratingArray];
    hgl.refresh();
  } else {
    hgl.data = [];
    hgl.refresh();
  }
}
