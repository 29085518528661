import { LocalDateTime } from '@js-joda/core';
import { PourForm } from 'providers/api';
import {
  array,
  number,
  object,
  SchemaOf,
  string
} from 'yup';

export interface PourFormInput extends Omit<PourForm, 'plannedDate'> {
  plannedDate: LocalDateTime | null;
}

const generatePourSchema = (numberOfWarnings: number, minDate: LocalDateTime): SchemaOf<PourFormInput> => object().shape({
  reference: string().required('A pour reference is required'),
  plannedDate: object()
    .shape({})
    .required('A planned pour date is required')
    .nullable()
    .test(
      'date-valid',
      'Pour date cannot be in the past',
      (value: LocalDateTime) => value && (value.isEqual(minDate) || value.isAfter(minDate)),
    ),
  volume: number().typeError('Volume must be a number').required().moreThan(0, 'Volume must be greater than zero'),
  volumeRetainedAfterOutflow: number().typeError('Volume retained after outflow must be a number').max(100).moreThan(0),
  acceptedWarnings: array().min(numberOfWarnings, 'You must accept all pour warnings before creating the').required('You must accept all pour warnings before creating the'),
});

export const pourSchemaInitialValues = (): PourFormInput => ({
  reference: '',
  volume: 0,
  plannedDate: null,
  acceptedWarnings: [],
});

export default generatePourSchema;
