import { SpoolJSON } from '../Diagra/types';
import PCNode from '../DiagraExtended/PCNode';
import PCPipe from '../DiagraExtended/PCPipe';
import PCSpool from '../DiagraExtended/PCSpool';

export default function setupSpools(nodes: Record<string, PCNode>, pipes: Record<string, PCPipe>, spools: Record<string, SpoolJSON>) {
  const spoolObjs: Record<string, PCSpool> = {};
  Object.values(nodes).forEach((node) => {
    const currentId = node.pointId;
    const { previousId, pipeId } = node;
    if (previousId && currentId !== previousId) {
      const id = `${previousId}-${currentId}`;
      const spoolJson: SpoolJSON = {
        spoolId: `${previousId}-${currentId}`,
        pointAId: previousId,
        pointBId: currentId,
        pipeId,
        loopOrChoke: node.chokeLength,
      };
      // pass in nodes on spool creation in case the nodes' previous node obj not set
      // the spool class has a method to set it.
      // Useful in case node class changed to remove previous.
      const newSpool = new PCSpool(spoolJson, nodes, pipes, true, false);
      spoolObjs[id] = newSpool;
    }
  });
  Object.values(spools).forEach((spool) => {
    const json = {
      spoolId: spool.spoolId,
      pointAId: spool.nodeAId,
      pointBId: spool.nodeBId,
      pipeId: spool.pipeId,
      loopOrChoke: spool.chokeLength,
    };
    const AB = spool.allowFlowAB || false;
    const BA = spool.allowFlowBA || false;
    const newSpool = new PCSpool(json as SpoolJSON, nodes, pipes, AB, BA);
    spoolObjs[spool.spoolId] = newSpool;
  });
  console.log('final spool list', spoolObjs);
  // additional spools for multiple routes
  // join node 536 to 464
  // const extraSpoolJSON = {
  //   spoolId: '536-464',
  //   pointAId: '536',
  //   pointBId: '464',
  //   pipeId: '2',
  // };
  // INFO: not being used
  // const extraSpool = new PCSpool(extraSpoolJSON, nodes, pipes, true, false);
  // const extraSpoolJSON2 = {
  //   spoolId: '130-536',
  //   pointAId: '130',
  //   pointBId: '536',
  //   pipeId: '2',
  // };
  // INFO: Not being used
  // const extraSpool2 = new PCSpool(extraSpoolJSON2, nodes, pipes, true, false);
  // spoolObjs[spoolCount] = extraSpool;
  // spoolObjs[spoolCount + 1] = extraSpool2;
  return spoolObjs;
}
