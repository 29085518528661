export interface PObjectParams {
  cvs?: any; // I think this is unused but support to be canvas?
  desc?: string;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  colour?: string;
  transp?: number;
  font?: string;
  fun?: () => void;
}

export interface Position {
  x: number;
  y: number;
}

export default class PObject {
  cvs: any; // I think this is unused but suppose to be canvas?

  desc: string;

  width: number;

  height: number;

  x: number;

  y: number;

  colour: string;

  transp: number;

  font: string;

  isMouseOver: boolean;

  dragging: boolean;

  to?: PObject;

  fun: (pos: Position | any, action: string, o: PObject) => void;

  constructor(params: PObjectParams) {
    this.cvs = params.cvs || undefined;
    this.desc = params.desc || '';
    this.width = params.width || 0;
    this.height = params.height || 0;
    this.x = params.x || 0;
    this.y = params.y || 0;
    this.colour = params.colour || '#fff';
    this.transp = params.transp || 0;
    this.font = params.font || 'courier';
    this.fun = params.fun ? params.fun : () => { };
    this.isMouseOver = false;
    this.dragging = false;
  }

  public isInside(pos: Position) {
    // checks if mouse inside the canvas
    return pos.x > this.x && pos.x < this.x + this.width && pos.y < this.y + this.height && pos.y > this.y;
  }

  public action(pos: Position | any, act: string) {
    if (act === 'mouseMove') {
      this.isMouseOver = this.isInside(pos);
      this.fun(pos, act, this);
    } else if (act === 'mouseDown' && this.isMouseOver) {
      this.fun(pos, act, this);
    } else if (act === 'mouseUp') {
      this.dragging = false;
      this.fun(pos, act, this);
    } else if (this.fun) {
      this.fun(pos, act, this);
    }
  }

  public receive(data: any) {
    this.action(data, 'update');
  }

  public send(data: any) {
    if (this.to) {
      this.to.receive(data);
    }
  }
}
