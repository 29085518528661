import Box from '@material-ui/core/Box';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  bufferCircle: {
    '& svg.MuiCircularProgress-svg': {
      color: `${lighten(theme.palette.primary.main, 0.8)} !important`,
    },
  },
}));

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number, buffer?: number }) => {
  const { value, buffer } = props;
  const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
      >
        <CircularProgress variant="determinate" {...props} color="secondary" value={100} />
      </Box>

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
      >
        <CircularProgress className={classes.bufferCircle} variant="determinate" {...props} value={buffer || 0} />
      </Box>

      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="span" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
