import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  createStyles,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Address from 'components/Address';
import GoogleMapEmbed from 'components/GoogleMapEmbed';
import Initials from 'components/Initials';
import { FillType, ProjectDto, ThroughputControlType } from 'providers/api';
import { getFlagFromCountryName } from 'utils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  projectDetailsCard: {
    position: 'relative',
    minHeight: 450,
    '& .MuiCardContent-root': {
      margin: 0,
      padding: 0,
    },
  },
  mapContainer: {
    height: '100%',
  },
  map: {
    position: 'absolute',
    minHeight: '100%',
    minWidth: '100%',
    zIndex: 0,
  },
  media: {
    paddingTop: '56.25%',
    paddingLeft: '56.25%',
    position: 'relative',
    backgroundColor: theme.palette.grey[700],
  },
}));

interface ProjectDetailsCardProps {
  project: ProjectDto;
  handleEditProjectDetails: () => void;
}

const ProjectDetailsCard = ({ project, handleEditProjectDetails }: ProjectDetailsCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card className={classes.projectDetailsCard}>
      <CardHeader
        action={(
          <IconButton
            onClick={handleEditProjectDetails}
            aria-label="Edit project details"
          >
            <EditIcon />
          </IconButton>
        )}
        title={project.name}
        subheader={project.client.name}
      />
      <CardContent>
        <Grid container>
          <Grid className={classes.mapContainer} item xs={12} sm={12} md={4}>
            <CardMedia
              className={classes.media}
              image={project.image?.sizes.thumb}
              title={project.name}
            >
              { !project.image?.sizes.thumb && <Initials projectName={project.name} /> }
            </CardMedia>
            <CardContent>
              <Box mx={2} mb={2}>
                <List dense aria-label="project details box">
                  { project.siteAddress && (
                    <>
                      <ListSubheader>Address</ListSubheader>
                      <Divider />
                      <ListItem>
                        <Address
                          type="inline"
                          address={project.siteAddress}
                        />
                      </ListItem>
                    </>
                  )}

                  <ListSubheader>Location</ListSubheader>
                  <Divider />
                  <ListItem>
                    <ListItemText primary={`${project.siteLocation.latitude}, ${project.siteLocation.longitude}`} />
                  </ListItem>
                  <ListSubheader>Time Zone</ListSubheader>
                  <Divider />
                  <ListItem>
                    <ListItemText primary={`${getFlagFromCountryName(project.timeZone ?? '')} ${project.timeZone}`} />
                  </ListItem>
                  <ListSubheader>Billing Details</ListSubheader>
                  <Divider />
                  <ListItem>
                    <ListItemText primary={<Link href={`mailto:${project.billingEmail}`}>{project.billingEmail}</Link>} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Contract No. ${project.contractNumber}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`PO No. ${project.poNumber}`} />
                  </ListItem>
                  <ListItem>
                    { project.billingAddress && (
                      <Address
                        type="inline"
                        address={project.billingAddress}
                      />
                    )}
                  </ListItem>
                  <ListSubheader>Project Type Details</ListSubheader>
                  <Divider />
                  <ListItem>
                    <ListItemText primary={`Fill Type: ${FillType[project.fillType] ?? ''}`} />
                    <ListItemText primary={`Throughput Type: ${ThroughputControlType[project.throughput] ?? ''}`} />
                  </ListItem>
                </List>
              </Box>
            </CardContent>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            {!matches && project && (
            <GoogleMapEmbed
              lat={project.siteLocation.latitude}
              lng={project.siteLocation.longitude}
              container={classes.map}
            />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProjectDetailsCard;
