import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import {
  FillType,
  PourDataDto,
  UnitSystem,
  useCreatePourDataMutation,
  useUpdatePourDataMutation
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import React from 'react';
import CompletionForm from './CompletionForm';
import CompletionSummary from './CompletionSummary';

interface ApproveAndCompleteAreaProps {
  pourId: string;
  pourData?: PourDataDto;
  unitSystemPreference: UnitSystem;
  fillType: FillType;
}

const ApproveAndCompleteArea = ({
  pourId,
  pourData,
  unitSystemPreference,
  fillType,
}: ApproveAndCompleteAreaProps) => {
  const [editing, setEditing] = React.useState(false);
  const mutation = pourData ? useUpdatePourDataMutation(pourId, { onSuccess: () => setEditing(false) }) : useCreatePourDataMutation(pourId);

  if (!pourData || editing) {
    return (
      <CompletionForm
        pourId={pourId}
        pourData={pourData}
        mutation={mutation}
        closeForm={() => setEditing(false)}
        unitSystemPreference={unitSystemPreference}
        fillType={fillType}
      />
    );
  }

  return (
    <>
      <CompletionSummary pourId={pourId} pourData={pourData} unitSystemPreference={unitSystemPreference} fillType={fillType} />
      <ProjectAuthorise permission={Permission.ManagePours}>
        <Box display="flex" flexDirection="row-reverse" mt={2}>
          <Button variant="contained" color="secondary" onClick={() => setEditing(true)}>Update Completion Details</Button>
        </Box>
      </ProjectAuthorise>
    </>
  );
};

export default ApproveAndCompleteArea;
