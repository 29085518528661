import GuiState from '../GUI/GuiState';

  // HGL
export default function plotHGL(guiState: GuiState) {
  const { modelState: { hglData } } = guiState;
  const { unitSystemPreference, modelContext, hglCanvas } = guiState;
  const ctx = hglCanvas.getContext('2d');
  if (ctx && modelContext) {
    ctx.clearRect(0, 0, hglCanvas.width, hglCanvas.height);
    ctx.globalAlpha = 1;
    hglData.plot(ctx, unitSystemPreference);
    modelContext.drawImage(hglCanvas, hglData.x, hglData.y);
  }
}

// export function clearPlots(graph: LineGraph) {
//   const hglData = graph;
//   hglData.data = hglData.data.slice(0, 2);
//   hglData.refresh();
// }
