import Container from '@material-ui/core/Container';
import * as UsersPageConstants from 'modules/user-admin/UsersAreaPage/constants';
import { useCreateInviteMutation } from 'providers/api';
import { useHistory } from 'react-router-dom';
import UserInviteForm from './UserInviteForm';

const NewUserPage = () => {
  const history = useHistory();
  const mutation = useCreateInviteMutation({
    onSuccess: () => {
      history.push(UsersPageConstants.INVITATION_ROUTE_PATH);
    },
  });

  return (
    <Container maxWidth="lg">
      <UserInviteForm mutation={mutation} />
    </Container>
  );
};

export default NewUserPage;
