/* eslint-disable */
const regex = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;

function validate(uuid) {
  return typeof uuid === 'string' && regex.test(uuid);
}
const byteToHex = [];
for (let i = 0; i < 256; i += 1) {
  byteToHex.push((i + 0x100).toString(16).substr(1));
}

function stringify(arr, offset = 0) {
  // Note: Be careful editing this code!  It's been tuned for performance
  // and works in ways you may not expect. See https://github.com/uuidjs/uuid/pull/434
  const uuid = (byteToHex[arr[offset + 0]] + byteToHex[arr[offset + 1]] + byteToHex[arr[offset + 2]]
  + byteToHex[arr[offset + 3]] + '-' + byteToHex[arr[offset + 4]] + byteToHex[arr[offset + 5]] + '-'
  + byteToHex[arr[offset + 6]] + byteToHex[arr[offset + 7]] + '-' + byteToHex[arr[offset + 8]]
  + byteToHex[arr[offset + 9]] + '-' + byteToHex[arr[offset + 10]] + byteToHex[arr[offset + 11]]
  + byteToHex[arr[offset + 12]] + byteToHex[arr[offset + 13]] + byteToHex[arr[offset + 14]]
  + byteToHex[arr[offset + 15]]).toLowerCase();
  // Consistency check for valid UUID.  If this throws, it's likely due to one
  // of the following:
  // - One or more input array values don't map to a hex octet (leading to
  // "undefined" in the uuid)
  // - Invalid input values for the RFC `version` or `variant` fields

  if (!(0, validate(uuid))) {
    throw TypeError('Stringified UUID is invalid');
  }

  return uuid;
}

const rnds8 = new Uint8Array(16);
function rng() {
  // lazy load so that environments that need to polyfill have a chance to do so
  const getRandomValues = typeof crypto !== 'undefined' && crypto.getRandomValues && crypto.getRandomValues.bind(crypto) || typeof msCrypto !== 'undefined' && typeof msCrypto.getRandomValues === 'function' && msCrypto.getRandomValues.bind(msCrypto);

  return getRandomValues(rnds8);
}

function uuidv4(options, buf, offset) {
  //console.log('uuidv4', options, buf, offset);

  const rnds = rng(); // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
  rnds[6] = rnds[6] & 0x0f | 0x40;
  rnds[8] = rnds[8] & 0x3f | 0x80; // Copy bytes to buffer, if provided

  if (buf) {
    offset = offset || 0;

    for (let i = 0; i < 16; ++i) {
      buf[offset + i] = rnds[i];
    }

    return buf;
  }
  return (0, stringify(rnds));
}

/**
 * Converter
 *
 * @param {string|Array} srcAlphabet
 * @param {string|Array} dstAlphabet
 * @constructor
 */
function Converter(srcAlphabet, dstAlphabet) {
  if (!srcAlphabet || !dstAlphabet || !srcAlphabet.length || !dstAlphabet.length) {
    throw new Error('Bad alphabet');
  }
  this.srcAlphabet = srcAlphabet;
  this.dstAlphabet = dstAlphabet;
}

/**
 * Convert number from source alphabet to destination alphabet
 *
 * @param {string|Array} number - number represented as a string or array of points
 *
 * @returns {string|Array}
 */
Converter.prototype.convert = function(number) {
    var i, divide, newlen,
    numberMap = {},
    fromBase = this.srcAlphabet.length,
    toBase = this.dstAlphabet.length,
    length = number.length,
    result = typeof number === 'string' ? '' : [];

    if (!this.isValid(number)) {
        throw new Error('Number "' + number + '" contains of non-alphabetic digits (' + this.srcAlphabet + ')');
    }

    if (this.srcAlphabet === this.dstAlphabet) {
        return number;
    }

    for (i = 0; i < length; i++) {
        numberMap[i] = this.srcAlphabet.indexOf(number[i]);
    }
    do {
        divide = 0;
        newlen = 0;
        for (i = 0; i < length; i++) {
            divide = divide * fromBase + numberMap[i];
            if (divide >= toBase) {
                numberMap[newlen++] = parseInt(divide / toBase, 10);
                divide = divide % toBase;
            } else if (newlen > 0) {
                numberMap[newlen++] = 0;
            }
        }
        length = newlen;
        result = this.dstAlphabet.slice(divide, divide + 1).concat(result);
    } while (newlen !== 0);

    return result;
};

/**
 * Valid number with source alphabet
 *
 * @param {number} number
 *
 * @returns {boolean}
 */
Converter.prototype.isValid = function(number) {
    var i = 0;
    for (; i < number.length; ++i) {
        if (this.srcAlphabet.indexOf(number[i]) === -1) {
            return false;
        }
    }
    return true;
};

/**
 * Function get source and destination alphabet and return convert function
 *
 * @param {string|Array} srcAlphabet
 * @param {string|Array} dstAlphabet
 *
 * @returns {function(number|Array)}
 */
function anyBase(srcAlphabet, dstAlphabet) {
    var converter = new Converter(srcAlphabet, dstAlphabet);
    /**
     * Convert function
     *
     * @param {string|Array} number
     *
     * @return {string|Array} number
     */
    return function (number) {
        return converter.convert(number);
    }
};

anyBase.BIN = '01';
anyBase.OCT = '01234567';
anyBase.DEC = '0123456789';
anyBase.HEX = '0123456789abcdef';

const shortenUUID = (longId, translator, paddingParams) => {
  // console.log('shorten', longId, translator, paddingParams);
  const translated = translator(longId.toLowerCase().replace(/-/g, ''));

  if (!paddingParams || !paddingParams.consistentLength) return translated;

  return translated.padStart(
    paddingParams.shortIdLength,
    paddingParams.paddingChar,
  );
};

const baseOptions = {
  consistentLength: true,
};

const flickrBase58 = '123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';

// Calculate length for the shortened ID
const getShortIdLength = (alphabetLength) => (
  Math.ceil(Math.log(2 ** 128) / Math.log(alphabetLength)));

const makeConvertor = (toAlphabet, options) => {
  // Default to Flickr 58
  const useAlphabet = toAlphabet || flickrBase58;

  // Default to baseOptions
  const selectedOptions = { ...baseOptions, ...options };

  // Check alphabet for duplicate entries
  if ([...new Set(Array.from(useAlphabet))].length !== useAlphabet.length) {
    throw new Error('The provided Alphabet has duplicate characters resulting in unreliable results');
  }

  const shortIdLength = getShortIdLength(useAlphabet.length);

  // Padding Params
  const paddingParams = {
    shortIdLength,
    consistentLength: selectedOptions.consistentLength,
    paddingChar: useAlphabet[0],
  };

  // UUIDs are in hex, so we translate to and from.
  const fromHex = anyBase(anyBase.HEX, useAlphabet);
  const toHex = anyBase(useAlphabet, anyBase.HEX);
  const generate = () => shortenUUID(uuidv4(), fromHex, paddingParams);

  const translator = {
    new: generate,
    generate,
    uuid: uuidv4,
    fromUUID: (uuid) => shortenUUID(uuid, fromHex, paddingParams),
    toUUID: (shortUuid) => enlargeUUID(shortUuid, toHex),
    alphabet: useAlphabet,
    maxLength: shortIdLength,
  };

  Object.freeze(translator);

  return translator;
};

// TODO: test this
export default function generateUuid() {
  return (makeConvertor('0123456789abcdefghjkmnpqrstvwxyz').new());
}
