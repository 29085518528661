import { LocalDateTime } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BadgeRow from 'components/BadgeRow';
import { ResponsiveGridColDef } from 'components/DataGrid';
import DataTransactionBox from 'components/DataTransactionBox';
import DataMultipleTransactionBox from 'components/DataTransactionBox/DataMultipleTransactionBox';
import { HOUR_IN_MILLISECONDS } from 'config';
import { BasePageProps } from 'modules/makePage';
import {
  ROUTE_PARAM_IDENTIFIER as EditProjectPageParamIdentifier,
  ROUTE_PATH as EditProjectPageRoute
} from 'modules/project-admin/EditProjectPage/constants';
import {
  ROUTE_PARAM_IDENTIFIER as NewRheologyPageParamIdentifier,
  ROUTE_PATH as NewRheologyPageRoute
} from 'modules/project-admin/NewRheologyPage/constants';
import { RheologyDataSummaryDto, useDeleteRheologyDatasetMutation, useReticulationDataExport } from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { replace } from 'ramda';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { displayTemporal, notNilOrEmpty } from 'utils';
import ProjectDetailsCard from './ProjectDetailsCard';
import RheologyDataSummaryActionMenu from './RheologyDataSummaryActionMenu';
import { ProjectDetailsPageParams } from './constants';
import { ProjectDetailsQueries } from './useProjectDetailsQueries';
import useSaveReticulationData from './useSaveReticulationData';
import useSaveRheologyData from './useSaveRheologyData';

const routePaths: any = {
  details: {
    update: EditProjectPageRoute,
  },
  rheologyData: {
    new: NewRheologyPageRoute,
  },
};

const generateRheologyDataColumns = (
  projectName: string,
  mineModelId: string,
  onRheologyDatasetDelete: (rheologyDatasetMineModelId: string, rheologyDataset: RheologyDataSummaryDto) => void,
): ResponsiveGridColDef[] => ([
  {
    field: 'reference',
    headerName: 'Reference',
    flex: 1,
    resizable: false,
    sortable: false,
    filterable: false,
  },
  {
    field: 'generated',
    headerName: 'Generated',
    flex: 1,
    resizable: false,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => displayTemporal(LocalDateTime.ofInstant(row.generated)),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    resizable: false,
    sortable: false,
    filterable: false,
    width: 110,
    renderCell: ({ row }) => (
      <>
        <RheologyDataSummaryActionMenu
          projectName={projectName}
          rheologyDataSummary={(row as any) as RheologyDataSummaryDto}
          mineModelId={mineModelId}
          onDelete={() => onRheologyDatasetDelete(mineModelId, row as RheologyDataSummaryDto)}
        />
      </>
    ),
  },
]);

const ProjectDetailsPage = ({ queries }: BasePageProps<ProjectDetailsQueries>) => {
  const params = useParams<ProjectDetailsPageParams>();
  const confirm = useConfirmation();
  const [
    projectQuery,
    mineModelQuery,
  ] = queries;
  const project = projectQuery.data;
  const mineModel = mineModelQuery.data;

  const [triggerReticulationDataExport, setTriggerReticulationDataExport] = React.useState(false);

  const reticulationDataExport = useReticulationDataExport(params.entityId, {
    staleTime: HOUR_IN_MILLISECONDS,
    enabled: triggerReticulationDataExport,
    onSettled: () => setTriggerReticulationDataExport(false),
  });

  const deleteRheologyDatasetMutation = useDeleteRheologyDatasetMutation();

  const handleDeleteRheologyDataset = (mineModelId: string, rheologyDataset: RheologyDataSummaryDto) => {
    confirm({
      variant: 'danger',
      description: `Are you sure you want to remove '${rheologyDataset.reference ?? 'this rheology dataset'}'?`,
    }).then(
      () => deleteRheologyDatasetMutation.mutate(
        {
          mineModelId,
          rheologyDatasetId: rheologyDataset.rheologyDataSetId,
          rheologyDatasetReference: rheologyDataset.reference,
        },
      ),
    );
  };

  const history = useHistory();

  const {
    save: saveReticulation,
    errors: reticulationErrors,
    isSubmitting: isSubmittingReticulation,
  } = useSaveReticulationData(
    () => mineModelQuery.refetch(),
    project?.entityId,
    project?.name,
    notNilOrEmpty(mineModel?.reticulationData),
  );

  const {
    errors: rheologyErrors,
    isSubmitting: isSubmittingRheology,
  } = useSaveRheologyData(
    () => mineModelQuery.refetch(),
    project?.entityId,
    project?.name,
  );

  const handleEditProjectData = (type: string, mutation: string) => {
    if (project) {
      history.push(replace(EditProjectPageParamIdentifier, project.entityId, routePaths[type][mutation]));
    }
  };

  const handleEnterRheologyData = () => {
    if (project) {
      history.push(replace(NewRheologyPageParamIdentifier, project.entityId, NewRheologyPageRoute));
    }
  };

  return (
    <Container maxWidth="lg">
      <>
        {project && (
          <>
            <ProjectDetailsCard
              project={project}
              handleEditProjectDetails={() => handleEditProjectData('details', 'update')}
            />
            <Box mt={3}>
              <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} lg={6}>
                  <DataTransactionBox
                    title="Reticulation"
                    fileName={`${project.name}-reticulation-data`}
                    fileData={reticulationDataExport.data}
                    errors={reticulationErrors}
                    disabled={isSubmittingReticulation}
                    handleFileSelect={(data) => saveReticulation(data)}
                    downloadData={() => setTriggerReticulationDataExport(true)}
                  >
                    {mineModel?.reticulationData && (
                      <BadgeRow badgeList={[
                        { text: 'Pipes', total: mineModel.reticulationData.pipes ?? 0 },
                        { text: 'Nodes', total: mineModel.reticulationData.nodes ?? 0 },
                        { text: 'Stopes', total: mineModel.reticulationData.stopesWithData ?? 0 },
                        { text: 'Spools', total: mineModel.reticulationData.spools ?? 0 },
                      ]}
                      />
                    )}
                  </DataTransactionBox>
                </Grid>

                {project && (
                  <Grid item xs={12} lg={6}>
                    <DataMultipleTransactionBox
                      title="Rheology Data Sets"
                      dataList={mineModel?.rheologyDataList ? mineModel?.rheologyDataList.map((dataSet) => ({ ...dataSet, entityId: dataSet.rheologyDataSetId })) : []}
                      dataColumns={generateRheologyDataColumns(project.name, project.entityId, handleDeleteRheologyDataset)}
                      dataListLength={3}
                      errors={rheologyErrors}
                      disabled={isSubmittingRheology}
                      handleEnterRheologyData={handleEnterRheologyData}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </>
        )}
      </>
    </Container>
  );
};

export default ProjectDetailsPage;
