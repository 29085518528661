import Button from '@material-ui/core/Button';
import Actions from 'components/Actions';
import ProgressButton from 'components/ProgressButton';
import { FormikProps } from 'formik';
import { UseMutationResult } from 'react-query';

interface FormikSubmitButtonProps {
  formik: FormikProps<any>;
  text?: string;
  disabledOverride?: boolean;
}

const FormikSubmitButton = ({ formik: { isSubmitting, isValid, dirty }, text = 'Submit', disabledOverride = false }: FormikSubmitButtonProps) => (
  <ProgressButton variant="contained" color="primary" type="submit" disabled={disabledOverride || !isValid || !dirty} loading={isSubmitting}>
    {text}
  </ProgressButton>
);

interface FormikResetButtonProps {
  mutation: UseMutationResult<any, any, any, any>,
  formik: FormikProps<any>,
  text?: string,
}

const FormikResetButton = ({ mutation, formik: { resetForm, isSubmitting, dirty }, text = 'Reset' }: FormikResetButtonProps) => {
  const handleResetForm = () => {
    resetForm();
    mutation.reset();
  };

  return (
    <Button variant="contained" color="secondary" onClick={handleResetForm} disabled={!dirty || isSubmitting}>
      {text}
    </Button>
  );
};

const makeActionFactory = (
  mutation: UseMutationResult<any, any, any, any>,
  formik: FormikProps<any>,
  submitText: string,
  resetText: string,
  submitDisableOverride: boolean,
) => (type: 'submit' | 'reset' | [string, JSX.Element]) => {
  if (type === 'submit') {
    return ['submit', <FormikSubmitButton formik={formik} text={submitText} disabledOverride={submitDisableOverride} />] as [string, JSX.Element];
  }

  if (type === 'reset') {
    return ['reset', <FormikResetButton mutation={mutation} formik={formik} text={resetText} />] as [string, JSX.Element];
  }

  return type;
};

interface FormikActionsProps {
  mutation: UseMutationResult<any, any, any, any>;
  formik: FormikProps<any>;
  left?: ('submit' | 'reset' | [string, JSX.Element])[];
  center?: ('submit' | 'reset' | [string, JSX.Element])[];
  right?: ('submit' | 'reset' | [string, JSX.Element])[];
  submitText?: string;
  resetText?: string;
  submitDisableOverride?: boolean;
}

const FormikActions = ({
  mutation,
  formik,
  left = [],
  right = [],
  center = [],
  submitText = 'Submit',
  resetText = 'Reset',
  submitDisableOverride = false,
}: FormikActionsProps) => {
  const getAction = makeActionFactory(mutation, formik, submitText, resetText, submitDisableOverride);

  const leftActions = left.map(getAction);
  const centerActions = center.map(getAction);
  const rightActions = right.map(getAction);

  return <Actions left={leftActions} center={centerActions} right={rightActions} />;
};

export default FormikActions;
