import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles<Theme, SimpleRowStyleProps>((theme) => createStyles({
  root: ({ spacing, direction }) => (direction === 'left' ? {
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(spacing),
    },
  } : {
    '& > div:not(:last-child)': {
      marginLeft: theme.spacing(spacing),
    },
  }),
}));

interface SimpleRowStyleProps {
  spacing: number;
  direction: 'left' | 'right'
}

interface SimpleRowProps {
  children: [string, JSX.Element][];
  spacing?: number;
  direction?: 'left' | 'right'
}

const SimpleRow = ({ children, spacing = 2, direction = 'left' }: SimpleRowProps) => {
  const classes = useStyles({
    spacing,
    direction,
  });

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      {children.map(([key, Component]) => (<div key={key}>{Component}</div>))}
    </Box>
  );
};

export default SimpleRow;
