import NewPourPage from './NewPourPage';
import PourDetailsPage from './PourDetailsPage';
import ProjectAreaPage from './ProjectAreaPage';
import ProjectsPage from './ProjectsPage';

const projectModule = {
  pages: [
    NewPourPage,
    PourDetailsPage,
    ProjectsPage,
    ProjectAreaPage,
  ],
};

export default projectModule;
