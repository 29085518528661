import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PourNoteApprovalDto } from 'providers/api';
import React from 'react';

interface ApprovalTableFileContextMenuProps {
  pourId: string;
  approver: PourNoteApprovalDto;
  onApproveWithoutEvidence: (email: string, image?: File) => void;
  onApproverUnapprove: (email: string) => void;
}

const ApprovalTableFileContextMenu = ({ pourId, approver, onApproveWithoutEvidence, onApproverUnapprove }: ApprovalTableFileContextMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApproveWithoutEvidence = () => {
    onApproveWithoutEvidence(approver.email);
    setAnchorEl(null);
  };

  const handleApproverUnapprove = () => {
    onApproverUnapprove(approver.email);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`approval-actions-menu-${pourId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          approver.status === 10 && (
            <MenuItem onClick={handleApproveWithoutEvidence}>
              <ListItemIcon>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Approve without Evidence</Typography>
            </MenuItem>
          )
        }
        {
          approver.status === 20 && (
          <MenuItem onClick={handleApproverUnapprove}>
            <ListItemIcon>
              <ClearIcon color="error" fontSize="small" />
            </ListItemIcon>
            <Typography color="error" variant="inherit">Un-Approve</Typography>
          </MenuItem>
          )
        }
      </Menu>
    </>
  );
};

export default ApprovalTableFileContextMenu;
