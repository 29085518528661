import EditProjectLicenceExpiryPage from './EditProjectLicenceExpiryPage';
import EditProjectPage from './EditProjectPage';
import EditRheologyPage from './EditRheologyPage';
import NewProjectPage from './NewProjectPage';
import NewRheologyPage from './NewRheologyPage';
import ProjectDetailsPage from './ProjectDetailsPage';
import ProjectsPage from './ProjectsPage';

const projectAdminModule = {
  pages: [
    ProjectsPage,
    NewProjectPage,
    EditProjectPage,
    ProjectDetailsPage,
    EditProjectLicenceExpiryPage,
    NewRheologyPage,
    EditRheologyPage,
  ],
};

export default projectAdminModule;
