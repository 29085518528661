import BadgeRow from 'components/BadgeRow';
import DataTransactionBox from 'components/DataTransactionBox';
import {
  PourTypeDataDto, ProjectDto, useCreatePourTypesMutation, useUpdatePourTypesMutation
} from 'providers/api';
import React from 'react';
import { UseQueryResult } from 'react-query';

const EMPTY_POUR_TYPE_TEMPLATE = {
  stopeId: '--',
  stopeName: '--',
  pourType: '--',
  ucsStrength: 0,
  curingDays: 0,
  volume: 0,
};

interface PourTypeDataTransactionBoxProps {
  project: ProjectDto;
  pourTypesQuery: UseQueryResult<PourTypeDataDto[], unknown>;
}

const PourTypeDataTransactionBox = ({ project, pourTypesQuery }: PourTypeDataTransactionBoxProps) => {
  const [PourTypesErrors, setPourTypesErrors] = React.useState<string[]>([]);
  const updateMutation = useUpdatePourTypesMutation(project.entityId);
  const createMutation = useCreatePourTypesMutation();

  const { data: pourTypes } = pourTypesQuery;

  const savePourTypesData = (data: any[]) => {
    const create = data.filter((pourType) => !pourType.pourTypeDataId);
    const update = data.filter((pourType) => !!pourType.pourTypeDataId);

    create && create.length > 0 && createMutation.mutate({
      mineModelId: project.entityId,
      command: {
        mineModelId: project.entityId,
        pourTypes: create,
      },
    });

    update && update.length > 0 && updateMutation.mutate({
      mineModelId: project.entityId,
      command: {
        pourTypes: update,
      },
    });
  };

  return (
    <DataTransactionBox
      title="Pour Types"
      errors={PourTypesErrors}
      fileName={`${project.name}-pour-types-data`}
      fileFormat="csv"
      fileData={pourTypes && pourTypes.length > 0 ? pourTypes : [EMPTY_POUR_TYPE_TEMPLATE]}
      handleFileSelect={(data) => {
        setPourTypesErrors([]);
        savePourTypesData(data);
      }}
      csvColParser={{
        stopeId: 'string',
        stopeName: 'string',
        pourType: 'string',
        ucsStrength: 'number',
        curingDays: 'number',
        volume: 'number',
      }}
    >
      <BadgeRow badgeList={[
        { text: 'Pour Types Total', total: pourTypes?.length ?? 0 },
      ]}
      />
    </DataTransactionBox>
  );
};

export default PourTypeDataTransactionBox;
