import Container from '@material-ui/core/Container';
import ClientForm from 'modules/client-admin/ClientForm';
import * as ClientsPageConstants from 'modules/client-admin/ClientsPage/constants';
import { useCreateClientMutation } from 'providers/api';
import { useHistory } from 'react-router-dom';

const NewClientPage = () => {
  const history = useHistory();
  const mutation = useCreateClientMutation({
    onSuccess: () => {
      history.push(ClientsPageConstants.ROUTE_PATH);
    },
  });

  return (
    <Container maxWidth="lg">
      <ClientForm mutation={mutation} />
    </Container>
  );
};

export default NewClientPage;
