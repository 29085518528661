import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  SvgIcon,
  SvgIconProps,
  Theme
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AlertText from 'components/AlertText';
import React, { ReactNode } from 'react';
import { ReactComponent as CsvFileFormatSVG } from './csv-icon.svg';
import DataDownload from './DataDownload';
import DataUpload from './DataUpload';
import { ReactComponent as JsonFileFormatSVG } from './json-icon.svg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    minHeight: 200,
  },
  fileFormatIconContainer: {
    textAlign: 'center',
  },
  fileFormatIcon: {
    fontSize: 120,
    alignText: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  jsonMutationButton: {
    width: '90%',
    minWidth: 80,
    margin: 10,
  },
  orText: {
    textAlign: 'center',
  },
}));

const makeFileFormatIcon = (fileFormat: string) => (props: SvgIconProps) => (
  <SvgIcon {...props} component={fileFormat === 'csv' ? CsvFileFormatSVG : JsonFileFormatSVG} viewBox="0 0 58 58" />
);

const OrDivider = () => (
  <Grid container alignItems="center">
    <Grid item xs={5}>
      <Divider />
    </Grid>
    <Grid item xs={2}>
      <Box textAlign="center">OR</Box>
    </Grid>
    <Grid item xs={5}>
      <Divider />
    </Grid>
  </Grid>
);

interface DataTransactionProps<T> {
  title?: string;
  fileName: string;
  fileData?: T;
  errors: string[];
  disabled?: boolean,
  handleFileSelect: (fileData: T) => void;
  children?: ReactNode;
  fileFormat?: string;
  csvColParser?: { [column: string]: string }
  downloadData?: () => void;
}

function DataTransactionBox<T extends any>(
  {
    title,
    fileData,
    fileName = 'download',
    errors,
    disabled = false,
    handleFileSelect,
    children,
    fileFormat = 'json',
    csvColParser,
    downloadData,
  }: DataTransactionProps<T>,
) {
  const FileFormatIcon = makeFileFormatIcon(fileFormat);
  const classes = useStyles();
  const [internalErrors, setInternalErrors] = React.useState<string[]>([]);
  return (
    <Card>
      {title && <CardHeader title={title} />}
      <CardContent>
        <Grid className={classes.card} container spacing={1} direction="row">
          <Grid item container xs={12} sm={3} justify="center" alignItems="center">
            <FileFormatIcon className={classes.fileFormatIcon} />
          </Grid>
          <Grid item container xs={12} sm={9} direction="column" justify="center" alignItems="center">
            <Box>{children}</Box>
            <DataDownload
              data={fileData}
              fileName={`${fileName}.${fileFormat}`}
              fileFormat={fileFormat}
              buttonValue="Download Data"
              buttonProps={{
                disabled,
                variant: 'contained',
                className: classes.jsonMutationButton,
                color: 'primary',
                startIcon: <CloudDownloadIcon />,
              }}
              downloadData={downloadData}
            />
            <OrDivider />
            <DataUpload
              fileFormat={fileFormat}
              buttonValue={`Upload ${fileData ? 'New Version' : 'New Data'}`}
              buttonProps={{
                disabled,
                variant: 'contained',
                className: classes.jsonMutationButton,
                color: 'primary',
                startIcon: <CloudUploadIcon />,
              }}
              onSelect={(selectedFileDate) => {
                setInternalErrors([]);
                handleFileSelect(selectedFileDate);
              }}
              onError={(error) => setInternalErrors((existingInternalErrors) => [...existingInternalErrors, error])}
              csvColParser={csvColParser}
            />
            {
              errors && (
                errors.map((error) => (
                  <AlertText key={error} severity="error">
                    {error}
                  </AlertText>
                ))
              )
            }
            {
              internalErrors && (
                internalErrors.map((error) => (
                  <AlertText key={error} severity="error">
                    {error}
                  </AlertText>
                ))
              )
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default DataTransactionBox;
