import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  GridCellParams,
  GridOptions
} from '@material-ui/data-grid';
import CancelIcon from '@material-ui/icons/Cancel';
import DataGridWithLoading, { ResponsiveGridColDef } from 'components/DataGrid';
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  PAGINATION_PAGE_SIZES
} from 'config';
import { InvitationDto } from 'providers/api';
import { DEFAULT_LONG_DATE_TIME_CONFIG, displayTemporal } from 'utils';

interface InvitationsTableProps {
  data: InvitationDto[] | undefined;
  page: number;
  pageSize: number;
  totalItems: number | undefined;
  onPageChange: GridOptions['onPageChange'];
  onPageSizeChange: GridOptions['onPageSizeChange'];
  onRevoke: (invite: InvitationDto) => void;
  loading: boolean;
  loadingNew: boolean;
  searchTerm: string | null;
}

const InvitationTable = ({
  data,
  onPageChange,
  onPageSizeChange,
  onRevoke,
  loadingNew,
  loading,
  page,
  pageSize = PAGINATION_DEFAULT_PAGE_SIZE,
  totalItems = 0,
  searchTerm,
}: InvitationsTableProps) => {
  const pageIndex = data ? (page - 1) : 0;
  const rows = data ?? [];

  const handleClick = (invite: InvitationDto) => () => {
    onRevoke(invite);
  };

  const columns: ResponsiveGridColDef[] = [
    {
      field: 'createdUtc',
      headerName: 'Sent',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      mobileConfig: {
        width: 300,
        isVisible: true,
      },
      valueFormatter: ({ value }) => (value ? displayTemporal(value as Date, DEFAULT_LONG_DATE_TIME_CONFIG) : ''),
    },
    {
      field: 'email',
      headerName: 'Email',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 2,
      mobileConfig: {
        width: 300,
        isVisible: true,
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      resizable: false,
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: ({ row }: GridCellParams) => {
        const invite = (row as any) as InvitationDto;

        if (invite.acceptedUtc) {
          const tooltip = `Accepted ${displayTemporal(invite.acceptedUtc, DEFAULT_LONG_DATE_TIME_CONFIG)} UTC`;
          return (
            <Tooltip title={tooltip}>
              <Chip label="Accepted" color="primary" />
            </Tooltip>
          );
        }

        if (invite.revokedUtc) {
          const tooltip = `Revoked ${displayTemporal(invite.revokedUtc, DEFAULT_LONG_DATE_TIME_CONFIG)} UTC`;
          return (
            <Tooltip title={tooltip}>
              <Chip label="Revoked" color="secondary" />
            </Tooltip>
          );
        }

        return <Chip label="Sent" />;
      },
    },
    {
      field: '',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 80,
      renderCell: ({ row }: GridCellParams) => {
        const invite = (row as any) as InvitationDto;

        if (!!invite.acceptedUtc || !!invite.revokedUtc) {
          return <></>;
        }

        return (
          <Tooltip title="Revoke Invite">
            <IconButton color="inherit" onClick={handleClick(invite)}>
              <CancelIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <DataGridWithLoading
      loading={loading}
      loadingNew={loadingNew}
      searchTerm={searchTerm}
      idProp="invitationId"
      searchFields={['email']}
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      rows={rows}
      columns={columns}
      pagination
      paginationMode="server"
      page={pageIndex}
      pageSize={pageSize}
      rowCount={totalItems}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={PAGINATION_PAGE_SIZES}
    />
  );
};

export default InvitationTable;
