import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PeopleIcon from '@material-ui/icons/People';
import TimelineIcon from '@material-ui/icons/Timeline';
import Page from 'modules/Page';
import makePage from 'modules/makePage';
import * as parentConstants from 'modules/project/ProjectsPage/constants';
import { toTitleCase } from 'utils';
import withNavigation from '../../withNavigation';
import withLicenceAndDisclaimer from '../withLicenceAndDisclaimer';
import ProjectDetailsPage from './ProjectDetailsPage';
import ProjectReportsPage from './ProjectReportsPage';
import ProjectStopesPage from './ProjectStopesPage';
import * as constants from './constants';
import { ProjectAreaPageParams } from './constants';
import useProjectAreaQueries, { ProjectAreaQueries } from './useProjectAreaQueries';

const page: Page<constants.ProjectAreaPageParams> = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: false,
  },
  authorise: (context, params) => ({
    handler: context.functions.canViewTeam,
    teamId: params.entityId,
  }),
  component: withLicenceAndDisclaimer({
    projectIdIkey: 'entityId',
    Component: withNavigation({
      pageDataHook: useProjectAreaQueries,
      Component: makePage<ProjectAreaPageParams, [], ProjectAreaQueries>({
        titleFn: ([projectQuery]) => (projectQuery.data ? toTitleCase(projectQuery.data.name) : ''),
        parentPathTemplate: parentConstants.ROUTE_PATH,
        parentTitleFn: () => parentConstants.TITLE,
      }),
      defaultRoute: constants.DETAILS_ROUTE_PATH,
      pageNavigation: [
        {
          title: constants.DETAILS_TITLE,
          iconComponent: PeopleIcon,
          routeProps: {
            path: constants.DETAILS_ROUTE_PATH,
            exact: true,
          },
          Component: ProjectDetailsPage,
        },
        {
          title: constants.STOPES_TITLE,
          iconComponent: AccountTreeIcon,
          routeProps: {
            path: constants.STOPES_ROUTE_PATH,
            exact: false,

          },
          Component: ProjectStopesPage,
        },
        {
          title: constants.REPORTS_TITLE,
          iconComponent: TimelineIcon,
          routeProps: {
            path: constants.REPORTS_ROUTE_PATH,
            exact: true,

          },
          Component: ProjectReportsPage,
        },
      ],
    }),
  }),
};

export default page;
