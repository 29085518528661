import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import { BackfillWarningDto } from 'providers/api';
import { is } from 'ramda';

const useStyles = makeStyles((theme: Theme) => createStyles({
  warningIcon: {
    color: theme.palette.warning.main,
  },
}));

interface WarningBadgeProps {
  warnings: BackfillWarningDto[] | string[];
}

const WarningBadge = ({ warnings }: WarningBadgeProps) => {
  const classes = useStyles();

  if (warnings.length === 0) {
    return <CheckCircleOutlineIcon />;
  }

  return (
    <Tooltip title={
      warnings.map((warning) => (
        <Box>
          {is(String, warning) ? warning : (warning as BackfillWarningDto).display}
        </Box>
      ))
    }
    >
      <Badge badgeContent={warnings.length} color="secondary">
        <WarningIcon className={classes.warningIcon} />
      </Badge>
    </Tooltip>
  );
};

export default WarningBadge;
