import { GridOptions } from '@material-ui/data-grid/';
import DataGridWithLoading, { ResponsiveGridColDef } from 'components/DataGrid';
import {
  PAGINATION_DEFAULT_PAGE_SIZE,
  PAGINATION_PAGE_SIZES
} from 'config';
import { UserDto } from 'providers/api';

interface UsersTableProps {
  data: UserDto[] | undefined;
  page: number;
  pageSize: number;
  totalItems: number | undefined;
  onSelect: (project: UserDto) => void;
  onPageChange: GridOptions['onPageChange'];
  onPageSizeChange: GridOptions['onPageSizeChange'];
  loading: boolean;
  loadingNew: boolean;
  searchTerm: string | null;
}

const UsersTable = ({
  data,
  onPageChange,
  onPageSizeChange,
  onSelect,
  loadingNew,
  loading,
  page,
  pageSize = PAGINATION_DEFAULT_PAGE_SIZE,
  totalItems = 0,
  searchTerm,
}: UsersTableProps) => {
  const pageIndex = data ? (page - 1) : 0;
  const rows = data ?? [];

  const columns: ResponsiveGridColDef[] = [
    {
      field: 'forename',
      headerName: 'First Name',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      mobileConfig: {
        width: 200,
        isVisible: true,
      },
    },
    {
      field: 'surname',
      headerName: 'Last Name',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      mobileConfig: {
        width: 200,
        isVisible: true,
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 2,
      mobileConfig: {
        width: 300,
        isVisible: true,
      },
    },
  ];

  return (
    <DataGridWithLoading
      loading={loading}
      loadingNew={loadingNew}
      searchTerm={searchTerm}
      searchFields={['forename', 'surname', 'email']}
      idProp="userId"
      onRowClick={(row) => onSelect((row.row as any) as UserDto)}
      disableColumnMenu
      autoHeight
      rows={rows}
      columns={columns}
      pagination
      paginationMode="server"
      page={pageIndex}
      pageSize={pageSize}
      rowCount={totalItems}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={PAGINATION_PAGE_SIZES}
    />
  );
};

export default UsersTable;
