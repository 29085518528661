import { useContext } from 'react';
import ProjectContext, { IProjectContext } from './context';

export default function useProject(): IProjectContext {
  const context = useContext(ProjectContext);
  if (context === null) {
    throw new Error('No project context available');
  }
  return context;
}
