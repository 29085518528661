import { HOUR_IN_MILLISECONDS } from 'config';
import {
  MineModelDto,
  PourDto,
  ProjectDto,
  useMineModelQuery, usePourQuery,
  useProjectQuery
} from 'providers/api';
import { UseQueryResult } from 'react-query';
import { PourDetailsPageParams } from './constants';

export type PourDetailsQueries = [
  UseQueryResult<PourDto>,
  UseQueryResult<ProjectDto>,
  UseQueryResult<MineModelDto | undefined>,
];

const usePourDetailsQueries = (params: PourDetailsPageParams): PourDetailsQueries => [
  usePourQuery(params.pourId, {
    staleTime: HOUR_IN_MILLISECONDS,
  }),
  useProjectQuery(params.projectId, {
    staleTime: HOUR_IN_MILLISECONDS,
  }),
  useMineModelQuery(params.projectId, {
    staleTime: HOUR_IN_MILLISECONDS,
  }),
];

export default usePourDetailsQueries;
