import makePage from 'modules/makePage';
import Page from 'modules/Page';
import * as parentConstants from 'modules/user-admin/UsersAreaPage/constants';
import * as constants from './constants';
import { UserDetailsPageParams } from './constants';
import UserDetailsPage from './UserDetailsPage';
import useUserDetailsQueries, { UserDetailsQueries } from './useUserDetailsQueries';

const page: Page = {
  title: constants.TITLE,
  routeProps: {
    path: constants.ROUTE_PATH,
    exact: true,
  },
  admin: true,
  component: makePage<UserDetailsPageParams, UserDetailsQueries>({
    WrappedPage: UserDetailsPage,
    titleFn: ([user]) => (user.data ? `${user.data.forename} ${user.data.surname}` : ''),
    parentPathTemplate: parentConstants.ROUTE_PATH,
    parentTitleFn: () => parentConstants.TITLE,
    pageDataHook: useUserDetailsQueries,
  }),
};

export default page;
