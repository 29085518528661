import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import * as childConstants from 'modules/user-admin/NewUserPage/constants';
import { Link } from 'react-router-dom';

const InviteUserButton = () => (
  <>
    <Hidden xsDown>
      <Button
        aria-label="add new user"
        variant="contained"
        color="primary"
        size="large"
        startIcon={<MailIcon />}
        component={Link}
        to={childConstants.ROUTE_PATH}
      >
        Invite User
      </Button>
    </Hidden>
    <Hidden smUp>
      <IconButton
        aria-label="add new user"
        color="primary"
        component={Link}
        to={childConstants.ROUTE_PATH}
      >
        <MailIcon />
      </IconButton>
    </Hidden>
  </>
);

export default InviteUserButton;
